import React, { useState } from "react";
import {
	Text,
	SimpleGrid,
	Select,
	Tooltip,
	Button,
	Center,
	Space,
	TextInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SelectListItem } from "../../data/types/addEmployees";
import { Help } from "tabler-icons-react";
import {
	PensionFund,
	PensionFundExtra,
} from "../../data/types/formTemplates/employeeForm";

function PensionFundsComponent({
	pensionFunds,
	extraPensionFunds,
	formik,
}: {
	pensionFunds: SelectListItem[];
	extraPensionFunds: SelectListItem[];
	formik: any;
}) {
	const { t } = useTranslation();

	const initialPrivateSaving = {
		IsPercentage: true,
		FundID: 0,
		EmployeePercentage: 2,
		EmployerPercentage: 4,
	};

	const changePensionFundType = (index: number, IsPercentage: boolean) => {
		setPrivateSavings(
			privateSavings.map((ps, idx) => {
				if (index === idx) {
					ps.IsPercentage = IsPercentage;
				}
				return ps;
			})
		);
	};

	const changeSpecialPensionFundType = (
		index: number,
		isPercentage: boolean
	) => {
		let specialPensionFunds = formik.values.specialPensionFundExtraSettings.map(
			(ps: PensionFundExtra, idx: number) => {
				if (index === idx) {
					ps.isPercentage = isPercentage;
				}
				return ps;
			}
		);
		formik.setFieldValue(
			"specialPensionFundExtraSettings",
			specialPensionFunds
		);
	};

	const { setFieldValue, handleChange, values } = formik;

	const [privateSavings, setPrivateSavings] = useState<PensionFund[]>([]);
	const personalSavings = [...privateSavings, initialPrivateSaving];
	const addPersonalSaving = () => {
		setPrivateSavings(personalSavings);
		setFieldValue("specialPensionFundExtra", personalSavings);
	};

	const link = {
		display: "flex",
		marginTop: "5px",
		justifyContent: "flex-end",
		cursor: "pointer",
	};

	return (
		<div>
			<Text size="lg">{t("add_employees.pension.title")}</Text>
			<div style={link}>
				<Text
					component="a"
					variant={values.settings.isPensionFundPercentage ? "text" : "link"}
					onClick={() => {
						// setPensionFundType("%");
						setFieldValue("settings.isPensionFundPercentage", true);
					}}>
					{t("add_employees.pension.percentage")}
				</Text>
				<Space w="sm" />
				<Text
					component="a"
					variant={values.settings.isPensionFundPercentage ? "link" : "text"}
					onClick={() => {
						setFieldValue("settings.isPensionFundPercentage", false);
					}}>
					{t("add_employees.pension.fixed_amount")}
				</Text>
			</div>
			<SimpleGrid
				cols={3}
				breakpoints={[
					{ maxWidth: 980, cols: 3, spacing: "md" },
					{ maxWidth: 755, cols: 1, spacing: "sm" },
				]}>
				<Select
					searchable
					spellCheck="false"
					label={
						<>
							{t("add_employees.pension.statutory_pension_fund")}
							&nbsp;
							<Tooltip
								withArrow
								width={220}
								wrapLines
								label={t(
									"add_employees.pension.statutory_pension_fund_tootltip"
								)}>
								<Help size={16} />
							</Tooltip>
						</>
					}
					data={pensionFunds}
					name="settings.pensionFundId"
					value={values.settings.pensionFundId.toString()}
					onChange={(value) => setFieldValue("settings.pensionFundId", value)}
				/>

				<TextInput
					label={t("add_employees.pension.employee")}
					rightSection={values.settings.isPensionFundPercentage ? "%" : "ISK"}
					type="number"
					name="settings.pensionFundPercentageEmployee"
					onChange={handleChange}
					value={values.settings.pensionFundPercentageEmployee}
					sx={() => ({
						input: {
							textAlign: "right",
						},
					})}
				/>
				<TextInput
					label={t("add_employees.pension.salary_payer")}
					rightSection={values.settings.isPensionFundPercentage ? "%" : "ISK"}
					type="number"
					name="settings.pensionFundPercentageCompany"
					onChange={handleChange}
					value={values.settings.pensionFundPercentageCompany}
					sx={() => ({
						input: {
							textAlign: "right",
						},
					})}
				/>
			</SimpleGrid>
			<Space h="md" />
			<div style={link}>
				<Text
					component="a"
					variant={
						values.settings.isSpecialPensionFundPercentage ? "text" : "link"
					}
					onClick={() => {
						setFieldValue("settings.isSpecialPensionFundPercentage", true);
					}}>
					{t("add_employees.pension.percentage")}
				</Text>
				<Space w="sm" />
				<Text
					component="a"
					variant={
						values.settings.isSpecialPensionFundPercentage ? "link" : "text"
					}
					onClick={() => {
						setFieldValue("settings.isSpecialPensionFundPercentage", false);
					}}>
					{t("add_employees.pension.fixed_amount")}
				</Text>
			</div>
			<SimpleGrid
				cols={3}
				breakpoints={[{ maxWidth: 755, cols: 1, spacing: "sm" }]}>
				<Select
					searchable
					spellCheck="false"
					label={t("add_employees.pension.private_savings")}
					data={pensionFunds}
					name="settings.specialPensionFundId"
					value={values.settings.specialPensionFundId.toString()}
					onChange={(value) =>
						setFieldValue("settings.specialPensionFundId", value)
					}
				/>

				<TextInput
					label={t("add_employees.pension.employee")}
					rightSection={
						values.settings.isSpecialPensionFundPercentage ? "%" : "ISK"
					}
					type="number"
					name="settings.specialPensionFundPercentageEmployee"
					onChange={handleChange}
					value={values.settings.specialPensionFundPercentageEmployee}
					sx={() => ({
						input: {
							textAlign: "right",
						},
					})}
				/>
				<TextInput
					label={t("add_employees.pension.salary_payer")}
					rightSection={
						values.settings.isSpecialPensionFundPercentage ? "%" : "ISK"
					}
					type="number"
					name="settings.specialPensionFundPercentageCompany"
					onChange={handleChange}
					value={values.settings.specialPensionFundPercentageCompany}
					sx={() => ({
						input: {
							textAlign: "right",
						},
					})}
				/>
			</SimpleGrid>
			{privateSavings.map((privateSaving, index) => (
				<div key={index}>
					<div style={link}>
						<Text
							component="a"
							variant={privateSaving.IsPercentage ? "text" : "link"}
							onClick={() => {
								changePensionFundType(index, true);
							}}>
							{t("add_employees.pension.percentage")}
						</Text>
						<Space w="sm" />
						<Text
							component="a"
							variant={privateSaving.IsPercentage ? "link" : "text"}
							onClick={() => {
								changePensionFundType(index, false);
							}}>
							{t("add_employees.pension.fixed_amount")}
						</Text>
					</div>

					<SimpleGrid
						cols={3}
						breakpoints={[
							{ maxWidth: 980, cols: 3, spacing: "md" },
							{ maxWidth: 755, cols: 1, spacing: "sm" },
						]}>
						<Select
							searchable
							spellCheck="false"
							label={t("add_employees.pension.private_savings")}
							data={extraPensionFunds}
							value={values.specialPensionFundExtra[index]?.FundID.toString()}
							onChange={(value) =>
								setFieldValue(`specialPensionFundExtra[${index}].FundID`, value)
							}
						/>
						<TextInput
							label={t("add_employees.pension.employee")}
							rightSection={privateSaving.IsPercentage ? "%" : "ISK"}
							type="number"
							name={`specialPensionFundExtra[${index}].EmployeePercentage`}
							onChange={handleChange}
							value={values.specialPensionFundExtra[index]?.EmployeePercentage}
							sx={() => ({
								input: {
									textAlign: "right",
								},
							})}
						/>
						<TextInput
							label={t("add_employees.pension.salary_payer")}
							rightSection={privateSaving.IsPercentage ? "%" : "ISK"}
							type="number"
							name={`specialPensionFundExtra[${index}].EmployerPercentage`}
							onChange={handleChange}
							value={values.specialPensionFundExtra[index]?.EmployerPercentage}
							sx={() => ({
								input: {
									textAlign: "right",
								},
							})}
						/>
					</SimpleGrid>
				</div>
			))}

			{values.specialPensionFundExtraSettings.map(
				(extraSpecial: PensionFundExtra, index: number) => (
					<div key={index}>
						<div style={link}>
							<Text
								component="a"
								variant={extraSpecial.isPercentage ? "text" : "link"}
								onClick={() => {
									changeSpecialPensionFundType(index, true);
								}}>
								{t("add_employees.pension.percentage")}
							</Text>
							<Space w="sm" />
							<Text
								component="a"
								variant={extraSpecial.isPercentage ? "link" : "text"}
								onClick={() => {
									changeSpecialPensionFundType(index, false);
								}}>
								{t("add_employees.pension.fixed_amount")}
							</Text>
						</div>

						<SimpleGrid
							cols={3}
							breakpoints={[
								{ maxWidth: 980, cols: 3, spacing: "md" },
								{ maxWidth: 755, cols: 1, spacing: "sm" },
							]}>
							<Select
								label=" "
								searchable
								spellCheck="false"
								data={extraPensionFunds}
								value={values.specialPensionFundExtraSettings[
									index
								]?.fundID.toString()}
								onChange={(value) =>
									setFieldValue(
										`specialPensionFundExtraSettings[${index}].fundID`,
										value
									)
								}
							/>
							<TextInput
								label={t("add_employees.pension.employee")}
								rightSection={extraSpecial.isPercentage ? "%" : "ISK"}
								type="number"
								name={`specialPensionFundExtraSettings[${index}].EmployeePercentage`}
								onChange={handleChange}
								value={
									values.specialPensionFundExtraSettings[index]
										?.employeePercentage
								}
								sx={() => ({
									input: {
										textAlign: "right",
									},
								})}
							/>
							<TextInput
								label={t("add_employees.pension.salary_payer")}
								rightSection={extraSpecial.isPercentage ? "%" : "ISK"}
								type="number"
								name={`specialPensionFundExtraSettings[${index}].EmployerPercentage`}
								onChange={handleChange}
								value={
									values.specialPensionFundExtraSettings[index]
										?.employerPercentage
								}
								sx={() => ({
									input: {
										textAlign: "right",
									},
								})}
							/>
						</SimpleGrid>
					</div>
				)
			)}

			<Center mt={5} mb={12}>
				<Button onClick={addPersonalSaving}>
					{t("add_employees.pension.add_personal_savings")}
				</Button>
			</Center>
		</div>
	);
}
export default PensionFundsComponent;
