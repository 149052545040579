import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";

import { CompanyData, Charts } from "../types/companyData";

interface CompanyDataState {
	id: number;
	chartData?: Charts;
	isLoading: boolean;
	error: string;
}

const initialState: CompanyDataState = {
	id: 0,
	chartData: undefined,
	isLoading: false,
	error: "",
};

export const companyDataSlice = createSlice({
	name: "companydata",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action: PayloadAction<CompanyData>) => {
			state.id = action.payload.id;
			state.chartData = action.payload.chartData;
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } = companyDataSlice.actions;

export const fetchCompanyData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/company/get/main/${currentCompanyId}`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response: AxiosResponse<CompanyData, any>) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export default companyDataSlice.reducer;
export const selectCompanyData = (state: RootState) => state.companydata;
