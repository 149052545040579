import React, { useEffect, useState } from "react";
import setDefaultOptions from "date-fns/setDefaultOptions";
import { is } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { LaunaLoadingOverlay } from "../common/loadingOverlay";
import axios from "axios";
import { getCurrentCompanyId } from "../../../helpers/user";
import { PayslipBatchWithBasePayslipData } from "../../../data/types/payslipBatches";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";
import { Grid, Text, Title, Space, Table } from "@mantine/core";
import { format } from "date-fns";
import { Amount } from "../common/currency";

export default function PayslipBatchView(props: {
	batchId: number;
	// onDeleted: () => any;
}) {
	setDefaultOptions({ locale: is });
	const { t } = useTranslation();
	const [loadingData, setLoadingData] = useState(true);
	const companyId = getCurrentCompanyId();
	const [data, setData] = useState<PayslipBatchWithBasePayslipData | null>(
		null
	);

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/payslips/batch/${companyId}/single/${props.batchId}`
			)
			.then((response) => {
				console.log("response", response);
				setData(response.data.data);
				setLoadingData(false);
			})
			.catch((err) => {
				console.log("error", err);
				showNotification({
					autoClose: false,
					title: "Villa",
					message: "Villa kom upp við að sækja gögn",
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
				setLoadingData(false);
			});
	}, [companyId, props.batchId]);

	return (
		<>
			<LaunaLoadingOverlay visible={loadingData} />
			{data && (
				<>
					<Title order={1}>{data.batchName}</Title>
					<Grid columns={3} mt="xl" align="flex-end">
						<Grid.Col md={1}>
							<Text weight="bold">{t("payslip_batch.list.period")}</Text>
							<Text>
								{format(
									new Date(
										data.year +
											"-" +
											data.month.toString().padStart(2, "0") +
											"-01T00:00"
									),
									"MMMM yyyy"
								)}
								({data.month}-{data.year})
							</Text>
						</Grid.Col>
						<Grid.Col md={1}>
							<Text weight="bold">{t("payslip_batch.list.created")}</Text>
							<Text>
								{format(new Date(data.created), "d. MMMM yyyy - HH:mm")}
							</Text>
						</Grid.Col>
						<Grid.Col md={1}>
							<Text weight="bold">{t("payslip_batch.list.payslip_count")}</Text>
							<Text>{data.payslipCount}</Text>
						</Grid.Col>
					</Grid>
					<Space h="lg" />
					<Title order={2}>{t("payslips.Payslips")}</Title>
					{data.payslips.length > 0 && (
						<Table mt={"lg"}>
							<thead>
								<tr>
									<th>
										{t(
											"payslip_batch.single_batch_view.table_headers.employee"
										)}
									</th>
									<th>
										{t(
											"payslip_batch.single_batch_view.table_headers.registration_id"
										)}
									</th>
									<th style={{ textAlign: "right" }}>
										{t(
											"payslip_batch.single_batch_view.table_headers.salary_total"
										)}
									</th>
									<th style={{ textAlign: "right" }}>
										{t(
											"payslip_batch.single_batch_view.table_headers.salary_to_pay"
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.payslips.map((payslip) => {
									return (
										<tr key={payslip.id}>
											<td>{payslip.employeeName}</td>
											<td>{payslip.employeeRegistrationId}</td>
											<td style={{ textAlign: "right" }}>
												<Amount value={payslip.salaryTotal} />
											</td>
											<td style={{ textAlign: "right" }}>
												<Amount value={payslip.salarytoPay} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					)}
				</>
			)}
		</>
	);
}
