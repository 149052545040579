import React, { MouseEventHandler } from "react";
import { Text, Group, UnstyledButton, Avatar } from "@mantine/core";

import { BasicEmployeeData } from "../../../data/types/employee";
import EmployeeMenu from "./employeeMenu";
import { formatRegistrationId } from "../../../helpers/functions";

interface Props {
	employee: BasicEmployeeData;
	onEmployeeSelect?: MouseEventHandler;
	deleteEmployee?: (id: number) => void;
	deactivateEmployee?: (id: number) => void;
	activateEmployee?: (id: number) => void;
	showPayslips?: (id: number) => void;
}

function EmployeeList(props: Props) {
	const employee = props.employee;

	return (
		<tr>
			<td>
				<Group>
					<Avatar src={employee.photo} radius="xl" />
					<UnstyledButton
						sx={{ "&:hover": { textDecoration: "underline" } }}
						onClick={props.onEmployeeSelect}>
						<Text size="lg" weight={500}>
							{employee.fullName}
						</Text>
					</UnstyledButton>
				</Group>
			</td>
			<td align="center" color="dimmed">
				{formatRegistrationId(employee.registrationId)}
			</td>
			<td>{employee.companyRole}</td>
			<td>
				<EmployeeMenu
					onEmployeeSelect={props.onEmployeeSelect}
					employee={employee}
					deleteEmployee={props.deleteEmployee}
					deactivateEmployee={props.deactivateEmployee}
					activateEmployee={props.activateEmployee}
					showPayslips={props.showPayslips}
				/>
			</td>
		</tr>
	);
}

export default EmployeeList;
