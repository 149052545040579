import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Group, SimpleGrid, Space, Text } from "@mantine/core";
import { format } from "date-fns";
import { Amount } from "../../components/ui/common/currency";
import { CardView } from "../../components/ui/common/sharedComponents";
import { BookedData } from "../../data/types/payslip";
import { ActionButton } from "../../components/ui/buttons";
import exportFromJSON from "export-from-json";
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useForm } from "@mantine/form";
interface reportDataContentValueProps {
	description: string;
	amount: number;
	debitkey: number;
	creditkey: number;
	origin: string;
	unicontaCompany: string;
	journal: string;
}

interface reportDataContentProps {
	code: number;
	val: reportDataContentValueProps;
}
export default function ReportsData({
	payslipData,
}: {
	payslipData: BookedData;
}) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const pathName = window.location.pathname;
	const pathLength = window.location.pathname.split("/").length;
	const curentId = Number(pathName.split("/")[pathLength - 1]);
	const reportData = JSON.parse(JSON.stringify(payslipData)).salaryProcessDatas;
	const printRef = useRef(null);
	const doc = new jsPDF();
	const form = useForm({
		initialValues: {
			ID: curentId,
		},
	});
	const tableHeaderTranslations: string[] = t(
		"salary_process_view.reporting_table_header",
		{
			returnObjects: true,
		}
	);

	const pdf = (file_name: any) => {
		const headers = ["description", "debitkey", "creditkey", "amount"];
		const tableRow: any = [];
		payslipData.salaryProcessDatas.forEach((tabelRow: any) => {
			const data = Object.values(JSON.parse(tabelRow.content));
			data.forEach((elemetData: any) => {
				let tableCell: any = [];
				headers.forEach((tableHeader: any) => {
					tableCell.push(elemetData.val[tableHeader]);
				});
				tableRow.push(tableCell);
			});
		});
		autoTable(doc, {
			theme: "grid",
			head: [tableHeaderTranslations],
			body: tableRow,
		});

		doc.save(`${file_name}.pdf`);
	};
	const exportTxtData = (exportData: any, file_name: string) => {
		const data = exportData;
		const fileName = `${file_name}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.txt;
		return exportFromJSON({ data, fileName, exportType });
	};

	const downloadPaymnetRegister = () => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/salaryprocess/get/paymentfile/si080/${curentId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					exportTxtData(
						response.data,
						`${t("common.payment_register")}_si080${curentId}`
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	return (
		<>
			{reportData &&
				reportData.map((elemetData: any, index: number) => {
					let content = JSON.parse(elemetData.content);
					let contentValue: reportDataContentProps[] = Object.values(content);
					return (
						<div key={index}>
							<Group position="right">
								<ActionButton
									onClick={() => {
										pdf(`${t("common.report_data")}`);
									}}
								>
									{
										t("salary_process_view.reporting_button", {
											returnObjects: true,
										})[0]
									}
								</ActionButton>
								<ActionButton
									onClick={() => {
										downloadPaymnetRegister();
									}}
									loading={loading}
								>
									{
										t("salary_process_view.reporting_button", {
											returnObjects: true,
										})[1]
									}
								</ActionButton>
							</Group>
							<CardView
								value={
									<CardView
										value={
											<div
												id="my-table"
												ref={printRef}
												style={{
													padding: "12px",
												}}
											>
												<Group direction={"column"}>
													<Text size="md">
														<b>{elemetData.origin}</b>
														<br />
														{format(
															new Date(elemetData.created),
															"dd,MMMM,yyyy hh:mm"
														)}
													</Text>
												</Group>

												<Space h={"xl"} />

												<SimpleGrid cols={4} spacing={"lg"} pb={"xs"}>
													<Text>{tableHeaderTranslations[0]}</Text>
													<Text>{tableHeaderTranslations[1]}</Text>
													<Text>{tableHeaderTranslations[2]}</Text>
													<Text>{tableHeaderTranslations[3]}</Text>
												</SimpleGrid>
												{contentValue.map(
													(
														accountingData: reportDataContentProps,
														id: number
													) => (
														<SimpleGrid key={id}>
															<Divider size={"xs"} />
															<SimpleGrid cols={4} pb={"xs"}>
																<Text>{accountingData.val.description}</Text>
																<Text>
																	{
																		<Amount
																			value={accountingData.val.debitkey}
																		/>
																	}
																</Text>
																<Text>
																	{
																		<Amount
																			value={accountingData.val.creditkey}
																		/>
																	}
																</Text>
																<Text>
																	{<Amount value={accountingData.val.amount} />}
																</Text>
															</SimpleGrid>
														</SimpleGrid>
													)
												)}
												<Divider />
												<Group p={"xl"}>
													<form
														onSubmit={form.onSubmit((values) => {
															setButtonLoading(true);
															axios
																.post(
																	`${process.env.REACT_APP_API_URL}/salaryprocess/postbook/uniconta`,
																	values,
																	{
																		headers: {
																			Accept: "application/json",
																			"Content-Type": "application/json",
																		},
																	}
																)
																.then(function (response) {
																	setButtonLoading(false);
																})
																.catch(function (error) {});
														})}
													>
														<ActionButton
															onClick={() => {}}
															type="submit"
															loading={buttonLoading}
														>
															{
																t("salary_process_view.reporting_button", {
																	returnObjects: true,
																})[2]
															}
														</ActionButton>
													</form>
												</Group>
											</div>
										}
									/>
								}
							/>
						</div>
					);
				})}
		</>
	);
}
