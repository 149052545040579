import { Company } from "../data/types/userData";

export enum APP_ENV {
	PROD = "prod",
	STAGING = "staging",
}

function appEnvironment(): APP_ENV {
	return process.env.REACT_APP_ENV?.toLowerCase() === "staging"
		? APP_ENV.STAGING
		: APP_ENV.PROD;
}

function isProd(): boolean {
	return appEnvironment() === APP_ENV.PROD;
}

function isAuthenticated(): boolean {
	const storageUser = localStorage.getItem("paasDashboardUser");

	return storageUser !== null;
}

function isUserInApp(location: string): boolean {
	return location.startsWith("/app");
}

function isAppInBeta(): boolean {
	return process.env.REACT_APP_BETA_PREVIEW === "true";
}

function isUserBetaPreview(): boolean {
	const storageUserBetaPreview = localStorage.getItem("userBetaPreview");
	const isUserBetaPreviewSet: boolean =
		storageUserBetaPreview !== null && storageUserBetaPreview === "true";

	return isAppInBeta() && isUserBetaPreviewSet;
}

function setUserBetaPreview() {
	localStorage.setItem("userBetaPreview", "true");
}

function unSetUserBetaPreview() {
	localStorage.removeItem("userBetaPreview");
}

function logOut() {
	localStorage.removeItem("passDashboardLastView");
	localStorage.removeItem("paasDashboardUser");
	window.location.reload();
}

function getCurrentCompanyId(): number {
	const storageId = localStorage.getItem("launa.client.currentcompany.id");

	if (storageId !== null) {
		return Number.parseInt(storageId);
	}
	return -1;
}

function setCurrentCompanyId(id: string) {
	localStorage.setItem("launa.client.currentcompany.id", id);
}

function getEmployeeListView(): string {
	const storageVal = localStorage.getItem("launa.client.employee.listview");

	if (storageVal !== null) {
		return storageVal;
	}
	return "CARD";
}

function setEmployeeListView(view: string) {
	localStorage.setItem("launa.client.employee.listview", view);
}

//TODO: type Company
function getCurrentCompany(companies: any): Company | null {
	if (companies && companies.length > 0) {
		let currCompId = getCurrentCompanyId();
		let comp =
			currCompId === -1
				? companies[0]
				: companies.filter((c: any) => {
						return c.id === currCompId;
				  });

		if (comp.length < 1) return null;

		return comp[0];
	}

	return null;
}

export {
	appEnvironment,
	isProd,
	isAuthenticated,
	isUserInApp,
	isAppInBeta,
	isUserBetaPreview,
	setUserBetaPreview,
	unSetUserBetaPreview,
	logOut,
	getCurrentCompanyId,
	setCurrentCompanyId,
	getCurrentCompany,
	getEmployeeListView,
	setEmployeeListView,
};
