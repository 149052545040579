import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { SalaryProcessSimpleModel } from "./models";
import { DataStatus } from "../types/shared";
interface SalaryProcessDataState {
	data: SalaryProcessSimpleModel[];
	companyId: number;
	status: DataStatus;
}
const initialState: SalaryProcessDataState = {
	data: [] as SalaryProcessSimpleModel[],
	companyId: 0,
	status: DataStatus.NOT_FETCHED,
};
export const salaryProcessSlice = createSlice({
	name: "salaryProcessSlice",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.status = DataStatus.FETCHING;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.status = DataStatus.ERROR;
		},
		dataLoaded: (state, action) => {
			if (!action.payload.success) {
				state.status = DataStatus.ERROR;
			} else {
				state.data = action.payload.data;
				state.companyId = getCurrentCompanyId();
				if (!state.data || state.data.length === 0)
					state.status = DataStatus.EMPTY;
				else state.status = DataStatus.FETCHED;
			}
		},
		dataAdded: (state, action: PayloadAction<SalaryProcessSimpleModel>) => {
			//add the incoming data to the front of state.data
			state.data = [action.payload, ...state.data];
			state.status = DataStatus.NEEDS_UPDATE;
		},
		dataDeleted: (state, action: PayloadAction<number>) => {
			state.data = state.data.filter(
				(salaryProcess) => salaryProcess.id !== action.payload
			);
			state.status = DataStatus.NEEDS_UPDATE;
		},
		setState: (state, action: PayloadAction<DataStatus>) => {
			state.status = action.payload;
		},
	},
});
export const {
	startLoading,
	hasError,
	dataLoaded,
	dataAdded,
	dataDeleted,
	setState,
} = salaryProcessSlice.actions;
//thunk function for async api call
export const fetchSalaryProcessData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/api/v2/company/${currentCompanyId}/salaryprocesses`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error) => {
			dispatch(hasError(JSON.parse(error.request.responseText).Message));
		});
};

export const addSalaryProcessData =
	(data: SalaryProcessSimpleModel) =>
	(dispatch: Dispatch<PayloadAction<SalaryProcessSimpleModel>>) => {
		dispatch(dataAdded(data));
	};

export const setSalaryProcessState =
	(state: DataStatus) => (dispatch: Dispatch<PayloadAction<DataStatus>>) => {
		dispatch(setState(state));
	};

export default salaryProcessSlice.reducer;
export const selectSalaryProcessData = (state: RootState) =>
	state.salaryProcessData;
