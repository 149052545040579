import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { PayslipsCreateData } from "../types/payslipsCreate";
interface CreatePyslipsDataState {
	data: PayslipsCreateData;
	companyId: number;
	isLoading: boolean;
	error: boolean;
}

const initialState: CreatePyslipsDataState = {
	data: {} as PayslipsCreateData,
	companyId: 0,
	isLoading: false,
	error: false,
};

export const payslipsCreateData = createSlice({
	name: "payslipsCreateData",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.data = action.payload;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } =
	payslipsCreateData.actions;

//thunk function for async api call
export const fetchPayslipsCreateData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/api/v2/company/${currentCompanyId}/salaryprocess/data`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error) => {
			console.log("error fetching payslips create data");
			dispatch(hasError(JSON.parse(error.request.responseText).Message));
		});
};

export default payslipsCreateData.reducer;
export const selectPayslipsCreateData = (state: RootState) =>
	state.payslipsCreateData;
