import React from "react";
import { EmployeePayslip } from "../../../../data/types/payslip";
import { ActionIcon, Group, Table, Title, Tooltip } from "@mantine/core";
import { t } from "react-i18nify";
import { format } from "date-fns";
import { Amount } from "../../common/currency";
import { Search, Send } from "tabler-icons-react";

export default function EmployeePayslipsView({
	payslips,
	fetchPayslip,
	setSendModalOpened,
	setPayslipToSend,
}: {
	payslips: EmployeePayslip[];
	fetchPayslip: (id: number) => void;
	setSendModalOpened: (state: boolean) => void;
	setPayslipToSend: (state: EmployeePayslip) => void;
}) {
	return payslips.length > 0 ? (
		<div>
			<Title order={2}>
				{`${t("employees.payslips")}: ${payslips[0].employeeName}`}
			</Title>
			<Table>
				<thead>
					<tr>
						<th>#</th>
						<th>{t("employees.time_period")}</th>
						<th></th>
						<th>{t("employees.total_salary")}</th>
						<th>{t("employees.salary_paid")}</th>
					</tr>
				</thead>
				<tbody>
					{payslips.map((payslip) => (
						<tr key={payslip.id}>
							<td>{payslip.id}</td>
							<td>{payslip.yearMonth}</td>
							<td>{`${format(
								new Date(payslip.periodFrom),
								"dd/MM/yyyy"
							)} - ${format(new Date(payslip.periodTo), "dd/MM/yyyy")}`}</td>
							<td>
								<Amount value={payslip.salaryTotal} />
							</td>
							<td>
								<Amount value={payslip.salaryToPay} />
							</td>
							<td>
								<Group>
									<ActionIcon onClick={() => fetchPayslip(payslip.id)}>
										<Tooltip label={t("employees.view_payslip")}>
											<Search />
										</Tooltip>
									</ActionIcon>
									<ActionIcon
										onClick={() => {
											setPayslipToSend(payslip);
											setSendModalOpened(true);
										}}>
										<Tooltip label={t("employees.send_payslip")}>
											<Send />
										</Tooltip>
									</ActionIcon>
								</Group>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	) : null;
}
