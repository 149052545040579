import React from "react";
import { Helmet } from "react-helmet-async";

export function PublicHtmlHeader() {
	return (
		<Helmet>
			<title>Ástæðulaust að flækja hlutina | launa</title>
			<meta
				name="description"
				content="launa einfaldar launavinnsluna og sparar þér tíma. Ástæðulaust að flækja hlutina."
			/>
		</Helmet>
	);
}
