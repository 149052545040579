import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "../../../../hooks/useCountdown";
import "./countdownTimer.css";

const CountdownTimer = ({ targetDate }) => {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return null; //<ExpiredNotice />;
	} else {
		return (
			<ShowCounter
				days={days}
				hours={hours}
				minutes={minutes}
				seconds={seconds}
			/>
		);
	}
};

const ExpiredNotice = () => {
	return (
		<div className="expired-notice">
			<span>Expired!!!</span>
			<p>Please select a future date and time.</p>
		</div>
	);
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
	const dayLabel = days === 1 ? "Dagur" : "Dagar";
	return (
		<div className="show-counter">
			<div className="countdown-wrapper">
				{/* isDanger={days <= 3} */}
				<DateTimeDisplay value={days} type={dayLabel} isDanger={false} />
				<p>:</p>
				<DateTimeDisplay value={hours} type={"Klst"} isDanger={false} />
				<p>:</p>
				<DateTimeDisplay value={minutes} type={"Mín"} isDanger={false} />
				<p>:</p>
				<DateTimeDisplay value={seconds} type={"Sek"} isDanger={false} />
			</div>
		</div>
	);
};

export default CountdownTimer;
