import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../ui/buttons";
import axios from "axios";
import { Amount } from "../ui/common/currency";
import { TableData, tableHeaderColumn } from "../ui/common/tableData";
import { CardView, GroupView } from "../ui/common/sharedComponents";
import { RegistrationId } from "../ui/common/registrationId";
import { useForm } from "@mantine/form";
import {
	Text,
	Title,
	Grid,
	Textarea,
	Select,
	MultiSelect,
	NumberInput,
	SimpleGrid,
} from "@mantine/core";
import { IconChevronDown, IconCheck, IconExclamationMark } from "@tabler/icons";
import { ListItem } from "../../data/types/shared";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCurrentCompanyId } from "../../helpers/user";
import { selectUser } from "../../data/user/userSlice";
import { showNotification } from "@mantine/notifications";
import {
	fetchSalaryIncreaseData,
	selectSalaryCreateData,
} from "../../data/salaryIncrease/salaryIncreaseCreateData";
import {
	Department,
	Employee,
	EmployeeGroup,
	SalaryElement,
} from "../../data/types/payslipsCreate";
export default function SalaryChangeForm() {
	const { t } = useTranslation();
	const userData = useAppSelector(selectUser);
	const salaryIncreaseCreateData = useAppSelector(selectSalaryCreateData);
	const dispatch = useAppDispatch();
	var currentDate = new Date();
	const form_data = useForm({
		initialValues: {
			departments: [],
			groups: [],
			elements: [],
			employees: [],
			type: t("salary_increase.type_value", {
				returnObjects: true,
			})[0],
			value: 5,
			companyId: getCurrentCompanyId(),
			description: "",
		},
	});
	const [employeeList, setEmployeeList] = useState<ListItem[]>([]);
	const [departmentList, setDepartmentList] = useState<ListItem[]>([]);
	const [salaryGroupList, setSalaryGroupList] = useState<ListItem[]>([]);
	const [salaryItemList, setSalaryItemList] = useState<ListItem[]>([]);
	const [butoon_loading_state, setButtonLoadingState] = useState(false);
	const [loading_state, setLoadingState] = useState(false);
	const [rows, setRows] = useState([]);
	const [isPercent, setIspercent] = useState(true);
	const [salaryData, setSalaryData] = useState<any>();
	const tableHeaderTranslations: string[] = t("salary_increase.table_header", {
		returnObjects: true,
	});
	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[3] },
		{ text: tableHeaderTranslations[4] },
	];
	useEffect(() => {
		function shouldFetchData(companyId: number, isLoading: boolean): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			if (userHasAccess && currentCompanyId !== companyId && !isLoading)
				return true;
			return false;
		}
		if (
			shouldFetchData(
				salaryIncreaseCreateData.companyId,
				salaryIncreaseCreateData.isLoading
			)
		) {
			dispatch(fetchSalaryIncreaseData());
		}
	}, [
		dispatch,
		userData.user.companies,
		salaryIncreaseCreateData.companyId,
		salaryIncreaseCreateData.isLoading,
	]);
	useEffect(() => {
		if (salaryIncreaseCreateData.data.employees) {
			setEmployeeList([]);
			setSalaryGroupList([]);
			setSalaryItemList([]);
			setDepartmentList([]);
			salaryIncreaseCreateData.data.employees.forEach((element: Employee) => {
				setEmployeeList((prev) => {
					return [
						...prev,
						{ value: element.id.toString(), label: element.fullName },
					];
				});
			});
			salaryIncreaseCreateData.data.departments.forEach(
				(element: Department) => {
					setDepartmentList((prev) => {
						return [
							...prev,
							{
								value: element.id.toString(),
								label: element.name,
							},
						];
					});
				}
			);
			salaryIncreaseCreateData.data.employeeGroups.forEach(
				(element: EmployeeGroup) => {
					setSalaryGroupList((prev) => {
						return [
							...prev,
							{
								value: element.id.toString(),
								label: element.name,
							},
						];
					});
				}
			);
			salaryIncreaseCreateData.data.salaryElements.forEach(
				(element: SalaryElement) => {
					setSalaryItemList((prev) => {
						return [
							...prev,
							{
								value: element.id.toString(),
								label: element.description,
							},
						];
					});
				}
			);
		}
	}, [salaryIncreaseCreateData]);
	const salaryCalculate = function (formValue: any) {
		setLoadingState(true);
		let type_value = isPercent ? formValue.value / 100 : formValue.value;
		const employeFilterParm: any = formValue;
		let salaryValue = [];
		let employeeSalaryProcesse: any = {
			companyId: formValue.companyId,
			type:
				formValue.type ===
				t("salary_increase.type_value", {
					returnObjects: true,
				})[0]
					? 1
					: 2,
			value: formValue.value,
			description: formValue.description,
		};
		const filteredEmploye: any = salaryIncreaseCreateData.data.employees.filter(
			(item: Employee) => {
				if (
					employeFilterParm.employees.length > 0 &&
					employeFilterParm.employees.includes(item.id.toString())
				)
					return item;
				else if (employeFilterParm.employees.length === 0) return item;
			}
		);
		for (let i = 0; i < filteredEmploye.length; i++) {
			for (let j = 0; j < filteredEmploye[i].salaryElements.length; j++) {
				salaryValue.push({
					salaryElements: [
						{
							id: filteredEmploye[i].salaryElements[j].id,
							created: filteredEmploye[i].salaryElements[j].created,
							units: filteredEmploye[i].salaryElements[j].units,
							value: filteredEmploye[i].salaryElements[j].value,
							description: filteredEmploye[i].salaryElements[j].description,
							salaryElementType:
								filteredEmploye[i].salaryElements[j].salaryElementType,
							salaryElementValueType:
								filteredEmploye[i].salaryElements[j].salaryElementValueType,
							isCalcTaxed: filteredEmploye[i].salaryElements[j].isCalcTaxed,
							isCalcInsuranceFee:
								filteredEmploye[i].salaryElements[j].isCalcInsuranceFee,
							isCalcPension: filteredEmploye[i].salaryElements[j].isCalcPension,
							isCalcUnion: filteredEmploye[i].salaryElements[j].isCalcUnion,
							isCalcHoliday: filteredEmploye[i].salaryElements[j].isCalcHoliday,
							isCalcHolidayPaid:
								filteredEmploye[i].salaryElements[j].isCalcHolidayPaid,
							alagPercentage:
								filteredEmploye[i].salaryElements[j].alagPercentage,
							isBaseForAlag: filteredEmploye[i].salaryElements[j].isBaseForAlag,
							baseId: filteredEmploye[i].salaryElements[j].baseId,
							isHourlyUnit: filteredEmploye[i].salaryElements[j].isHourlyUnit,
						},
					],
				});
			}
		}
		employeeSalaryProcesse["employees"] = salaryValue;
		setSalaryData(employeeSalaryProcesse);
		if (salaryIncreaseCreateData.data.employees) {
			var row: any = salaryIncreaseCreateData.data.employees
				.filter((item: Employee) => {
					if (
						employeFilterParm.employees.length > 0 &&
						employeFilterParm.employees.includes(item.id.toString())
					)
						return item;
					else if (employeFilterParm.employees.length === 0) return item;
				})
				.map((element: Employee) =>
					element.salaryElements.map((salary: SalaryElement, id: number) => (
						<tr key={id}>
							<td>{element.fullName}</td>
							<td>
								<RegistrationId value={element.registrationId} />
							</td>
							<td>{salary.description}</td>
							<td>
								<Amount value={salary.value} />
							</td>
							<td>
								{isPercent
									? salary.value * type_value + salary.value
									: salary.value + type_value}
							</td>
						</tr>
					))
				);
			setRows(row);
		} else {
			setRows([]);
		}
	};
	return (
		<>
			<CardView
				value={
					<>
						<SimpleGrid mb={"lg"}>
							<Title order={2}>
								{t("salary_increase.salary_increase_header")}
							</Title>
							<Text>
								{t("salary_increase.salary_increase_header_description")}
							</Text>
						</SimpleGrid>
						<form
							onSubmit={form_data.onSubmit((values) => {
								salaryCalculate(values);
							})}>
							<Grid columns={4}>
								<Grid.Col md={1} sm={4}>
									<MultiSelect
										label={t("salary_increase.departments")}
										placeholder={t("salary_increase.departments_placeholder")}
										data={departmentList}
										searchable
										limit={20}
										rightSection={<IconChevronDown size={14} />}
										{...form_data.getInputProps("departments")}
									/>
								</Grid.Col>
								<Grid.Col md={1}>
									<MultiSelect
										label={t("salary_increase.salary_groups")}
										placeholder={t("salary_increase.salary_groups_placeholder")}
										data={salaryGroupList}
										searchable
										limit={20}
										rightSection={<IconChevronDown size={14} />}
										{...form_data.getInputProps("groups")}
									/>
								</Grid.Col>
								<Grid.Col md={1}>
									<MultiSelect
										label={t("salary_increase.payroll_items")}
										placeholder={t("salary_increase.payroll_items_placeholder")}
										data={salaryItemList}
										searchable
										limit={20}
										rightSection={<IconChevronDown size={14} />}
										{...form_data.getInputProps("elements")}
									/>
								</Grid.Col>
								<Grid.Col md={1}>
									<MultiSelect
										data={employeeList}
										label={t("salary_increase.employees")}
										placeholder={t("salary_increase.employees_placeholder")}
										searchable
										limit={20}
										rightSection={<IconChevronDown size={14} />}
										{...form_data.getInputProps("employees")}
									/>
								</Grid.Col>
							</Grid>
							<Grid columns={4}>
								<Grid.Col md={1}>
									<Select
										placeholder={
											t("salary_increase.type_value", {
												returnObjects: true,
											})[0]
										}
										rightSection={<IconChevronDown size={14} />}
										label={t("salary_increase.type")}
										data={t("salary_increase.type_value", {
											returnObjects: true,
										})}
										onChange={(typeValue: string) => {
											t("salary_increase.type_value", {
												returnObjects: true,
											})[0] === typeValue
												? setIspercent(true)
												: setIspercent(false);
										}}
									/>
								</Grid.Col>
								<Grid.Col md={1}>
									<NumberInput
										label={t("salary_increase.increase_decrease")}
										rightSectionWidth={42}
										defaultValue={5}
										rightSection={isPercent ? <p>%</p> : <p>ISK</p>}
										{...form_data.getInputProps("value")}
										styles={{ input: { textAlign: "right" } }}
									/>
								</Grid.Col>
								<Grid.Col md={2} sm={5}>
									<Textarea
										placeholder={
											t("salary_increase.explanation_placeholder") +
											" " +
											t("payslips.months", { returnObjects: true })[
												currentDate.getMonth()
											] +
											" " +
											currentDate.getFullYear() +
											"...."
										}
										label={t("salary_increase.explanation")}
										{...form_data.getInputProps("description")}
									/>
								</Grid.Col>
							</Grid>
							<GroupView
								value={
									<PrimaryButton type="submit">
										{t("salary_increase.claculateSalaryincreasebutton")}
									</PrimaryButton>
								}
								position={"right"}
							/>
						</form>
					</>
				}
			/>
			<>
				{loading_state && (
					<CardView
						value={
							<form
								onSubmit={form_data.onSubmit(() => {
									setButtonLoadingState(true);
									axios
										.post(
											`${process.env.REACT_APP_API_URL}/salary/history/create`,
											salaryData,
											{
												headers: {
													Accept: "application/json",
													"Content-Type": "application/json",
												},
											}
										)
										.then(function (response) {
											if (response.status === 200) {
												setButtonLoadingState(false);
												showNotification({
													title: "",
													message: t(
														"salary_increase.response_success_message"
													),
													autoClose: true,
													disallowClose: false,
													icon: <IconCheck size={16} />,
												});
											}
										})
										.catch((error) => {
											setButtonLoadingState(false);
											error.response?.data?.errors?.forEach((err: any) => {
												showNotification({
													autoClose: true,
													disallowClose: false,
													title: t("salary_increase.response_error_message"),
													message: err.message,
													color: "red",
													icon: <IconExclamationMark size={16} />,
												});
											});
										});
								})}>
								<TableData tableHeader={tableHeaders} tableBody={rows} />
								{rows.length > 0 ? (
									<GroupView
										value={
											<PrimaryButton
												type="submit"
												loading={butoon_loading_state}>
												{t("salary_increase.saveSalaryincreasebutton")}
											</PrimaryButton>
										}
										position={"right"}
									/>
								) : (
									""
								)}
							</form>
						}
					/>
				)}
			</>
		</>
	);
}
