import React from "react";
import {
	Card,
	Container,
	Group,
	Modal,
	Paper,
	createStyles,
	Title,
	Text,
} from "@mantine/core";
import { CloudOff } from "tabler-icons-react";
import { useNavigate } from "react-router";
export function GenericError(props: { errorMessage: string }) {
	return props.errorMessage !== null && props.errorMessage.length > 0 ? (
		<div>error occurred: {props.errorMessage}</div>
	) : null;
}

const noDataStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},
	icon: {
		textAlign: "center",
	},
	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: "center",
		fontWeight: 900,
		fontSize: 24,

		[theme.fn.smallerThan("sm")]: {
			fontSize: 16,
		},
	},
	linkSection: {
		textAlign: "center",
		cursor: "pointer",
		marginTop: theme.spacing.md,
		fontSize: 16,
	},
}));

export function NoData(props: {
	visible: boolean;
	message: string;
	routeText?: string;
	route?: string;
	onclick?: () => void;
}) {
	const { classes } = noDataStyles();
	const navigate = useNavigate();

	return props.visible ? (
		<Container className={classes.root}>
			<div className={classes.icon}>
				<CloudOff size={80} />
			</div>
			<Title className={classes.title}>{props.message}</Title>
			{props.routeText ? (
				<Text
					className={classes.linkSection}
					onClick={() => {
						if (props.onclick) props.onclick();
						else navigate(props.route as string);
					}}>
					{props.routeText}
				</Text>
			) : null}
		</Container>
	) : null;
}

export function CardView(props: { value: any }) {
	return (
		<Card p="md" radius="md" mb={"lg"}>
			{props.value}
		</Card>
	);
}
export function PaperView(props: { value: any }) {
	return (
		<Paper p="md" radius="md" mb={"lg"}>
			{props.value}
		</Paper>
	);
}
export function GroupView(props: { value: any; position: any }) {
	return (
		<Group p="md" mb={"lg"} position={props.position}>
			{props.value}
		</Group>
	);
}

interface DrawerViewProps {
	children: string | JSX.Element;
	opened: boolean;
	onClose: any;
	title?: any | "";
	shadow?: string | "xl";
}

export function ModalView(props: DrawerViewProps) {
	return (
		<Modal
			padding={"xl"}
			{...props}
			radius={"md"}
			size="100%"
			overflow="inside">
			{props.children}
		</Modal>
	);
}
