import { Avatar, Button, Center, Group, Space } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import React, { useRef, useState } from "react";
import { t } from "react-i18nify";
import { ExclamationMark } from "tabler-icons-react";
import { getCurrentCompanyId } from "../helpers/user";
import { LaunaLoadingOverlay } from "./ui/common/loadingOverlay";

export default function CompanyLogo({ logoUrl }: { logoUrl: string }) {
	const openRef = useRef(() => {});
	const reader = new FileReader();
	const [src, setSrc] = useState(logoUrl);
	const [visible, setVisible] = useState(false);

	function upload(base64string: string | undefined) {
		setVisible(true);
		const logoData = {
			ID: getCurrentCompanyId(),
			base64string,
		};
		axios
			.post(`${process.env.REACT_APP_API_URL}/company/upload/logo`, logoData)
			.then(function (response) {
				if (response.status === 200) {
					setSrc(response.data.Message);
				}
				setVisible(false);
			})
			.catch((error) => {
				console.log(error.response.data);
				showNotification({
					autoClose: false,
					title: "Villa",
					message: error.response.data.message,
					color: "red",
					icon: <ExclamationMark size={16} />,
				});
				setVisible(false);
			});
	}

	return (
		<div>
			<LaunaLoadingOverlay
				visible={visible}
				label={t("settings.logo.loading")}
			/>
			<Center>
				<Avatar src={src} size="xl" alt="Logo" />
				<Dropzone
					sx={{ visibility: "hidden" }}
					onDrop={(files) => {
						reader.onload = () => {
							upload(reader.result?.toString().split(";base64,")[1]);
						};
						reader.readAsDataURL(files[0]);
					}}
					onReject={(files) => console.log("rejected files", files)}
					//may be size restrictions
					maxSize={3 * 1024 ** 2}
					accept={IMAGE_MIME_TYPE}
					openRef={openRef}
				>
					{() => null}
				</Dropzone>
			</Center>
			<Group position="center" mt="md">
				<Button onClick={() => openRef.current()}>
					{t("settings.logo.select")}
				</Button>
			</Group>
		</div>
	);
}
