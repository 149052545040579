import React from "react";
import {
	formatDateString,
	formatDateTimeString,
	formatDateWithMonthName,
} from "../../../helpers/functions";
import { format } from "date-fns";

function Date(props: { value: string; showTime?: boolean }) {
	return props.showTime ? (
		<div>{formatDateTimeString(props.value)}</div>
	) : (
		<div>{formatDateString(props.value)}</div>
	);
}

function DateFull(props: { date: Date }) {
	return <div>{formatDateWithMonthName(props.date)}</div>;
}

function DateRange(props: { from: string; to: string }) {
	return (
		<div>
			{formatDateString(props.from)} - {formatDateString(props.to)}
		</div>
	);
}

export { Date, DateFull, DateRange };
