import {
	AnyAction,
	createSlice,
	Dispatch,
	PayloadAction,
} from "@reduxjs/toolkit";
import { Notification } from "../types/userData";

interface NotificationsState {
	data: Array<Notification>;
	isLoading: boolean;
	error: boolean;
}

const initialState: NotificationsState = {
	data: [],
	isLoading: false,
	error: false,
};

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		initialNotifications: (state, action) => {
			action.payload.map((n: Notification) => state.data.push(n));
			state.isLoading = false;
		},
		newNotification: (state, action: PayloadAction<string>) => {
			state.data.unshift(JSON.parse(action.payload));
		},
		markRead: (state, action) => {
			const id = action.payload;
			const notification = state.data.find((item) => item.id === id);
			if (notification) notification.isRead = true;
		},
		removeNotification: (state, action: PayloadAction<number>) => {
			state.data = state.data.filter((n) => n.id !== action.payload);
		},
	},
});

export const {
	startLoading,
	initialNotifications,
	newNotification,
	markRead,
	removeNotification,
} = notificationsSlice.actions;

export const notificationReceived =
	(notification: string) => (dispatch: Dispatch<AnyAction>) => {
		dispatch(newNotification(notification));
	};

export const notificationRead =
	(id: number) => (dispatch: Dispatch<AnyAction>) => {
		dispatch(markRead(id));
	};

export const notificationRemoved =
	(id: number) => (dispatch: Dispatch<AnyAction>) => {
		dispatch(removeNotification(id));
	};

export default notificationsSlice.reducer;
export const getNotifications = (state: any) => state.notifications;
