import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../app/store";
import { getCurrentCompanyId } from "../../helpers/user";
import { EmployeeFormData, Data } from "../types/employeeFormData";
import { DataStatus } from "../types/shared";

const initialState: EmployeeFormData = {
	data: {
		departments: [],
		employeeGroups: [],
		salaryTables: [],
		unions: [],
		pensionfunds: [],
		salaryElements: [],
		holidayDayOptions: [],
		companyRoles: [],
		id: 0, //this is the company ID
		created: "",
		deleted: "",
		registrationId: "",
		name: "",
		address: "",
		address2: "",
		city: "",
		region: "",
		postalCode: "",
		country: "",
		email: "",
		phone: "",
		companyLogoUrl: "",
		companyLogoBase64: "",
		connections: "",
		companySettings: "",
		companySubscription: "",
		isInActive: false,
		chartData: null,
		servicePartyId: 0,
		ownerId: 0,
	},
	isLoading: false,
	status: DataStatus.NOT_FETCHED,
	error: "",
};

export const employeeCreateDataSlice = createSlice({
	name: "employeecreatedata",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
			state.status = DataStatus.FETCHING;
		},
		hasError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.isLoading = false;
			state.status = DataStatus.EMPTY;
		},
		addCompanyRole: (state, action: PayloadAction<string>) => {
			state.data.companyRoles = [...state.data.companyRoles, action.payload];
		},
		dataLoaded: (state, action: PayloadAction<Data>) => {
			state.data = action.payload;
			state.isLoading = false;
			state.status = DataStatus.FETCHED;
		},
	},
});

export const { startLoading, hasError, dataLoaded, addCompanyRole } =
	employeeCreateDataSlice.actions;

export const fetchEmployeeCreateData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	axios
		.get(
			`${
				process.env.REACT_APP_API_URL
			}/company/getcreateemployee/data/${getCurrentCompanyId()}`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response: AxiosResponse<Data, any>) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export default employeeCreateDataSlice.reducer;
export const selectEmployeeCreateData = (state: RootState) =>
	state.employeeCreate;
