import React from "react";

import {
	ThemeIcon,
	Text,
	Title,
	Container,
	SimpleGrid,
	createStyles,
	Button,
	Group,
} from "@mantine/core";
import {
	TablerIcon,
	IconCalculator,
	IconMail,
	IconPlugConnected,
	IconCalendarTime,
	IconCloudUpload,
	IconClock,
	IconCheck,
} from "@tabler/icons";

export const MOCKDATA = [
	{
		icon: IconCalculator,
		title: "Launaútreikningar",
		description:
			"Reiknar bæði mánaðarlaun og tímalaun án takmarkana á fjölda starfsmanna og kjarasamninga. Sjálfvirkur útreikningur orlofsskuldbindinga, álags og réttinda.",
	},
	{
		icon: IconMail,
		title: "Launaseðlar",
		description:
			"Útprentun og útsending launaseðla í tölvupósti eða rafrænt í heimabanka.",
	},
	{
		icon: IconPlugConnected,
		title: "Samþættingar",
		description:
			"Tengist Uniconta, Microsoft Dynamics 365 Business Central og Microsoft Dynamics Nav, en getur unnið eitt og sér óháð fjárhagskerfum.",
	},
	{
		icon: IconCalendarTime,
		title: "Greiðslubeiðnir",
		description: "Rafrænar greiðslubeiðnir í banka.",
	},
	{
		icon: IconCloudUpload,
		title: "Skilagreinar",
		description:
			"Rafræn skil á gögnum til RSK, lífeyrissjóða og stéttarfélaga.",
	},
	{
		icon: IconClock,
		title: "Tímaskráningar",
		description:
			"Skrá má tíma beint í launavinnslunni eða lesa inn með Excel- og CSV-skrá.",
	},
];

const useStyles = createStyles((theme) => ({
	wrapper: {
		// paddingTop: "8px",//theme.spacing.xl,
		// paddingBottom: "8px",//theme.spacing.xl,
		padding: "32px",
		borderRadius: "8px",
		backgroundColor: "white",
	},

	title: {
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",
		fontWeight: 900,
		marginBottom: theme.spacing.md,
		textAlign: "center",

		[theme.fn.smallerThan("sm")]: {
			fontSize: 28,
			textAlign: "left",
		},
	},

	description: {
		textAlign: "center",

		[theme.fn.smallerThan("sm")]: {
			textAlign: "left",
		},
	},
	featureHeader: {
		fontSize: "1.5em",
		color: "#66615b",
		fontWeight: 300,
		marginBottom: "16px",
		display: "inline-block",
		marginLeft: "8px",
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",
		"@media (max-width: 520px)": {
			textAlign: "left",
			fontSize: theme.fontSizes.md,
		},
	},
	featureDescription: {
		marginLeft: "50px",
		lineHeight: 1.6,
	},
}));

interface FeatureProps {
	icon: TablerIcon;
	title: React.ReactNode;
	description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
	const { classes } = useStyles();
	return (
		<div>
			<ThemeIcon size={40} radius={40}>
				<Icon size={20} stroke={1.5} />
			</ThemeIcon>
			<Title className={classes.featureHeader}>{title}</Title>
			<Text size="sm" color="dimmed" className={classes.featureDescription}>
				{description}
			</Text>
		</div>
	);
}

interface FeaturesGridProps {
	title: React.ReactNode;
	description: React.ReactNode;
	data?: FeatureProps[];
	subscriptionData?: any[];
}

export function FeaturesGrid({
	data = MOCKDATA,
	subscriptionData,
}: FeaturesGridProps) {
	const { classes, theme } = useStyles();
	const features = data.map((feature, index) => (
		<Feature {...feature} key={index} />
	));
	const chips = subscriptionData?.map((el: any, index) => {
		if (el.col2 && el.col2.toString() === "true") {
			const chipId = "chip-" + index;
			return (
				<>
					<Button key={chipId} leftIcon={<IconCheck />}>
						{el.text}
					</Button>
				</>
			);
		} else {
			return null;
		}
	});
	return (
		<Container className={classes.wrapper} size={1200}>
			<div
				style={{ marginBottom: "80px", color: "#66615b", textAlign: "center" }}>
				Áskrift að launa kostar aðeins <strong>4.000 kr. á mánuði</strong> fyrir
				1-5 virka launþega og 400 kr. á virkan launþega eftir það.
			</div>
			<SimpleGrid
				cols={2}
				spacing={theme.spacing.xl * 2}
				breakpoints={[
					{ maxWidth: 980, cols: 2, spacing: "xl" },
					{ maxWidth: 755, cols: 1, spacing: "xl" },
				]}>
				{features}
			</SimpleGrid>
			<div style={{ marginTop: "80px" }}></div>
			<Group position="center">{chips}</Group>
		</Container>
	);
}
