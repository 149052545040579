import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../components/ui/buttons";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import {
	Title,
	Text,
	Card,
	Group,
	SimpleGrid,
	Select,
	MultiSelect,
	Table,
	TextInput,
	Skeleton,
	Paper,
	Grid,
	Modal,
	Tabs,
	useMantineTheme,
	Center,
	Button,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
	IconCheck,
	IconChevronDown,
	IconExclamationMark,
	IconFile3d,
} from "@tabler/icons";
import "dayjs/locale/is";
import { DataStatus, ListItem } from "../data/types/shared";
import { getCurrentCompanyId } from "../helpers/user";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectUser } from "../data/user/userSlice";
import {
	fetchPayslipsCreateData,
	selectPayslipsCreateData,
} from "../data/payslips/payslipsCreateData";
import {
	Department,
	Employee,
	EmployeeGroup,
} from "../data/types/payslipsCreate";
import {
	Payslip,
	SalaryElementEditState,
	SimpleSalaryElementPropsExt,
} from "../data/types/payslip";
import { Amount } from "../components/ui/common/currency";
import { RegistrationId } from "../components/ui/common/registrationId";
import { format } from "date-fns";
import PayslipView from "../components/ui/shared/payslip/payslipView";
import { CalculatePayslipError } from "../data/types/errorTypes/calculatePayslipError";
import { AlertTriangle, List, Pencil } from "tabler-icons-react";
import { downloadZip } from "client-zip";
import PayslipViewReadOnly from "../components/ui/shared/payslip/readonly/payslipViewReadOnly";
import { LaunaLoadingOverlay } from "../components/ui/common/loadingOverlay";
import {
	fetchPayslipBatchesData,
	selectPayslipBatchesData,
} from "../data/payslips/payslipBatchesData";
import { NoData } from "../components/ui/common/sharedComponents";
import PayslipBatchesList from "../components/ui/payslipBatches/payslipBatchesList";
import {
	fetchCompanySalaryElementData,
	selectCompanySalaryElementData,
} from "../data/payslips/companySalaryElementsSlice";
import ErrorBoundary from "../components/ui/shared/errors/ErrorBoundary";
import {
	getPayslipEditorState,
	currentPayslipSet,
	payslipsSet,
	salaryElementsSet,
	payslipsEditedSet,
	yearMonthSet,
	salaryElementEditUndo,
} from "../data/payslips/payslipViewSlice";
import { ReCalculatePayslipPayloadSalaryElement } from "../data/types/payslipsCreate";
import { RemovedItem } from "../data/types/payslipsCreate";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

//TODO: extract these interfaces to the /types folder ...
interface ValidationError {
	entity: string;
	message: string;
}

interface PayslipBlob {
	name: string;
	lastModified: Date;
	input: string;
}

interface UpdatePayslipModel {
	periodStart: Date;
	periodEnd: Date;
	yearMonth: string;
	employeeId: number;
	guid: string;
	salaryElements: ReCalculatePayslipPayloadSalaryElement[];
	removedItems: RemovedItem[];
}

interface PayslipBatchPostData {
	companyId: number;
	batchName: string;
	year: number;
	month: number;
	periodStart: Date;
	periodEnd: Date;
	payDay: Date;
	employeeIds: number[];
	updatePayslipModels: UpdatePayslipModel[];
	removedItems: RemovedItem[];
}

interface PayslipsBatchCreateResponse {
	success: boolean;
	message?: string;
	innerMessage?: string;
}

export default function Payslips() {
	//TODO: remove this and any reference to isDebug once debugging is no longer needed
	const [searchParams, setSearchParams] = useSearchParams();
	const isDebug = searchParams.get("debug") === "true";
	const [readOnlyPayslips, setReadOnlyPayslips] = useState<JSX.Element[]>([]);

	const theme = useMantineTheme();
	const userData = useAppSelector(selectUser);
	const payslipsCreateData = useAppSelector(selectPayslipsCreateData);
	const payslipBatches = useAppSelector(selectPayslipBatchesData);
	const dispatch = useAppDispatch();
	const skeleton_hight = 20;
	const [skeletonLength, setSkeletonLength] = useState(3);
	const { t, i18n } = useTranslation();
	const [select_employee, setSelectEmployee] = useState(false);
	const [butoon_loading_state, setButtonLoadingState] = useState(false);
	const [loading_state, setLoadingState] = useState(false);
	var currentDate = new Date();
	const year = [
		"" + (currentDate.getFullYear() - 1) + "",
		"" + currentDate.getFullYear() + "",
		"" + (currentDate.getFullYear() + 1) + "",
	];
	const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth() + 1);
	const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
	const [firstDay, setFirstDay] = useState(
		new Date(currentYear, currentMonth - 1, 1)
	);
	const [lastDay, setLastDay] = useState(
		new Date(currentYear, currentMonth, 0)
	);
	const [openModal, setOpenModal] = useState(false);

	const [savingPayslips, setSavingPayslips] = useState(false);
	const [savingLabel, setSavingLabel] = useState<string>("");
	const [currentBatchListYear, setCurrentBatchListYear] = useState(
		new Date().getFullYear()
	);

	const payslipBlobs: PayslipBlob[] = [];
	const payslipEditorCache = useAppSelector(getPayslipEditorState);
	const baseSalaryElementData = useAppSelector(selectCompanySalaryElementData);
	const [payslipInModalIsEdited, setPayslipInModalIsEdited] = useState(false);
	const [
		confirmClosePayslipEditorModalOpen,
		setConfirmClosePayslipEditorModalOpen,
	] = useState(false);

	const setPayslips = (payslips: Payslip[], yearMonth: string) => {
		dispatch(payslipsSet(payslips));
		dispatch(yearMonthSet(yearMonth));
	};

	const navigate = useNavigate();

	const { tab, id } = useParams();
	const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>(
		undefined
	);

	useEffect(() => {
		if (id) {
			const batchId = parseInt(id);
			setSelectedBatchId(batchId);
		} else {
			setSelectedBatchId(undefined);
		}
	}, [id]);

	const getTabFromUrl = () => {
		if (tab) {
			switch (tab) {
				case "list":
					return 1;
				case "create":
				default:
					return 0;
			}
		}
	};

	const setTab = (indx: number) => {
		setActiveTab(indx);
		switch (indx) {
			case 1:
				navigate("/app/payslips/list");
				return;
			case 0:
			default:
				navigate("/app/payslips/create");
				return;
		}
	};

	const [activeTab, setActiveTab] = useState(getTabFromUrl);

	useEffect(() => {
		setActiveTab(getTabFromUrl);
	}, [tab]);

	useEffect(() => {
		if (
			payslipEditorCache.payslipsEdited &&
			payslipEditorCache.salaryElements.filter(
				(el) => el.editState !== SalaryElementEditState.UNCHANGED
			).length > 0
		)
			setPayslipInModalIsEdited(true);
		else setPayslipInModalIsEdited(false);
	}, [payslipEditorCache.payslipsEdited, payslipEditorCache.salaryElements]);

	useEffect(() => {
		if (
			baseSalaryElementData.status === DataStatus.NOT_FETCHED ||
			baseSalaryElementData.status === DataStatus.NEEDS_UPDATE
		) {
			dispatch(fetchCompanySalaryElementData());
		}
	}, [baseSalaryElementData.status, dispatch]);

	async function setCurrentPayslip(payslip: Payslip) {
		dispatch(currentPayslipSet(payslip.guid));
		// store the initial salary elements in payslipViewSlice redux store
		const elements: SimpleSalaryElementPropsExt[] =
			payslip.paySlipSalaryElements
				.filter(
					// showing types: 0 = salary, 2 = perks, 4 = holiday pay
					(el) =>
						el.salaryElementType === 0 ||
						el.salaryElementType === 2 ||
						el.salaryElementType === 4
				)
				.map((el) => {
					return {
						id: el.baseId,
						rate: el.baseUnitValue,
						originalRate: el.baseUnitValue,
						description: el.description,
						originalDescription: el.description,
						units: el.units,
						originalUnits: el.units,
						value: el.value,
						originalValue: el.value,
						editState: SalaryElementEditState.UNCHANGED,
						canEdit: el.salaryElementType !== 4,
						employeeId: payslip.employee.id,
					};
				});
		dispatch(salaryElementsSet(elements));
	}

	async function triggerSavePayslips() {
		setSavingPayslips(true);
		setSavingLabel(t("payslips.loading_status.writing_payslips"));
		const employeeIds: number[] = [];
		//TODO: remove this once debugging is no longer needed
		const _readOnlyPayslips: JSX.Element[] = [];

		payslipEditorCache.payslips.forEach((payslip: Payslip) => {
			const payslipView = (
				<PayslipViewReadOnly
					key={payslip.guid}
					payslipData={payslip}
					payDay={format(form_data.values.SalaryPayDay, "yyyy-MM-dd")}
					logo={payslipsCreateData.data.logo}
				/>
			);

			if (isDebug) _readOnlyPayslips.push(payslipView);

			const html = renderToString(payslipView);

			payslipBlobs.push({
				name: payslip.guid + ".html",
				lastModified: new Date(),
				input: html,
			});
			employeeIds.push(payslip.employee.id);
		});

		setReadOnlyPayslips(_readOnlyPayslips);

		let payslipBatchPostData: PayslipBatchPostData = {
			companyId: payslipsCreateData.companyId,
			batchName: form_data.values.BatchName,
			year: currentYear,
			month: currentMonth,
			periodStart: form_data.values.PeriodStarts,
			periodEnd: form_data.values.PeriodEnds,
			payDay: form_data.values.SalaryPayDay,
			employeeIds: employeeIds,
			updatePayslipModels: [],
			removedItems: [],
		};

		const recalculatedElements =
			payslipEditorCache.allEditedSalaryElements.filter(
				(el) =>
					el.editState === SalaryElementEditState.EDITED ||
					el.editState === SalaryElementEditState.NEW ||
					el.editState === SalaryElementEditState.DELETED
			);

		//recalculatedEmployeeIds needs to be unique
		let recalculatedEmployeeIds = recalculatedElements.map(
			(el) => el.employeeId
		);

		recalculatedEmployeeIds = recalculatedEmployeeIds.filter(
			(value, index, self) => self.indexOf(value) === index
		);

		if (recalculatedEmployeeIds.length > 0) {
			recalculatedEmployeeIds.forEach((employeeId) => {
				const thisEmployeeElements = recalculatedElements.filter(
					(el) => el.employeeId === employeeId
				);
				payslipBatchPostData.updatePayslipModels.push({
					periodStart: firstDay,
					periodEnd: lastDay,
					yearMonth: currentYear + "-" + currentMonth,
					employeeId: employeeId,
					guid: thisEmployeeElements[0].payslipGuid || "",
					salaryElements: thisEmployeeElements
						.filter(
							(f) =>
								f.editState === SalaryElementEditState.NEW ||
								SalaryElementEditState.EDITED
						)
						.map((el) => {
							return {
								employeeId: el.employeeId,
								baseId: el.id,
								units: el.units,
								value: el.value,
								baseUnitValue: el.rate,
								description: el.description,
							};
						}),
					removedItems: thisEmployeeElements
						.filter((f) => f.editState === SalaryElementEditState.DELETED)
						.map((el) => {
							return {
								employeeId: el.employeeId,
								removedItem: el.id,
							};
						}),
				});
			});
		}

		if (isDebug) {
			setSavingPayslips(false);
			console.log("payslipBatchPostData", payslipBatchPostData);
			return;
		}

		const blob = await downloadZip(payslipBlobs).blob();

		const fileName = "payslips_archive.zip";
		let file = new File([blob], fileName);

		var iData = new FormData();
		iData.append("UploadedFile", file, fileName);
		iData.append("FileName", fileName);
		iData.append("FileExt", "zip");

		// Post to the endpoint that saves payslips as html to Azure storage
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/v2/dataimport/payslips`,
				iData
			)
			.then(function (response) {
				if (response.status === 200) {
					setSavingLabel(t("payslips.loading_status.saving_payslips"));
					// Now post data to API
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/payslips/batch/create`,
							payslipBatchPostData
						)
						.then(function (response) {
							setSavingPayslips(false);
							const responseData: PayslipsBatchCreateResponse = response.data;
							if (!responseData.success) {
								showNotification({
									title: "Error",
									message: t("payslips.errors.error_saving_batch"),
									color: "red",
									icon: <IconExclamationMark size={16} />,
								});
							} else {
								setPayslips([], "");
								//re-fetch payslip batches
								dispatch(fetchPayslipBatchesData(currentBatchListYear));
								setTab(1);

								showNotification({
									message: "Launaseðlar vistaðir og bunki búinn til",
									color: "green",
									icon: <IconCheck size={16} />,
								});
							}
						})
						.catch((error) => {
							setSavingPayslips(false);
							console.log(error);
							showNotification({
								title: "Error",
								message: t("payslips.errors.error_saving_batch"),
								color: "red",
								icon: <IconExclamationMark size={16} />,
							});
						});
				}
			})
			.catch((error) => {
				setSavingPayslips(false);
				console.log(error);
				showNotification({
					title: "Error",
					message: t("payslips.errors.error_saving_payslips"),
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
			});
	}

	const form_data = useForm({
		initialValues: {
			CompanyId: getCurrentCompanyId(),
			BatchName: "",
			PeriodStarts: firstDay,
			PeriodEnds: lastDay,
			SalaryPayDay: lastDay,
			YearMonth: currentYear + "-" + currentMonth,
			selGroup: "0",
			selEmployees: [],
			sendSchedulerRsk: false,
			sendSchedulerLif: false,
			sendSchedulerEmailPayslips: false,
		},
		validate: {
			selEmployees: (value, values) =>
				values.selGroup === "-1"
					? value.length < 1
						? t("payslips.errors.choose_employee")
						: null
					: null,
		},
	});

	// If the user has edited the batch name then return that, else return default value
	function getBatchName() {
		return form_data.values.BatchName.length > 0
			? form_data.values.BatchName
			: t("payslips.Payslips") +
					" " +
					t("payslips.months", { returnObjects: true })[currentMonth - 1] +
					" " +
					currentYear;
	}

	const [listData, setListData] = useState<ListItem[]>([]);
	const [employeeSelectGroup, setEmployeeSelectGroup] = useState<ListItem[]>(
		[]
	);
	const [calculatePayslipErrors, setCalculatePayslipErrors] = useState<
		CalculatePayslipError[]
	>([]);
	useEffect(() => {
		function shouldFetchData(companyId: number, isLoading: boolean): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			if (userHasAccess && currentCompanyId !== companyId && !isLoading)
				return true;
			return false;
		}
		if (
			shouldFetchData(
				payslipsCreateData.companyId,
				payslipsCreateData.isLoading
			)
		) {
			dispatch(fetchPayslipsCreateData()); // fetch the data needed to create payslips
			dispatch(fetchPayslipBatchesData(currentBatchListYear)); // fetch the list of created payslip batches
		}
	}, [
		dispatch,
		currentBatchListYear,
		userData.user.companies,
		payslipsCreateData.companyId,
		payslipsCreateData.isLoading,
	]);
	useEffect(() => {
		if (payslipsCreateData.data.employees) {
			let adjusted_list_data: ListItem[] = [];
			payslipsCreateData.data.employees.forEach((element: Employee) => {
				adjusted_list_data.push({
					value: element.id.toString(),
					label: element.fullName,
				});
			});
			let select_groups = [
				{ value: "0", label: t("payslips.employees_all") },
				{ value: "-1", label: t("payslips.employees_select") },
			];
			payslipsCreateData.data.employeeGroups.forEach((group: EmployeeGroup) => {
				select_groups.push({
					value: group.id.toString(),
					label: "Launahópur: " + group.name,
				});
			});
			payslipsCreateData.data.departments.forEach((department: Department) => {
				select_groups.push({
					value: "D" + department.id,
					label: "Deild: " + department.name,
				});
			});
			setListData(adjusted_list_data);
			setEmployeeSelectGroup(select_groups);
		}
	}, [payslipsCreateData, t]);

	return (
		<>
			<LaunaLoadingOverlay visible={savingPayslips} label={savingLabel} />
			{calculatePayslipErrors.length > 0 && (
				<Modal
					overlayColor={
						theme.colorScheme === "dark"
							? theme.colors.dark[9]
							: theme.colors.gray[2]
					}
					overlayOpacity={0.55}
					overlayBlur={3}
					overflow="inside"
					opened={true}
					onClose={() => {
						setCalculatePayslipErrors([]);
					}}>
					<div>
						<AlertTriangle size={36} strokeWidth={2} color={"#f00"} />{" "}
						<strong style={{ position: "absolute", marginLeft: 24 }}>
							{t("payslips.errors.calculation")}
						</strong>
					</div>
					<div style={{ marginLeft: 60 }}>
						{calculatePayslipErrors.map((err) => (
							<div key={"err-" + err.EmployeeId}>
								<strong>{err.EmployeeName}</strong>
								{err.Errors.map((errMessage) => (
									<div key={err.EmployeeId + "-" + errMessage}>
										- {t("payslips.errors." + errMessage)}
									</div>
								))}
							</div>
						))}
					</div>
				</Modal>
			)}
			<Title order={1}>{t("payslips.Payslips")}</Title>
			<Tabs
				active={activeTab}
				onTabChange={(indx) => {
					setTab(indx);
				}}>
				<Tabs.Tab
					key="tab1"
					icon={<Pencil size={14} />}
					label={t("payslips.tab_createform")}>
					<Paper>
						<Card shadow="lg" p="lg" radius="md" withBorder>
							<Group position="apart">
								<Text>{t("payslips.description_line")}</Text>
								<IconFile3d size={18} />
							</Group>
							<form
								onSubmit={form_data.onSubmit((values) => {
									setButtonLoadingState(true);
									setLoadingState(true);
									form_data.setFieldValue("BatchName", getBatchName());
									setSkeletonLength(
										form_data.values.selGroup === "0"
											? 5
											: form_data.values.selEmployees.length > 0
											? form_data.values.selEmployees.length
											: skeletonLength
									);
									axios
										.post(
											`${process.env.REACT_APP_API_URL}/salaryprocess/create`,
											values,
											{
												headers: {
													Accept: "application/json",
													"Content-Type": "application/json",
												},
											}
										)
										.then(function (response) {
											setLoadingState(false);
											setButtonLoadingState(false);
											if (response.status === 200) {
												//TODO: fix this once the API returns errors on 5XX status, because this is stupid
												if (response.data.Status === "ERROR") {
													const errors: CalculatePayslipError[] = JSON.parse(
														response.data.Message
													);
													setCalculatePayslipErrors(errors);
													setPayslips([], "");
												}
												if (response.data.payslips.length === 0) {
													setPayslips([], "");
												} else {
													setPayslips(response.data.payslips, values.YearMonth);
												}
											}
										})
										.catch((error) => {
											console.log("Villa!", error);
											setLoadingState(false);
											setButtonLoadingState(false);
											error.response?.data?.errors?.forEach(
												(err: ValidationError) => {
													showNotification({
														autoClose: false,
														title: "Villa",
														message: err.message,
														color: "red",
														icon: <IconExclamationMark size={16} />,
													});
												}
											);
											if (error.response?.data?.exceptionMessage) {
												showNotification({
													autoClose: false,
													title: "Villa",
													message: error.response?.data?.exceptionMessage,
													color: "red",
													icon: <IconExclamationMark size={16} />,
												});
											}
										});
								})}>
								<Grid columns={5} mt="xl" align="flex-end">
									<Grid.Col md={1} sm={5}>
										<Select
											onChange={(selectedMonth: string) => {
												setCurrentMonth(
													t("payslips.months", { returnObjects: true }).indexOf(
														selectedMonth
													) + 1
												);
												setFirstDay(
													new Date(
														currentYear,
														t("payslips.months", {
															returnObjects: true,
														}).indexOf(selectedMonth),
														1
													)
												);
												setLastDay(
													new Date(
														currentYear,
														t("payslips.months", {
															returnObjects: true,
														}).indexOf(selectedMonth) + 1,
														0
													)
												);
												const month =
													t("payslips.months", { returnObjects: true }).indexOf(
														selectedMonth
													) + 1;

												form_data.setFieldValue(
													"YearMonth",
													currentYear + "-" + month
												);
											}}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
											label=""
											placeholder="Pick month"
											data={t("payslips.months", { returnObjects: true })}
											defaultValue={
												t("payslips.months", { returnObjects: true })[
													currentDate.getMonth()
												]
											}
										/>
									</Grid.Col>
									<Grid.Col md={1} sm={5}>
										<Select
											onChange={(selectedYear: string) => {
												setCurrentYear(parseInt(selectedYear));
												setFirstDay(
													new Date(parseInt(selectedYear), currentMonth - 1, 1)
												);
												setLastDay(
													new Date(parseInt(selectedYear), currentMonth, 0)
												);
												form_data.setFieldValue("PeriodStarts", firstDay);
												form_data.setFieldValue("PeriodEnds", lastDay);
												form_data.setFieldValue(
													"YearMonth",
													selectedYear + "-" + currentMonth
												);
											}}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
											label=""
											placeholder="Year"
											data={year}
											defaultValue={"" + currentDate.getFullYear() + ""}
										/>
									</Grid.Col>
									<Grid.Col md={1} sm={5}>
										<DatePicker
											clearable={false}
											locale={i18n.language}
											pr={"md"}
											inputFormat="DD/MM/YYYY"
											labelFormat="MM/YYYY"
											label={t("payslips.from")}
											{...form_data.getInputProps("PeriodStarts")}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
										/>
									</Grid.Col>
									<Grid.Col md={1} sm={5}>
										<DatePicker
											locale={i18n.language}
											clearable={false}
											inputFormat="DD/MM/YYYY"
											labelFormat="MM/YYYY"
											placeholder="Pick date"
											label={t("payslips.to")}
											{...form_data.getInputProps("PeriodEnds")}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
										/>
									</Grid.Col>
									<Grid.Col md={1} sm={5}>
										<DatePicker
											locale={i18n.language}
											clearable={false}
											inputFormat="DD/MM/YYYY"
											labelFormat="MM/YYYY"
											{...form_data.getInputProps("SalaryPayDay")}
											placeholder="Pick date"
											label={t("payslips.payout_date")}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
										/>
									</Grid.Col>
								</Grid>

								<Grid columns={5} mt="md">
									<Grid.Col md={1} sm={5}>
										<Select
											{...form_data.getInputProps("selGroup")}
											required={true}
											label={t("payslips.employees")}
											rightSection={<IconChevronDown size={14} />}
											rightSectionWidth={30}
											styles={{ rightSection: { pointerEvents: "none" } }}
											defaultValue={JSON.stringify(employeeSelectGroup)}
											data={employeeSelectGroup}
											onChange={(selectedValue: string) => {
												form_data.setFieldValue("selGroup", selectedValue);
												if (parseInt(selectedValue) === -1) {
													setSelectEmployee(true);
												} else {
													setSelectEmployee(false);
												}
											}}
										/>
									</Grid.Col>
									<Grid.Col md={3} sm={5}>
										{select_employee && (
											<MultiSelect
												data={listData}
												required={true}
												searchable
												limit={20}
												label={t("payslips.select_employee")}
												placeholder={t("payslips.choose_employee")}
												rightSection={<IconChevronDown size={14} />}
												{...form_data.getInputProps("selEmployees")}
											/>
										)}
									</Grid.Col>
									<Grid.Col md={1} sm={5}>
										<PrimaryButton
											type="submit"
											fullWidth
											loading={butoon_loading_state}>
											{t("payslips.buttonLable")}
										</PrimaryButton>
									</Grid.Col>
								</Grid>
							</form>
						</Card>
						{(payslipEditorCache.payslips.length > 0 || loading_state) && (
							<>
								<Card mt={"lg"} shadow="lg" p="lg" radius="md" withBorder>
									<Table mt={"lg"}>
										<thead>
											<tr>
												<th>{t("payslips.table_header.0")}</th>
												<th>{t("payslips.table_header.1")}</th>
												<th>{t("payslips.table_header.2")}</th>
												<th>{t("payslips.table_header.3")}</th>
											</tr>
										</thead>
										{!loading_state && (
											<tbody>
												{payslipEditorCache.payslips.map((element: Payslip) => (
													<tr key={element.guid}>
														<td>{element.employee.fullName}</td>
														<td>
															<RegistrationId
																value={element.employee.registrationId}
															/>
														</td>
														<td>
															<Amount value={element.salaryTotal} />
														</td>
														<td>
															<Group position="apart">
																<Amount value={element.salaryToPay} />
																<Pencil
																	size={25}
																	onClick={(e) => {
																		setCurrentPayslip(element);
																		setOpenModal(true);
																	}}
																	style={{
																		cursor: "pointer",
																	}}
																/>
															</Group>
														</td>
													</tr>
												))}
											</tbody>
										)}
										{loading_state && (
											<tbody>
												{[...Array(skeletonLength)].map((e, index) => (
													<tr key={index}>
														{[...Array(4)].map((e, index) => (
															<td key={index}>
																<Skeleton
																	height={skeleton_hight}
																	mt={6}
																	width="100%"
																/>
															</td>
														))}
													</tr>
												))}
											</tbody>
										)}
									</Table>

									{!loading_state && (
										<Group position="right">
											<SimpleGrid>
												<TextInput
													defaultValue={getBatchName()}
													label={t("payslips.bachNameLable")}
													onChange={(el) => {
														form_data.setFieldValue(
															"BatchName",
															el.currentTarget.value
														);
													}}
												/>
											</SimpleGrid>
											<SimpleGrid>
												<PrimaryButton onClick={() => triggerSavePayslips()}>
													{t("payslips.savePayslipsbutton")}
												</PrimaryButton>
											</SimpleGrid>
										</Group>
									)}
								</Card>
							</>
						)}
						{payslipEditorCache.currentPayslip && (
							<Modal
								size="80%"
								opened={openModal}
								onClose={() => {
									if (payslipInModalIsEdited) {
										setConfirmClosePayslipEditorModalOpen(true);
									} else {
										dispatch(currentPayslipSet(""));
										dispatch(salaryElementsSet([]));
										dispatch(payslipsEditedSet(false));
										setOpenModal(false);
									}
								}}
								closeOnClickOutside={!payslipInModalIsEdited}>
								<ErrorBoundary>
									<PayslipView
										payDay={format(form_data.values.SalaryPayDay, "yyyy-MM-dd")}
										logo={payslipsCreateData.data.logo}
										editMode={true}
										yearMonth={form_data.values.YearMonth}
									/>
								</ErrorBoundary>
							</Modal>
						)}
						<Modal
							opened={confirmClosePayslipEditorModalOpen}
							withCloseButton={false}
							zIndex={1000}
							onClose={() => {
								setConfirmClosePayslipEditorModalOpen(false);
							}}
							title={t("add_employees.are_you_sure")}>
							<Center>{t("add_employees.changes_made")}</Center>
							<Group position="center" mt="sm">
								<Button
									onClick={() => {
										dispatch(
											salaryElementEditUndo(
												payslipEditorCache.currentPayslip?.employee.id
											)
										);
										dispatch(currentPayslipSet(""));
										dispatch(salaryElementsSet([]));
										dispatch(payslipsEditedSet(false));
										setConfirmClosePayslipEditorModalOpen(false);
										setOpenModal(false);
									}}>
									{t("common.yes")}
								</Button>
								<Button
									color="gray"
									onClick={() => setConfirmClosePayslipEditorModalOpen(false)}>
									{t("common.no")}
								</Button>
							</Group>
						</Modal>
					</Paper>
					{isDebug && readOnlyPayslips.length > 0 && (
						<Text mt="lg">Debug: payslips to be posted to Azure storage</Text>
					)}
					{isDebug &&
						!savingPayslips &&
						readOnlyPayslips.length > 0 &&
						readOnlyPayslips.map((rop) => {
							return rop;
						})}
				</Tabs.Tab>
				<Tabs.Tab
					key="tab2"
					icon={<List size={14} />}
					label={t("payslips.tab_batches")}>
					{payslipBatches.data.length === 0 ? (
						<NoData
							visible
							message={t("common.no_data")}
							routeText={t("payslips.payslips_page_title")}
							route="/app/payslips/create"
						/>
					) : (
						<PayslipBatchesList
							openBatchId={selectedBatchId}
							onDeleted={() => {
								dispatch(fetchPayslipBatchesData(currentBatchListYear));
							}}
							onBatchOpened={(batchId) => {
								if (batchId > 0) navigate("/app/payslips/list/" + batchId);
								else navigate("/app/payslips/list");
							}}
							data={payslipBatches.data}
						/>
					)}
				</Tabs.Tab>
			</Tabs>
		</>
	);
}
