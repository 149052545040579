import React from "react";
import { createStyles, Title, Text, Button, Container } from "@mantine/core";
import { useNavigate } from "react-router";
import { isUserBetaPreview } from "../../../helpers/user";

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: "relative",
		paddingTop: 120,
		paddingBottom: 80,

		"@media (max-width: 755px)": {
			paddingTop: 80,
			paddingBottom: 60,
		},
	},

	inner: {
		position: "relative",
		zIndex: 1,
	},

	title: {
		textAlign: "center",
		fontWeight: 700,
		fontSize: 48,
		letterSpacing: 2,
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		marginBottom: theme.spacing.xs,
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",

		"@media (max-width: 520px)": {
			fontSize: 28,
			textAlign: "left",
		},
	},

	highlight: {
		color:
			theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
	},

	description: {
		textAlign: "center",
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",
		"@media (max-width: 520px)": {
			textAlign: "left",
			fontSize: theme.fontSizes.md,
		},
	},

	controls: {
		marginTop: theme.spacing.lg,
		display: "flex",
		justifyContent: "center",

		"@media (max-width: 520px)": {
			flexDirection: "column",
		},
	},

	control: {
		"&:not(:first-of-type)": {
			marginLeft: theme.spacing.md,
		},

		"@media (max-width: 520px)": {
			height: 42,
			fontSize: theme.fontSizes.md,

			"&:not(:first-of-type)": {
				marginTop: theme.spacing.md,
				marginLeft: 0,
			},
		},
	},
}));

export function HomeHero() {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const betaPreview: boolean = isUserBetaPreview();

	return (
		<Container className={classes.wrapper} size={1200}>
			<div className={classes.inner}>
				<Title className={classes.title}>Ástæðulaust að flækja hlutina</Title>

				<Container p={0} size={600}>
					<Text size="xl" color="white" className={classes.description}>
						einfaldar launavinnsluna og sparar þér tíma
					</Text>
				</Container>

				<div className={classes.controls}>
					<Button
						radius={8}
						className={classes.control}
						size="lg"
						onClick={(event: any) => {
							event.preventDefault();
							betaPreview
								? navigate("signup")
								: (window.location.href =
										process.env.REACT_APP_LEGACY_URL + "/account/register");
						}}>
						Prófaðu frítt í 30 daga
					</Button>
				</div>
			</div>
		</Container>
	);
}
