import React from "react";
import { BankAccount } from "../../../data/types/payslip";

export function BankAccountInfo(props: { data?: BankAccount }) {
	return (
		<span>
			{props.data?.bankNUmber}-{props.data?.hofudbok}-
			{props.data?.accountNumber}
		</span>
	);
}
