import React from "react";
import { Link } from "react-router-dom";
import { TextInput, Text, Anchor, Notification } from "@mantine/core";
import { At } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useSetState } from "@mantine/hooks";
import { Alert, Space } from "@mantine/core";
import { AlertCircle, Check } from "tabler-icons-react";
import { PrimaryButton } from "../../components/ui/buttons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Wrapper from "../../components/ui/Wrapper";

function ForgotPassword() {
	const { t } = useTranslation();
	const [state, setState] = useSetState({
		formSubmitting: false,
		forgotPasswordError: "",
		resetEmailSent: "",
	});
	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},

		validate: {
			email: (value) =>
				/^\S+@\S+$/.test(value)
					? null
					: t("authentication.email_address_invalid"),
		},
	});
	return (
		<Wrapper title={t("forgot_password.forgot_password")}>
			<form
				onSubmit={form.onSubmit((values) => {
					setState({ formSubmitting: true, forgotPasswordError: "" });
					axios
						.get(
							`${process.env.REACT_APP_API_URL}/account/resetpasswordrequest?email=${values.email}&client=v2`
						)
						.then(function (response) {
							console.log(response);
							if (response.status === 200) {
								setState({
									formSubmitting: false,
									forgotPasswordError: "",
									resetEmailSent: response.data.message,
								});
							}
						})
						.catch((error) => {
							console.log(error);
						});
				})}>
				<TextInput
					label={t("forgot_password.email")}
					placeholder="hallo@gmail.com"
					size="md"
					icon={<At size={14} />}
					{...form.getInputProps("email")}
				/>
				<PrimaryButton fullWidth type="submit" loading={state.formSubmitting}>
					{t("forgot_password.reset_password")}
				</PrimaryButton>
				{state.forgotPasswordError && (
					<>
						<Space h="md" />
						<Alert
							mt="mt"
							icon={<AlertCircle size={16} />}
							title={t("errors.error_occurred")}
							color="gray">
							{state.forgotPasswordError}
						</Alert>
					</>
				)}
				{state.resetEmailSent && (
					<>
						<Space h="md" />
						<Notification
							mt="mt"
							icon={<Check size={16} />}
							title={t("forgot_password.mail_sent") + form.values.email}
							color="teal"
							onClose={() => {
								setState({ resetEmailSent: "" });
							}}>
							{t("forgot_password.follow_instructions")}
						</Notification>
					</>
				)}
			</form>
			<Text align="center" mt="md">
				<Anchor component={Link} to="/signup">
					{t("authentication.signup_quesiton")}
				</Anchor>
			</Text>
		</Wrapper>
	);
}

export default ForgotPassword;
