function getChartColors(): string[] {
    return [
        "rgb(42, 191, 200)", //blue
        "rgb(239, 73, 112)", //red
        "rgb(250, 165, 54)", //yellow
        "rgb(147, 83,  161)", //purple
        "rgb(238, 53, 128)", //pink
        "rgb(255, 159, 64)", //orange
        "rgb(65, 64, 66)", //grey
        "rgb(255,255,255)", //white
    ]
}

function getChartOptions(title: string, subtitle?: string) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                color: "#ffffff",
                text: [title, subtitle || ""],
                font: {
                    family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
                    size: 18,
                    weight: "400"
                }
            },
        }
    };
}

export { getChartColors, getChartOptions };