const launaTheme = {
  colorScheme: "dark",
  colors: {
    launablue: [
      "#ddfcfc",
      "#bbeff0",
      "#98e2e4",
      "#71d5d8",
      "#4cc9cd",
      "#32b0b3",
      "#22898b",
      "#126364",
      "#51cbce",
      "#198487",
    ],
  },
  primaryColor: "launablue",
  defaultRadius: 0,
};

export default launaTheme;
