import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { LayoutPublic, LayoutPrivate } from "./components/layout";
import { setTranslations, setLocale } from "react-i18nify";
import { useTranslation } from "react-i18next";
import { fetchUserData, selectUser } from "./data/user/userSlice";
import en from "./lang/en.json";
import is from "./lang/is.json";
import CacheBuster from "react-cache-buster";
import { LoadingOverlay } from "@mantine/core";
import ProtectedRoute from "./components/protectedRoute";
// Content pages >>>
import HomePage from "./pages/public/home/homePage";
// << Content pages
import NoMatch from "./pages/public/404";
import Dashboard from "./pages/dashboard";
import Company from "./pages/company";
import Employees from "./pages/employees";
import Payslips from "./pages/payslips";

import SalaryChange from "./pages/salaryChange";
import Login from "./pages/public/login";
import Signup from "./pages/public/signup";
import {
	getCurrentCompanyId,
	isAppInBeta,
	isAuthenticated,
	isProd,
	isUserBetaPreview,
	isUserInApp,
} from "./helpers/user";
import ForgotPassword from "./pages/public/ForgotPassword";
import ResetPassword from "./pages/public/resetPassword";
import Releasenotes from "./pages/Releasenotes";
import Notifications from "./pages/notifications";
import Blog from "./site/blog";
import BlogEntry from "./site/blogEntry";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import ContentPage from "./site/contentPage";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import SalaryProcesses from "./pages/salaryProcesses/salaryprocesses";
import SalaryProcessBookedView from "./pages/salaryprocessView/salaryProcessViewList";
import BetaPreview from "./pages/public/betaPreview";
import Settings from "./pages/settings";
import EmployeeFormPage from "./pages/employeeFormPage";
import CompanyForm from "./pages/companyForm";
import Users from "./pages/users";
import { ModalsProvider } from "@mantine/modals";
import { Provider } from "@rollbar/react";
import GlobalError from "./components/ui/shared/errors/globalError";
import CreateSalaryProcess from "./components/salaryProcesses/CreateSalaryProcess";

function AppRouter() {
	const location = useLocation();
	const [gaInitialized, setGaInitialized] = useState(false);
	if (isProd() && !gaInitialized) {
		ReactGA.initialize("UA-157750688-1");
		setGaInitialized(true);
	}

	return (
		<Routes>
			<Route
				path="/"
				element={
					isAuthenticated() && isUserInApp(location.pathname) ? (
						<LayoutPrivate />
					) : (
						<LayoutPublic children={null} />
					)
				}>
				{/* routes that don't require authentication */}
				<Route path="/" element={<LayoutPublic children={<HomePage />} />} />
				<Route
					path="blog/:slug"
					element={<LayoutPublic children={<BlogEntry />} />}
				/>
				<Route
					path="efni/:slug"
					element={<LayoutPublic children={<ContentPage />} />}
				/>
				<Route path="blog" element={<LayoutPublic children={<Blog />} />} />
				<Route
					path="signup"
					element={isUserBetaPreview() ? <Signup /> : <NoMatch />}
				/>
				<Route path="login" element={<Login />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route
					path="beta"
					element={isAppInBeta() ? <BetaPreview /> : <NoMatch />}
				/>
				<Route
					path="account/resetpassword/:token"
					element={<ResetPassword />}
				/>
				<Route
					element={
						<ProtectedRoute
							isAllowed={isAuthenticated()}
							redirectPath="/login"
						/>
					}>
					{/* routes that DO require authentication */}
					<Route path="app" element={<Dashboard />} />
					<Route path="app/company" element={<Company />} />
					<Route
						path="app/company/create"
						element={<CompanyForm openModal={true} />}
					/>
					<Route path="app/employees" element={<Employees />} />
					<Route path="app/payslips">
						<Route index element={<Payslips />} />
						<Route path=":tab">
							<Route index element={<Payslips />} />
							<Route path=":id" element={<Payslips />} />
						</Route>
					</Route>
					<Route path="app/salarychange" element={<SalaryChange />} />
					<Route
						path="app/salaryprocess/register"
						element={<CreateSalaryProcess />}
					/>
					<Route path="app/salaryprocesses">
						<Route index element={<SalaryProcesses />} />
						<Route path=":tab" element={<SalaryProcesses />} />
					</Route>
					<Route
						path="app/salaryprocesses/view/:id"
						element={<SalaryProcessBookedView />}
					/>
					<Route path="app/about" element={<Releasenotes />} />
					<Route path="app/notifications" element={<Notifications />} />
					<Route path="app/employees/add" element={<EmployeeFormPage />} />
					<Route path="app/employees/form" element={<EmployeeFormPage />} />
					<Route path="app/settings">
						<Route index element={<Settings />} />
						<Route path=":tab" element={<Settings />} />
					</Route>
					<Route path="app/users" element={<Users />} />
				</Route>
			</Route>
			{/* our 404 route */}
			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
}
function App() {
	const { i18n } = useTranslation();
	setTranslations({ en, is });
	setLocale(i18n.language, true);
	const userData = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	useEffect(() => {
		//fetch user data before rendering the actual application
		if (isAuthenticated() && Object.keys(userData.user).length === 0) {
			dispatch<any>(fetchUserData(true));
		}
	}, [dispatch, userData.user]);
	const isProduction = process.env.NODE_ENV === "production";
	const version = process.env.REACT_APP_VERSION;

	const accessToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;

	const rollbarConfig = {
		accessToken,
		captureUncaught: true,
		captureUnhandledRejections: true,
		environment: process.env.NODE_ENV,
		enabled: isProduction,
		itemsPerMinute: 5,
		custom: {
			userId: userData.user.id,
			userEmail: userData.user.email,
			companyId: getCurrentCompanyId(),
		},
		payload: {
			client: {
				javascript: {
					code_version: "1.0.0",
					source_map_enabled: true,
				},
			},
		},
	};

	return isAuthenticated() && (userData.isLoading || userData.error) ? (
		userData.isLoading ? (
			<LoadingOverlay visible={true} />
		) : (
			<GlobalError message="Villa kom upp við að sækja upplýsingar um notanda" />
		)
	) : (
		<Provider config={rollbarConfig}>
			<HelmetProvider>
				<CacheBuster
					currentVersion={version}
					isEnabled={isProduction}
					isVerboseMode={false}
					loadingComponent={<LoadingOverlay visible={true} />}>
					<NotificationsProvider position="top-right">
						<ModalsProvider>
							<AppRouter />
						</ModalsProvider>
					</NotificationsProvider>
				</CacheBuster>
			</HelmetProvider>
		</Provider>
	);
}
export default App;
