import React, { useEffect, useState } from "react";
import { Card, createStyles, Grid, LoadingOverlay } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
	fetchCompanyData,
	selectCompanyData,
} from "../../data/company/companyDataSlice";
import { selectUser } from "../../data/user/userSlice";
import { getCurrentCompanyId } from "../../helpers/user";
import DonutChart from "./donutChart";
import PiChart from "./pieChart";
import StackedBarChart from "./stackedBarChart";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
	card: {
		height: 320,
		marginBottom: 16,
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[0],
	},
	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		fontWeight: 700,
	},
}));

function CompanyCharts() {
	const { classes } = useStyles();
	const userData = useAppSelector(selectUser);
	const companyData = useAppSelector(selectCompanyData);
	const dispatch = useAppDispatch();
	const [isFetched, setIsFetched] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		function shouldFetchCompanyData(companyId: number): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			if (userHasAccess && currentCompanyId !== companyId && !isFetched)
				return true;

			return false;
		}

		if (shouldFetchCompanyData(companyData.id)) {
			dispatch(fetchCompanyData());
			setIsFetched(true);
		}
	}, [dispatch, userData.user.companies, companyData.id, isFetched]);

	return (
		<>
			<LoadingOverlay visible={companyData.isLoading} />
			{companyData.chartData ? (
				<Card withBorder radius="md" className={classes.card}>
					<Grid>
						<Grid.Col xs={4}>
							<DonutChart
								title={t("charts.titles.year_cost")}
								data={companyData.chartData.pieChartYearToDate}
							/>
						</Grid.Col>
						<Grid.Col xs={4}>
							<PiChart
								title={t("charts.titles.month_cost")}
								data={companyData.chartData.pieChart}
								lastfourlabels={companyData.chartData.barChart.labels}
							/>
						</Grid.Col>
						<Grid.Col xs={4}>
							<StackedBarChart
								title={t("charts.titles.last_four")}
								data={companyData.chartData.barChart}
							/>
						</Grid.Col>
					</Grid>
				</Card>
			) : null}
		</>
	);
}

export default CompanyCharts;
