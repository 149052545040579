import React from "react";
import {
	PaySlipSalaryElement,
	PensionFund,
	TaxDeduction,
	UnionFee,
} from "../../../../data/types/payslip";
import PayslipDeductionPension from "./payslipDeductionPension";
import PayslipDeductionTax from "./payslipDeductionTax";
import PayslipSimpleRow from "./payslipSimpleRow";

type PayslipUnion = {
	name: string;
	fee: UnionFee | null;
};

type DeductionData = {
	taxDeduction?: TaxDeduction;
	deductableElements?: PaySlipSalaryElement[];
	pensionFund?: PensionFund[];
	specialPensionFunds?: PensionFund[];
	union?: PayslipUnion | null; // Union is optional for employees
};

function PayslipDeduction(props: { data: DeductionData }) {
	return (
		<div key="payslipDeductionWrapper">
			<div
				style={{
					padding: "3px",
					marginTop: "5px",
					backgroundColor: "#f1f1f1",
				}}>
				<div
					style={{
						display: "inline-block",
						width: "50%",
						textAlign: "left",
					}}>
					<b>Frádráttur</b>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "50%",
						textAlign: "right",
					}}>
					Upphæð
				</div>
			</div>
			{/* pension fund deduction */}
			<PayslipDeductionPension
				key={"pensionFund"}
				data={{
					key: "PensionFund",
					title: "Lífeyrissjóður",
					pensionFunds: props.data.pensionFund || [],
				}}
			/>
			{props.data.specialPensionFunds &&
				props.data.specialPensionFunds.length > 0 && (
					<PayslipDeductionPension
						key={"SpecialPensionFunds"}
						data={{
							key: "SpecialPensionFunds",
							title: "Séreignarsparnaður",
							pensionFunds: props.data.specialPensionFunds,
						}}
					/>
				)}
			{props.data.union && props.data.union.fee && (
				<PayslipSimpleRow
					id={8999}
					description={
						"Stéttarfélag: " +
						props.data.union.name +
						", " +
						props.data.union.fee.title
					}
					value={props.data.union.fee.amount}
				/>
			)}
			{/* tax deduction */}
			<PayslipDeductionTax data={props.data.taxDeduction} />
			{/* general deduction */}
			{props.data.deductableElements?.map((element: PaySlipSalaryElement) => (
				<PayslipSimpleRow
					key={element.baseId}
					id={element.baseId}
					description={element.description}
					value={element.value}
				/>
			))}
		</div>
	);
}

export default PayslipDeduction;
