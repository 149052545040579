import React from "react";
import { AppShell, createStyles } from "@mantine/core";
import { HeaderResponsive } from "./Header";
import bgImage from "../../../assets/bg.jpg";
import { LaunaFooter } from "./Footer";

const useStyles = createStyles((theme) => ({
	wrapper: {
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",
		minHeight: "100vh",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat",
		backgroundImage:
			"linear-gradient(to right,rgba(147,83,161,.8),rgba(42,191,200,.8),rgba(250,165,54,.8),rgba(239,73,112,.8),rgba(238,53,128,.73)),url('" +
			bgImage +
			"');",
	},

	logo: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		width: 120,
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

function PublicPagesWrapper(props: { title: string; children: any }) {
	const { classes } = useStyles();
	return (
		<div className={classes.wrapper}>
			<AppShell
				header={<HeaderResponsive />}
				footer={<LaunaFooter />}
				children={props.children}
				fixed></AppShell>
		</div>
	);
}

export default PublicPagesWrapper;
