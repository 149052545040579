import React, { useState, MouseEventHandler } from "react";
import {
	Navbar,
	Tooltip,
	UnstyledButton,
	createStyles,
	Group,
} from "@mantine/core";
import {
	Home,
	Logout,
	Users,
	List,
	FileInvoice,
	UserPlus,
	FileInfo,
} from "tabler-icons-react";
import { Link } from "react-router-dom";
import { logOut } from "../../helpers/user";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../data/user/userSlice";

const useStyles = createStyles((theme) => ({
	navbar: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors[theme.primaryColor][6],
		paddingBottom: 0,
	},
	link: {
		width: 50,
		height: 50,
		borderRadius: theme.radius.md,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[0]
				: theme.colors.gray[0],

		opacity: 1,

		"&:hover": {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[5]
					: theme.colors[theme.primaryColor][7],
		},
	},

	active: {
		opacity: 1,
		"&, &:hover": {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
					: theme.colors[theme.primaryColor][7],
			color:
				theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 0],
		},
	},
}));

interface NavbarLinkProps {
	icon: any;
	label: string;
	linkPath: string;
	active: boolean;
	onClick: MouseEventHandler;
}

function NavbarLink({
	icon: Icon,
	label,
	linkPath,
	active,
	onClick,
}: NavbarLinkProps) {
	const { classes, cx } = useStyles();

	return (
		<Tooltip label={label} position="right" withArrow transitionDuration={0}>
			<UnstyledButton
				onClick={onClick}
				className={cx(classes.link, { [classes.active]: active })}
				component={Link}
				key={label}
				// variant="link"
				to={linkPath}>
				<Icon />
			</UnstyledButton>
		</Tooltip>
	);
}

export function SidemenuNav({ opened }: { opened: boolean }): JSX.Element {
	const { t } = useTranslation();
	const userData = useAppSelector(selectUser);

	const sidemenuData = [
		{
			label: t("side_menu.home"),
			icon: Home,
			linkPath: "app",
		},
		{
			label: t("side_menu.employees"),
			icon: Users,
			linkPath: "app/employees",
		},
		{
			label: t("side_menu.new_employee"),
			icon: UserPlus,
			linkPath: "app/employees/add",
		},

		{
			label: t("side_menu.payslips"),
			icon: FileInvoice,
			linkPath: "app/payslips/list",
		},
		{
			label: t("side_menu.salary_processes"),
			icon: List,
			linkPath: "app/salaryprocesses/list",
		},
	];

	const current_url = window.location.pathname.replace("/", "");
	var reload_index = sidemenuData.findIndex(
		(item) => item.linkPath === current_url
	);
	if (window.location.pathname === "/app") {
		reload_index = 0;
	}
	const [active, setActive] = useState(reload_index);
	const { classes } = useStyles();

	const links = sidemenuData.map((link, index) => (
		<NavbarLink
			{...link}
			key={link.linkPath}
			active={index === active}
			onClick={() => {
				setActive(index);
			}}
		/>
	));

	return (
		<Navbar
			hiddenBreakpoint="sm"
			hidden={!opened}
			width={{ base: 80 }}
			p="md"
			className={classes.navbar}>
			{/* only render navigation if user has companies */}
			{userData &&
				userData.user &&
				userData.user.companies &&
				userData.user.companies.length > 0 && (
					<Navbar.Section grow>
						<Group direction="column" align="center" spacing={0}>
							{links}
						</Group>
					</Navbar.Section>
				)}
			<Navbar.Section>
				<Group direction="column" align="center" spacing={0}>
					<NavbarLink
						icon={Logout}
						label={t("side_menu.log_out")}
						linkPath={""}
						active={active === sidemenuData.length}
						onClick={() => {
							setActive(sidemenuData.length);
							logOut();
						}}
					/>
				</Group>
			</Navbar.Section>
		</Navbar>
	);
}
