import React from "react";
import { Table } from "@mantine/core";

export enum ColumnAlign {
	LEFT = "left",
	RIGHT = "right",
}
export type tableHeaderColumn = {
	text: string;
	align?: ColumnAlign;
};

export function TableData(props: {
	tableHeader: tableHeaderColumn[];
	tableBody: any[];
}) {
	return (
		<Table mt={"lg"}>
			<thead>
				<tr>
					{props.tableHeader.map((element: tableHeaderColumn, id: number) => (
						<th style={{ textAlign: element.align }} key={id}>
							{element.text}
						</th>
					))}
				</tr>
			</thead>
			{props.tableBody?.length > 0 && <tbody>{props.tableBody}</tbody>}
		</Table>
	);
}
