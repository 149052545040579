import React, { ChangeEvent, useEffect, useState } from "react";
import {
	fetchEmployeesData,
	selectEmployees,
} from "../data/employees/employeeSlice";
import {
	LoadingOverlay,
	Grid,
	Center,
	ActionIcon,
	Card,
	Title,
	Chips,
	Chip,
	TextInput,
	Button,
	Menu,
	Modal,
	SegmentedControl,
	Table,
	SimpleGrid,
	TypographyStylesProvider,
	Group,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import EmployeeCard, {
	useStyles,
} from "../components/ui/employee/employeeCard";
import {
	getCurrentCompanyId,
	getEmployeeListView,
	setEmployeeListView,
} from "../helpers/user";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectUser } from "../data/user/userSlice";
import { BasicEmployeeData } from "../data/types/employee";
import EmployeeFormComponent from "../components/employees/employeeForm";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { LayoutGrid, List, Menu2, Plus, UserPlus } from "tabler-icons-react";
import { EmployeeForm } from "../data/types/formTemplates/employeeForm";
import { useNavigate } from "react-router";
import EmployeePayslipsView from "../components/ui/shared/payslip/employeePayslipsView";
import { EmployeePayslip } from "../data/types/payslip";
import EmployeeList from "../components/ui/employee/employeeList";
import { NoData } from "../components/ui/common/sharedComponents";

enum EmployeeListType {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
}

function Employees() {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const userData = useAppSelector(selectUser);
	const employeesData = useAppSelector(selectEmployees);
	const [selectedEmployee, setSelectedEmployee] =
		useState<BasicEmployeeData | null>(null); //the clicked employee
	const [employeeData, setEmployeeData] = useState<EmployeeForm | null>(null);
	const [createEmployee, setCreateEmployee] = useState(false);
	const [employeeListType, setEmployeeListType] = useState("ACTIVE");
	const [searchTerm, setSearchTerm] = useState("");
	const [employees, setEmployees] = useState<BasicEmployeeData[]>([]);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [opened, setOpened] = useState(false);
	const [payslips, setPayslips] = useState<EmployeePayslip[]>([]);
	const [view, setView] = useState(getEmployeeListView());

	useEffect(() => {
		setEmployeeListView(view);
	}, [view]);

	const [show, setShow] = useState(false);
	const [payslip, setPayslip] = useState("");

	const [sendModalOpened, setSendModalOpened] = useState(false);
	const [payslipToSend, setPayslipToSend] = useState<EmployeePayslip>(
		{} as EmployeePayslip
	);

	const { classes } = useStyles();

	function fetchSingleEmployeesData(employee: BasicEmployeeData): any {
		setIsLoading(true);
		setSelectedEmployee(employee);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/employee/get/data/${employee.id}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					setEmployeeData(response.data);
					setIsLoading(false);
				}
			})
			.catch((error: any) => {
				console.log(error);
				setIsLoading(false);
				showNotification({
					message: error?.response
						? error.response.data.message
						: "Sorry something went wrong please try again!",
					color: "red",
				});
			});
	}
	const deleteEmployee = (id: number) => {
		setIsLoading(true);
		axios
			.delete(`${process.env.REACT_APP_API_URL}/employee/delete`, {
				data: {
					id,
				},
			})
			.then(function (response) {
				console.log(response.data);
				showNotification({ message: response.data.Message });
				dispatch(fetchEmployeesData());
				setIsLoading(false);
			})
			.catch(function (error) {
				console.error(error.message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.message });
			});
	};
	const deactivateEmployee = (ID: number) => {
		setIsLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/employee/deactivate`, {
				ID,
			})
			.then(function (response) {
				console.log(response.data);
				setIsLoading(false);
				showNotification({ message: response.data.Message });
				dispatch(fetchEmployeesData());
			})
			.catch(function (error) {
				console.error(error.message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.message });
			});
	};

	const activateEmployee = (ID: number) => {
		setIsLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/employee/activate`, {
				ID,
			})
			.then(function (response) {
				console.log(response.data);
				setIsLoading(false);
				showNotification({ message: response.data.Message });
				dispatch(fetchEmployeesData());
			})
			.catch(function (error) {
				console.error(error.message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.message });
			});
	};
	const searchEmployees = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		const allEmployees =
			employeeListType === EmployeeListType.ACTIVE
				? employeesData.employees
				: employeesData.inactiveEmployees;
		setEmployees(
			allEmployees.filter(
				(employee) =>
					employee.fullName.toLowerCase().includes(value.toLowerCase()) ||
					employee.registrationId.toLowerCase().includes(value.toLowerCase())
			)
		);
	};

	const showPayslips = (id: number) => {
		setIsLoading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/employee/${id}/list/payslips`)
			.then(function (response) {
				setPayslips(response.data);
				setIsLoading(false);
				setOpened(true);
			})
			.catch(function (error) {
				console.error(error.message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.message });
			});
	};

	const fetchPayslip = (payslipID: number) => {
		setIsLoading(true);
		const companyId = getCurrentCompanyId();
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/payslip/get/htmlslip/${companyId}/${payslipID}`
			)
			.then(function (response) {
				setPayslip(response.data);
				setShow(true);
				setIsLoading(false);
			})
			.catch(function (error) {
				console.error(error.message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.message });
			});
	};

	const sendPayslip = () => {
		setIsLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/payslip/send/email`, {
				guid: payslipToSend.guid,
				email: payslipToSend.employeeEmail,
			})
			.then(function (response) {
				console.log(response.data);
				setIsLoading(false);
				if (response.data.Status === "ERROR") {
					showNotification({ color: "red", message: response.data.Message });
				}
			})
			.catch(function (error) {
				console.error(error.Message);
				setIsLoading(false);
				showNotification({ color: "red", message: error.Message });
			});
	};

	useEffect(() => {
		setEmployees(
			employeeListType === EmployeeListType.ACTIVE
				? employeesData.employees
				: employeesData.inactiveEmployees
		);
		if (
			employeesData.inactiveEmployees.length === 0 &&
			employeeListType === EmployeeListType.INACTIVE
		)
			setEmployeeListType(EmployeeListType.ACTIVE);
	}, [
		employeeListType,
		employeesData.employees,
		employeesData.inactiveEmployees,
	]);

	useEffect(() => {
		function shouldFetchEmployeesData(
			companyId: number,
			isLoading: boolean
		): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			// only fetch data here if user has access to the company
			// and has switched companies in the companies selector
			if (userHasAccess && currentCompanyId !== companyId && !isLoading)
				return true;

			return false;
		}

		if (
			shouldFetchEmployeesData(employeesData.companyId, employeesData.isLoading)
		)
			dispatch(fetchEmployeesData());
	}, [
		dispatch,
		userData.user.companies,
		employeesData.companyId,
		employeesData.isLoading,
	]);
	const CreateEmployeeButton = (
		<Grid.Col md={6} lg={3}>
			<Card withBorder p="md" radius="md" className={classes.card}>
				<Card.Section sx={{ height: 228 }}>
					<ActionIcon
						size={100}
						mx="auto"
						mt={90}
						onClick={() => {
							setCreateEmployee(true);
						}}>
						<Plus size={100} />
					</ActionIcon>
				</Card.Section>
			</Card>
		</Grid.Col>
	);

	return (
		<>
			<LoadingOverlay visible={employeesData.isLoading || isLoading} />
			{employeesData.error ? (
				<div>error occurred: {employeesData.error}</div>
			) : !employeesData.isLoading ? (
				employeesData.employees.length < 1 ? (
					<>
						<NoData
							visible
							message={t("employees.no_data")}
							routeText={t("common.create_employee")}
							onclick={() => setCreateEmployee(true)}
						/>
					</>
				) : (
					<>
						<Grid>
							<Grid.Col span={6}>
								<Title order={1}>
									{t("employees.title")}
									<Menu
										style={{ minWidth: 250 }}
										placement="end"
										control={
											<Button
												style={{
													backgroundColor: "transparent",
													padding: "0 0 0 16px",
												}}
												leftIcon={<Menu2 />}
											/>
										}>
										<Menu.Label>{t("employees.title")}</Menu.Label>
										<Menu.Item
											onClick={() => setCreateEmployee(true)}
											icon={<UserPlus />}>
											{t("company_actions.register_employee")}
										</Menu.Item>
										<Menu.Label>
											{t("employees_menu.labels.salary_changes")}
										</Menu.Label>
										<Menu.Item
											onClick={() => navigate("/app/salarychange")}
											icon={<Plus />}>
											{t("employees_menu.actions.register_salary_change")}
										</Menu.Item>
									</Menu>
								</Title>
							</Grid.Col>
							<Grid.Col span={6}>
								<Chips
									sx={(theme) => ({
										float: "right",
										marginTop: 8,
										backgroundColor: theme.colors.dark[5],
										borderRadius: "32px",
										padding: "4px",
									})}
									multiple={false}
									value={employeeListType.toString()}
									onChange={setEmployeeListType}>
									<Chip value={EmployeeListType.ACTIVE}>
										{t("employees.active")}
									</Chip>
									<Chip
										disabled={employeesData.inactiveEmployees.length === 0}
										value={EmployeeListType.INACTIVE}>
										{t("employees.inactive")}
									</Chip>
								</Chips>
							</Grid.Col>
						</Grid>
						<SimpleGrid
							sx={{
								margin: "8px 0",
							}}
							cols={2}>
							<TextInput
								value={searchTerm}
								onChange={searchEmployees}
								placeholder={t("employees.search")}
								sx={{
									minWidth: "360px",
								}}
							/>
							<div
								style={{
									justifySelf: "end",
								}}>
								<SegmentedControl
									data={[
										{
											label: (
												<Center>
													<LayoutGrid />
													{t("employees.card")}
												</Center>
											),
											value: "CARD",
										},
										{
											label: (
												<Center>
													<List />
													{t("employees.list")}
												</Center>
											),
											value: "LIST",
										},
									]}
									value={view}
									onChange={setView}
								/>
							</div>
						</SimpleGrid>
						{view === "CARD" ? (
							<Grid>
								{employees.map((employee) => (
									<EmployeeCard
										onEmployeeSelect={() => fetchSingleEmployeesData(employee)}
										key={employee.id}
										employee={employee}
										deleteEmployee={deleteEmployee}
										deactivateEmployee={deactivateEmployee}
										activateEmployee={activateEmployee}
										showPayslips={showPayslips}
									/>
								))}
								{employeeListType === EmployeeListType.ACTIVE &&
									CreateEmployeeButton}
							</Grid>
						) : (
							<Table>
								<tbody>
									{employees.map((employee) => (
										<EmployeeList
											onEmployeeSelect={() =>
												fetchSingleEmployeesData(employee)
											}
											key={employee.id}
											employee={employee}
											deleteEmployee={deleteEmployee}
											deactivateEmployee={deactivateEmployee}
											activateEmployee={activateEmployee}
											showPayslips={showPayslips}
										/>
									))}
								</tbody>
							</Table>
						)}
					</>
				)
			) : (
				// TODO: convert this to a component
				<Center>
					<div>{t("common.fetching_employees")}</div>
				</Center>
			)}
			{selectedEmployee && employeeData ? (
				<EmployeeFormComponent
					showInModal={true}
					title={selectedEmployee.fullName}
					employeeData={employeeData}
					onEditCancel={() => {
						setSelectedEmployee(null);
						setEmployeeData(null);
					}}
				/>
			) : null}
			{createEmployee ? (
				<EmployeeFormComponent
					showInModal={true}
					onEditCancel={() => {
						setSelectedEmployee(null);
						setEmployeeData(null);
						setCreateEmployee(false);
					}}
				/>
			) : null}
			<Modal opened={opened} onClose={() => setOpened(false)} size="100%">
				<EmployeePayslipsView
					payslips={payslips}
					fetchPayslip={fetchPayslip}
					setSendModalOpened={setSendModalOpened}
					setPayslipToSend={setPayslipToSend}
				/>
			</Modal>
			<Modal opened={show} onClose={() => setShow(false)} size="100%">
				<TypographyStylesProvider>
					<div id="print" dangerouslySetInnerHTML={{ __html: payslip }} />
				</TypographyStylesProvider>
				<Group position="center">
					<Button
						color="gray"
						variant="filled"
						onClick={() => {
							const printWindow = window.open();
							if (printWindow) {
								printWindow.document.write("<title>wages</title>");
								printWindow.document.write(
									document.getElementById("print")?.innerHTML || ""
								);
								printWindow.document.close(); // necessary for IE >= 10
								printWindow.focus(); // necessary for IE >= 10*/
								printWindow.print();
								printWindow.close();
							}

							return true;
						}}>
						Print
					</Button>
				</Group>
			</Modal>
			<Modal
				size="xl"
				opened={sendModalOpened}
				onClose={() => setSendModalOpened(false)}
				title={
					<Title order={3}>
						{t("employees.send_payslip")} #{payslipToSend.id} -{" "}
						{payslipToSend.employeeName}
					</Title>
				}>
				<TextInput
					value={payslipToSend?.employeeEmail}
					onChange={(e) => {
						if (e.target.value) {
							setPayslipToSend({
								...payslipToSend,
								employeeEmail: e.target.value,
							});
						}
					}}
				/>
				<Center mt={5}>
					<Button onClick={sendPayslip}>{t("employees.send_payslip")}</Button>
				</Center>
			</Modal>
		</>
	);
}

export default Employees;
