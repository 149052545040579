import React, { useState } from "react";
import { TextInput, Center, Popover, Button } from "@mantine/core";
import { Calendar, TimeInput } from "@mantine/dates";
import { CalendarTime, Check, Divide } from "tabler-icons-react";
import { format } from "date-fns";

function DateTimePicker({
	date,
	setDate,
	enabled = true,
}: {
	date: Date;
	setDate: (date: Date) => void;
	enabled?: boolean;
}) {
	const [opened, setOpened] = useState(false);

	const changeDate = (newDate: Date) => {
		newDate.setHours(date.getHours());
		newDate.setMinutes(date.getMinutes());
		setDate(newDate);
	};

	const changeTime = (time: Date) => {
		const hours = time.getHours();
		const minutes = time.getMinutes();
		let timeAdjustedDate = new Date(date);
		timeAdjustedDate.setHours(hours);
		timeAdjustedDate.setMinutes(minutes);
		setDate(timeAdjustedDate);
	};

	return (
		<>
			<Popover
				opened={opened}
				onClose={() => setOpened(false)}
				position="bottom"
				width="target"
				target={
					<TextInput
						disabled={!enabled}
						onClick={() => {
							setOpened(!opened);
						}}
						value={format(date, "dd/MM/yyyy HH:mm")}
						onChange={() => {}}
					/>
				}>
				<>
					<Calendar value={date} onChange={changeDate} />
					<Center>
						<TimeInput
							icon={<CalendarTime />}
							value={date}
							onChange={changeTime}
						/>
						<Button
							onClick={() => {
								setOpened(false);
							}}>
							<Check />
						</Button>
					</Center>
				</>
			</Popover>
		</>
	);
}

export default DateTimePicker;
