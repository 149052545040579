import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AccoutChart } from "../../data/types/payslip";
import {
	ColumnAlign,
	TableData,
	tableHeaderColumn,
} from "../../components/ui/common/tableData";
import { Amount } from "../../components/ui/common/currency";
import { CardView } from "../../components/ui/common/sharedComponents";
import { format } from "date-fns";
import { Box, Divider, Group, Space, Tooltip } from "@mantine/core";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
export default function AccountingEntrie({
	accountingData,
}: {
	accountingData: AccoutChart[];
}) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);
	const pdfDocumnet = new jsPDF();
	const tableHeaderTranslations: string[] = t(
		"salary_process_view.accouting_table_header",
		{ returnObjects: true }
	);
	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1], align: ColumnAlign.RIGHT },
		{ text: tableHeaderTranslations[2], align: ColumnAlign.RIGHT },
		{ text: tableHeaderTranslations[3], align: ColumnAlign.RIGHT },
	];
	useEffect(() => {
		var tableBody =
			accountingData &&
			accountingData.map((data: AccoutChart, index: number) => {
				return (
					<tr key={index}>
						<td>{data.description}</td>
						<td align="right">
							<Amount value={data.amount} />
						</td>
						<td align="right">
							<Amount value={data.debitkey} />
						</td>
						<td align="right">
							<Amount value={data.creditkey} />
						</td>
					</tr>
				);
			});
		setRows(tableBody);
	}, [t, accountingData]);
	const pdf = () => {
		const headers = ["description", "amount", "debitkey", "creditkey"];
		const tableRow: any = [];
		accountingData.forEach((tabelRow: any) => {
			let tableCell: any = [];
			headers.forEach((tableHeader: any) => {
				tableCell.push(tabelRow[tableHeader]);
			});
			tableRow.push(tableCell);
		});
		autoTable(pdfDocumnet, {
			headStyles: { fillColor: "gray" },
			theme: "grid",
			head: [tableHeaderTranslations],
			body: tableRow,
		});

		pdfDocumnet.save(`${t("common.acounting_entrie")}.pdf`);
	};
	const exportJsonData = () => {
		const data = accountingData;
		const fileName = `${t("common.acounting_entrie")}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.json;
		return exportFromJSON({ data, fileName, exportType });
	};
	const exportTxtData = () => {
		const data = accountingData;
		const fileName = `${t("common.acounting_entrie")}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.txt;
		return exportFromJSON({ data, fileName, exportType });
	};
	const exportCsvData = () => {
		const data = accountingData;
		const fileName = `${t("common.acounting_entrie")}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.csv;
		return exportFromJSON({ data, fileName, exportType });
	};
	const exportXlsData = () => {
		const data = accountingData;
		const fileName = `${t("common.acounting_entrie")}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.xls;
		return exportFromJSON({ data, fileName, exportType });
	};
	return (
		<CardView
			value={
				<>
					{rows && (
						<>
							<TableData tableHeader={tableHeaders} tableBody={rows} />
							<Space />
							<Divider m={"md"} />
							<Group position="center">
								<Box>{t("common.download_file")}</Box>
								<Tooltip
									arrowSize={6}
									label={
										t("salary_process_view.accounting_table_export", {
											returnObjects: true,
										})[0]
									}
								>
									<Box
										onClick={exportXlsData}
										style={{
											textDecoration: "none",
											color: "cyan",
											cursor: "pointer",
										}}
									>
										xls
									</Box>
								</Tooltip>
								<Tooltip
									label={
										t("salary_process_view.accounting_table_export", {
											returnObjects: true,
										})[1]
									}
								>
									<Box
										onClick={exportCsvData}
										style={{
											textDecoration: "none",
											color: "cyan",
											cursor: "pointer",
										}}
									>
										csv
									</Box>
								</Tooltip>
								<Tooltip
									arrowSize={6}
									label={
										t("salary_process_view.accounting_table_export", {
											returnObjects: true,
										})[2]
									}
								>
									<Box
										onClick={exportJsonData}
										style={{ cursor: "pointer", color: "cyan" }}
									>
										json
									</Box>
								</Tooltip>
								<Tooltip
									arrowSize={6}
									label={
										t("salary_process_view.accounting_table_export", {
											returnObjects: true,
										})[3]
									}
								>
									<Box
										onClick={pdf}
										style={{ cursor: "pointer", color: "cyan" }}
									>
										pdf
									</Box>
								</Tooltip>
								<Tooltip
									label={
										t("salary_process_view.accounting_table_export", {
											returnObjects: true,
										})[4]
									}
								>
									<Box
										onClick={exportTxtData}
										style={{ cursor: "pointer", color: "cyan" }}
									>
										txt
									</Box>
								</Tooltip>
							</Group>
						</>
					)}
				</>
			}
		/>
	);
}
