import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { blogClient } from "../../site/contentfulClient";

import { RootState } from "../../app/store";

interface BlogEntry {
    article: {
        fields: {
            title: string;
            description: string;
            publishDate: Date;
            content: string
        }
    }
}

interface BlogState {
    blogEntries: Array<BlogEntry>,
    isLoading: boolean,
    error: boolean
}

const initialState: BlogState = {
    blogEntries: [] as Array<BlogEntry>,
    isLoading: false,
    error: false
};

export const blogSlice = createSlice({
    name: "blogentries",
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        blogEntriesLoaded: (state, action) => {
            state.blogEntries = action.payload;
            state.isLoading = false;
        }
    },
});

export const { startLoading, hasError, blogEntriesLoaded } = blogSlice.actions;

export const fetchAllBlogEntries = async () => (dispatch: Dispatch) => {
    dispatch(startLoading());

    try {
        blogClient
            .getEntries({ content_type: 'blogPost', order: '-fields.publishDate' })
            .then((entries) => dispatch(blogEntriesLoaded(entries.items)));
    } catch (error: any) {
        console.log(error);
        dispatch(hasError(error));
    }
};

export default blogSlice.reducer;
export const selectBlogEntries = (state: RootState) => state.blogEntries;