import React, { useEffect } from "react";
import {
	Button,
	Card,
	Grid,
	Group,
	Select,
	SimpleGrid,
	Switch,
	Text,
} from "@mantine/core";
import { t } from "react-i18nify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
	fetchPayslipBatchesData,
	selectPayslipBatchesData,
	setPayslipBatchInUse,
} from "../../data/payslips/payslipBatchesData";
import { useFormik } from "formik";
import DateTimePicker from "../dateTimePicker";
import { SalaryProcessCreatePostModel } from "../../data/salaryProcess/models";
import { getCurrentCompanyId } from "../../helpers/user";
import { PayslipBatchesData } from "../../data/types/payslipBatches";
import { useStyles } from "./salaryProcessList";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { Check, ExclamationMark } from "tabler-icons-react";
import {
	addSalaryProcessData,
	selectSalaryProcessData,
} from "../../data/salaryProcess/salaryProcessSlice";
import { formatDateTimeToIsoString } from "../../helpers/functions";
import { DataStatus } from "../../data/types/shared";
import { set } from "date-fns";
import { LaunaLoadingOverlay } from "../ui/common/loadingOverlay";

export default function CreateSalaryProcess() {
	const { classes } = useStyles();
	const payslipBatches = useAppSelector(selectPayslipBatchesData);
	const salaryProcessData = useAppSelector(selectSalaryProcessData);
	const [selectedBatchData, setSelectedBatchData] = React.useState<
		PayslipBatchesData | undefined
	>(undefined);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const shouldFetch =
			payslipBatches.status === DataStatus.NOT_FETCHED ||
			payslipBatches.status === DataStatus.NEEDS_UPDATE;
		if (shouldFetch) {
			dispatch(fetchPayslipBatchesData(new Date().getFullYear()));
		}
	}, [dispatch, payslipBatches.status]);

	const payslipBatchesData = payslipBatches.data
		.filter((b) => !b.inUse) // If the inUse boolean is true, it means that the batch is already used in a salary process
		.map((payslipBatch) => ({
			label: payslipBatch.batchName,
			value: payslipBatch.id.toString(),
		}));

	const today = new Date();

	const formik = useFormik({
		initialValues: {
			payslip_in_email: true,
			payslip_to_online_bank: true,
			rsk_reports: true,
			pension_reports: true,
			payment_request_to_online_bank: true,
			child_support: true,
			payslip_in_email_date: today,
			payslip_to_online_bank_date: today,
			rsk_reports_date: today,
			pension_reports_date: today,
			payment_request_to_online_bank_date: today,
			child_support_date: today,
			payslipBatch: null,
			enable_all: true,
		},
		onSubmit: (values) => {
			if (!selectedBatchData) return;

			setIsSubmitting(true);

			const payload: SalaryProcessCreatePostModel = {
				companyId: getCurrentCompanyId(),
				payslipBatchIds: [selectedBatchData.id],
				yearMonth: selectedBatchData.year + "-" + selectedBatchData.month,
				salaryPayDay: selectedBatchData.payDay,
				sendPayslipsEmail: values.payslip_in_email
					? formatDateTimeToIsoString(values.payslip_in_email_date)
					: null,
				sendPayslipsBank: values.payslip_to_online_bank
					? formatDateTimeToIsoString(values.payslip_to_online_bank_date)
					: null,
				sendRsk: values.rsk_reports
					? formatDateTimeToIsoString(values.rsk_reports_date)
					: null,
				sendUnionAndPension: values.pension_reports
					? formatDateTimeToIsoString(values.pension_reports_date)
					: null,
				sendChildSupport: values.child_support
					? formatDateTimeToIsoString(values.child_support_date)
					: null,
				sendPaymentRequest: values.payment_request_to_online_bank
					? formatDateTimeToIsoString(
							values.payment_request_to_online_bank_date
					  )
					: null,
			};

			axios
				.post(
					`${process.env.REACT_APP_API_URL}/api/v2/salaryprocess/register`,
					payload
				)
				.then(function (response) {
					setIsSubmitting(false);
					if (response.status === 200) {
						//TODO: this is cuasing an error, fix it but until then we just refetch the data
						// if (salaryProcessData.status === DataStatus.FETCHED) {
						// 	dispatch(addSalaryProcessData(response.data));
						// }
						dispatch(fetchPayslipBatchesData(new Date().getFullYear()));

						dispatch(setPayslipBatchInUse(selectedBatchData.id));
						showNotification({
							message: "Launavinnsla stofnuð",
							color: "green",
							icon: <Check size={16} />,
						});
					} else {
						showNotification({
							autoClose: false,
							title: "Villa",
							message: "Villa kom upp við að stofna launavinnslu",
							color: "red",
							icon: <ExclamationMark size={16} />,
						});
					}
					formik.setSubmitting(false);
					formik.resetForm();
					setSelectedBatchData(undefined);
				})
				.catch((error) => {
					console.log("error", error);
					showNotification({
						autoClose: false,
						title: "Villa",
						message: "Villa kom upp við að stofna launavinnslu",
						color: "red",
						icon: <ExclamationMark size={16} />,
					});
					formik.setSubmitting(false);
					formik.resetForm();
					setSelectedBatchData(undefined);
				});
		},
	});

	const { values, setFieldValue, handleSubmit } = formik;

	useEffect(() => {
		if (values.enable_all) {
			setFieldValue("payslip_in_email", true);
			setFieldValue("payslip_to_online_bank", true);
			setFieldValue("rsk_reports", true);
			setFieldValue("pension_reports", true);
			setFieldValue("payment_request_to_online_bank", true);
			setFieldValue("child_support", true);
		} else {
			setFieldValue("payslip_in_email", false);
			setFieldValue("payslip_to_online_bank", false);
			setFieldValue("rsk_reports", false);
			setFieldValue("pension_reports", false);
			setFieldValue("payment_request_to_online_bank", false);
			setFieldValue("child_support", false);
		}
	}, [values.enable_all, setFieldValue]);

	useEffect(() => {
		if (values.payslipBatch) {
			const batchData = payslipBatches.data.find(
				(batch) => batch.id.toString() === values.payslipBatch
			);
			if (batchData?.payDay) {
				// set default dates/times based on payDay
				// these 2 fields should be set to payDay at 9:00 in the morning
				let dateAtNineInTheMorning = new Date(batchData.payDay);
				dateAtNineInTheMorning.setHours(9, 0);
				setFieldValue(
					"payslip_in_email_date",
					new Date(dateAtNineInTheMorning)
				);
				setFieldValue(
					"payslip_to_online_bank_date",
					new Date(dateAtNineInTheMorning)
				);

				// these 2 fields should be set to 7th day of the following month at midnight
				// example: payDay is 2023-05-31, then these fields should be set to 2023-05-07 00:00
				let seventhAtMidNight = new Date(batchData.payDay);
				seventhAtMidNight.setMonth(seventhAtMidNight.getMonth() + 1, 7);
				setFieldValue("rsk_reports_date", new Date(seventhAtMidNight));
				setFieldValue("pension_reports_date", new Date(seventhAtMidNight));

				// these 2 fields should be set to payDay at midnight (which should just be batchData.payDay)
				setFieldValue(
					"payment_request_to_online_bank_date",
					new Date(batchData.payDay)
				);
				setFieldValue("child_support_date", new Date(batchData.payDay));
			}

			setSelectedBatchData(batchData);
		}
	}, [values.payslipBatch, payslipBatches.data, setFieldValue]);

	return (
		<>
			<LaunaLoadingOverlay
				visible={isSubmitting}
				label="Stofna launavinnslu ..."
			/>
			<Card withBorder p="xl" radius="md" className={classes.card}>
				<form onSubmit={handleSubmit}>
					<Grid p="md">
						<Grid.Col md={8}>
							<Text size="lg">{t("salary_process.select_batch")}</Text>
							<Select
								my={7}
								data={payslipBatchesData}
								searchable
								spellCheck={false}
								value={values.payslipBatch}
								onChange={(value) => {
									setFieldValue("payslipBatch", value);
								}}
							/>
						</Grid.Col>
						<Grid.Col md={4}>
							<Button
								style={{ float: "right", marginTop: "35px" }}
								disabled={values.payslipBatch ? false : true}
								type="submit"
								loading={formik.isSubmitting}>
								{t("salary_process.save")}
							</Button>
						</Grid.Col>
					</Grid>
					{values.payslipBatch && (
						<Switch
							checked={values.enable_all}
							onChange={(event) => {
								setFieldValue("enable_all", event.currentTarget.checked);
							}}
							label={t("salary_process.enable_all")}
							p="md"
						/>
					)}
					{values.payslipBatch && values.enable_all && (
						<SimpleGrid
							cols={2}
							breakpoints={[{ maxWidth: 850, cols: 1 }]}
							p="md"
							spacing="md">
							<SimpleGrid cols={2}>
								<Switch
									checked={values.payslip_in_email}
									onChange={(event) => {
										setFieldValue(
											"payslip_in_email",
											event.currentTarget.checked
										);
									}}
									label={t("salary_process.payslip_in_email")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.payslip_in_email}
										date={values.payslip_in_email_date}
										setDate={(date) => {
											setFieldValue("payslip_in_email_date", date);
										}}
									/>
								</Group>
							</SimpleGrid>
							<SimpleGrid cols={2}>
								<Switch
									checked={values.payslip_to_online_bank}
									onChange={(event) => {
										setFieldValue(
											"payslip_to_online_bank",
											event.currentTarget.checked
										);
									}}
									label={t("salary_process.payslip_to_online_bank")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.payslip_to_online_bank}
										date={values.payslip_to_online_bank_date}
										setDate={(date) => {
											setFieldValue("payslip_to_online_bank_date", date);
										}}
									/>
								</Group>
							</SimpleGrid>
							<SimpleGrid cols={2}>
								<Switch
									checked={values.rsk_reports}
									onChange={(event) => {
										setFieldValue("rsk_reports", event.currentTarget.checked);
									}}
									label={t("salary_process.rsk_reports")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.rsk_reports}
										date={values.rsk_reports_date}
										setDate={(date) => {
											setFieldValue("rsk_reports_date", date);
										}}
									/>
								</Group>
							</SimpleGrid>
							<SimpleGrid cols={2}>
								<Switch
									checked={values.pension_reports}
									onChange={(event) => {
										setFieldValue(
											"pension_reports",
											event.currentTarget.checked
										);
									}}
									label={t("salary_process.pension_reports")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.pension_reports}
										date={values.pension_reports_date}
										setDate={(date) => {
											setFieldValue("pension_reports_date", date);
										}}
									/>
								</Group>
							</SimpleGrid>
							<SimpleGrid cols={2}>
								<Switch
									checked={values.payment_request_to_online_bank}
									onChange={(event) => {
										setFieldValue(
											"payment_request_to_online_bank",
											event.currentTarget.checked
										);
									}}
									label={t("salary_process.payment_request_to_online_bank")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.payment_request_to_online_bank}
										date={values.payment_request_to_online_bank_date}
										setDate={(date) => {
											setFieldValue(
												"payment_request_to_online_bank_date",
												date
											);
										}}
									/>
								</Group>
							</SimpleGrid>
							<SimpleGrid cols={2}>
								<Switch
									checked={values.child_support}
									onChange={(event) => {
										setFieldValue("child_support", event.currentTarget.checked);
									}}
									label={t("salary_process.child_support")}
								/>
								<Group position="left">
									<DateTimePicker
										enabled={values.child_support}
										date={values.child_support_date}
										setDate={(date) => {
											setFieldValue("child_support_date", date);
										}}
									/>
								</Group>
							</SimpleGrid>
						</SimpleGrid>
					)}
				</form>
			</Card>
		</>
	);
}
