import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
	createStyles,
	Title,
	Text,
	Button,
	Container,
	Group,
	Switch,
	useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
	isUserBetaPreview,
	setUserBetaPreview,
	unSetUserBetaPreview,
} from "../../helpers/user";
import { IconCheck, IconX } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	label: {
		textAlign: "center",
		fontWeight: 900,
		fontSize: 220,
		lineHeight: 1,
		marginBottom: theme.spacing.xl * 1.5,
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[4]
				: theme.colors.gray[2],

		[theme.fn.smallerThan("sm")]: {
			fontSize: 120,
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: "center",
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan("sm")]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 500,
		margin: "auto",
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl * 1.5,
	},
}));

export default function BetaPreview() {
	const theme = useMantineTheme();
	const { t } = useTranslation();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [betaChecked, setBetaChecked] = useState(isUserBetaPreview());
	const baseUrl = process.env.REACT_APP_URL;
	const appUrl = baseUrl + "/app";

	return (
		<Container className={classes.root}>
			<Title className={classes.title}>Aðgangur að launa V2 BETA</Title>
			<Text
				color="dimmed"
				size="lg"
				align="center"
				className={classes.description}>
				{/* {t("notfound.text")} */}
				Við erum að endurskrifa launa kerfið. Hér er hægt að virkja aðgang að
				BETA útgáfu nýja kerfisins sem veitir betri, hraðvirkari og skilvirkari
				upplifun.
			</Text>
			<Group position="center">
				<Switch
					checked={betaChecked}
					label={betaChecked ? "Óvirkja BETA útgáfu" : "Virkja BETA útgáfu"}
					size="lg"
					onChange={(event) => {
						setBetaChecked(event.currentTarget.checked);
						if (event.currentTarget.checked) setUserBetaPreview();
						else unSetUserBetaPreview();
					}}
				/>
			</Group>
			<Group position="center" style={{ marginTop: 24 }}>
				{betaChecked ? (
					<>
						<div
							style={{
								textAlign: "center",
								lineHeight: 2,
							}}>
							<strong>BETA útgáfa nýja launa kerfisins er virk.</strong>
							<br />
							<br />
							Nýja kerfið er aðgengilegt með því að fara inn á slóðina{" "}
							<a
								style={{ color: "#ffffff" }}
								href={appUrl}
								onClick={(ev) => {
									ev.preventDefault();
									navigate("/app");
								}}>
								{appUrl}
							</a>{" "}
							<br />
							eða með því að smella á "Innskráning" eða "Stjórnborð" hnapp á
							slóðinni{" "}
							<a
								style={{ color: "#ffffff" }}
								href={baseUrl}
								onClick={(ev) => {
									ev.preventDefault();
									navigate("/");
								}}>
								{baseUrl}
							</a>
							. <br />
							Athugið að enn hafa ekki allar aðgerðir verið innleiddar í nýja
							kerfið og munu tenglar á þær aðgerðir vísa inn á gamla kerfið.
						</div>
					</>
				) : null}
			</Group>
		</Container>
	);
}
