import React, { MouseEventHandler } from "react";
import { Button, MantineColor } from "@mantine/core";
import { logOut } from "../../helpers/user";
import { Trash } from "tabler-icons-react";
interface Props {
	children: string | JSX.Element;
	type?: "button" | "submit" | "reset" | undefined;
	fullWidth?: boolean;
	loading?: boolean;
	disabled?: boolean;
	onClick?: MouseEventHandler;
}
interface PropsAction {
	children: string | JSX.Element;
	type?: "button" | "submit" | "reset" | undefined;
	fullWidth?: boolean;
	loading?: boolean;
	color?: MantineColor;
	onClick: MouseEventHandler;
}
function PrimaryButton(props: Props) {
	return (
		<Button mt="xl" size="md" loaderPosition="right" {...props}>
			{props.children}
		</Button>
	);
}
function ActionButton(props: PropsAction) {
	return (
		<Button ml={"xs"} {...props} radius={"md"} loaderPosition="right">
			{props.children}
		</Button>
	);
}

function LogOutButton(props: Props) {
	return (
		<Button mt="xl" size="md" {...props} onClick={logOut}>
			{props.children}
		</Button>
	);
}

function DeleteButton(props: {
	text: string;
	onClick: () => any;
	fullWidth?: boolean;
}) {
	return (
		<Button
			fullWidth={props.fullWidth}
			color="red"
			radius={8}
			rightIcon={<Trash />}
			onClick={() => props.onClick()}>
			{props.text}
		</Button>
	);
}

export { PrimaryButton, LogOutButton, ActionButton, DeleteButton };
