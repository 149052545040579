import React, { useEffect, useState } from "react";
import { fetchUserData, selectUser } from "../data/user/userSlice";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../components/ui/buttons";
import {
	Divider,
	Paper,
	SimpleGrid,
	Title,
	TextInput,
	Select,
	Center,
	Modal,
	Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useElementSize } from "@mantine/hooks";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { IconCheck, IconExclamationMark } from "@tabler/icons";
import { ListItem } from "../data/types/shared";
import { useNavigate } from "react-router";
import { LaunaLoadingOverlay } from "../components/ui/common/loadingOverlay";

interface Props {
	openModal: boolean;
}
interface ValidationError {
	entity: string;
	message: string;
}
export default function CompanyForm(props: Props) {
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const [listData, setListData] = useState<ListItem[]>([]);
	const { t } = useTranslation();
	const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const form = useForm({
		initialValues: {
			registrationId: "",
			name: "",
			address: "",
			city: "",
			postalcode: "",
			country: "ISL",
			email: "",
			phone: "",
			Isat: "",
			plan: "STANDARD",
		},

		validate: {
			email: (value) =>
				/^\S+@\S+$/.test(value) ? null : t("company.email_address_invalid"),
			registrationId: (value) =>
				value.length === 0 ? t("company.errors.company_id_missing") : null,
		},
	});

	useEffect(() => {
		setOpened(props.openModal);

		let adjusted_list_data: ListItem[] = [];
		const fetchListData = () => {
			axios
				.get(`${process.env.REACT_APP_API_URL}/listdata/companyregistration`)
				.then(function (response) {
					const list_data = response.data.isatModel.results;
					list_data.forEach((element: { id: string; text: string }) => {
						adjusted_list_data.push({ value: element.id, label: element.text });
					});
					setListData(adjusted_list_data);
				})
				.catch(function (error) {
					//TODO: handle error
				});
		};

		if (listData.length === 0) {
			fetchListData();
		}
	}, [props.openModal, dispatch, listData.length, user.user]);

	const { ref, width } = useElementSize();

	return (
		<>
			<LaunaLoadingOverlay visible={listData.length === 0} />
			<Modal
				opened={opened}
				size="80%"
				withCloseButton={true}
				onClose={() => {
					setOpened(false);
					navigate("/app");
				}}>
				<Paper ref={ref} mx="auto" p={12}>
					<Title order={3}>{t("company.register")}</Title>
					<em>{t("company.info")}</em>
					<Divider my="sm" />
					<form
						onSubmit={form.onSubmit((values) => {
							setLoading(true);
							axios
								.post(
									`${process.env.REACT_APP_API_URL}/company/create`,
									values,
									{
										headers: {
											Accept: "application/json",
											"Content-Type": "application/json",
										},
									}
								)
								.then(function (response) {
									if (response.status === 200) {
										showNotification({
											message: "Fyrirtæki vistað",
											color: "green",
											icon: <IconCheck size={16} />,
										});
										dispatch(fetchUserData());
										setLoading(false);
									}
								})
								.catch((error) => {
									error.response?.data?.errors?.foreach(
										(err: ValidationError) => {
											showNotification({
												autoClose: false,
												title: "Villa",
												message: err.message,
												color: "red",
												icon: <IconExclamationMark size={16} />,
											});
										}
									);
									setLoading(false);
								});
						})}>
						<SimpleGrid cols={width > 768 ? 2 : 1} spacing="lg">
							<TextInput
								label={t("company.company_id")}
								placeholder={t("company.company_id")}
								size="md"
								{...form.getInputProps("registrationId")}
							/>
							<TextInput
								label={t("company.address")}
								placeholder={t("company.address")}
								size="md"
								{...form.getInputProps("address")}
							/>
							<TextInput
								label={t("company.name")}
								placeholder={t("company.name")}
								size="md"
								{...form.getInputProps("name")}
							/>
							<TextInput
								label={t("company.postalcode")}
								placeholder={t("company.postalcode")}
								size="md"
								{...form.getInputProps("postalcode")}
							/>
							<TextInput
								label={t("company.email")}
								placeholder={t("company.email")}
								size="md"
								{...form.getInputProps("email")}
							/>
							<TextInput
								label={t("company.city")}
								placeholder={t("company.city")}
								size="md"
								{...form.getInputProps("city")}
							/>
							<TextInput
								label={t("company.phone")}
								placeholder={t("company.company_phone")}
								size="md"
								{...form.getInputProps("phone")}
							/>
							<Select
								label={t("company.company_is_at")}
								placeholder={t("company.company_is_at")}
								searchable
								clearable
								spellCheck={false}
								dropdownPosition="bottom"
								data={listData}
								{...form.getInputProps("Isat")}
							/>
						</SimpleGrid>
						<Center>
							<PrimaryButton type="submit" loading={loading}>
								{t("company.create")}
							</PrimaryButton>
						</Center>
					</form>
				</Paper>
			</Modal>
		</>
	);
}
