import React from "react";
import { PaySlipSalaryElement } from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";

function PayslipSalaryelementsReadOnly(props: {
	salaryElements: PaySlipSalaryElement[];
}) {
	const getPayslipSalaryTotal = () => {
		let total = 0;
		props.salaryElements
			// showing summary of types: 0 = salary, 2 = perks, 4 = holiday pay
			.filter(
				(el) =>
					el.salaryElementType === 0 ||
					el.salaryElementType === 2 ||
					el.salaryElementType === 4
			)
			.forEach((el) => {
				total += el.value || 0;
			});

		return total;
	};

	return (
		<div key="salaryElementsWrapper">
			<div
				style={{
					padding: "3px",
					marginTop: "5px",
					backgroundColor: "#f1f1f1",
				}}>
				<div
					style={{
						display: "inline-block",
						width: "55%",
						textAlign: "left",
					}}>
					<strong>Laun</strong>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					<strong>Taxti</strong>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					<strong>Ein.</strong>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					<strong>Upphæð</strong>
				</div>
			</div>
			{props.salaryElements
				.filter(
					(el) =>
						el.salaryElementType === 0 ||
						el.salaryElementType === 2 ||
						el.salaryElementType === 4
				)
				.map((el) => {
					return (
						<div key={el.id + el.description + "_readonly"}>
							<div
								style={{
									display: "inline-block",
									width: "55%",
									textAlign: "left",
								}}>
								{el.description}
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								<Amount value={el.baseUnitValue} />
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								<Amount value={el.units} />
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								<Amount value={el.value} />
							</div>
						</div>
					);
				})}
			<div
				style={{ display: "inline-block", width: "85%", textAlign: "right" }}>
				<strong>Samtals laun:</strong>
			</div>
			<div
				style={{ display: "inline-block", width: "15%", textAlign: "right" }}>
				<strong>
					<Amount value={getPayslipSalaryTotal()} />
				</strong>
			</div>
		</div>
	);
}

export default PayslipSalaryelementsReadOnly;
