import React, { useEffect, useState } from "react";
import { NoData } from "../ui/common/sharedComponents";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../../data/user/userSlice";
import {
	fetchSalaryProcessData,
	selectSalaryProcessData,
	dataDeleted,
} from "../../data/salaryProcess/salaryProcessSlice";
import { getCurrentCompanyId } from "../../helpers/user";
import { DataStatus } from "../../data/types/shared";
import {
	Badge,
	Card,
	Table,
	Tooltip,
	UnstyledButton,
	createStyles,
	Text,
} from "@mantine/core";
import { LaunaLoadingOverlay } from "../ui/common/loadingOverlay";
import { tableHeaderColumn } from "../ui/common/tableData";
import { DateRange } from "../ui/common/dateAndTime";
import { formatDateTimeStringWithMonthName } from "../../helpers/functions";
import { DeliveryData } from "../../data/salaryProcess/models";
import { Check, ExclamationMark, ClockOff } from "tabler-icons-react";
import { IconClockPlay } from "@tabler/icons";
import SalaryProcessMenu from "./salaryProcessMenu";
import { showNotification } from "@mantine/notifications";
import SalaryProcessDeliveryTimes from "./salaryProcessDeliveryTimes";
import { useModals } from "@mantine/modals";
import axios from "axios";
import { setPayslipBatchNotInUse } from "../../data/payslips/payslipBatchesData";

export const useStyles = createStyles((theme) => ({
	card: {
		marginBottom: "20px",
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
	},
	lightcard: {
		padding: "12px!important",
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
	},
}));

export default function SalaryProcessList() {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const userData = useAppSelector(selectUser);
	const salaryProcessData = useAppSelector(selectSalaryProcessData);
	const dispatch = useAppDispatch();
	const modals = useModals();
	const [loading, setLoading] = useState(false);

	const [deliveryDataSpId, setDeliveryDataSpId] = React.useState<
		string | undefined
	>(undefined);

	useEffect(() => {
		function shouldFetchData(companyId: number, needsUpdate: boolean): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			if (userHasAccess && currentCompanyId !== companyId && needsUpdate)
				return true;
			return false;
		}
		const shouldFetch = shouldFetchData(
			salaryProcessData.companyId,
			salaryProcessData.status === DataStatus.NOT_FETCHED ||
				salaryProcessData.status === DataStatus.NEEDS_UPDATE
		);
		if (shouldFetch) {
			dispatch(fetchSalaryProcessData());
		}
	}, [
		dispatch,
		userData.user.companies,
		salaryProcessData.companyId,
		salaryProcessData.status,
	]);

	const tableHeaderTranslations: string[] = t(
		"salary_processe_list.table_header",
		{
			returnObjects: true,
		}
	);

	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[4] },
	];

	const getStatusLabelLabel = (state: string) => {
		switch (state) {
			case "PROCESSED":
				return <Badge color="green">bókuð</Badge>;
			case "CREATED":
				return <Badge color="indigo">stofnuð</Badge>;
			case "IN_PROCESS":
				return <Badge color="orange">í bókunarferli</Badge>;
			default:
				return null;
		}
	};

	const hasDeliveries = (data: DeliveryData) => {
		if (!data) return false;
		return (
			data.csupport.scheduleSendTime !== null ||
			data.lifStett.scheduleSendTime !== null ||
			data.payslipBank.scheduleSendTime !== null ||
			data.payslipEmail.scheduleSendTime !== null ||
			data.paymentRequest.scheduleSendTime !== null ||
			data.rsk.scheduleSendTime !== null
		);
	};

	const markBatchNotInUser = (id: number) => {
		// find the salary process
		const salaryProcess = salaryProcessData.data.find(
			(salaryProcess) => salaryProcess.id === id
		);
		if (!salaryProcess) return;

		const batchId = salaryProcess.batchIds[0];
		dispatch(setPayslipBatchNotInUse(batchId));
	};

	const deleteSalaryProcess = (id: number) => {
		setLoading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/api/v2/salaryprocess/${id}/delete`
			)
			.then((response) => {
				console.log(response);
				setLoading(false);
				if (response.data.success) {
					dispatch(dataDeleted(id));
					markBatchNotInUser(id);
					showNotification({
						message: t("salary_process.deleted_successfully"),
						color: "green",
						icon: <Check />,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				showNotification({
					title: t("common.error"),
					message: t("salary_process.delete_error"),
					color: "red",
					icon: <ExclamationMark size={16} />,
				});
			});
	};

	const openConfirmDeleteModal = (id: number) =>
		modals.openConfirmModal({
			size: "lg",
			title: t("common.confirm"),
			children: <Text>{t("salary_process.confirm_delete")}</Text>,
			labels: {
				confirm: t("salary_process.confirm_delete_yes"),
				cancel: t("salary_process.confirm_delete_no"),
			},
			confirmProps: { color: "red" },
			onConfirm: () => deleteSalaryProcess(id),
		});

	return (
		<>
			<LaunaLoadingOverlay
				visible={salaryProcessData.status === DataStatus.FETCHING || loading}
			/>
			{salaryProcessData.status !== DataStatus.FETCHING && (
				<NoData
					visible={
						salaryProcessData.status === DataStatus.EMPTY ||
						!salaryProcessData.data ||
						salaryProcessData.data.length === 0
					}
					message={t("salary_process.no_data")}
					routeText={t("company_actions.payroll_processing")}
					route="/app/salaryprocesses/create"
				/>
			)}
			{salaryProcessData.status !== DataStatus.FETCHING &&
				salaryProcessData.data &&
				salaryProcessData.data.length > 0 && (
					<Card withBorder p="xl" radius="md" className={classes.card}>
						<Table>
							<thead>
								<tr>
									<th></th>
									<th>{tableHeaders[0].text}</th>
									<th>{tableHeaders[1].text}</th>
									<th></th>
									<th colSpan={2}></th>
								</tr>
							</thead>
							<tbody>
								{salaryProcessData.data.map((salaryProcess) => (
									<React.Fragment key={salaryProcess.id}>
										<tr key={salaryProcess.guid}>
											<td>{getStatusLabelLabel(salaryProcess.state)}</td>
											<td>
												{formatDateTimeStringWithMonthName(
													salaryProcess.created
												)}
											</td>
											<td>{salaryProcess.yearMonth}</td>
											<td>
												<DateRange
													from={salaryProcess.periodStarts}
													to={salaryProcess.periodEnds}
												/>
											</td>
											<td style={{ textAlign: "right" }}>
												{hasDeliveries(salaryProcess.deliveryData) ? (
													<Tooltip label="Innsendingar gagna">
														<UnstyledButton
															onClick={() => {
																if (salaryProcess.guid === deliveryDataSpId)
																	setDeliveryDataSpId(undefined);
																else setDeliveryDataSpId(salaryProcess.guid);
															}}>
															<IconClockPlay size={20} />
														</UnstyledButton>
													</Tooltip>
												) : (
													<Tooltip label="Engar innsendingar gagna">
														<ClockOff size={22} />
													</Tooltip>
												)}
											</td>
											<td>
												<SalaryProcessMenu
													salaryProcess={salaryProcess}
													onDelete={() =>
														openConfirmDeleteModal(salaryProcess.id)
													}
												/>
											</td>
										</tr>
										{deliveryDataSpId &&
											deliveryDataSpId === salaryProcess.guid && (
												<tr key={salaryProcess.guid + "_deliveries"}>
													<td colSpan={6}>
														<SalaryProcessDeliveryTimes
															deliveryData={salaryProcess.deliveryData}
														/>
													</td>
												</tr>
											)}
									</React.Fragment>
								))}
							</tbody>
						</Table>
					</Card>
				)}
		</>
	);
}
