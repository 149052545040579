import React from "react";
import { useParams } from "react-router-dom";
import { Notification, PasswordInput } from "@mantine/core";
import { Lock } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useSetState } from "@mantine/hooks";
import { Alert, Space } from "@mantine/core";
import { AlertCircle, Check } from "tabler-icons-react";
import { PrimaryButton } from "../../components/ui/buttons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Wrapper from "../../components/ui/Wrapper";

function ResetPassword() {
    let { token } = useParams();
    const { t } = useTranslation();
    const [state, setState] = useSetState({
        formSubmitting: false,
        forgotPasswordError: "",
        resetDone: false,
    });
    const form = useForm({
        initialValues: {
            pwd: "",
        },
        validate: {
            pwd: (value) =>
                value.length < 8 ? t("authentication.password_too_short_8") : null,
        }
    });
    return (
        <Wrapper title={t("forgot_password.reset_password")}>
            <form
                onSubmit={form.onSubmit((values) => {
                    let postData = {
                        Password: values.pwd,
                        Code: token,
                    };
                    setState({ formSubmitting: true, forgotPasswordError: "" });
                    axios
                        .post(
                            `${process.env.REACT_APP_API_URL}/account/resetpassword`
                            , postData)
                        .then(function (response) {
                            console.log(response);
                            if (response.status === 200) {
                                setState({
                                    formSubmitting: false,
                                    forgotPasswordError: "",
                                    resetDone: true,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })}
            >
                <PasswordInput
                    label=""
                    placeholder={t("forgot_password.new_password")}
                    size="md"
                    icon={<Lock size={14} />}
                    {...form.getInputProps("pwd")}
                />
                <PrimaryButton fullWidth type="submit" loading={state.formSubmitting}>
                    {t("forgot_password.reset_password")}
                </PrimaryButton>
                {state.forgotPasswordError && (
                    <>
                        <Space h="md" />
                        <Alert
                            mt="mt"
                            icon={<AlertCircle size={16} />}
                            title={t("errors.error_occurred")}
                            color="gray"
                        >
                            {state.forgotPasswordError}
                        </Alert>
                    </>
                )}
                {state.resetDone && (
                    <>
                        <Space h="md" />
                        <Notification
                            mt="mt"
                            icon={<Check size={16} />}
                            title={t("forgot_password.password_has_been_reset")}
                            color="teal"
                            onClose={() => {
                                setState({ resetDone: false });
                            }}
                        >
                        </Notification>
                    </>
                )}
            </form>
        </Wrapper>
    );
}

export default ResetPassword;
