import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicPagesWrapper from "../components/ui/publicpages/PublicPagesWrapper";
import { PageEntry } from "../data/types/public/contentful";
import { pageClient } from "./contentfulClient";
import NoMatch from "../pages/public/404";
import { Document } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// export interface IArticle {
// 	article: {
// 		fields: {
// 			title: string;
// 			description: string;
// 			publishDate: Date;
// 			content: string;
// 		};
// 	};
// }

export default function ContentPage() {
	let { slug } = useParams();
	const [pageEntries, setPageEntries] = useState<PageEntry[]>([]);
	const [pageComponent, setPageComponent] = useState<any>();
	const [pageTitle, setPageTitle] = useState<string>("");
	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			const resp = await pageClient.getEntries({
				content_type: "page",
			});
			setPageEntries(resp.items as any);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	useEffect(() => {
		if (loading) {
			setLoading(false);
			fetchData();
		}
		if (pageEntries.length > 0) {
			const currentPage = pageEntries.find(
				(entry) => entry.fields.slug === slug
			);
			if (!currentPage) {
				setPageComponent(<NoMatch />);
			} else {
				const pageField = currentPage.fields.efni as any;

				setPageTitle(currentPage.fields.titill);
				setPageComponent(documentToReactComponents(pageField as Document));
			}
		}
	}, [loading, pageEntries, slug]);

	return (
		<>
			<PublicPagesWrapper title="Ástæðulaust að flækja hlutina | launa">
				<div
					style={{
						width: "100%",
						backgroundColor: "#ffffff",
					}}>
					<div
						style={{
							maxWidth: 1200,
							margin: "40px auto",
							padding: 16,
							color: "#66615b",
						}}>
						<h1 style={{ textAlign: "center" }}>{pageTitle}</h1>
						{pageComponent}
					</div>
				</div>
			</PublicPagesWrapper>
		</>
	);
}
