import React, { useState, useEffect } from "react";
import { Loader, Button, Autocomplete } from "@mantine/core";
import { fetchUserData, selectUser } from "../../data/user/userSlice";
import {
	getCurrentCompanyId,
	setCurrentCompanyId,
	getCurrentCompany,
} from "../../helpers/user";
import { useClickOutside } from "@mantine/hooks";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSettingsData } from "../../data/companySettings/companySettingsSlice";
import { fetchUsers } from "../../data/users/usersSlice";
import { setSalaryProcessState } from "../../data/salaryProcess/salaryProcessSlice";
import { DataStatus } from "../../data/types/shared";
import { setPayslipBatchesState } from "../../data/payslips/payslipBatchesData";

function CompanySelector(props) {
	const userData = useAppSelector(selectUser);
	const [componentData, setComponentData] = useState({
		userLoaded: false,
		userCompanies: [],
		currentCompany: null,
	});
	const [selectMode, setSelectMode] = useState(false);
	const ref = useClickOutside(() => setSelectMode(false));
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userData.user.id) {
			setComponentData({
				userLoaded: true,
				userCompanies: userData.user.companies,
				currentCompany: getCurrentCompany(userData.user.companies),
			});
			if (getCurrentCompanyId() === -1 && userData.user.companies.length > 0) {
				setCurrentCompanyId(userData.user.companies[0].id);
			}
		}
	}, [userData, props.showUserName, componentData.userCompanies]);

	function handleCompanyChange(item) {
		//update local storage
		localStorage.setItem("launa.client.currentcompany.id", item.id);
		//refetch user data
		dispatch(fetchUserData());
		//refetch company settings data
		dispatch(fetchSettingsData());
		//refetch users data
		dispatch(fetchUsers());
		//reset selector view
		setSelectMode(false);
		//reset salary process list and payslip batches
		dispatch(setSalaryProcessState(DataStatus.NEEDS_UPDATE));
		dispatch(setPayslipBatchesState(DataStatus.NEEDS_UPDATE));
	}

	return !componentData.userLoaded ? (
		<Loader size="sm" />
	) : (
		<>
			<div style={{ width: "100%", marginLeft: "24px" }}>
				{componentData.currentCompany ? (
					!selectMode ? (
						<Button
							style={{ width: "100%" }}
							radius="md"
							onClick={() => setSelectMode(true)}>
							{componentData.currentCompany.name}
						</Button>
					) : (
						<div style={{ width: "100%", display: "inline-block" }}>
							<Autocomplete
								radius="md"
								limit={10}
								ref={ref}
								placeholder={componentData.currentCompany.name}
								data={componentData.userCompanies.map((item) => ({
									value: item.name,
									id: item.id,
								}))}
								onItemSubmit={(item) => handleCompanyChange(item)}
								initiallyOpened={true}
							/>
						</div>
					)
				) : null}
			</div>
		</>
	);
}

export default CompanySelector;
