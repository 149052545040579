import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	Text,
	Menu,
	Tooltip,
	Divider,
	Center,
	SegmentedControl,
	Modal,
	SimpleGrid,
	Group,
	Button,
	TextInput,
	TypographyStylesProvider,
	LoadingOverlay,
	Title,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
	CardView,
	ModalView,
	PaperView,
} from "../../ui/common/sharedComponents";
import { BookedData, Payslip } from "../../../data/types/payslip";
import { format } from "date-fns";
import {
	At,
	BuildingBank,
	DatabaseExport,
	Download,
	Exchange,
	Eye,
	GridDots,
	InfoCircle,
	Send,
	TruckDelivery,
} from "tabler-icons-react";
import { TableData, tableHeaderColumn } from "../../ui/common/tableData";
import DisplaySalaryProcessSummary from "./salaryprocessView";
import { useAppSelector } from "../../../app/hooks";
import { selectSalaryProcessSingleEmpData } from "../../../data/salaryIncrease/salaryprocessSingleEmpData";
import { useNavigate } from "react-router-dom";
import { Amount } from "../../ui/common/currency";
import { ActionButton } from "../../ui/buttons";
import exportFromJSON from "export-from-json";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { getCurrentCompanyId } from "../../../helpers/user";
export default function InBooking({
	salaryBookedData,
}: {
	salaryBookedData: BookedData[];
}) {
	const { t } = useTranslation();
	const companyId = getCurrentCompanyId();
	const [rows, setRows] = useState<any[]>([]);
	const [selectedId, setSelectedId] = useState<any>();
	const [viewModal, setViewModal] = useState(false);
	const [rskModal, setRskModal] = useState(false);
	const [rskData, setRskData] = useState<any[]>([{}]);
	const [cashDeliveryModal, setCashDeliveryModal] = useState(false);
	const [cashDeliveryData, setCashDeliveryData] = useState(null);
	const [submissionModal, setSubmissionModal] = useState(false);
	const [submissionDate, setSubmissionDate] = useState(null);
	const [payslipsModal, setPayslipsModal] = useState(false);
	const [payslipViewPayslipData, setPayslipViewPayslipData] = useState("");
	const [payslipViewPayslipModal, setPayslipViewPayslipModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sendpayslipModal, setSendpayslipModal] = useState(false);
	const [emailAddress, setEmailAddress] = useState<any>(null);
	const [sendEmailData, setSendEmailData] = useState<any>(null);
	const [resendData, setResendData] = useState<any>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [currentId, setCurrentId] = useState<number>(0);
	const printRef = useRef(null);
	var salaryprocessSingleEmpdata = useAppSelector(
		selectSalaryProcessSingleEmpData
	);
	const navigate = useNavigate();
	const handleOnClick = (curentId: any) => {
		setCurrentId(curentId);
	};
	const tableHeaderTranslations: string[] = t(
		"salary_processe_list.table_header",
		{
			returnObjects: true,
		}
	);

	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[3] },
		{ text: tableHeaderTranslations[4] },
		{ text: tableHeaderTranslations[5] },
	];
	const exportCsvData = (exportData: any) => {
		const data = exportData;
		const fileName = `accountingRecords${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.csv;
		return exportFromJSON({ data, fileName, exportType });
	};
	const exportTxtData = (exportData: any, file_name: any) => {
		const data = exportData;
		const fileName = `${file_name}${format(
			new Date(),
			"mmmm-dd-yyyy hh:mm aaa"
		)}`;
		const exportType = exportFromJSON.types.txt;
		return exportFromJSON({ data, fileName, exportType });
	};
	const menuAction = (selectedData: any) => (
		<Menu
			withArrow={true}
			size={"xl"}
			shadow={"lg"}
			control={
				<Box style={{ color: "cyan", cursor: "pointer" }}>
					{t("salary_processe_list.menu_item_header")}
				</Box>
			}
			placement={"end"}>
			<Menu.Label>
				<Divider />
			</Menu.Label>
			<Menu.Item
				onClick={() => {
					var rskArrayData = [];
					rskArrayData.push(selectedData.yearMonth);
					rskArrayData.push(
						JSON.parse(selectedData.skilagreinRskJson).Skilagrein
							.Stadgreidsla_heild
					);
					rskArrayData.push(
						JSON.parse(selectedData.skilagreinRskJson).Skilagrein
							.Stadgreidsla_laun
					);
					rskArrayData.push(
						JSON.parse(selectedData.skilagreinRskJson).Skilagrein.Tryggingagjald
					);
					rskArrayData.push(
						JSON.parse(selectedData.skilagreinRskJson).Skilagrein.Heildar_laun
					);
					// setRskData(rskArrayData);
					// setRskModal(true);
				}}
				icon={<Exchange />}>
				{
					t("salary_processe_list.inbooking_more_button", {
						returnObjects: true,
					})[0]
				}
			</Menu.Item>
			<Menu.Item
				icon={<BuildingBank />}
				onClick={() => {
					//fetchPaymnetRegisterTxtExportData(selectedData.id);
				}}>
				{
					t("salary_processe_list.inbooking_more_button", {
						returnObjects: true,
					})[1]
				}
			</Menu.Item>
			<Menu.Item
				icon={<Download />}
				onClick={() => {
					//fetchAccountingCsvExportData(selectedData.id);
				}}>
				<Text size="sm">
					{
						t("salary_processe_list.inbooking_more_button", {
							returnObjects: true,
						})[2]
					}
				</Text>
			</Menu.Item>
			<Menu.Item
				icon={<TruckDelivery />}
				onClick={() => {
					// setCashDeliveryData(selectedData.id);
					// setCashDeliveryModal(true);
				}}>
				{
					t("salary_processe_list.inbooking_more_button", {
						returnObjects: true,
					})[3]
				}
			</Menu.Item>
			<Menu.Item
				icon={<DatabaseExport />}
				onClick={() => {
					// setSubmissionModal(true);
					// setSubmissionDate(selectedData.deliverySetting.sendRsk);
				}}>
				{
					t("salary_processe_list.inbooking_more_button", {
						returnObjects: true,
					})[4]
				}
			</Menu.Item>
			<Menu.Item
				icon={<GridDots />}
				onClick={() => {
					//setPayslipsModal(true);
				}}>
				<Text size="sm">
					{
						t("salary_processe_list.inbooking_more_button", {
							returnObjects: true,
						})[5]
					}
				</Text>
			</Menu.Item>
		</Menu>
	);
	const handlePrint = useReactToPrint({ content: () => printRef.current });
	useEffect(() => {
		if (currentId) {
			salaryprocessSingleEmpdata.employeeData &&
				navigate("/app/salaryprocesses/view/" + currentId, {
					replace: false,
				});
		}
	}, [currentId]);

	const fetchPaymnetRegisterTxtExportData = (exportId: any) => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/salaryprocess/get/paymentfile/si080/${exportId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					exportTxtData(
						response.data,
						`${t("common.payment_register")}_si080${exportId}`
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const fetchViewPayslipData = (exportId: any) => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/payslip/get/htmlslip/${companyId}/${exportId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					setPayslipViewPayslipData(response.data);
					setPayslipViewPayslipModal(true);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const fetchCashOnDeliveryTxtExportData = (exportId: any) => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/salaryprocess/get/gjutstgrfile/${exportId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					exportTxtData(
						response.data.Message,
						`${t("common.cash_payment")}_${exportId}`
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const fetchAccountingCsvExportData = (accountingCsvExportId: any) => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/salaryprocess/get/accountingchart/excel/${accountingCsvExportId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					exportCsvData(response.data);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (sendEmailData) {
			setButtonLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/payslip/send/email`,
					sendEmailData,
					{
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					}
				)
				.then(function (response) {
					setButtonLoading(false);
				})
				.catch(function (error) {
					setButtonLoading(false);
				});
		}
	}, [sendEmailData]);

	useEffect(() => {
		if (resendData) {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_URL_API_URL}/salaryprocess/changestatus/saved`,
					resendData,
					{
						headers: {
							Accept: "applicatoin/json",
							"Content-Type": "application/json",
						},
					}
				)
				.then(function (response) {
					if (response.status === 200) {
						setLoading(false);
					}
				})
				.catch(function (error) {
					setLoading(false);
				});
		}
	}, [resendData]);
	useEffect(() => {
		const bookedRow: any[] = salaryBookedData.map(
			(data: BookedData, index: number) => (
				<tr key={data.id}>
					<td>{data.id}</td>
					<td>
						{format(new Date(data.processDateTime), "d. MMMM yyyy h:mm aaa")}
					</td>
					<td>{data.yearMonth}</td>
					<td>
						{format(new Date(data.periodStarts), "d. MMMM yyyy") +
							" - " +
							format(new Date(data.periodEnds), "d. MMMM yyyy")}
					</td>
					<td>{format(new Date(data.salaryPayDay), "d. MMMM yyyy")}</td>
					<td>
						{
							<SegmentedControl
								color="gray"
								fullWidth
								data={[
									{
										value: "info",
										label: (
											<Center
												onClick={() => {
													// setSelectedId(data.id);
													// setViewModal(true);
												}}>
												<Tooltip
													withArrow={true}
													arrowSize={6}
													radius={"md"}
													position={"bottom"}
													label={t("salary_processe_list.inbooking_tooltip", {
														returnObjects: true,
													})}>
													<InfoCircle
														style={{ color: "cyan" }}
														size={25}
														cursor={"pointer"}
													/>
												</Tooltip>
											</Center>
										),
									},
									{
										value: "view",
										label: (
											<Center
												onClick={() => {
													//handleOnClick(data.id);
												}}>
												<Eye color="cyan" />
												<Box style={{ color: "cyan" }}>
													{
														t("salary_processe_list.inbooking_button", {
															returnObjects: true,
														})[0]
													}
												</Box>
											</Center>
										),
									},
									{
										value: "resend",
										label: (
											<Center
												onClick={() => {
													setResendData({ id: data.id });
												}}>
												<Box style={{ color: "cyan" }}>
													{
														t("salary_processe_list.inbooking_button", {
															returnObjects: true,
														})[1]
													}
												</Box>
											</Center>
										),
									},
									{ value: "", label: <Center>{menuAction(data)}</Center> },
								]}
							/>
						}
					</td>
				</tr>
			)
		);

		setRows(bookedRow);
	}, [salaryBookedData, t]);

	return (
		<>
			<CardView
				value={
					rows.length == 0 ? (
						<Title order={6}>{t("salary_processe_list.no_booking")}</Title>
					) : (
						<TableData tableHeader={tableHeaders} tableBody={rows} />
					)
				}
			/>
			{selectedId && (
				<ModalView
					opened={viewModal}
					onClose={() => {
						setViewModal(false);
						setSelectedId(null);
					}}>
					<DisplaySalaryProcessSummary selectedId={selectedId} />
				</ModalView>
			)}
			{rskData.length === 0 ? (
				<></>
			) : (
				<ModalView
					opened={rskModal}
					onClose={() => {
						setRskModal(false);
						setRskData([]);
					}}
					title={""}>
					<>
						<SimpleGrid p={"lg"} ref={printRef}>
							<Divider
								label={
									<Group position="apart">
										<Text size="lg">
											{t("salary_processe_list.inbooking_more_button", {
												returnObjects: true,
											})[0] +
												":\t\t" +
												"\t(" +
												rskData[0] +
												")"}
										</Text>
									</Group>
								}
								labelPosition={"center"}
							/>
							<Group position={"apart"}>
								<Text size={"sm"}>
									{
										t("salary_processe_list.rsk_return", {
											returnObjects: true,
										})[0]
									}
								</Text>
								<Text size={"sm"}>{<Amount value={rskData[1]} />}</Text>
							</Group>

							<Group position={"apart"}>
								<Text size={"sm"}>
									{
										t("salary_processe_list.rsk_return", {
											returnObjects: true,
										})[1]
									}
								</Text>
								<Text size={"sm"}>{<Amount value={rskData[2]} />}</Text>
							</Group>

							<Group position={"apart"}>
								<Text size={"sm"}>
									{
										t("salary_processe_list.rsk_return", {
											returnObjects: true,
										})[2]
									}
								</Text>
								<Text size={"sm"}>{<Amount value={rskData[3]} />}</Text>
							</Group>

							<Group position={"apart"}>
								<Text size={"sm"}>
									{
										t("salary_processe_list.rsk_return", {
											returnObjects: true,
										})[3]
									}
								</Text>
								<Text size={"sm"}>{<Amount value={rskData[4]} />}</Text>
							</Group>
						</SimpleGrid>
						<Group position="center" spacing={"xl"}>
							<SimpleGrid cols={2} spacing={"xl"}>
								<Tooltip
									withArrow={true}
									arrowSize={6}
									radius={"md"}
									position={"bottom"}
									label={t("common.print")}>
									<ActionButton
										onClick={() => {
											handlePrint();
										}}>
										{t("common.print")}
									</ActionButton>
								</Tooltip>

								<Tooltip
									style={{
										cursor: "pointer",
										color: "red",
										fontWeight: "bolder",
									}}
									withArrow={true}
									arrowSize={6}
									radius={"md"}
									position={"bottom"}
									label={t("common.close")}>
									<ActionButton
										color={"red"}
										onClick={() => {
											setRskModal(false);
										}}>
										{t("common.close")}
									</ActionButton>
								</Tooltip>
							</SimpleGrid>
						</Group>
					</>
				</ModalView>
			)}
			{
				<ModalView
					opened={cashDeliveryModal}
					onClose={() => {
						setCashDeliveryModal(false);
					}}
					title={""}>
					<>
						<Divider
							label={
								<Group position="apart">
									<Text size={"lg"}>
										{t("salary_processe_list.cash_delivery_header")}
									</Text>
								</Group>
							}
							labelPosition={"center"}
						/>
						<Group position="center">
							<Text p={"lg"} size={"sm"}>
								{t("salary_processe_list.cash_delivery", {
									returnObjects: true,
								})[0] + "\t\t"}
								<a
									href={"mailto:" + "tbrkrafa@runuvinnsla.is"}
									style={{ textDecoration: "none", color: "cyan" }}>
									tbrkrafa@runuvinnsla.is
								</a>
								{"\t\t" +
									t("salary_processe_list.cash_delivery", {
										returnObjects: true,
									})[1]}
							</Text>
						</Group>
						<Group position="center" spacing={"xl"}>
							<SimpleGrid cols={2} spacing={"xl"}>
								<Tooltip
									withArrow={true}
									arrowSize={6}
									radius={"md"}
									position={"bottom"}
									label={"Print"}>
									<ActionButton
										onClick={() => {
											cashDeliveryData &&
												fetchCashOnDeliveryTxtExportData(cashDeliveryData);
										}}>
										{t("common.print")}
									</ActionButton>
								</Tooltip>

								<Tooltip
									style={{
										cursor: "pointer",
										color: "red",
										fontWeight: "bolder",
									}}
									withArrow={true}
									arrowSize={6}
									radius={"md"}
									position={"top"}
									label={t("common.close")}>
									<ActionButton
										onClick={() => {
											setCashDeliveryModal(false);
										}}
										color={"red"}>
										{t("common.close")}
									</ActionButton>
								</Tooltip>
							</SimpleGrid>
						</Group>
					</>
				</ModalView>
			}
			{submissionDate && (
				<ModalView
					opened={submissionModal}
					onClose={() => {
						setSubmissionModal(false);
					}}
					title={""}>
					<>
						<Divider
							label={
								<Text size="lg">
									{
										t("salary_processe_list.inbooking_more_button", {
											returnObjects: true,
										})[4]
									}
								</Text>
							}
							labelPosition={"center"}
						/>

						<Text size={"sm"} p={"xl"}>
							{format(new Date(submissionDate), "MMMM,dd,yyyy hh:mm aaa")}
						</Text>
					</>
				</ModalView>
			)}
			<ModalView
				opened={payslipsModal}
				onClose={() => {
					setPayslipsModal(false);
				}}
				title={""}>
				<SimpleGrid>
					<Divider
						label={<Text size="lg">{"Payslips"}</Text>}
						labelPosition={"center"}
					/>
					{salaryBookedData &&
						salaryBookedData.map((data: BookedData, index: number) =>
							data.paySlips.map((payslipsValue: Payslip, id: number) => (
								<SimpleGrid cols={5} key={id}>
									<Text size={"sm"}>
										{payslipsValue.employee.fullName +
											"\t" +
											payslipsValue.employee.registrationId}
									</Text>
									<Text size={"sm"}>
										{<Amount value={payslipsValue.salaryTotal} />}
									</Text>
									<Text size={"sm"}>
										{<Amount value={payslipsValue.salaryToPay} />}
									</Text>
									<Button
										onClick={() => {
											fetchViewPayslipData(payslipsValue.id);
										}}
										size="xs"
										radius={"sm"}
										style={{ width: "100px" }}>
										{
											t("salary_processe_list.inbooking_button", {
												returnObjects: true,
											})[2]
										}
									</Button>
									{emailAddress && (
										<Modal
											opened={sendpayslipModal}
											onClose={() => {
												setSendpayslipModal(false);
												setEmailAddress(null);
											}}
											title={`${t(
												"salary_processe_list.inbooking_payslip_send_email_header"
											)} #${emailAddress.id}	   									
												${payslipsValue.employee.fullName}`}>
											<Divider />
											<SimpleGrid>
												<TextInput
													label={t(
														"salary_processe_list.inbooking_payslip_send_email_input_label"
													)}
													placeholder="Your email"
													icon={<At size={14} />}
													readOnly
													value={emailAddress.email}
												/>
												<Group position="center" spacing={"lg"}>
													<ActionButton
														onClick={() => {
															setSendEmailData({
																guid: emailAddress.guid,
																email: emailAddress.email,
															});
														}}
														loading={buttonLoading}>
														{
															t(
																"salary_processe_list.inbooking_payslip_send_email_button_label",
																{ returnObjects: true }
															)[0]
														}
													</ActionButton>
													<ActionButton
														onClick={() => {
															setSendpayslipModal(false);
															setEmailAddress(null);
														}}>
														{
															t(
																"salary_processe_list.inbooking_payslip_send_email_button_label",
																{ returnObjects: true }
															)[1]
														}
													</ActionButton>
												</Group>
											</SimpleGrid>
										</Modal>
									)}
									<Send
										color="cyan"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setEmailAddress({
												email: payslipsValue.employee.email,
												id: data.paySlips[id].id,
												guid: data.paySlips[id].guid,
											});

											setSendpayslipModal(true);
										}}
									/>
								</SimpleGrid>
							))
						)}
				</SimpleGrid>
			</ModalView>
			<LoadingOverlay visible={loading} overlayOpacity={0.1} />
			{payslipViewPayslipData && (
				<Modal
					opened={payslipViewPayslipModal}
					onClose={() => {
						setPayslipViewPayslipModal(false);
						setPayslipViewPayslipData("");
					}}
					size={"100%"}>
					<PaperView
						value={
							<TypographyStylesProvider>
								<div
									dangerouslySetInnerHTML={{
										__html: payslipViewPayslipData,
									}}></div>
							</TypographyStylesProvider>
						}
					/>
				</Modal>
			)}
		</>
	);
}
