import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { SalaryElementSimpleModel } from "../types/employeeFormData";
import { DataStatus } from "../types/shared";
interface CompanySalaryElementDataState {
	data: SalaryElementSimpleModel[];
	companyId: number;
	status: DataStatus;
	error: boolean;
}

const initialState: CompanySalaryElementDataState = {
	data: [],
	companyId: 0,
	status: DataStatus.NOT_FETCHED,
	error: false,
};

export const companySalaryElementData = createSlice({
	name: "companySalaryElementData",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.status = DataStatus.FETCHING;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.status = DataStatus.EMPTY;
		},
		dataLoaded: (state, action) => {
			state.data = action.payload;
			state.companyId = getCurrentCompanyId();
			state.status = DataStatus.FETCHED;
		},
	},
});

export const { startLoading, hasError, dataLoaded } =
	companySalaryElementData.actions;

//thunk function for async api call
export const fetchCompanySalaryElementData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/api/v2/company/${currentCompanyId}/salaryelements`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error) => {
			console.log("error fetching company salary elements", error);
			dispatch(hasError(JSON.parse(error.request.responseText).Message));
		});
};

export default companySalaryElementData.reducer;
export const selectCompanySalaryElementData = (state: RootState) =>
	state.companySalaryElementData;
