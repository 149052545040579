import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons";
import {
	Box,
	Center,
	SegmentedControl,
	Space,
	Text,
	Tooltip,
} from "@mantine/core";
import { CardView, ModalView } from "../../ui/common/sharedComponents";
import { Exchange, Eye } from "tabler-icons-react";
import { TableData, tableHeaderColumn } from "../../ui/common/tableData";
import { useAppSelector } from "../../../app/hooks";
import { selectSalaryProcessSingleEmpData } from "../../../data/salaryIncrease/salaryprocessSingleEmpData";
import { BookedData } from "../../../data/types/payslip";
import { is } from "date-fns/locale";
import { format, setDefaultOptions } from "date-fns";
import { useNavigate } from "react-router-dom";
import ReverseGear from "./reverseGear";
import DisplaySalaryProcessSummary from "./salaryprocessView";
export default function Booked({
	salaryBookedData,
}: {
	salaryBookedData: BookedData[];
}) {
	setDefaultOptions({ locale: is });
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [reverseGearModal, setReveseGearModal] = useState(false);
	const [selectedId, setSelectedId] = useState<any>();
	const [reverseId, setReverseId] = useState<any>();
	const [currentId, setCurrentId] = useState<number>(0);
	var salaryprocessSingleEmpdata = useAppSelector(
		selectSalaryProcessSingleEmpData
	);
	useEffect(() => {
		if (currentId) {
			salaryprocessSingleEmpdata.employeeData &&
				navigate("/app/salaryprocesses/view/" + currentId, {
					replace: true,
				});
		}
	}, [currentId]);

	const navigate = useNavigate();
	const handleOnClick = (curentId: any) => {
		setCurrentId(curentId);
	};
	useEffect(() => {
		const bookedRow = salaryBookedData.map((data: any, index: number) => (
			<tr key={index}>
				<td>{data.id}</td>
				<td>{format(new Date(data.created), "dd.MMM yyyy HH:mm")}</td>
				<td>{data.yearMonth}</td>
				<td>
					{format(new Date(data.periodStarts), "dd.MMM yyyy") +
						" - " +
						format(new Date(data.periodEnds), "dd.MMM yyyy")}
				</td>
				<td>{format(new Date(data.salaryPayDay), "dd.MMM yyyy")}</td>
				<td>
					{
						<SegmentedControl
							color="gray"
							fullWidth
							data={[
								{
									value: "",
									label: (
										<Center
											onClick={() => {
												setSelectedId(data.id);
												setOpenModal(true);
											}}>
											<Tooltip
												radius={"md"}
												position={"bottom"}
												label={t("salary_processe_list.inbooking_tooltip", {
													returnObjects: true,
												})}>
												<IconInfoCircle
													style={{ color: "cyan" }}
													size={25}
													cursor={"pointer"}
													onClick={(e) => {}}
												/>
											</Tooltip>
										</Center>
									),
								},
								{
									value: "view",

									label: (
										<Center
											onClick={() => {
												handleOnClick(data.id);
											}}>
											<Eye color="cyan" />
											<Space w="sm" />
											<Box style={{ color: "cyan" }}>
												{
													t("salary_processe_list.action_button", {
														returnObjects: true,
													})[0]
												}
											</Box>
										</Center>
									),
								},
								{
									value: "revers",
									label: (
										<Center
											onClick={() => {
												setReverseId(data.id + ":" + data.yearMonth);
												setReveseGearModal(true);
											}}>
											<Exchange color="cyan" />
											<Space w="sm" />
											<Box style={{ color: "cyan" }}>
												{
													t("salary_processe_list.action_button", {
														returnObjects: true,
													})[2]
												}
											</Box>
										</Center>
									),
								},
							]}
						/>
					}
				</td>
			</tr>
		));
		setRows(bookedRow);
	}, [salaryBookedData, t]);

	const tableHeaderTranslations: string[] = t(
		"salary_processe_list.table_header",
		{
			returnObjects: true,
		}
	);
	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[3] },
		{ text: tableHeaderTranslations[4] },
		{ text: tableHeaderTranslations[5] },
	];
	return (
		<>
			<CardView
				value={
					rows.length === 0 ? (
						<Text>{t("common.data_not_found") + "..."}</Text>
					) : (
						<TableData tableHeader={tableHeaders} tableBody={rows} />
					)
				}
			/>
			{selectedId && (
				<ModalView
					opened={openModal}
					onClose={() => {
						setOpenModal(false);
						setSelectedId(null);
					}}>
					<DisplaySalaryProcessSummary selectedId={selectedId} />
				</ModalView>
			)}

			{reverseId && (
				<ModalView
					opened={reverseGearModal}
					onClose={() => {
						setReveseGearModal(false);
						setSelectedId(null);
					}}>
					<ReverseGear reverseId={reverseId} />
				</ModalView>
			)}
		</>
	);
}
