import {
	Autocomplete,
	AutocompleteItem,
	Button,
	Grid,
	Modal,
	NumberInput,
	Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
	fetchCompanySalaryElementData,
	selectCompanySalaryElementData,
} from "../../../../../data/payslips/companySalaryElementsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { DataStatus } from "../../../../../data/types/shared";
import { useTranslation } from "react-i18next";
import {
	SalaryElementEditState,
	SimpleSalaryElementProps,
	SimpleSalaryElementWithRateProps,
} from "../../../../../data/types/payslip";
import LaunaCurrencyInput from "../../../common/launaCurrencyInput";
import { Amount } from "../../../common/currency";

interface PayslipSalaryElementEditorProps {
	// pass in NEW or EDITED depending on whether we are adding a new element or editing an existing one
	editState: SalaryElementEditState;
	title?: string;
	onClosed: () => void;
	onElementAdded?: (el: SimpleSalaryElementProps) => void;
	onElementUpdated?: (el: SimpleSalaryElementProps) => void;
	skipElementIds?: number[];
	editElement?: SimpleSalaryElementWithRateProps;
}

function PayslipSalaryElementEditor(props: PayslipSalaryElementEditorProps) {
	const { t } = useTranslation();
	const [salaryElementId, setSalaryElementId] = useState(0);
	const [description, setDescription] = useState("");
	const [units, setUnits] = useState(1);
	const [val, setVal] = useState(0);
	const [rate, setRate] = useState(0);
	const [canSave, setCanSave] = useState(false);

	useEffect(() => {
		if (props.editState === SalaryElementEditState.EDITED) {
			if (props.editElement) {
				setSalaryElementId(props.editElement.id);
				setDescription(props.editElement.description);
				setUnits(props.editElement.units);
				setVal(props.editElement.value || 0);
				setRate(props.editElement.rate);
			}
		}
	}, []); //this effect should only run once

	useEffect(() => {
		if (props.editState === SalaryElementEditState.EDITED) {
			setCanSave(true);
		} else {
			if (
				salaryElementId > 0 &&
				description.length > 0 &&
				units > 0 &&
				val > 0
			) {
				setCanSave(true);
			} else {
				setCanSave(false);
			}
		}
	}, [props.editState, salaryElementId, description, units, val]);

	const dispatch = useAppDispatch();
	// Fetch the base salary elements from the redux store
	const baseSalaryElementData = useAppSelector(selectCompanySalaryElementData);

	// Fetch the base salary elements from the server if in "add element" mode and they are not already fetched or need update
	useEffect(() => {
		if (
			props.editState === SalaryElementEditState.NEW &&
			(baseSalaryElementData.status === DataStatus.NOT_FETCHED ||
				baseSalaryElementData.status === DataStatus.NEEDS_UPDATE)
		) {
			dispatch(fetchCompanySalaryElementData());
		}
	}, [props.editState, baseSalaryElementData.status, dispatch]);

	// Create a local list of salary elements
	const listSalaryElements =
		baseSalaryElementData.data.length > 0 && props.skipElementIds
			? baseSalaryElementData.data.filter(
					(element) => !props.skipElementIds?.includes(element.id)
			  )
			: baseSalaryElementData.data;

	// now sort the listSalaryElements by salaryElementType
	listSalaryElements.sort((a, b) => a.salaryElementType - b.salaryElementType);

	const getSalElGroupName = (salElType: number) => {
		switch (salElType) {
			case 0:
				return "Launaliðir";
			case 1:
				return "Frádráttur";
			case 2:
				return "Hlunnindi";
		}
	};

	// Create list of AutocompleteItems for the dropdown
	const ddItems = listSalaryElements.map((el) => {
		return {
			key: el.id,
			value: el.description,
			group: getSalElGroupName(el.salaryElementType),
		} as AutocompleteItem;
	});

	return (
		<Modal
			size={600}
			title={props.title || ""}
			centered
			opened
			onClose={props.onClosed}>
			<Grid columns={12}>
				<Grid.Col span={6}>
					<Text weight={700}>{t("add_employees.salary.salary_types")}</Text>
				</Grid.Col>
				<Grid.Col span={4}>
					<Text weight={700}>
						{t("add_employees.salary.hourly_item_modal.rate")}
					</Text>
				</Grid.Col>
				<Grid.Col span={2}>
					<Text weight={700}>{t("add_employees.salary.unit_short")}</Text>
				</Grid.Col>
				<Grid.Col span={6}>
					{props.editState === SalaryElementEditState.NEW && (
						<Autocomplete
							placeholder="Veljið launalið"
							required
							maxDropdownHeight={150}
							data={ddItems}
							limit={150}
							onItemSubmit={(item) => {
								setSalaryElementId(item.key);
								setDescription(item.value);
							}}
						/>
					)}
					{props.editState === SalaryElementEditState.EDITED && (
						<Text>{description}</Text>
					)}
				</Grid.Col>
				<Grid.Col span={4}>
					{props.editState === SalaryElementEditState.NEW && (
						<LaunaCurrencyInput
							label=""
							defaultValue="0"
							onChange={(value) => {
								if (value) {
									setRate(parseFloat(value));
									setVal(units * parseFloat(value));
								}
							}}
						/>
					)}
					{props.editState === SalaryElementEditState.EDITED && (
						<Amount value={rate} />
					)}
				</Grid.Col>
				<Grid.Col span={2}>
					<NumberInput
						hideControls
						defaultValue={1}
						decimalSeparator=","
						precision={2}
						step={0.1}
						min={0.1}
						onChange={(value) => {
							if (value) {
								setUnits(value);
							}
						}}
					/>
				</Grid.Col>
				<Grid.Col span={12}>
					<Button
						disabled={!canSave}
						onClick={() => {
							if (props.onElementAdded) {
								props.onElementAdded({
									id: salaryElementId,
									description: description,
									units: units,
									value: val,
									canEdit: true,
								});
							}
							if (props.onElementUpdated) {
								props.onElementUpdated({
									id: salaryElementId,
									description: description,
									units: units,
									value: val,
									canEdit: true,
								});
							}
						}}>
						{t("common.continue")}
					</Button>
				</Grid.Col>
			</Grid>
		</Modal>
	);
}

export default PayslipSalaryElementEditor;
