import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { AccoutChart, BookedData, Payslip } from "../types/payslip";

interface SummaryPayslipsData {
	summaryData: AccoutChart[];
	companyId: number;
	isLoading: boolean;
	error: boolean;
}

const initialState: SummaryPayslipsData = {
	summaryData: [] as AccoutChart[],
	companyId: 0,
	isLoading: false,
	error: false,
};

export const displaySummaryPayslipsData = createSlice({
	name: "summaryPayslipsData",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.summaryData = action.payload;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } =
	displaySummaryPayslipsData.actions;

//thunk function for async api call
export const fetchSummarylPayslipsData =
	(dateForm_dateTo: string) => (dispatch: Dispatch) => {
		dispatch(startLoading());
		const currentCompanyId = getCurrentCompanyId();
		axios
			.get(
				`${process.env.REACT_APP_API_URL}//api/v2/companyreport/${currentCompanyId}/${dateForm_dateTo}/accountingchart`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					dispatch(dataLoaded(response.data.Obj));
				}
			})
			.catch((error) => {
				dispatch(hasError(error.request.responseText.Message));
				console.log(error.request.responseText.Message);
			});
	};

export default displaySummaryPayslipsData.reducer;
export const summaryPayslipsData = (state: RootState) =>
	state.summarypayslipsData;
