import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
	currentPayslipSet,
	getPayslipEditorState,
	payslipReplaced,
	recalculating,
} from "../../../../../data/payslips/payslipViewSlice";
import { PrimaryButton } from "../../../buttons";
import { Amount } from "../../../common/currency";
import { Badge, Text } from "@mantine/core";
import { ReCalculatePayslipPayload } from "../../../../../data/types/payslipsCreate";
import { ExclamationMark } from "tabler-icons-react";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import {
	Payslip,
	SalaryElementEditState,
} from "../../../../../data/types/payslip";

function PayslipViewEditBar() {
	const payslipSlice = useAppSelector(getPayslipEditorState);
	const dispatch = useAppDispatch();
	const editedSalaryElements = payslipSlice.salaryElements.filter(
		(el) => el.editState !== SalaryElementEditState.UNCHANGED
	);
	const showEditedElements =
		payslipSlice.payslipsEdited && editedSalaryElements.length > 0;

	const replacePayslip = (payslip: Payslip) => {
		dispatch(payslipReplaced(payslip));
		dispatch(currentPayslipSet(""));
	};

	const recalculatePayslip = () => {
		dispatch(recalculating(true));
		// check if there were any deleted elements
		const deletedElements = payslipSlice.salaryElements.filter(
			(el) => el.editState === SalaryElementEditState.DELETED
		);
		const payload: ReCalculatePayslipPayload = {
			guid: payslipSlice.currentPayslip?.guid
				? payslipSlice.currentPayslip.guid
				: "",
			employeeId: payslipSlice.currentPayslip?.employee.id
				? payslipSlice.currentPayslip.employee.id
				: 0,
			periodStarts: payslipSlice.currentPayslip?.periodStart
				? payslipSlice.currentPayslip.periodStart
				: "",
			periodEnds: payslipSlice.currentPayslip?.periodEnd
				? payslipSlice.currentPayslip.periodEnd
				: "",
			yearMonth: payslipSlice.yearMonth,
			salaryElements: payslipSlice.salaryElements
				// remove any elements by id in deletedElements
				.filter((el) => {
					return (
						el.canEdit && !deletedElements.some((delEl) => delEl.id === el.id)
					);
				})
				.map((el) => {
					return {
						employeeId: payslipSlice.currentPayslip?.employee.id
							? payslipSlice.currentPayslip.employee.id
							: 0,
						baseId: el.id,
						units: el.units,
						value: el.value,
						baseUnitValue:
							el.editState === SalaryElementEditState.EDITED
								? el.originalRate
								: el.rate,
						description:
							el.editState === SalaryElementEditState.EDITED
								? el.originalDescription
								: el.description,
					};
				}),
			removedItems: deletedElements.map((el) => {
				return {
					employeeId: payslipSlice.currentPayslip?.employee.id
						? payslipSlice.currentPayslip.employee.id
						: 0,
					removedItem: el.id,
				};
			}),
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/salaryprocess/updatesalary`,
				payload
			)
			.then(function (response) {
				if (response.status === 200) {
					replacePayslip(response.data);
				}
				dispatch(recalculating(false));
			})
			.catch((error) => {
				showNotification({
					autoClose: false,
					title: "Villa",
					message: "Villa kom upp við að endurreikna launaseðil",
					color: "red",
					icon: <ExclamationMark size={16} />,
				});
				dispatch(recalculating(false));
			});
	};

	const getSalaryElementStateLabel = (state: SalaryElementEditState) => {
		switch (state) {
			case SalaryElementEditState.NEW:
				return <Badge color="green">bætt við</Badge>;
			case SalaryElementEditState.EDITED:
				return <Badge color="indigo">breytt</Badge>;
			case SalaryElementEditState.DELETED:
				return <Badge color="red">fjarlægt</Badge>;
			default:
				return null;
		}
	};

	return (
		<>
			{showEditedElements && (
				<>
					<Text size="xl">Uppfærðir launaliðir á launaseðli</Text>
					<div
						style={{
							display: "inline-block",
							width: "50%",
							textAlign: "left",
						}}></div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Taxti</strong>
					</div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Ein.</strong>
					</div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Upphæð</strong>
					</div>
				</>
			)}
			{showEditedElements &&
				editedSalaryElements.map((el) => {
					return (
						<div key={el.id + el.description + "_edit"}>
							<div
								data-editstate={el.editState}
								style={{
									display: "inline-block",
									width: "50%",
									textAlign: "left",
								}}>
								{el.description + " "}
								{getSalaryElementStateLabel(el.editState)}
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								<Amount value={el.originalRate} />
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								{el.units}
							</div>
							<div
								style={{
									display: "inline-block",
									width: "15%",
									textAlign: "right",
								}}>
								<Amount value={el.units * el.originalRate} />
							</div>
						</div>
					);
				})}

			{showEditedElements && (
				<div style={{ textAlign: "center", marginBottom: "10px" }}>
					<PrimaryButton
						onClick={() => {
							recalculatePayslip();
						}}>
						Endurreikna launaseðil
					</PrimaryButton>
				</div>
			)}
		</>
	);
}

export default PayslipViewEditBar;
