import React, { useState, useEffect, useRef } from "react";
import {
	Accordion,
	Avatar,
	Box,
	Button,
	Center,
	createStyles,
	Drawer,
	Grid,
	Group,
	Image,
	LoadingOverlay,
	Modal,
	MultiSelect,
	Select,
	Space,
	Text,
	TextInput,
	Container,
	Title,
	UnstyledButton,
	useAccordionState,
	Tooltip,
} from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchBankData, selectBankData } from "../../data/bank/bankDataSlice";
import {
	fetchEmployeeCreateData,
	selectEmployeeCreateData,
	addCompanyRole,
} from "../../data/employees/employeeCreateDataSlice";
import { DataStatus } from "../../data/types/shared";
import { selectUser } from "../../data/user/userSlice";
import { getCurrentCompanyId, isProd } from "../../helpers/user";
import { SelectListItem } from "../../data/types/addEmployees";
import { PensionFund } from "../../data/types/employee";
import { Bank } from "../../data/types/shared";
import { Department } from "../../data/types/employeeFormData";
import { EmployeeForm } from "../../data/types/formTemplates/employeeForm";
import {
	AsteriskSimple,
	AlertCircle,
	Calendar,
	Check,
	CircleCheck,
	ExclamationMark,
	Percentage,
	Help,
} from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";
import { PrimaryButton } from "../ui/buttons";
import { IconArrowBack, IconArrowForward } from "@tabler/icons";
import TaxComponent from "./employeeForm_taxComponent";
import PensionFundsComponent from "./employeeForm_pensionFunds";
import UnionComponent from "./employeeForm_unionComponent";
import VacationComponent from "./employeeForm_vacationComponent";
import SalaryItemsRateCalculateComponent from "./employeeForm_salaryItemsRateCalculateComponent";
import { format } from "date-fns";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import kennitala from "kennitala";
import { Dropzone } from "@mantine/dropzone";
import placeHolder from "../../assets/profile-placeholder.png";
import { fetchEmployeesData } from "../../data/employees/employeeSlice";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { LaunaLoadingOverlay } from "../ui/common/loadingOverlay";

interface PageProps {
	title?: string;
	employeeData?: EmployeeForm;
	showInModal?: boolean;
	onEditCancel?: () => void;
}

function EmployeeFormComponent(props: PageProps) {
	const itemsGrid = {
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		alignItems: "end",
		rowGap: "7px",
		columnGap: "24px",
	};
	const useStyles = createStyles((theme) => ({
		wrapper: {
			paddingTop: theme.spacing.xl * 2,
			paddingBottom: theme.spacing.xl * 2,
			minHeight: 650,
		},

		title: {
			marginBottom: theme.spacing.xl * 1.5,
		},

		root: {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[6]
					: theme.colors.gray[0],
			borderRadius: theme.radius.sm,
		},

		item: {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[6]
					: theme.colors.gray[0],
			border: "1px solid #000",
			position: "relative",
			zIndex: 0,
			transition: "transform 150ms ease",

			"&.mantine-Accordion-itemOpened": {
				transform: "scale(1.01)",
				backgroundColor:
					theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
				boxShadow: theme.shadows.md,
				borderColor:
					theme.colorScheme === "dark"
						? theme.colors.dark[4]
						: theme.colors.gray[2],
				borderRadius: theme.radius.md,
				zIndex: 1,
				h3: {
					backgroundColor: "#2c2e34",
				},
			},
		},
		grid: {
			width: "100%",
		},
		salaryElements: { ...itemsGrid },
		rightAlign: {
			cursor: "default",
			display: "inline-block",
			width: "100%",
			textAlign: "right",
		},
	}));

	const customSelectStyles = {
		input: {
			marginTop: 2,
			height: 42.42,
		},
	};

	const { classes } = useStyles();
	const { t } = useTranslation();

	const [bankBranches, setBankBranches] = useState<SelectListItem[]>([]);
	const [salaryGroups, setSalaryGroups] = useState<SelectListItem[]>([]);
	const [companyRoles, setCompanyRoles] = useState<SelectListItem[]>([]);
	const [departments, setDepartments] = useState<SelectListItem[]>([]);

	const [salaryElements, setSalaryElements] = useState<SelectListItem[]>([]);
	const [pensionFunds, setPensionFunds] = useState<SelectListItem[]>([]);
	const [extraPensionFunds, setExtraPensionFunds] = useState<SelectListItem[]>(
		[]
	);
	const [unions, setUnions] = useState<SelectListItem[]>([]);

	const bankBranchData = useAppSelector(selectBankData);
	const createData = useAppSelector(selectEmployeeCreateData);
	const userData = useAppSelector(selectUser);
	const [bankDataLoaded, setBankDataLoaded] = useState(false);
	const [createDataLoaded, setCreateDataLoaded] = useState(false);
	const [validationDrawerOpened, setValidationDrawerOpened] = useState(false);
	const dispatch = useAppDispatch();

	const [editPhotoDrawerOpened, setEditPhotoDrawerOpened] = useState(false);
	const openRef = useRef(() => {});
	const [formDataChanged, setFormDataChanged] = useState(false);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (bankBranchData.status === DataStatus.NOT_FETCHED) {
			dispatch(fetchBankData());
		}
		if (bankBranchData.status === DataStatus.FETCHED) {
			setBankDataLoaded(true);
			// once bank data is loaded, populate the bank select list
			let adjusted_list_data: SelectListItem[] = [];
			const bank_branches = bankBranchData.banks;

			bank_branches.forEach((bank: Bank) => {
				bank.branches.forEach((branch) => {
					let branchDescription =
						branch.bankNumber + " - " + bank.name + ", " + branch.branch;
					var index = adjusted_list_data.findIndex(
						(item) => item.value === branchDescription
					);
					if (index === -1) {
						adjusted_list_data.push({
							value: branch.bankNumber.toString(),
							label: branchDescription,
						});
					}
				});
			});
			setBankBranches(adjusted_list_data);
		}
	}, [bankBranchData.status, bankBranchData.banks, dispatch]);

	useEffect(() => {
		if (createData.status === DataStatus.FETCHED || createData.error !== "")
			setCreateDataLoaded(true);
		// once the create data is loaded, populate the various select lists
		setSalaryGroups(
			createData.data.employeeGroups.map((group: Department) => {
				return { value: group.id.toString(), label: group.name };
			})
		);
		setCompanyRoles(
			createData.data.companyRoles.map((role: string) => {
				return { value: role, label: role };
			})
		);
		setDepartments(
			createData.data.departments.map((department: Department) => {
				return { value: department.id.toString(), label: department.name };
			})
		);
		const salaryElementGroups = ["Launaliðir", "Frádráttur", "Hlunnindi"];
		setSalaryElements(
			createData.data.salaryElements.map((salaryElement) => {
				return {
					// format this in the shape of data that will be posted to API on save.
					// see: interface EmployeeForm->Salary>SalaryElement
					value: JSON.stringify(salaryElement),
					label: salaryElement.description,
					group: salaryElementGroups[salaryElement.salaryElementType],
				};
			})
		);

		setPensionFunds([
			{ value: "0", label: "Enginn" },
			...createData.data.pensionfunds
				.filter((pensionFund) => !pensionFund.isExtraFundEntity)
				.map((pensionFund) => {
					return {
						value: pensionFund.id.toString(),
						label: `${pensionFund.name} (${pensionFund.entityNo})`,
					};
				}),
		]);

		setExtraPensionFunds([
			{ value: "0", label: "Enginn" },
			...createData.data.pensionfunds
				.filter((pensionFund) => pensionFund.isExtraFundEntity)
				.map((pensionFund) => {
					return {
						value: pensionFund.id.toString(),
						label: `${pensionFund.name} (${pensionFund.entityNo})`,
					};
				}),
		]);

		setUnions(
			createData.data.unions.map((union: PensionFund) => {
				return {
					value: JSON.stringify(union),
					label: `${union.name} (${union.entityNo})`,
				};
			})
		);
	}, [
		createData.status,
		createData.data.employeeGroups,
		createData.data.companyRoles,
		createData.data.departments,
		createData.data.salaryElements,
		createData.error,
		createData.data.pensionfunds,
		createData.data.unions,
	]);

	useEffect(() => {
		function shouldFetchCreateData(
			companyId: number,
			isLoading: boolean
		): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			// only fetch data here if user has access to the company
			// and has switched companies in the companies selector
			if (userHasAccess && currentCompanyId !== companyId && !isLoading)
				return true;

			return false;
		}

		if (shouldFetchCreateData(createData.data.id, createData.isLoading))
			dispatch(fetchEmployeeCreateData());
	}, [
		dispatch,
		userData.user.companies,
		createData.isLoading,
		createData.data.id,
	]);

	const dateNowString = format(new Date(), "yyyy-MM-dd");

	function dateToString(date: Date | null) {
		return date ? format(date, "yyyy-MM-dd") : "";
	}

	// a little hack until it gets fixed in the backend. This should not be required in the form.
	if (props.employeeData && props.employeeData.settings)
		props.employeeData.settings.Istarf98 = "9999.99";

	useEffect(() => {
		if (props.employeeData) {
			props.employeeData.salary.salaryElements =
				props.employeeData.salary.salaryElements.map((salaryElement) => {
					if (salaryElement.baseId) {
						salaryElement.id = salaryElement.baseId;
					}
					return salaryElement;
				});
			props.employeeData.departmentIds =
				props.employeeData.departmentIds.map(String);
			props.employeeData.salaryGroupIds =
				props.employeeData.salaryGroupIds.map(String);
		}
	}, [props.employeeData]);

	const formData: EmployeeForm = props.employeeData
		? props.employeeData
		: {
				companyId: getCurrentCompanyId(),
				settings: {
					bankAccount: {
						ownerName: "",
						ownerRegistrationId: "",
						bankNUmber: undefined,
						hofudbok: undefined,
						accountNumber: undefined,
					},
					startingTaxStep: "1",
					unionId: 0,
					unionFees: [],
					pensionFundId: 0,
					isPensionFundPercentage: true,
					isSpecialPensionFundPercentage: true,
					payrollPeriodId: 1,
					payrollPaymentMethodId: 1,
					pensionFundPercentageEmployee: 4,
					pensionFundPercentageCompany: 11.5,
					specialPensionFundId: 0,
					specialPensionFundPercentageCompany: 4,
					specialPensionFundPercentageEmployee: 2,
					taxFreeAllowance: 100,
					taxFreeAllowanceUnused: 0,
					taxFreeAllowanceSpouse: 0,
					taxFreeAllowanceSpouseUnused: 0,
					taxCalculationConfig: "STANDARD",
					Istarf98: "9999.99" || "0110.01",
					payslipLanguage: "is",
				},
				specialPensionFundExtraSettings: [],
				specialPensionFundExtra: [],
				salaryGroupIds: [],
				departmentIds: [],
				companyRole: "",
				nextSuperiorId: 0,
				registrationId: "",
				fullName: "",
				dateOfBirth: "",
				dateOfHire: dateNowString,
				address: "",
				address2: "",
				city: "",
				region: "",
				postalCode: "",
				country: "",
				photo: "",
				phone: "",
				notes: "",
				email: "",
				isSalaryHourlyBased: false,
				starfshlutfall: 100,
				ID: 0,
				holidaySettings: {
					holidaySettingType: "0",
					daysCollected: "0",
					percentageOfSalary: "10.17",
					holidayDaysOption: 1,
					holidayDayOption: {
						id: 1,
						description: "Launað frí",
						details: "Orlof tekið út sem launað frí",
						percentage: 0,
						numberOfDays: 0,
					},
					holidayBankAccount: {
						accountNumber: "0",
						bankNUmber: "0",
						hofudbok: "0",
					},
				},
				salary: {
					salaryTableId: getCurrentCompanyId(),
					salaryElements: [],
				},
		  };

	function AccordionLabel(text: string, isSectionValid: boolean) {
		return (
			<>
				<Group>
					{isSectionValid ? <CircleCheck color="green" /> : <AlertCircle />}
					<Text size="xl">{text}</Text>
				</Group>
			</>
		);
	}

	const requiredMark = <AsteriskSimple size={9} color="#ff6b6b" />;

	const validationSchema = Yup.object({
		registrationId: Yup.string()
			.length(10)
			.required(t("validation.kennitala_required"))
			.test({
				test(value, ctx) {
					if (isProd() && !kennitala.isValid(value)) {
						return ctx.createError({
							message: t("validation.kennitala_invalid"),
						});
					}
					return true;
				},
			}),
		fullName: Yup.string().required(t("validation.name_required")),
	});
	const formik = useFormik<EmployeeForm>({
		initialValues: formData,
		onSubmit: (values, { setSubmitting }) => {
			// values.registrationId = kennitala.sanitize(values.registrationId);
			if (kennitala.isValid(values.registrationId)) {
				values.dateOfBirth = kennitala
					.info(values.registrationId)
					.birthday.toISOString();
			}
			if (values.settings?.unionFees?.length) {
				values.settings.unionFees.map((union) => {
					union.percentage = union.percentage / 100;
					return union;
				});
			}
			if (values.holidaySettings?.holidayDaysOption) {
				const holidayOptions = JSON.parse(
					values.holidaySettings?.holidayDaysOption.toString()
				);
				values.holidaySettings.holidayDaysOption = holidayOptions.id;
				values.holidaySettings.holidayDayOption = holidayOptions;
			}
			const endPoint = props.employeeData
				? "/employee/edit"
				: "/employee/create";
			values.photo = croppedImage;
			axios
				.post(`${process.env.REACT_APP_API_URL}${endPoint}`, values)
				.then(function (response) {
					if (response.status === 200) {
						console.log(response.data.Message);
						showNotification({
							message: response.data.Message,
							color: "green",
							icon: <Check size={16} />,
						});
						if (props.onEditCancel) {
							props.onEditCancel();
						}
						setSubmitting(false);
						dispatch(fetchEmployeesData());
					}
				})
				.catch((error) => {
					console.log(error.response.data);
					setSubmitting(false);
					showNotification({
						autoClose: false,
						title: "Villa",
						message: error.response.data.message,
						color: "red",
						icon: <ExclamationMark size={16} />,
					});
				});
		},
		validationSchema: validationSchema,
		validateOnBlur: true,
	});

	function isSectionValid(section: number) {
		var valErrors = formik.errors;

		switch (section) {
			case 0: {
				return (
					!valErrors.registrationId && !valErrors.fullName && !valErrors.email
				);
			}
			case 1:
				return formik.values.salary?.salaryElements.length > 0;
			default:
				return true;
		}
	}

	enum ValidationLevel {
		ERROR = "error",
		WARNING = "warning",
		INFO = "info",
	}

	interface validationOverview {
		level: ValidationLevel;
		message: string;
	}

	const [accordionState, accordionHandlers] = useAccordionState({
		total: 6,
		initialItem: 0,
	});

	function navigateSection(section: number) {
		accordionHandlers.toggle(section);
	}

	useEffect(() => {
		// run validation to determine if sections should be marked OK (isSectionValid function)
		formik.validateForm();
	}, []);

	// useEffect(() => {
	function getValidationOverview(): validationOverview[] {
		const validationErrors = formik.errors;
		const salaryElements = formik.values.salary?.salaryElements;

		let overview: validationOverview[] = [];

		if (validationErrors.registrationId)
			overview.push({
				level: ValidationLevel.ERROR,
				message: validationErrors.registrationId,
			} as validationOverview);

		if (validationErrors.fullName)
			overview.push({
				level: ValidationLevel.ERROR,
				message: validationErrors.fullName,
			} as validationOverview);

		if (salaryElements?.length === 0)
			overview.push({
				level: ValidationLevel.ERROR,
				message: t("validation.salary_elements_cannot_be_empty"),
			} as validationOverview);

		if (salaryElements?.length > 0) {
			salaryElements.every((element) => {
				if (!element.value && element.value !== 0) {
					overview.push({
						level: ValidationLevel.WARNING,
						message: t("validation.salary_elements_null_amounts"),
					} as validationOverview);
					return false;
				}
				return true;
			});
		}

		return overview;
	}

	useEffect(() => {
		if (formData !== formik.values) {
			setFormDataChanged(true);
		}
	}, [formik.values]);

	const { handleChange, setFieldValue, values } = formik;

	function getValidationColor(level: string): string {
		switch (level) {
			case "error":
				return "red";
			case "warning":
				return "orange";
			case "info":
				return "blue";
			default:
				return "";
		}
	}

	const cropperRef = useRef<ReactCropperElement>(null);
	const onCrop = () => {
		const cropper = cropperRef.current?.cropper;
		if (typeof cropper !== "undefined") {
			const imageUrl = cropper.getCroppedCanvas().toDataURL("image/jpeg");
			setCroppedImage(imageUrl);
		}
	};

	const [imageSrc, setImageSrc] = useState(values.photo);
	const [croppedImage, setCroppedImage] = useState("");

	const formComponent = (
		<>
			<Grid>
				<Grid.Col span={9}>
					{props.title ? (
						<>
							<Title order={1}>{props.title}</Title>
							<Space h="md" />
						</>
					) : null}
				</Grid.Col>
				<Grid.Col span={3}>
					{getValidationOverview().length > 0 ? (
						<div style={{ float: "right", marginTop: 12 }}>
							<UnstyledButton onClick={() => setValidationDrawerOpened(true)}>
								<AlertCircle size="36" color="orange" />
							</UnstyledButton>
						</div>
					) : null}
				</Grid.Col>
			</Grid>
			<Drawer
				opened={validationDrawerOpened}
				onClose={() => setValidationDrawerOpened(false)}
				position="right"
				size="xl"
				overlayOpacity={0.55}
				overlayBlur={3}>
				{getValidationOverview().map((el, index) => (
					<div style={{ margin: 24 }} key={index}>
						<AlertCircle
							style={{ marginTop: 8, marginRight: 12 }}
							size="24"
							color={getValidationColor(el.level)}
						/>
						{el.message}
					</div>
				))}
			</Drawer>
			<Space h="md" />
			<LaunaLoadingOverlay
				visible={formik.isSubmitting}
				label={t("add_employees.saving")}
			/>
			<LoadingOverlay visible={!bankDataLoaded && !createDataLoaded} />
			{bankDataLoaded && createDataLoaded ? (
				<form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
					<Accordion
						state={accordionState}
						onChange={accordionHandlers.setState}
						iconPosition="right"
						classNames={classes}
						className={classes.root}>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.basic_information"),
								isSectionValid(0)
							)}>
							{requiredMark}
							{" " + t("add_employees.required")}
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									cursor: "pointer",
								}}>
								<Avatar
									style={{ display: "inline-block" }}
									src={imageSrc}
									size={80}
									radius={80}
									onClick={() => {
										setEditPhotoDrawerOpened(true);
									}}
								/>
							</Box>
							<Drawer
								position="right"
								size="xl"
								opened={editPhotoDrawerOpened}
								onClose={() => {
									setEditPhotoDrawerOpened(false);
								}}>
								{imageSrc && (
									<Container>
										<Cropper
											src={imageSrc}
											guides={false}
											crop={onCrop}
											ref={cropperRef}
											checkCrossOrigin={false}
											style={{ margin: "0 50px", height: 400 }}></Cropper>
										<Box mt={12} sx={{ width: "90%" }}>
											<Center mt={7}>
												<Button
													onClick={() => {
														if (
															typeof cropperRef.current?.cropper !== "undefined"
														) {
															setCroppedImage(
																cropperRef.current?.cropper
																	.getCroppedCanvas()
																	.toDataURL("image/jpeg")
															);
														}
														setEditPhotoDrawerOpened(false);
													}}>
													Save
												</Button>
												<Button color="gray" onClick={() => openRef.current()}>
													Change
												</Button>
												<Button
													color="red"
													onClick={() => {
														setImageSrc("");
														setFieldValue("photo", null);
													}}>
													Remove
												</Button>
											</Center>
										</Box>
										<Center>
											<div className="img-preview"></div>
										</Center>
									</Container>
								)}
								<Dropzone
									sx={{
										visibility: imageSrc ? "hidden" : "visible",
									}}
									onDrop={(files) => {
										setImageSrc(URL.createObjectURL(files[0]));
										const fileReader = new FileReader();
										fileReader.onload = () => {
											setFieldValue(
												"photo",
												fileReader.result?.toString() || ""
											);
										};
										fileReader.readAsDataURL(files[0]);
									}}
									accept={[
										"image/png",
										"image/jpeg",
										"image/sgv+xml",
										"image/gif",
										"image/webp",
									]}
									maxSize={5000000}
									openRef={openRef}>
									{() => (
										<Group
											position="center"
											spacing="xl"
											style={{ minHeight: 220, pointerEvents: "none" }}>
											<Image src={placeHolder} />
											<div>
												<Text size="xl" inline>
													Drag an image here or click to select
												</Text>
												<Text size="sm" color="dimmed" inline mt={7}>
													A file should not exceed 5mb
												</Text>
											</div>
										</Group>
									)}
								</Dropzone>
							</Drawer>
							<Grid columns={24} className={classes.grid}>
								<Grid.Col md={8}>
									<TextInput
										label={t("add_employees.ssn")}
										required={true}
										name="registrationId"
										id="registrationId"
										onChange={handleChange}
										value={values.registrationId}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<TextInput
										label={t("add_employees.name")}
										required={true}
										name="fullName"
										onChange={handleChange}
										value={values.fullName}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<TextInput
										label={t("add_employees.job_percentage")}
										required={true}
										max={100}
										min={1}
										icon={<Percentage />}
										type="number"
										name="starfshlutfall"
										onChange={handleChange}
										value={values.starfshlutfall}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<TextInput
										label={t("add_employees.address")}
										placeholder={t("add_employees.address_placeholder")}
										name="address"
										onChange={handleChange}
										value={values.address}
									/>
								</Grid.Col>
								<Grid.Col md={3}>
									<TextInput
										label={t("add_employees.postal_code")}
										placeholder={t("add_employees.postal_code")}
										name="postalCode"
										onChange={handleChange}
										value={values.postalCode}
									/>
								</Grid.Col>
								<Grid.Col md={9}>
									<TextInput
										label={t("add_employees.city")}
										placeholder={t("add_employees.city")}
										name="city"
										onChange={handleChange}
										value={values.city}
									/>
								</Grid.Col>
								<Grid.Col md={4}>
									<TextInput
										label={t("add_employees.phone")}
										placeholder={t("add_employees.phone")}
										name="phone"
										onChange={handleChange}
										value={values.phone}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<Select
										styles={customSelectStyles}
										label={t("add_employees.salary_account_number")}
										searchable
										clearable
										dropdownPosition="bottom"
										spellCheck={false}
										data={bankBranches}
										name="settings.bankAccount.bankNUmber"
										onChange={(val) =>
											setFieldValue(
												"settings.bankAccount.bankNUmber",
												val ? parseInt(val) : 0
											)
										}
										value={values.settings?.bankAccount?.bankNUmber?.toString()}
									/>
								</Grid.Col>
								<Grid.Col md={3}>
									<TextInput
										label={t("add_employees.ledger")}
										placeholder={t("add_employees.ledger_placeholder")}
										max={99}
										min={1}
										type="number"
										name="settings.bankAccount.hofudbok"
										onChange={handleChange}
										value={
											values.settings?.bankAccount?.hofudbok
												? Number.parseInt(
														values.settings?.bankAccount?.hofudbok
												  )
												: undefined
										}
									/>
								</Grid.Col>
								<Grid.Col md={5}>
									<TextInput
										label={t("add_employees.account_number")}
										placeholder={t("add_employees.account_number")}
										min={0}
										type="number"
										name="settings.bankAccount.accountNumber"
										onChange={handleChange}
										value={
											values.settings?.bankAccount?.accountNumber
												? Number.parseInt(
														values.settings?.bankAccount?.accountNumber
												  )
												: undefined
										}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<DatePicker
										icon={<Calendar size={16} />}
										styles={customSelectStyles}
										label={t("add_employees.date_of_hire")}
										name="dateOfHire"
										inputFormat="D. MMMM YYYY"
										locale="is-IS"
										onChange={(dt) => {
											if (dt === null)
												setFieldValue("dateOfHire", dateNowString);
											else setFieldValue("dateOfHire", dateToString(dt));
										}}
										value={new Date(values.dateOfHire)}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<TextInput
										label={
											<>
												{t("add_employees.email")}
												&nbsp;
												<Tooltip
													withArrow
													width={220}
													wrapLines
													label={t("add_employees.email_tooltip")}>
													<Help size={16} />
												</Tooltip>
											</>
										}
										name="email"
										onChange={handleChange}
										value={values.email}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<Select
										styles={customSelectStyles}
										label={t("add_employees.company_role")}
										placeholder={t("add_employees.company_role_placeholder")}
										searchable
										clearable
										creatable
										dropdownPosition="bottom"
										spellCheck={false}
										data={companyRoles}
										name="companyRole"
										onCreate={(role) => {
											setCompanyRoles([
												...companyRoles,
												{ label: role, value: role },
											]);
											dispatch(addCompanyRole(role));
										}}
										getCreateLabel={(query) => `+ Create ${query}`}
										onChange={(val) => setFieldValue("companyRole", val)}
										value={values.companyRole}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<Select
										styles={customSelectStyles}
										label={t("add_employees.payslip_language")}
										dropdownPosition="bottom"
										spellCheck={false}
										data={t("add_employees.payslip_languages", {
											returnObjects: true,
										})}
										name="settings.payslipLanguage"
										onChange={(val) =>
											setFieldValue("settings.payslipLanguage", val)
										}
										value={values.settings?.payslipLanguage}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<MultiSelect
										styles={customSelectStyles}
										label={t("add_employees.salary_group")}
										placeholder={t("add_employees.salary_group_placeholder")}
										clearable
										dropdownPosition="bottom"
										spellCheck={false}
										data={salaryGroups}
										name="salaryGroupIds"
										onChange={(val) => {
											setFieldValue("salaryGroupIds", val);
										}}
										value={values.salaryGroupIds}
									/>
								</Grid.Col>
								<Grid.Col md={8}>
									<MultiSelect
										styles={customSelectStyles}
										label={t("add_employees.departments")}
										placeholder={t("add_employees.departments_placeholder")}
										dropdownPosition="bottom"
										spellCheck={false}
										data={departments}
										name="departmentIds"
										onChange={(val) => {
											setFieldValue("departmentIds", val);
										}}
										value={values.departmentIds}
									/>
								</Grid.Col>
							</Grid>

							<Space h="md" />
							<Group position="right">
								<Button
									onClick={() => navigateSection(1)}
									rightIcon={<IconArrowForward />}></Button>
							</Group>
						</Accordion.Item>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.salary_elements"),
								isSectionValid(1)
							)}>
							<Space h="md" />
							<SalaryItemsRateCalculateComponent
								salaryElements={salaryElements}
								formik={formik}
							/>
							<Space h="md" />
							<Group position="right">
								<Button
									onClick={() => navigateSection(0)}
									leftIcon={<IconArrowBack />}></Button>
								<Button
									onClick={() => navigateSection(2)}
									rightIcon={<IconArrowForward />}></Button>
							</Group>
						</Accordion.Item>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.cash_payment"),
								isSectionValid(2)
							)}>
							{<TaxComponent formik={formik} />}
							<Space h="md" />
							<Group position="right">
								<Button
									onClick={() => navigateSection(1)}
									leftIcon={<IconArrowBack />}></Button>
								<Button
									onClick={() => navigateSection(3)}
									rightIcon={<IconArrowForward />}></Button>
							</Group>
						</Accordion.Item>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.pension_funds"),
								isSectionValid(3)
							)}>
							{
								<PensionFundsComponent
									pensionFunds={pensionFunds}
									extraPensionFunds={extraPensionFunds}
									formik={formik}
								/>
							}
							<Group position="right">
								<Button
									onClick={() => navigateSection(2)}
									leftIcon={<IconArrowBack />}></Button>
								<Button
									onClick={() => navigateSection(4)}
									rightIcon={<IconArrowForward />}></Button>
							</Group>
						</Accordion.Item>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.union"),
								isSectionValid(4)
							)}>
							<UnionComponent unions={unions} formik={formik} />
							<Group position="right">
								<Button
									onClick={() => navigateSection(3)}
									leftIcon={<IconArrowBack />}></Button>
								<Button
									onClick={() => navigateSection(5)}
									rightIcon={<IconArrowForward />}></Button>
							</Group>
						</Accordion.Item>
						<Accordion.Item
							label={AccordionLabel(
								t("add_employees.vacation"),
								isSectionValid(5)
							)}>
							<VacationComponent
								holidayOptions={createData.data.holidayDayOptions}
								bankBranches={bankBranches}
								formik={formik}
							/>
							<Group position="right">
								<Button
									onClick={() => navigateSection(4)}
									leftIcon={<IconArrowBack />}></Button>
							</Group>
						</Accordion.Item>
					</Accordion>

					<PrimaryButton
						type="submit"
						fullWidth
						loading={formik.isSubmitting}
						disabled={!formik.isValid || getValidationOverview().length > 0}>
						{t("common.save")}
					</PrimaryButton>

					<Modal
						opened={opened}
						onClose={() => {
							setOpened(false);
						}}
						withCloseButton={false}
						title={t("add_employees.are_you_sure")}>
						<Center>{t("add_employees.changes_made")}</Center>
						<Group position="center" mt="sm">
							<Button
								onClick={() => {
									if (props.onEditCancel) {
										props.onEditCancel();
									}
								}}>
								{t("common.yes")}
							</Button>
							<Button color="gray" onClick={() => setOpened(false)}>
								{t("common.no")}
							</Button>
						</Group>
					</Modal>

					{props.showInModal && props.employeeData?.registrationId ? (
						<>
							<Space h="md"></Space>
							<Center>
								<Button
									type="button"
									variant="outline"
									onClick={props.onEditCancel}>
									{t("common.cancel")}
								</Button>
							</Center>
						</>
					) : null}
				</form>
			) : null}
		</>
	);

	return props.showInModal ? (
		<Modal
			opened={true}
			size="80%"
			onClose={() => {
				if (formDataChanged) {
					setOpened(true);
				} else if (props.onEditCancel) {
					props.onEditCancel();
				}
			}}
			closeOnClickOutside={!formDataChanged}>
			{formComponent}
		</Modal>
	) : (
		formComponent
	);
}

export default EmployeeFormComponent;
