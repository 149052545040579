import React from "react";
import { Paper, createStyles, Title, Text, Stack } from "@mantine/core";
import bgImage from "../../assets/bg.jpg";
import logo from "../../assets/logo-white.png";
import ToggleLanguageButton from "./ToggleLanguageButton";
import { LANGUAGE_BUTTON_TYPES } from "../../helpers/enums";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "linear-gradient(to right,rgba(147,83,161,.8),rgba(42,191,200,.8),rgba(250,165,54,.8),rgba(239,73,112,.8),rgba(238,53,128,.73)),url('" +
      bgImage +
      "');",
  },

  form: {
    borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
    backgroundColor: "rgb(0 0 0 / 80%);",
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function Wrapper({ title, children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title
          order={2}
          className={classes.title}
          align="center"
          mt="md"
          mb={50}
        >
          <img alt="" src={logo} style={{ maxWidth: "200px" }} />
          <Text>{title}</Text>
        </Title>
        <div style={{ textAlign: "right" }}>
        </div>
        {children}
        <Stack align="center" justify="flex-end" sx={(theme) => ({ height: 100 })}>
          <ToggleLanguageButton type={LANGUAGE_BUTTON_TYPES.BUTTON} />
        </Stack>
      </Paper>
    </div>
  );
}

export default Wrapper;
