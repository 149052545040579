import React, { useEffect } from "react";
import { Text, Select, Tooltip, SimpleGrid, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SelectData } from "../../data/types/addEmployees";
import { Help, Percentage } from "tabler-icons-react";
import { HolidayDayOption } from "../../data/types/employeeFormData";

function VacationComponent({
	holidayOptions,
	bankBranches,
	formik,
}: {
	holidayOptions: HolidayDayOption[];
	bankBranches: SelectData;
	formik: any;
}) {
	const { setFieldValue, handleChange, values } = formik;

	const { t } = useTranslation();

	holidayOptions = [...holidayOptions].sort(
		(a: HolidayDayOption, b: HolidayDayOption) =>
			a.numberOfDays - b.numberOfDays
	);

	const holidayDaysOption: SelectData = holidayOptions.map((holidayOption) => {
		const label =
			holidayOption.percentage === 0
				? holidayOption.description
				: `${holidayOption.percentage}% (${holidayOption.description})`;
		return {
			value: holidayOption.percentage.toString(),
			label,
		};
	});
	const holidaySettingType =
		values.holidaySettings.holidaySettingType.toString();

	useEffect(() => {
		if (values.holidaySettings.holidaySettingType === "1") {
			setFieldValue("holidaySettings.holidayDaysOption", "2");
		}
	}, [values.holidaySettings.holidaySettingType]);

	useEffect(() => {
		if (
			typeof parseInt(values.holidaySettings.holidayDaysOption) === "number"
		) {
			values.holidaySettings.holidayDaysOption = JSON.stringify(
				holidayOptions.find(
					(holidayOption) =>
						holidayOption.id === values.holidaySettings.holidayDaysOption
				)
			);
		}
	}, []);

	return (
		<>
			<Text size="lg">{t("add_employees.vacation_information.title")}</Text>
			<SimpleGrid
				cols={3}
				mt={12}
				mb={12}
				breakpoints={[{ maxWidth: 850, cols: 1 }]}>
				<Select
					label={
						<>
							{t("add_employees.vacation_information.holiday_type")}
							&nbsp;
							<Tooltip
								withArrow
								width={300}
								wrapLines
								position="top"
								label={t(
									"add_employees.vacation_information.holiday_type_tooltip"
								)}>
								<Help size={16} />
							</Tooltip>
						</>
					}
					data={
						t("add_employees.vacation_information.vacation", {
							returnObjects: true,
						}) || []
					}
					onChange={(val) =>
						setFieldValue("holidaySettings.holidaySettingType", val)
					}
					value={values.holidaySettings.holidaySettingType.toString()}
				/>

				{holidaySettingType === "1" ? (
					<TextInput
						label={t("add_employees.vacation_information.percentage_of_salary")}
						type="number"
						rightSection={<Percentage />}
						name="holidaySettings.percentageOfSalary"
						onChange={handleChange}
						value={values.holidaySettings.percentageOfSalary.toString()}
					/>
				) : (
					<Select
						label={t("add_employees.vacation_information.percentage_of_salary")}
						data={holidayDaysOption}
						value={values.holidaySettings.percentageOfSalary.toString()}
						onChange={(val) =>
							setFieldValue("holidaySettings.percentageOfSalary", val)
						}
					/>
				)}

				{(holidaySettingType === "0" ||
					holidaySettingType === "3" ||
					holidaySettingType === "4") && (
					<TextInput
						label={
							<>
								{t("add_employees.vacation_information.holiday_collected_days")}
								&nbsp;
								<Tooltip
									withArrow
									label={t(
										"add_employees.vacation_information.holiday_collected_days_tooltip"
									)}>
									<Help size={16} />
								</Tooltip>
							</>
						}
						type="number"
						name="holidaySettings.daysCollected"
						onChange={handleChange}
						value={values.holidaySettings.daysCollected}
					/>
				)}

				{(holidaySettingType === "1" || holidaySettingType === "2") && (
					<div></div>
				)}
				{(holidaySettingType === "1" ||
					holidaySettingType === "2" ||
					holidaySettingType === "4") && (
					<>
						<Select
							label={t("add_employees.vacation_information.bank_account")}
							searchable
							clearable
							dropdownPosition="bottom"
							spellCheck={false}
							data={bankBranches}
							onChange={(val) => {
								setFieldValue(
									"holidaySettings.holidayBankAccount.bankNUmber",
									val
								);
							}}
							value={values.holidaySettings.holidayBankAccount?.bankNUmber.toString()}
						/>
						<TextInput
							label={t("add_employees.vacation_information.ledger")}
							type="number"
							placeholder="hb"
							name="holidaySettings.holidayBankAccount.hofudbok"
							onChange={handleChange}
							value={values.holidaySettings.holidayBankAccount?.hofudbok}
						/>
						<TextInput
							label={t("add_employees.vacation_information.account_number")}
							type="number"
							name="holidaySettings.holidayBankAccount.accountNumber"
							onChange={handleChange}
							value={values.holidaySettings.holidayBankAccount?.accountNumber}
						/>
					</>
				)}
			</SimpleGrid>
		</>
	);
}

export default VacationComponent;
