import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
	Employee,
	Payslip,
	SalaryElementEditState,
	SimpleSalaryElementPropsExt,
} from "../types/payslip";
import axios from "axios";
import { CalculatePayslipError } from "../types/errorTypes/calculatePayslipError";

/*
* This slice is used to manage the state of caluculated payslips and the payslip view, either in edit mode or not.
==================================================================================================================
It keeps track of the following:
- whether the payslipView is in edit mode or not
- the list of payslips calculated
- the current payslip in the view
- the list of salary elements being worked on for current payslip

payslips.tsx uses this slice to display the payslips being worked on.

payslipView.tsx uses this slice to display the payslip being worked on and 
shows a list of added, edited and removed salary elements in a separate component.
If there are any changes to the salary elements, a "recalculate" button is shown.

payslipSalaryElements.tsx and payslipSalaryElement.tsx use this slice 
to notify the payslipView that it needs to recalculate the payslip.
*/
interface PayslipViewState {
	editMode: boolean;
	yearMonth: string;
	payslips: Payslip[];
	calculatePayslipErrors: CalculatePayslipError[];
	currentPayslip: Payslip | null;
	payslipsEdited: boolean;
	salaryElements: SimpleSalaryElementPropsExt[];
	recalculating: boolean;
	allEditedSalaryElements: SimpleSalaryElementPropsExt[];
}

const initialState: PayslipViewState = {
	editMode: false,
	yearMonth: "",
	payslips: [],
	calculatePayslipErrors: [],
	currentPayslip: null,
	payslipsEdited: false,
	salaryElements: [],
	recalculating: false,
	allEditedSalaryElements: [],
};

interface SalaryElementIdWithEmployeeId {
	id: number;
	employeeId: number;
}

export const payslipViewSlice = createSlice({
	name: "payslipeditor",
	initialState,
	reducers: {
		setEditMode: (state, action: PayloadAction<boolean>) => {
			state.editMode = action.payload;
		},
		setYearMonth: (state, action: PayloadAction<string>) => {
			state.yearMonth = action.payload;
		},
		setPayslips: (state, action: PayloadAction<Payslip[]>) => {
			state.payslips = action.payload;
		},
		replacesPayslip: (state, action: PayloadAction<Payslip>) => {
			const index = state.payslips.findIndex(
				(p) => p.guid === action.payload.guid
			);
			if (index !== -1) {
				state.payslips[index] = action.payload;
			}
		},
		setCalculatePayslipErrors: (
			state,
			action: PayloadAction<CalculatePayslipError[]>
		) => {
			state.calculatePayslipErrors = action.payload;
		},
		setCurrentPayslip: (state, action: PayloadAction<string>) => {
			if (action.payload === "") state.currentPayslip = null;
			state.currentPayslip =
				state.payslips.find((p) => p.guid === action.payload) || null;
		},
		setPayslipsEdited: (state, action: PayloadAction<boolean>) => {
			state.payslipsEdited = action.payload;
		},
		setSalaryElements: (
			state,
			action: PayloadAction<SimpleSalaryElementPropsExt[]>
		) => {
			state.salaryElements = action.payload;
		},
		addToAllEditedSalaryElements: (
			state,
			action: PayloadAction<SimpleSalaryElementPropsExt[]>
		) => {
			action.payload.forEach((se) => {
				const index = state.allEditedSalaryElements.findIndex(
					(existing) =>
						se.employeeId === existing.employeeId && se.id === existing.id
				);
				if (index === -1) state.allEditedSalaryElements.push(se);
			});
		},
		addSalaryElement: (
			state,
			action: PayloadAction<SimpleSalaryElementPropsExt>
		) => {
			state.salaryElements.push(action.payload);
			// check if salary element is already in allEditedSalaryElements
			// if so, replace it - otherwise add it
			const index = state.allEditedSalaryElements.findIndex(
				(existing) =>
					action.payload.employeeId === existing.employeeId &&
					action.payload.id === existing.id
			);
			if (index === -1) {
				state.allEditedSalaryElements.push(action.payload);
			} else {
				state.allEditedSalaryElements[index] = action.payload;
			}
		},
		updateSalaryElement: (
			state,
			action: PayloadAction<SimpleSalaryElementPropsExt>
		) => {
			const index = state.salaryElements.findIndex(
				(se) => se.id === action.payload.id
			);
			if (index !== -1) {
				state.salaryElements[index] = action.payload;
			}
			// check if salary element is already in allEditedSalaryElements
			// if so, replace it - otherwise add it
			const index2 = state.allEditedSalaryElements.findIndex(
				(existing) =>
					action.payload.employeeId === existing.employeeId &&
					action.payload.id === existing.id
			);
			if (index2 === -1) {
				state.allEditedSalaryElements.push(action.payload);
			} else {
				state.allEditedSalaryElements[index2] = action.payload;
			}
		},
		undoSalaryElementEdit: (state, action: PayloadAction<number>) => {
			const salaryElementsToUndo = state.salaryElements.filter(
				(se) =>
					se.employeeId === action.payload &&
					se.editState !== undefined &&
					se.editState !== SalaryElementEditState.UNCHANGED
			);
			console.log("undoing these salary elements", salaryElementsToUndo);
			if (salaryElementsToUndo.length > 0) {
				// remove salaryElementsToUndo from state.salaryElements
				state.salaryElements = state.salaryElements.filter(
					(se) => se.employeeId !== action.payload
				);
				//also remove from allEditedSalaryElements
				state.allEditedSalaryElements = state.allEditedSalaryElements.filter(
					(se) => se.employeeId !== action.payload
				);
			}
		},
		removeSalaryElement: (
			state,
			action: PayloadAction<SalaryElementIdWithEmployeeId>
		) => {
			const salaryElementToRemove = state.salaryElements.find(
				(se) =>
					se.id === action.payload.id &&
					se.employeeId === action.payload.employeeId
			);
			if (salaryElementToRemove) {
				state.salaryElements.push({
					...salaryElementToRemove,
					editState: SalaryElementEditState.DELETED,
				});
				// we also need to check if the salary element needs to be added or update in allEditedSalaryElements
				state.allEditedSalaryElements.push({
					...salaryElementToRemove,
					editState: SalaryElementEditState.DELETED,
				});
			} else {
				const index = state.salaryElements.findIndex(
					(se) =>
						se.id === action.payload.id &&
						se.employeeId === action.payload.employeeId
				);
				if (index !== -1)
					state.salaryElements[index].editState =
						SalaryElementEditState.DELETED;
			}
		},
		setRecalculating: (state, action: PayloadAction<boolean>) => {
			state.recalculating = action.payload;
		},
	},
});

export const {
	setEditMode,
	setYearMonth,
	setPayslips,
	replacesPayslip,
	setCalculatePayslipErrors,
	setCurrentPayslip,
	setPayslipsEdited,
	setSalaryElements,
	addSalaryElement,
	updateSalaryElement,
	removeSalaryElement,
	undoSalaryElementEdit,
	setRecalculating,
} = payslipViewSlice.actions;

export const editModeSet =
	(editMode: boolean) => (dispatch: Dispatch<PayloadAction<boolean>>) => {
		dispatch(setEditMode(editMode));
	};

export const yearMonthSet =
	(yearMonth: string) => (dispatch: Dispatch<PayloadAction<string>>) => {
		dispatch(setYearMonth(yearMonth));
	};

export const payslipsSet =
	(payslips: Payslip[]) => (dispatch: Dispatch<PayloadAction<Payslip[]>>) => {
		dispatch(setPayslips(payslips));
	};

export const payslipReplaced =
	(payslip: Payslip) => (dispatch: Dispatch<PayloadAction<Payslip>>) => {
		dispatch(replacesPayslip(payslip));
	};

export const currentPayslipSet =
	(guid: string) => (dispatch: Dispatch<PayloadAction<string>>) => {
		dispatch(setCurrentPayslip(guid));
	};

export const payslipsEditedSet =
	(payslipsEdited: boolean) => (dispatch: Dispatch<PayloadAction<boolean>>) => {
		dispatch(setPayslipsEdited(payslipsEdited));
	};

export const salaryElementsSet =
	(salaryElements: SimpleSalaryElementPropsExt[]) =>
	(dispatch: Dispatch<PayloadAction<SimpleSalaryElementPropsExt[]>>) => {
		dispatch(setSalaryElements(salaryElements));
	};

export const salaryElementAdd =
	(salaryElement: SimpleSalaryElementPropsExt) =>
	(dispatch: Dispatch<PayloadAction<SimpleSalaryElementPropsExt>>) => {
		dispatch(addSalaryElement(salaryElement));
	};

export const salaryElementUpdate =
	(salaryElement: SimpleSalaryElementPropsExt) =>
	(dispatch: Dispatch<PayloadAction<SimpleSalaryElementPropsExt>>) => {
		dispatch(updateSalaryElement(salaryElement));
	};

export const salaryElementRemove =
	(idWithEmployeeId: SalaryElementIdWithEmployeeId) =>
	(dispatch: Dispatch<PayloadAction<SalaryElementIdWithEmployeeId>>) => {
		dispatch(removeSalaryElement(idWithEmployeeId));
	};

export const salaryElementEditUndo =
	(employeeId: number | undefined) =>
	(dispatch: Dispatch<PayloadAction<number>>) => {
		console.log("undoing edits for employee", employeeId);
		if (employeeId) dispatch(undoSalaryElementEdit(employeeId));
	};

export const recalculating =
	(rec: boolean) => (dispatch: Dispatch<PayloadAction<boolean>>) => {
		dispatch(setRecalculating(rec));
	};

export default payslipViewSlice.reducer;
export const getPayslipEditorState = (state: RootState) => state.payslipEditor;
