import React, { useEffect, useState } from "react";
import {
	Paper,
	Table,
	TextInput,
	Tooltip,
	Grid,
	Checkbox,
	ActionIcon,
	Text,
	Space,
	Divider,
	Button,
	Modal,
	LoadingOverlay,
} from "@mantine/core";
import { t } from "react-i18nify";
import { Help, UserPlus, UserX } from "tabler-icons-react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { UserData, UserPermission } from "../data/types/userData";
import { fetchUsers, selectUsers } from "../data/users/usersSlice";
import { getCurrentCompanyId } from "../helpers/user";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconExclamationMark } from "@tabler/icons";

function Users() {
	const dispatch = useAppDispatch();
	const companyId = getCurrentCompanyId();
	const [email, setEmail] = useState("");
	const [loadingDimmer, setLoadingDimmer] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showPermissions, setShowPermissions] = useState(false);
	const [modalContent, setModalContent] = useState<UserPermission[]>([]);

	// const currentCompanyName = getCurrentCompany()

	const users = useAppSelector(selectUsers);
	useEffect(() => {
		if (Object.keys(users.users).length === 0 && !users.isLoading) {
			dispatch(fetchUsers());
		}
	}, [users, dispatch]);

	const changeAccess = (user: UserData) => {
		const previousPermissions = user.userPermissions.find(
			(permissions) => permissions.companyId === companyId
		);
		if (previousPermissions) {
			const { id, companyId, permissionsReadable, ...permissions } =
				previousPermissions;
			setUserPermissions(permissions);
		}
		setEmail(user.email);
	};

	const rows = users.users.users?.map((user: UserData) => {
		return (
			<tr key={user.id}>
				<td>{user.fullName}</td>
				<td>{user.email}</td>
				<td>
					<Tooltip label={t("users.show_permissions")}>
						<ActionIcon
							onClick={() => {
								setShowPermissions(true);
								setModalContent(user.userPermissions);
							}}>
							<Help />
						</ActionIcon>
					</Tooltip>
				</td>
				<td>
					<Tooltip label={t("users.change_access")}>
						<ActionIcon
							ml={21}
							onClick={() => {
								changeAccess(user);
							}}>
							<UserPlus />
						</ActionIcon>
					</Tooltip>
				</td>
				<td>
					<Tooltip label={t("users.revoke_access")}>
						<ActionIcon
							color="yellow"
							ml={21}
							disabled={user.isOwner}
							onClick={() => {
								setUser(user);
								setOpened(true);
								console.log(user);
							}}>
							<UserX />
						</ActionIcon>
					</Tooltip>
				</td>
			</tr>
		);
	});

	const initialPermissions = {
		company: true,
		employeeView: true,
		employeeEdit: true,
		employeeDelete: true,
		salaryProcessRegister: true,
		salaryProcessView: true,
		salaryProcessOpenSaved: true,
		salaryProcessDeleteSaved: true,
		salaryProcessOpenInProcess: true,
		salaryProcessopenProcessed: true,
		salaryProcessManualSendData: true,
		canInviteUsers: false,
		payslipsReview: false,
	};
	const [userPermissions, setUserPermissions] = useState(initialPermissions);

	const handleClick = (e: { target: { id: string; checked: boolean } }) => {
		const { id, checked } = e.target;
		setUserPermissions((prev) => {
			return { ...prev, [id]: checked };
		});
	};

	const selectAll = (e: { target: { checked: boolean } }) => {
		const { checked } = e.target;
		setUserPermissions((prev) => {
			for (let key of Object.keys(prev)) {
				prev = { ...prev, [key]: checked };
			}
			return prev;
		});
	};

	const permissions = Object.entries(userPermissions).map((permission) => {
		const [id, checked] = permission;
		return (
			<Checkbox
				key={id}
				mt={5}
				id={id}
				checked={checked}
				onChange={handleClick}
				label={t(`users.permissions.${id}`)}
			/>
		);
	});

	const save = () => {
		setLoading(true);
		const data = {
			email,
			companyId,
			...userPermissions,
		};
		axios
			.post(`${process.env.REACT_APP_API_URL}/invite/send`, data)
			.then(function (response) {
				console.log(response);
				if (response.status === 200) {
					showNotification({
						message: response.data.message,
						color: "green",
						icon: <IconCheck size={16} />,
					});
					dispatch(fetchUsers());
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				showNotification({
					title: "Error",
					message: error.message,
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
				setLoading(false);
			});
	};

	// type User = { id: number; fullName: string };
	const [opened, setOpened] = useState(false);
	const [user, setUser] = useState<UserData>({} as UserData);

	const revokeUser = () => {
		if (user.id) {
			setOpened(false);
			setLoadingDimmer(true);
			const data = { companyId, id: user.id };
			axios
				.post(`${process.env.REACT_APP_API_URL}/invite/recall`, data)
				.then(function (response) {
					console.log(response);
					if (response.status === 200) {
						showNotification({
							message: response.data.message,
							color: "green",
							icon: <IconCheck size={16} />,
						});
						dispatch(fetchUsers());
						setLoadingDimmer(false);
					}
				})
				.catch((error) => {
					console.log(error);
					showNotification({
						title: "Error",
						message: error.message,
						color: "red",
						icon: <IconExclamationMark size={16} />,
					});
					setLoadingDimmer(false);
				});
		}
	};

	return (
		<>
			<LoadingOverlay visible={loadingDimmer || users.isLoading} />
			<Paper>
				<h3>{t("users.title")}</h3>
				<Table striped>
					<thead>
						<tr>
							<th>{t("users.name")}</th>
							<th>{t("users.email")}</th>
							<th>{t("users.rights")}</th>
							<th>{t("users.change_access")}</th>
							<th>{t("users.revoke_access")}</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
				<Grid mt={24}>
					<Grid.Col md={5} lg={4}>
						<TextInput
							value={email}
							onChange={(event) => setEmail(event.currentTarget.value)}
							type="email"
							placeholder={t("users.email")}
							label={
								<Tooltip
									width={200}
									withArrow
									wrapLines={true}
									label={
										<>
											{t("users.invitation_tooltip")} {users.users.name}
										</>
									}>
									<Text size="lg">
										{t("users.invitation")} <Help size="19" />
									</Text>
								</Tooltip>
							}
						/>
					</Grid.Col>
					<Grid.Col md={5} lg={4}>
						<Text size="lg">{t("users.rights")}</Text>
						<Space h={12} />
						<Checkbox onChange={selectAll} label={t("users.select_all")} />
						<Divider my={5} />
						{permissions}
					</Grid.Col>
					<Grid.Col md={2} lg={2}>
						<Button loading={loading} onClick={save}>
							{t("users.save")}
						</Button>
					</Grid.Col>
				</Grid>
				<Modal
					size="sm"
					opened={opened}
					onClose={() => {
						setOpened(false);
						setUser({} as UserData);
					}}>
					<div style={{ textAlign: "center" }}>
						{t("users.are_you_sure_to_revoke")}
						<Divider mt={21} />
						<div>{user.fullName}</div>
					</div>
					<div
						style={{
							marginTop: "21px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "12px",
						}}>
						<Button color="yellow" onClick={() => revokeUser()}>
							{t("users.yes")}
						</Button>
						<Button
							onClick={() => {
								setOpened(false);
								setUser({} as UserData);
							}}>
							{t("users.no")}
						</Button>
					</div>
				</Modal>
			</Paper>
			<Modal opened={showPermissions} onClose={() => setShowPermissions(false)}>
				{modalContent.map((permission) => {
					return permission.companyId === companyId
						? permission.permissionsReadable.map((permission: string) => (
								<div key={permission}>
									{"✅" + permission}
									<br />
								</div>
						  ))
						: null;
				})}
			</Modal>
		</>
	);
}
export default Users;
