import React, { useEffect } from "react";
import { Plus } from "tabler-icons-react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
	getPayslipEditorState,
	payslipsEditedSet,
} from "../../../../data/payslips/payslipViewSlice";
import {
	SalaryElementEditState,
	SimpleSalaryElementProps,
	SimpleSalaryElementPropsExt,
} from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";
import PayslipSalaryElement from "./payslipSalaryElement";
import { Tooltip } from "@mantine/core";
import PayslipSalaryElementEditor from "./editor/payslipSalaryElementEditor";
import { useTranslation } from "react-i18next";
import { salaryElementAdd } from "../../../../data/payslips/payslipViewSlice";

function PayslipSalaryelements(props: {
	employeeId: number;
	editMode?: boolean;
}) {
	const { t } = useTranslation();
	const [showAddElement, setShowAddElement] = React.useState(false);
	const dispatch = useAppDispatch();
	const payslipSlice = useAppSelector(getPayslipEditorState);
	let firstColWidth = props.editMode ? "50%" : "55%";
	const [salaryElementIds, setSalaryElementIds] = React.useState<number[]>([]);

	const getPayslipSalaryTotal = () => {
		let total = 0;
		payslipSlice.salaryElements
			.filter((el) => el.editState === SalaryElementEditState.UNCHANGED)
			.forEach((el) => {
				total += el.value || 0;
			});
		return total;
	};

	useEffect(() => {
		// store the ids of the salary elements in local state
		const ids = payslipSlice.salaryElements.map((el) => {
			return el.id;
		});
		setSalaryElementIds(ids);
	}, [dispatch, payslipSlice.salaryElements]);

	return (
		<>
			<div key="salaryElementsWrapper">
				<div
					style={{
						padding: "3px",
						marginTop: "5px",
						backgroundColor: "#f1f1f1",
					}}>
					{props.editMode && (
						<div style={{ display: "inline-block", width: "5%" }}>
							<Tooltip
								position="right"
								label={t("add_employees.salary.add_salary_item")}>
								<Plus
									style={{ cursor: "pointer" }}
									size={24}
									onClick={() => {
										setShowAddElement(true);
									}}
								/>
							</Tooltip>
						</div>
					)}
					<div
						style={{
							display: "inline-block",
							width: firstColWidth,
							textAlign: "left",
						}}>
						<strong>Laun</strong>
					</div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Taxti</strong>
					</div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Ein.</strong>
					</div>
					<div
						style={{
							display: "inline-block",
							width: "15%",
							textAlign: "right",
						}}>
						<strong>Upphæð</strong>
					</div>
				</div>

				{payslipSlice.salaryElements
					.filter(
						(el) =>
							el.editState !== SalaryElementEditState.NEW &&
							el.editState !== SalaryElementEditState.DELETED
					)
					.map((element: SimpleSalaryElementPropsExt) => (
						<PayslipSalaryElement
							editMode
							key={element.id + element.description}
							data={{
								id: element.id,
								description: element.description,
								originalDescription: element.originalDescription,
								rate: element.rate,
								originalRate: element.originalRate,
								units: element.units,
								originalUnits: element.originalUnits,
								value: element.value,
								originalValue: element.originalValue,
								editState: element.editState,
								canEdit: element.canEdit,
								employeeId: props.employeeId,
							}}
							someEvent={() => {
								console.log("someEvent triggered");
							}}
						/>
					))}
				<div
					style={{ display: "inline-block", width: "85%", textAlign: "right" }}>
					<strong>Samtals laun:</strong>
				</div>
				<div
					style={{ display: "inline-block", width: "15%", textAlign: "right" }}>
					<strong>
						<Amount value={getPayslipSalaryTotal()} />
					</strong>
				</div>
			</div>
			{showAddElement && (
				<PayslipSalaryElementEditor
					editState={SalaryElementEditState.NEW}
					title={t("payslips.payslip_editor.add_element_line")}
					// Salary elements are unique in the payslip so skip the ids of the salary elements already in the payslip
					skipElementIds={salaryElementIds}
					onClosed={() => {
						setShowAddElement(false);
					}}
					onElementAdded={(el: SimpleSalaryElementProps) => {
						setShowAddElement(false);
						const elWithRate: SimpleSalaryElementPropsExt = {
							id: el.id,
							description: el.description,
							originalDescription: el.description,
							rate: el.value || 0,
							originalRate: el.value || 0,
							units: el.units,
							originalUnits: el.units,
							value: (el.value || 0) * el.units,
							originalValue: (el.value || 0) * el.units,
							editState: SalaryElementEditState.NEW,
							canEdit: el.canEdit,
							employeeId: props.employeeId,
							payslipGuid: payslipSlice.currentPayslip?.guid,
						};
						// add element to store
						dispatch(payslipsEditedSet(true));
						dispatch(salaryElementAdd(elWithRate));

						//add salary element id to salaryElementIds, so it can't be added again
						setSalaryElementIds([...salaryElementIds, el.id]);
					}}
				/>
			)}
		</>
	);
}

export default PayslipSalaryelements;
