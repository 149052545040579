import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Box, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../ui/buttons";
import { IconChevronDown } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { CardView } from "../../ui/common/sharedComponents";
import AccountingEntrie from "../../../pages/salaryprocessView/accountingEntries";
import {
	summaryPayslipsData,
	fetchSummarylPayslipsData,
} from "../../../data/salaryIncrease/summaryData";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
export default function Summary() {
	const { t, i18n } = useTranslation();
	var currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth();
	const fromDate = new Date(year, month - 1, 1);
	const toDate = new Date(year, 12, 0);
	const [dateFormDateTo, setDateFromDateTo] = useState<string>();
	var displaySummaryPayslip = useAppSelector(summaryPayslipsData);
	const dispatch = useAppDispatch();
	const form_data = useForm({
		initialValues: {
			from: fromDate,
			to: toDate,
		},
	});
	useEffect(() => {
		if (dateFormDateTo) {
			dispatch(fetchSummarylPayslipsData(dateFormDateTo));
		}
	}, [dateFormDateTo]);
	return (
		<CardView
			value={
				<>
					<Group position="right" ml={"md"}>
						<form
							onSubmit={form_data.onSubmit((values) => {
								var dateForm = format(new Date(values.from), "yyyy-MM-dd");
								var dateTo = format(new Date(values.to), "yyyy-MM-dd");
								setDateFromDateTo(`${dateForm}/${dateTo}`);
							})}>
							<Group position="apart" spacing={"xl"}>
								<Box> {t("salary_processe_list.summary_header")}</Box>
								<Group position="right" spacing={"xl"}>
									<DatePicker
										clearable={false}
										locale={i18n.language}
										pr={"md"}
										inputFormat="DD/MM/YYYY"
										labelFormat="MM/YYYY"
										label={t("payslips.from")}
										{...form_data.getInputProps("from")}
										onChange={(date: Date) =>
											form_data.setFieldValue("from", date)
										}
										rightSection={<IconChevronDown size={14} />}
										rightSectionWidth={30}
									/>
									<DatePicker
										clearable={false}
										locale={i18n.language}
										pr={"md"}
										inputFormat="DD/MM/YYYY"
										labelFormat="MM/YYYY"
										label={t("payslips.to")}
										{...form_data.getInputProps("to")}
										onChange={(date: Date) =>
											form_data.setFieldValue("to", date)
										}
										rightSection={<IconChevronDown size={14} />}
										rightSectionWidth={30}
									/>

									<PrimaryButton
										type="submit"
										loading={displaySummaryPayslip.isLoading}>
										{t("salary_processe_list.buttonLable")}
									</PrimaryButton>
								</Group>
							</Group>
						</form>
					</Group>
					{displaySummaryPayslip.summaryData.length === 0 ? (
						<></>
					) : (
						<AccountingEntrie
							accountingData={displaySummaryPayslip.summaryData}
						/>
					)}
				</>
			}
		/>
	);
}
