import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../data/user/userSlice";
import CompanyForm from "../pages/companyForm";
import Conditions from "../pages/Conditions";

interface Props {
	isAllowed: boolean;
	redirectPath: string;
	children?: JSX.Element;
}

const ProtectedRoute = ({
	isAllowed,
	redirectPath = "/login",
	children,
}: Props) => {
	let location = useLocation();
	const user = useAppSelector(selectUser);
	const [userMustAcceptAgreement, setUserMustAcceptAgreement] = useState(false);
	const [userMustRegisterCompany, setUserMustRegisterCompany] = useState(false);

	useEffect(() => {
		if (user.userConditions?.Html) {
			setUserMustAcceptAgreement(true);
		}
		if (user?.user?.companies?.length === 0) {
			setUserMustRegisterCompany(true);
		}
	}, [user.userConditions, user.user.companies]);

	if (!isAllowed) {
		return <Navigate to={redirectPath} state={{ from: location }} replace />;
	}

	let requiredModal = null;
	if (userMustAcceptAgreement) {
		requiredModal = <Conditions openModal={true} />;
	} else if (userMustRegisterCompany) {
		requiredModal = <CompanyForm openModal={true} />;
	}

	return children ? (
		children
	) : (
		<>
			{requiredModal}
			<Outlet />
		</>
	);
};

export default ProtectedRoute;
