import React from "react";
import { ChartData } from "../../data/types/companyData";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getChartColors, getChartOptions } from "./chartUtils";
import { Tilde } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface ChartProps {
	data: ChartData;
	title?: string;
}

function StackedBarChart(props: ChartProps) {
	let propsData = props.data;
	let colors = getChartColors();
	const { t } = useTranslation();

	let datasets = propsData.datasets.map((ds, indx) => ({
		...{ ...ds, label: t("charts.labels." + ds.label) },
		backgroundColor: [colors[indx]],
	}));
	let sumAmount = 0;
	datasets.forEach((ds) => {
		sumAmount += ds.data.reduce((a, b) => a + b, 0);
	});

	const options = {
		...getChartOptions(
			props.title || "Launakostnaður síðustu 4 mánuði",
			sumAmount.toLocaleString("da-DA")
		),
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	};

	let chartData: ChartData = {
		...propsData,
		datasets,
	};
	return <Bar height={280} options={options} data={chartData} />;
}

export default StackedBarChart;
