import React from "react";
import {
	HolidaySettingTypeEnum,
	Payslip,
} from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";

export default function PayslipHoliday(props: { payslip: Payslip }) {
	const holidayDescriptionAndAmount = () => {
		let description = "";
		let amount = 0;

		switch (props.payslip.employee.holidaySettings.holidaySettingType) {
			case HolidaySettingTypeEnum.PaidWithSalary:
			case HolidaySettingTypeEnum.DaysCollectedAndPaidOnOvertime: {
				const holidaySalaryElement = props.payslip.paySlipSalaryElements.filter(
					(el) => el.salaryElementType === 4
				)[0];
				if (holidaySalaryElement) {
					description = holidaySalaryElement.description;
					amount = holidaySalaryElement.value;
				}
				break;
			}
			case HolidaySettingTypeEnum.PaidIntoHolidayAccount:
			case HolidaySettingTypeEnum.DaysCollectedAndPaidIntoHolidayAccount: {
				const holidaySalaryElement = props.payslip.paySlipSalaryElements.filter(
					(el) => el.salaryElementType === 5
				)[0];
				if (holidaySalaryElement) {
					description =
						"Orlof lagt inn á orlofsreikning (" +
						props.payslip.employee.holidaySettings.percentageOfSalary +
						"%)";
					amount = holidaySalaryElement.value;
				}
				break;
			}
		}

		return { description, amount };
	};

	/*
	 * only show if:
	 * not "uppsafnaðir dagar (days collected)",
	 * if amount is shown, it should be > 0
	 * greitt á yfirvinnu (paid with salary)"
	 * and not a specific company (ID 137, can't remember the reason for this)
	 */
	return props.payslip.employee.holidaySettings.holidaySettingType ===
		HolidaySettingTypeEnum.DaysCollected ||
		props.payslip.employee.holidaySettings.holidaySettingType ===
			HolidaySettingTypeEnum.PaidOnOverTimeWithSalary ||
		holidayDescriptionAndAmount().amount === 0 ||
		props.payslip.employee.companyId === 137 ? null : (
		<div key="payslipHolidayWrapper">
			<div
				style={{
					padding: "3px",
					marginTop: "5px",
					backgroundColor: "#f1f1f1",
				}}>
				<div
					style={{
						display: "inline-block",
						width: "50%",
						textAlign: "left",
					}}>
					<b>Orlof</b>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "50%",
						textAlign: "right",
					}}>
					Upphæð
				</div>
			</div>
			<div
				style={{
					display: "inline-block",
					width: "50%",
					textAlign: "left",
				}}>
				{holidayDescriptionAndAmount().description}
			</div>
			<div
				style={{
					display: "inline-block",
					width: "50%",
					textAlign: "right",
				}}>
				<Amount value={holidayDescriptionAndAmount().amount} />
			</div>
		</div>
	);
}
