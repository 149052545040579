//@ts-check
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
	createStyles,
	Card,
	Text,
	SimpleGrid,
	UnstyledButton,
	Group,
	Divider,
	Grid,
	Switch,
	Space,
} from "@mantine/core";
import {
	PlaylistAdd,
	UserPlus,
	Calendar,
	List,
	Settings,
	Users,
	LayoutList,
	Link,
	Clock,
	User,
	Plus,
	FileInvoice,
	FilePlus,
	File,
} from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { getCurrentCompany, getCurrentCompanyId } from "../helpers/user";
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../data/user/userSlice";

interface actionItem {
	title: string;
	icon: any;
	route: string;
	legacy?: boolean;
}

const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[0],
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		fontWeight: 700,
	},

	item: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		borderRadius: theme.radius.md,
		height: 90,
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		transition: "box-shadow 150ms ease, transform 100ms ease",

		"&:hover": {
			boxShadow: `${theme.shadows.md} !important`,
			transform: "scale(1.05)",
			backgroundColor: "#5dbcc6",
			color: "#141517",
		},
	},
	item_legacy: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		borderRadius: theme.radius.md,
		height: 90,
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: theme.colors.dark[7],
		"&:hover": {
			boxShadow: `${theme.shadows.md} !important`,
			transform: "scale(1.05)",
			backgroundColor: theme.colors.dark[5],
		},
	},
}));

function CompanyActions() {
	const { classes, theme } = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userData = useAppSelector(selectUser);
	const [showLegacy, setShowLegacy] = useState(false);
	const [legacyBaseRoute, setLegacyBaseRoute] = useState<string | null>(null);

	useEffect(() => {
		const currentCompany = getCurrentCompany(userData.user.companies);
		if (currentCompany) {
			setLegacyBaseRoute(
				process.env.REACT_APP_LEGACY_URL +
					`/storecompany?id=${currentCompany.id}&name=${currentCompany.name}&r=`
			);
		}
	}, [userData]);

	const data: actionItem[] = [
		{
			title: "workers",
			icon: Users,
			route: "/app/employees",
			legacy: false,
		},
		{
			title: "register_employee",
			icon: UserPlus,
			route: "/app/employees/add",
			legacy: false,
		},
		{
			title: "payslips",
			icon: FileInvoice,
			route: "/app/payslips/list",
			legacy: false,
		},
		{
			title: "payslips_create",
			icon: FilePlus,
			route: "/app/payslips/create",
			legacy: false,
		},
		{
			title: "wage_processing",
			icon: List,
			route: "/app/salaryprocesses/list",
			legacy: false,
		},
		{
			title: "payroll_processing",
			icon: PlaylistAdd,
			route: "/app/salaryprocesses/create",
			legacy: false,
		},
		{
			title: "settings",
			icon: Settings,
			route: "/app/settings",
			legacy: false,
		},
		{
			title: "workers",
			icon: Users,
			route: "/employees/list",
			legacy: true,
		},
		{
			title: "payroll_processing_settings",
			icon: Calendar,
			route: "/company/main/" + getCurrentCompanyId() + "/spsettings",
			legacy: true,
		},
		{
			title: "wage_items",
			icon: LayoutList,
			route: "/company/main/" + getCurrentCompanyId() + "/salaryitems",
			legacy: true,
		},
		{
			title: "connections",
			icon: Link,
			route: "/company/main/" + getCurrentCompanyId() + "/connect",
			legacy: true,
		},
		{
			title: "employee_time_records",
			icon: Clock,
			route: "/company/main/" + getCurrentCompanyId() + "/timeregistration",
			legacy: true,
		},
		{
			title: "launamidar",
			icon: File,
			route: "/report/yearend/list",
			legacy: true,
		},
		{
			title: "settings",
			icon: Settings,
			route: "/company/main/" + getCurrentCompanyId() + "/settings",
			legacy: true,
		},
		{
			title: "business_users",
			icon: User,
			route: "/app/users",
			legacy: false,
		},
		{
			title: "register_a_company",
			icon: Plus,
			route: "/app/company/create",
		},
	];

	const registerCompanyAction = data.find(
		(item) => item.title === "register_a_company"
	);

	const items = data.map((item) =>
		!item.legacy ? (
			<UnstyledButton
				onClick={() => {
					if (item.legacy) window.location.href = item.route;
					else navigate(item.route as string);
				}}
				key={item.title}
				className={classes.item}>
				<item.icon color={theme.colors["gray"][6]} size={32} />
				<Text size="xs" mt={7}>
					{t("company_actions." + item.title)}
				</Text>
			</UnstyledButton>
		) : null
	);

	const legacyItems = data.map((item) =>
		item.legacy && legacyBaseRoute ? (
			<UnstyledButton
				onClick={() => {
					if (item.legacy && legacyBaseRoute) {
						console.log(legacyBaseRoute + item.route);
						window.location.href = legacyBaseRoute + item.route;
					} else navigate(item.route as string);
				}}
				key={item.title}
				className={classes.item_legacy}>
				<item.icon color={theme.colors["gray"][6]} size={32} />
				<Text size="xs" mt={7}>
					{t("company_actions." + item.title)}
				</Text>
			</UnstyledButton>
		) : null
	);

	return (
		<Card withBorder radius="md" className={classes.card}>
			<Group position="apart">
				<Text className={classes.title}>
					{
						// Company Name
					}
					{/* Services */}
				</Text>
				{/* <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                    + 21 other services
                </Anchor> */}
			</Group>
			{userData &&
				userData.user &&
				userData.user.companies &&
				userData.user.companies.length > 0 && (
					<>
						<SimpleGrid cols={4} mt="md">
							{items}
						</SimpleGrid>
						<Space h="md" />
						<Grid>
							<Grid.Col span={12}>
								<Switch
									style={{ float: "right" }}
									label={t("common.legacy_links_toggle")}
									checked={showLegacy}
									onChange={() => setShowLegacy(!showLegacy)}
								/>
							</Grid.Col>
						</Grid>
						{showLegacy && (
							<>
								<Divider my="sm" />
								<SimpleGrid cols={4} mt="md">
									{legacyItems}
								</SimpleGrid>
							</>
						)}
					</>
				)}
			{userData &&
				userData.user &&
				userData.user.companies &&
				userData.user.companies.length === 0 && (
					<>
						<SimpleGrid cols={4} mt="md">
							<UnstyledButton
								onClick={() => {
									navigate(registerCompanyAction?.route as string);
								}}
								key={registerCompanyAction?.title}
								className={classes.item}>
								<Plus color={theme.colors["gray"][6]} size={32} />
								<Text size="xs" mt={7}>
									{t("company_actions." + registerCompanyAction?.title)}
								</Text>
							</UnstyledButton>
						</SimpleGrid>
					</>
				)}
		</Card>
	);
}
export default CompanyActions;
