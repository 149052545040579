import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { PayslipBatchesData } from "../types/payslipBatches";
import { DataStatus } from "../types/shared";
import { stat } from "fs";
interface PayslipBatchesDataState {
	data: PayslipBatchesData[];
	companyId: number;
	isLoading: boolean;
	error: boolean;
	status: DataStatus;
}

const initialState: PayslipBatchesDataState = {
	data: [] as PayslipBatchesData[],
	companyId: 0,
	isLoading: false,
	error: false,
	status: DataStatus.NOT_FETCHED,
};

export const payslipBatchesData = createSlice({
	name: "payslipBatchesData",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
			state.status = DataStatus.ERROR;
		},
		dataLoaded: (state, action) => {
			state.data = action.payload;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
			state.status = DataStatus.FETCHED;
		},
		setInUse: (state, action: PayloadAction<number>) => {
			const index = state.data.findIndex(
				(batch) => batch.id === action.payload
			);
			state.data[index].inUse = true;
		},
		setNotInUser: (state, action: PayloadAction<number>) => {
			const index = state.data.findIndex(
				(batch) => batch.id === action.payload
			);
			state.data[index].inUse = false;
		},
		setState: (state, action: PayloadAction<DataStatus>) => {
			state.status = action.payload;
		},
	},
});

export const {
	startLoading,
	hasError,
	dataLoaded,
	setInUse,
	setNotInUser,
	setState,
} = payslipBatchesData.actions;

//thunk function for async api call
export const fetchPayslipBatchesData =
	(year?: number) => (dispatch: Dispatch) => {
		dispatch(startLoading());
		const currentCompanyId = getCurrentCompanyId();
		const apiUrl = year
			? `${process.env.REACT_APP_API_URL}/payslips/batch/${currentCompanyId}/list?year=${year}`
			: `${process.env.REACT_APP_API_URL}/payslips/batch/${currentCompanyId}/list`;
		axios
			.get(apiUrl, {
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					dispatch(dataLoaded(response.data.data));
				}
			})
			.catch((error) => {
				console.log("error fetching payslip batches data");
				dispatch(hasError(JSON.parse(error.request.responseText).Message));
			});
	};

export const setPayslipBatchInUse =
	(batchId: number) => (dispatch: Dispatch) => {
		dispatch(setInUse(batchId));
	};

export const setPayslipBatchNotInUse =
	(batchId: number) => (dispatch: Dispatch) => {
		dispatch(setNotInUser(batchId));
	};

export const setPayslipBatchesState =
	(status: DataStatus) => (dispatch: Dispatch) => {
		dispatch(setState(status));
	};

export default payslipBatchesData.reducer;
export const selectPayslipBatchesData = (state: RootState) =>
	state.payslipBatchesData;
