import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { BookedData } from "../types/payslip";
interface CreateBookedDataDataState {
	data: BookedData[];
	companyId: number;
	isLoading: boolean;
	error: boolean;
}
const initialState: CreateBookedDataDataState = {
	data: [] as BookedData[],
	companyId: 0,
	isLoading: false,
	error: false,
};
export const salaryProcessList = createSlice({
	name: "salaryProcessList",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.data = action.payload;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});
export const { startLoading, hasError, dataLoaded } = salaryProcessList.actions;
//thunk function for async api call
export const fetchBookedData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/salaryprocess/get/list/${currentCompanyId}`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error) => {
			dispatch(hasError(JSON.parse(error.request.responseText).Message));
		});
};

export default salaryProcessList.reducer;
export const selectSalaryBookedData = (state: RootState) =>
	state.salarryBookedData;
