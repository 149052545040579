import React from "react";
import { ChartData } from "../../data/types/companyData";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getChartColors, getChartOptions } from "./chartUtils";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartProps {
	data: ChartData;
	title?: string;
}

function DonutChart(props: ChartProps) {
	let propsData = props.data;
	const { t } = useTranslation();

	//set the colors and other properties on the dataset
	let dataset = {
		...propsData.datasets[0],
		backgroundColor: getChartColors(),
	};
	dataset.borderColor = ["#000000"];

	let translatedLabels = propsData.labels.map((label) =>
		t("charts.labels." + label)
	);

	let chartData: ChartData = {
		...propsData,
		datasets: [dataset],
		labels: translatedLabels,
	};
	let sumAmount = dataset.data.reduce((a, b) => a + b, 0);

	return (
		<div style={{ height: 280 }}>
			<Doughnut
				height={280}
				options={getChartOptions(
					props.title || "Launakostnaður ársins",
					sumAmount.toLocaleString("da-DA")
				)}
				data={chartData}
			/>
		</div>
	);
}

export default DonutChart;
