import { NumberInput } from "@mantine/core";
import React from "react";

export function Amount(props: { value: number | any; label?: string }) {
	return (
		<div>
			{props.label && <span>{props.label}: </span>}
			{props.value && props.value.toLocaleString("en-DE")}
		</div>
	);
}
