import React from "react";
import { Paper, Select, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconExclamationMark } from "@tabler/icons";
import axios from "axios";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectUser, userLoaded } from "../data/user/userSlice";
import { UserData } from "../data/types/userData";

function UserSettings() {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);

	const form = useForm({
		initialValues: {
			seconds: user.user.autologOutSeconds?.toString(),
		},
	});

	const save = (seconds: string) => {
		axios
			.put(`${process.env.REACT_APP_API_URL}/user/autologout/${seconds}/save`)
			.then(function (response) {
				if (response.status === 200) {
					let updatedUserData: UserData;
					updatedUserData = Object.assign({}, user.user);
					updatedUserData.autologOutSeconds = parseInt(seconds);
					dispatch(userLoaded(updatedUserData));
					showNotification({
						message: response.data.Message,
						color: "green",
						icon: <IconCheck size={16} />,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				showNotification({
					title: "Error",
					message: error.message,
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
			});
	};

	return (
		<Paper mx={29}>
			<Text size="lg">{t("settings.user.automatic_logout")}</Text>
			<div
				style={{
					display: "flex",
					gap: "7px",
					alignItems: "center",
					marginTop: "12px",
				}}
			>
				<Text>{t("settings.user.automatic_logout_label")}</Text>
				<form>
					<Select
						data={t("settings.user.logout_times", {
							returnObjects: true,
						})}
						value={form.values.seconds}
						onChange={(value) => {
							if (value) {
								form.setFieldValue("seconds", value);
								save(value);
							}
						}}
					/>
				</form>
			</div>
		</Paper>
	);
}
export default UserSettings;
