import React from "react";
import { Link } from "react-router-dom";
import { TextInput, PasswordInput, Text, Anchor } from "@mantine/core";
import { At } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useSetState } from "@mantine/hooks";
import { Alert, Space } from "@mantine/core";
import { AlertCircle } from "tabler-icons-react";
import { PrimaryButton } from "../../components/ui/buttons";
import { useTranslation } from "react-i18next";
import getErrorMessageFromResponse from "../../helpers/errorMessages";
import axios from "axios";
import Wrapper from "../../components/ui/Wrapper";

function Login() {
	const { t } = useTranslation();
	let returnTo = window.history.state.usr
		? window.history.state.usr.from.pathname
		: "/app";
	let redirectUrl = process.env.REACT_APP_URL + returnTo;

	const [state, setState] = useSetState({
		formSubmitting: false,
		loginError: "",
	});

	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},

		validate: {
			email: (value) =>
				/^\S+@\S+$/.test(value)
					? null
					: t("authentication.email_address_invalid"),
			password: (value) =>
				value.length > 3 ? null : t("authentication.password_too_short"),
		},
	});

	return (
		<Wrapper title={t("authentication.log_in")}>
			<form
				onSubmit={form.onSubmit((values) => {
					setState({ formSubmitting: true, loginError: "" });
					axios
						.post(`${process.env.REACT_APP_API_URL}/account/login`, values, {
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						})
						.then(function (response) {
							if (response.status === 200) {
								var localStorageData = {
									accessToken: response.data.accessToken,
									email: response.data.email,
									sessionId: response.data.sessionId,
								};
								localStorage.setItem(
									"paasDashboardUser",
									JSON.stringify(localStorageData)
								);
								setState({
									formSubmitting: false,
									loginError: "",
								});

								//while we are running two applications, we need to pass the auth data to the old/legacy app.
								//it will redirect back to "returnTo" after storing the data.
								let redir = `${process.env.REACT_APP_LEGACY_URL}/storeauth?e=${response.data.email}&s=${response.data.sessionId}&t=${response.data.accessToken}&r=${redirectUrl}`;
								window.location.href = redir;
							}
						})
						.catch((error) => {
							let errorMsg =
								(error.response
									? getErrorMessageFromResponse(error.response, t)
									: null) || t("errors.generic_login_error");
							setState({
								formSubmitting: false,
								loginError: errorMsg,
							});
						});
				})}>
				<TextInput
					label={t("authentication.email")}
					placeholder="hallo@gmail.com"
					size="md"
					icon={<At size={14} />}
					{...form.getInputProps("email")}
				/>
				<PasswordInput
					label={t("authentication.password")}
					placeholder={t("authentication.your_password")}
					mt="md"
					size="md"
					{...form.getInputProps("password")}
				/>
				<PrimaryButton fullWidth type="submit" loading={state.formSubmitting}>
					{t("authentication.log_in")}
				</PrimaryButton>
				{state.loginError && (
					<>
						<Space h="md" />
						<Alert
							mt="mt"
							icon={<AlertCircle size={16} />}
							title={t("errors.error_occurred")}
							color="gray">
							{state.loginError}
						</Alert>
					</>
				)}
			</form>

			<Text align="center" mt="md">
				<Anchor component={Link} to="/forgot-password">
					{t("forgot_password.forgot_password_question")}
				</Anchor>
				&nbsp;|&nbsp;
				<Anchor component={Link} to="/signup">
					{t("authentication.signup_quesiton")}
				</Anchor>
			</Text>
		</Wrapper>
	);
}

export default Login;
