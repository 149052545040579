import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { BasicEmployeeData } from "../types/employee";
import { CompanyConnection } from "../types/shared";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
interface EmployeesState {
	employees: Array<BasicEmployeeData>;
	inactiveEmployees: Array<BasicEmployeeData>;
	connections: Array<CompanyConnection>;
	companyId: number;
	isLoading: boolean;
	error: boolean;
}

const initialState: EmployeesState = {
	employees: [] as Array<BasicEmployeeData>,
	inactiveEmployees: [] as Array<BasicEmployeeData>,
	connections: [] as Array<CompanyConnection>,
	companyId: 0,
	isLoading: false,
	error: false,
};

export const employeesSlice = createSlice({
	name: "employees",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		employeesLoaded: (state, action) => {
			state.employees = action.payload.employees;
			state.inactiveEmployees = action.payload.inactiveEmployees;
			state.connections = action.payload.connections;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, employeesLoaded } =
	employeesSlice.actions;

//thunk function for async api call
export const fetchEmployeesData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const currentCompanyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/api/v2/company/${currentCompanyId}/employees`,
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			if (response.status === 200) {
				console.log(response.data);
				dispatch(employeesLoaded(response.data));
			}
		})
		.catch((error) => {
			console.log("employees fetch error");
			dispatch(hasError(JSON.parse(error.request.responseText).Message));
		});
};

export default employeesSlice.reducer;
export const selectEmployees = (state: RootState) => state.employees;
