import axios from "axios";
import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface SubscriptionDataState {
	tabledata: Array<any>; //TODO: type this
	isLoading: boolean;
	error: any; //TODO: type this
}

const initialState: SubscriptionDataState = {
	tabledata: [],
	isLoading: false,
	error: null,
};

export const subscriptionData = createSlice({
	name: "subscriptiondata",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.tabledata = action.payload;
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } = subscriptionData.actions;

export const getSubscriptionData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	return axios
		.get(`${process.env.REACT_APP_API_URL}/listdata/subscriptiontable`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
		.then(function (response) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error: any) => {
			console.log("error", error);
			dispatch(hasError(error));
		});
};

export default subscriptionData.reducer;
export const selectSubscriptionData = (state: RootState) =>
	state.subscriptionData;
