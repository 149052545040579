import React, { useState, useEffect } from "react";
import { selectUser } from "../../data/user/userSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getNotifications } from "../../data/notifications/notificationsSlice";
import {
	createStyles,
	Indicator,
	Avatar,
	UnstyledButton,
	Group,
	Text,
	Menu,
} from "@mantine/core";

import { Message2, Settings } from "tabler-icons-react";
import ToggleLanguageButton from "./ToggleLanguageButton";
import { LANGUAGE_BUTTON_TYPES } from "../../helpers/enums";
import { Notification } from "../../data/types/userData";
import { useAppSelector } from "../../app/hooks";
import UserIdleTimer from "../UserIdleTimer";

const useStyles = createStyles((theme) => ({
	user: {
		color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
		padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
		borderRadius: theme.radius.sm,
		transition: "background-color 100ms ease",

		"&:hover": {
			backgroundColor:
				theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
		},

		[theme.fn.smallerThan("xs")]: {
			display: "none",
		},
	},
	userActive: {
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
	},
}));

function UserMenu() {
	const { classes, cx } = useStyles();
	const userData = useAppSelector(selectUser);
	const [userLoaded, setUserLoaded] = useState(false);
	const notifications = useAppSelector(getNotifications);
	const [unreadCount, setUnReadCount] = useState(0);
	const [userName, setUserName] = useState("");
	const [userHasCmpanies, setUserHasCompanies] = useState(false);
	const [userMenuOpened, setUserMenuOpened] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setUserLoaded(true);
		setUserName(
			`${userData.user.firstName || ""} ${userData.user.lastName || ""}`
		);
	}, [userData]);

	useEffect(() => {
		if (userLoaded && userData.user.companies) {
			setUserHasCompanies(userData.user.companies.length > 0);
		}
	}, [userLoaded, userData.user.companies]);

	useEffect(() => {
		setUnReadCount(
			notifications.data.filter((n: Notification) => !n.isRead).length
		);
	}, [notifications]);

	return !userLoaded ? null : (
		<>
			<Menu
				withArrow={true}
				onClose={() => setUserMenuOpened(false)}
				onOpen={() => setUserMenuOpened(true)}
				control={
					<Indicator
						inline
						label={unreadCount}
						disabled={unreadCount < 1}
						size={20}
						offset={12}
						position="top-end"
						color="orange">
						<UnstyledButton
							className={cx(classes.user, {
								[classes.userActive]: userMenuOpened,
							})}>
							<Group spacing={7}>
								<Avatar />
								<Text weight={1000} size="sm" sx={{ lineHeight: 1 }} mr={3}>
									{userName}
								</Text>
							</Group>
						</UnstyledButton>
					</Indicator>
				}>
				{userHasCmpanies && (
					<>
						<Menu.Item
							key="settings"
							component={Link}
							to="/app/settings/user"
							icon={<Settings size={16} />}>
							{t("usermenu.settings")}
						</Menu.Item>
						<Menu.Item
							key="notifications"
							component={Link}
							to="/app/notifications"
							icon={<Message2 size={16} />}>
							<>
								{t("usermenu.notifications")}
								{unreadCount > 0 && <>({unreadCount})</>}
							</>
						</Menu.Item>
					</>
				)}
				<ToggleLanguageButton type={LANGUAGE_BUTTON_TYPES.MENU_ITEM} />
			</Menu>
			{userHasCmpanies && userData.user.autologOutSeconds > 0 && (
				<UserIdleTimer timeout={userData.user.autologOutSeconds * 1000} />
			)}
		</>
	);
}

export default UserMenu;
