import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
import { Payslip } from "../types/payslip";

interface AllPayslipsData {
	data: string;
	companyId: number;
	isLoading: boolean;
	error: boolean;
}

const initialState: AllPayslipsData = {
	data: "",
	companyId: 0,
	isLoading: false,
	error: false,
};

export const displayAllPayslipsData = createSlice({
	name: "displayAllPayslips",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.data = action.payload;
			state.companyId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } =
	displayAllPayslipsData.actions;

//thunk function for async api call
export const fetchAllPayslipsData =
	(paySlips: Payslip[]) => (dispatch: Dispatch) => {
		dispatch(startLoading());
		const currentCompanyId = getCurrentCompanyId();
		paySlips &&
			paySlips.map((payslipId: Payslip) => {
				axios
					.get(
						`${process.env.REACT_APP_API_URL}/payslip/get/htmlslip/${currentCompanyId}/${payslipId.id}`,
						{
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						}
					)
					.then(function (response) {
						if (response.status === 200) {
							dispatch(dataLoaded(response.data));
						}
					})
					.catch((error) => {
						dispatch(hasError(error.request.responseText.Message));
						console.log(error.request.responseText.Message);
					});
			});
	};

export default displayAllPayslipsData.reducer;
export const allPayslipsData = (state: RootState) =>
	state.displayAllPayslipsData;
