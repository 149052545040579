import React, { useState, useEffect } from "react";
import { Button, Image, Box, Menu } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import isflag from "../../assets/flags/iceland.png";
import enflag from "../../assets/flags/united-kingdom.png";
import { Language } from "tabler-icons-react";
import { setDefaultOptions } from "date-fns";
import { is, enUS } from "date-fns/locale";

interface Props {
	type: string;
}

export default function ToggleLanguageButton(props: Props) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const changeTo = i18n.language === "en" || "en-US" ? "is" : "en";
	const [languageLabel, setLanguageLabel] = useState({
		flag: isflag,
		label: "Íslenska",
	});

	const [value, toggle] = useToggle(changeTo, ["en", "is"]);
	const toggleLanguage = (lang: string) => {
		i18n.changeLanguage(lang);
		toggle();
	};

	const switcherType = props.type;

	//Update the button flag and label whenever language is changed
	useEffect(() => {
		setLanguageLabel(
			i18n.language === "is"
				? { flag: enflag, label: "English" }
				: { flag: isflag, label: "Íslenska" }
		);
		setDefaultOptions({ locale: i18n.language === "is" ? is : enUS });
	}, [i18n.language]);

	switch (switcherType) {
		case "button":
			return (
				<Button
					radius="md"
					style={{ marginTop: "8px" }}
					size="sm"
					onClick={() => toggleLanguage(value)}>
					<Image src={languageLabel.flag} width={24} />
					<Box sx={{ marginLeft: "8px" }}>{languageLabel.label}</Box>
				</Button>
			);
		case "menu-item":
			return (
				<>
					<Menu.Item
						key="language"
						onClick={() => toggleLanguage(i18n.language === "en" ? "is" : "en")}
						icon={<Language size={16} />}>
						{t("usermenu.switch_language")}: {languageLabel.label}
					</Menu.Item>
				</>
			);
		default:
			return <div>{languageLabel.label}</div>;
	}
}
