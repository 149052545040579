import axios from "axios";
import React, { useEffect, useState } from "react";
import { Paper, Title } from "@mantine/core";
import { LaunaLoadingOverlay } from "../components/ui/common/loadingOverlay";

interface Note {
	title: string;
	content: string;
}
function Releasenotes() {
	const [releaseNotes, setReleaseNotes] = useState([]);
	const [visible, setVisible] = useState(false);
	const fetchReleaseNotes = () => {
		if (releaseNotes.length === 0) {
			setVisible(true);
			axios
				.get(`${process.env.REACT_APP_API_URL}/web/releasenotes`)
				.then(function (response) {
					if (response.status === 200) {
						console.log(response.data);
						setReleaseNotes(response.data);
						setVisible(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setVisible(false);
				});
		}
	};

	useEffect(() => {
		fetchReleaseNotes();
	});
	return (
		<Paper p="md">
			<LaunaLoadingOverlay visible={visible} />
			{releaseNotes.map((note: Note, index) => {
				return (
					<div key={index}>
						<Title order={3}>{note.title}</Title>
						<div dangerouslySetInnerHTML={{ __html: note.content }}></div>
					</div>
				);
			})}
		</Paper>
	);
}

export default Releasenotes;
