import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../app/store";

const initialState = {
  isat: [],
  isLoading: false,
  error: "",
};

export const isatSlice = createSlice({
  name: "isat",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    dataLoaded: (state, action) => {
      state.isat = action.payload;
      state.isLoading = false;
    },
  },
});

export const { startLoading, hasError, dataLoaded } = isatSlice.actions;

export const fetchIsat = () => (dispatch: Dispatch) => {
  dispatch(startLoading());
  axios
    .get(`${process.env.REACT_APP_API_URL}/listdata/isat/get/list`)
    .then(function (response: AxiosResponse) {
      if (response.status === 200) {
        dispatch(dataLoaded(response.data.results));
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export default isatSlice.reducer;
export const selectIsat = (state: RootState) => state.isat;
