import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getCurrentCompanyId } from "../../helpers/user";
import initializeSignalr from "../../connections/signalr";
import { initialNotifications } from "../notifications/notificationsSlice";
import { RootState } from "../../app/store";
import { UserData, UserConditions } from "../types/userData";
interface UserState {
	user: UserData;
	userConditions: UserConditions;
	isLoading: boolean;
	error: boolean;
}
const initialState: UserState = {
	user: {} as UserData,
	userConditions: {} as UserConditions,
	isLoading: false,
	error: false,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		userLoaded: (state, action) => {
			state.user = action.payload;
			state.isLoading = false;
		},
		userFirstLogin: (state, action: PayloadAction<UserConditions>) => {
			state.userConditions = action.payload;
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, userLoaded, userFirstLogin } =
	userSlice.actions;
//thunk function for async api call
export const fetchUserData =
	(appendNotifications?: boolean) => (dispatch: Dispatch) => {
		dispatch(startLoading());
		const currentCompanyId = getCurrentCompanyId();
		axios
			.get(`${process.env.REACT_APP_API_URL}/user/get/me/${currentCompanyId}`, {
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
			.then(function (response: AxiosResponse<UserData, any>) {
				if (response.status === 200) {
					dispatch(userLoaded(response.data));
					if (
						appendNotifications &&
						response.data.notifications &&
						response.data.notifications.length > 0
					) {
						dispatch(initialNotifications(response.data.notifications));
					}
					initializeSignalr(dispatch);
				}
			})
			.catch((error: any) => {
				console.log(error);

				if (error.response && error.response.status === 426) {
					const parsedData = JSON.parse(
						error.response.data.Message
					) as UserConditions;
					dispatch(userFirstLogin(parsedData));
				} else {
					dispatch(hasError(error));
				}
			});
	};

export default userSlice.reducer;
export const selectUser = (state: RootState) => state.user;
