import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SimpleGrid, Title } from "@mantine/core";
import Booked from "./booked";
import InBooking from "./inBooking";
import InProgress from "./inProgress";
import Summary from "./summary";
import { TabView } from "../../ui/common/tabView";
import { CardView } from "../../ui/common/sharedComponents";
import { getCurrentCompanyId } from "../../../helpers/user";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../data/user/userSlice";
import {
	fetchBookedData,
	selectSalaryBookedData,
} from "../../../data/salaryIncrease/bookedData";

export default function SalaryProcessessesLegacy() {
	const { t } = useTranslation();
	const userData = useAppSelector(selectUser);
	const salaryBookedData = useAppSelector(selectSalaryBookedData);
	const dispatch = useAppDispatch();
	useEffect(() => {
		function shouldFetchData(companyId: number, isLoading: boolean): boolean {
			var currentCompanyId = getCurrentCompanyId();
			var userHasAccess = userData?.user?.companies
				?.flatMap((company) => company.id)
				.includes(currentCompanyId);
			if (userHasAccess && currentCompanyId !== companyId && !isLoading)
				return true;
			return false;
		}
		if (
			shouldFetchData(salaryBookedData.companyId, salaryBookedData.isLoading)
		) {
			dispatch(fetchBookedData());
		}
	}, [
		dispatch,
		userData.user.companies,
		salaryBookedData.companyId,
		salaryBookedData.isLoading,
	]);
	const tabValue = [
		<InProgress
			salaryBookedData={salaryBookedData.data.filter(
				(element) => element.isProcessed == null && element.saved != null
			)}
		/>,
		<InBooking
			salaryBookedData={salaryBookedData.data.filter(
				(element) => element.isProcessed == null && element.saved == null
			)}
		/>,
		<Booked
			salaryBookedData={salaryBookedData.data.filter(
				(element) => element.isProcessed != null
			)}
		/>,
		<Summary />,
	];
	return (
		<CardView
			value={
				<>
					<TabView
						tabs={t("salary_processe_list.tabs", {
							returnObjects: true,
						})}
						tabsValue={tabValue}
					/>
				</>
			}
		/>
	);
}
