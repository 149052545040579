import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./lang/en.json";
import is from "./lang/is.json";
import { isProd } from "./helpers/user";

const resources = {
	en: {
		translation: en,
	},
	is: {
		translation: is,
	},
};

const languages = ["en", "is"];

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: localStorage.getItem("i18nextLng") || "is",
		resources,
		fallbackLng: "en",
		debug: !isProd(),
		whitelist: languages,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
