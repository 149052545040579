import React, { useEffect, useState } from "react";
import {
	NumberInput,
	Select,
	Space,
	Tabs,
	Text,
	TextInput,
	SimpleGrid,
	Paper,
	LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchBankData, selectBankData } from "../data/bank/bankDataSlice";
import {
	fetchSettingsData,
	selectSettingsData,
} from "../data/companySettings/companySettingsSlice";
import { Bank, CompanyConnection, ListItem } from "../data/types/shared";
import { fetchIsat, selectIsat } from "../data/isat/isatSlice";
import { PrimaryButton } from "../components/ui/buttons";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconExclamationMark } from "@tabler/icons";
import UserSettings from "./UserSettings";
import CompanyLogo from "../components/CompanyLogo";
import { useNavigate, useParams } from "react-router-dom";

export default function Settings(props: any) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const params = useParams<{ tab: string }>();

	const getTabFromUrl = () => {
		if (params.tab) {
			switch (params.tab) {
				case "user":
					return 1;
				case "company":
				default:
					return 0;
			}
		}
	};

	const setTab = (indx: number) => {
		setActiveTab(indx);
		switch (indx) {
			case 1:
				navigate("/app/settings/user");
				return;
			case 0:
			default:
				navigate("/app/settings/company");
				return;
		}
	};

	const [activeTab, setActiveTab] = useState(getTabFromUrl);

	useEffect(() => {
		setActiveTab(getTabFromUrl);
	}, [params.tab]);

	const form = useForm({
		initialValues: {
			companyid: 0,
			email: "",
			phone: "",
			address: "",
			postalCode: "",
			city: "",
			salaryPeriod: "",
			isPayrollperiodMonth: true,
			payrollDayFrom: 0,
			payrollDayTo: 0,
			bankHb: 0,
			bankNumber: "",
			accountNumber: "",
			Isat: "",
		},
	});

	const dispatch = useAppDispatch();
	const bankData = useAppSelector(selectBankData);
	useEffect(() => {
		if (bankData.banks.length < 1) {
			dispatch(fetchBankData());
		}
	}, [bankData, dispatch]);

	const [branches, setBranches] = useState<ListItem[]>([]);
	useEffect(() => {
		if (bankData.banks.length > 0) {
			let adjusted_list_data: ListItem[] = [];
			bankData.banks.forEach((bank: Bank) => {
				bank.branches.forEach((Branch) => {
					const bankNumber = Branch.bankNumber.toString();
					let branchDescription = `${bankNumber} - ${bank.name}, ${Branch.branch}`;
					var index = adjusted_list_data.findIndex(
						(item) => item.value === bankNumber
					);
					if (index === -1) {
						adjusted_list_data.push({
							value: bankNumber,
							label: branchDescription,
						});
					}
				});
			});
			setBranches(adjusted_list_data);
		}
	}, [bankData]);

	const settings = useAppSelector(selectSettingsData);
	useEffect(() => {
		if (Object.keys(settings.company).length === 0) {
			dispatch(fetchSettingsData());
		}
	}, [settings, dispatch]);

	useEffect(() => {
		if (Object.keys(settings.company).length > 0) {
			const {
				email,
				phone,
				postalCode,
				city,
				address,
				id,
				companySettings: {
					isPayrollperiodMonth,
					payrollDayFrom,
					payrollDayTo,
					isat: { code },
				},
			} = settings.company;
			const { hofudbok, accountNumber, bankNUmber } = settings.company
				.companySettings.objBankAccount || {
				hofudbok: "",
				accountNumber: "",
				bankNUmber: "",
			};
			form.setValues({
				...form.values,
				companyid: id,
				email,
				phone,
				postalCode,
				city,
				address,
				isPayrollperiodMonth,
				salaryPeriod: isPayrollperiodMonth ? "month" : "period",
				payrollDayFrom,
				payrollDayTo,
				bankHb: hofudbok,
				accountNumber,
				bankNumber: bankNUmber.toString(),
				Isat: code,
			});
		}
	}, [settings.company]);

	const isat = useAppSelector(selectIsat);
	useEffect(() => {
		if (Object.keys(isat.isat).length === 0) {
			dispatch(fetchIsat());
		}
	}, [isat, dispatch]);

	const [isatList, setIsatList] = useState<ListItem[]>([]);
	useEffect(() => {
		if (isat.isat.length > 0) {
			let adjusted_list_data: ListItem[] = [];
			isat.isat.forEach((element: { id: string; text: string }) => {
				adjusted_list_data.push({ value: element.id, label: element.text });
			});
			setIsatList(adjusted_list_data);
		}
	}, [isat.isat]);

	const companyHasBankConnection =
		settings.company.connections?.findIndex(
			(connection: CompanyConnection) =>
				connection.name.toLowerCase() === "landsbanki"
		) > -1;

	return (
		<>
			<LoadingOverlay visible={settings.isLoading} />
			<Tabs
				active={activeTab}
				onTabChange={(indx) => {
					setTab(indx);
				}}>
				<Tabs.Tab label={t("settings.company.title")}>
					{settings.company && (
						<form
							onSubmit={form.onSubmit((values) => {
								setLoading(true);
								values.isPayrollperiodMonth =
									values.salaryPeriod === "month" ? true : false;
								axios
									.post(
										`${process.env.REACT_APP_API_URL}/company/settings/save`,
										values
									)
									.then(function (response) {
										if (response.status === 200) {
											showNotification({
												message: response.data.Message,
												color: "green",
												icon: <IconCheck size={16} />,
											});
											dispatch(fetchSettingsData());
											setLoading(false);
										}
									})
									.catch((error) => {
										console.log(error);
										showNotification({
											title: "Error",
											message: error.message,
											color: "red",
											icon: <IconExclamationMark size={16} />,
										});
										setLoading(false);
									});
							})}>
							<Paper px={29}>
								<SimpleGrid cols={2} breakpoints={[{ maxWidth: 900, cols: 1 }]}>
									<SimpleGrid
										cols={2}
										breakpoints={[{ maxWidth: 900, cols: 1 }]}>
										<div>
											<Text>{t("settings.company.ssn")}</Text>
											<Text>{settings.company.registrationId}</Text>
										</div>
										<Space h="md" />
										<div>
											<Text>{t("settings.company.name")}</Text>
											<Text>{settings.company.name}</Text>
										</div>
									</SimpleGrid>
									<CompanyLogo logoUrl={settings.company.companyLogoUrl} />
									<TextInput
										label={t("settings.company.email")}
										{...form.getInputProps("email")}
									/>
									<TextInput
										label={t("settings.company.phone")}
										{...form.getInputProps("phone")}
									/>
									<Select
										label={t("settings.company.isat")}
										searchable
										dropdownPosition="bottom"
										spellCheck={false}
										data={isatList}
										{...form.getInputProps("Isat")}
									/>
									<TextInput
										label={t("settings.company.address")}
										{...form.getInputProps("address")}
									/>
									<TextInput
										label={t("settings.company.postalCode")}
										{...form.getInputProps("postalCode")}
									/>
									<TextInput
										label={t("settings.company.city")}
										{...form.getInputProps("city")}
									/>
									<div>
										<Select
											label={t("settings.company.salaryPeriodLabel")}
											data={t("settings.company.salary_period", {
												returnObjects: true,
											})}
											{...form.getInputProps("salaryPeriod")}
										/>

										{form.values.salaryPeriod === "period" && (
											<SimpleGrid ml="40%" cols={2}>
												<NumberInput
													label={t("settings.company.from")}
													{...form.getInputProps("payrollDayFrom")}
												/>
												<NumberInput
													label={t("settings.company.to")}
													{...form.getInputProps("payrollDayTo")}
												/>
											</SimpleGrid>
										)}
									</div>

									{companyHasBankConnection && (
										<div>
											<Text>{t("settings.company.bank_account")}</Text>
											<SimpleGrid
												cols={3}
												breakpoints={[{ maxWidth: 900, cols: 1 }]}>
												<Select
													label={t("add_employees.salary_account_number")}
													searchable
													dropdownPosition="bottom"
													spellCheck={false}
													data={branches}
													{...form.getInputProps("bankNumber")}
												/>
												<TextInput
													label=" "
													{...form.getInputProps("bankHb")}
												/>
												<TextInput
													label=" "
													{...form.getInputProps("accountNumber")}
												/>
											</SimpleGrid>
											<Text size="xs">
												{t("settings.company.account_description")}
											</Text>
										</div>
									)}
									<div style={{ textAlign: "right" }}>
										<PrimaryButton type="submit" loading={loading}>
											{t("settings.company.save")}
										</PrimaryButton>
									</div>
								</SimpleGrid>
							</Paper>
						</form>
					)}
				</Tabs.Tab>
				<Tabs.Tab label={t("settings.user.title")}>
					<UserSettings />
				</Tabs.Tab>
			</Tabs>
		</>
	);
}
