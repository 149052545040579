import React from "react";
import { PensionFund } from "../../../../data/types/payslip";
import PayslipHeader from "./payslipHeader";
import PayslipDeduction from "./payslipDeduction";
import PayslipYtd from "./payslipYtd";
import PayslipSalaryelements from "./payslipSalaryElements";
import { useAppSelector } from "../../../../app/hooks";
import { getPayslipEditorState } from "../../../../data/payslips/payslipViewSlice";
import { BankAccountInfo } from "../../common/bankAccount";
import { Amount } from "../../common/currency";
import { getEmployeeUnionFee } from "../../../../helpers/functions";
import { LaunaLoadingOverlay } from "../../common/loadingOverlay";
import ErrorBoundary from "../errors/ErrorBoundary";
import PayslipViewEditBar from "./editor/payslipViewEditBar";
import PayslipHoliday from "./payslipHoliday";

function PayslipView({
	payDay,
	logo,
	editMode = false,
	yearMonth,
}: {
	payDay: string;
	logo: string | null;
	editMode: boolean;
	yearMonth: string;
}) {
	const payslipSlice = useAppSelector(getPayslipEditorState);

	/* Employees are legally bound to pay into a pension fund so this should never be null
	 But we will check it just to be safe. */
	var pensionFund = payslipSlice.currentPayslip?.pensionFund
		? [payslipSlice.currentPayslip.pensionFund]
		: [];

	//Special pension funds are optional for employees
	var specialPensionFunds: PensionFund[] = payslipSlice.currentPayslip
		?.specialPensionFund
		? [payslipSlice.currentPayslip?.specialPensionFund]
		: [];

	/* An employee can have multiple special pension funds.
	Because the option of having multiple was added later in the backend
	any special pension funds are stored as array in specialPensionFundsExtra.
	Here we will join them all using the ... spread syntax. */
	if (
		payslipSlice.currentPayslip?.specialPensionFundsExtra &&
		payslipSlice.currentPayslip?.specialPensionFundsExtra.length > 0
	) {
		specialPensionFunds = [
			...specialPensionFunds,
			...payslipSlice.currentPayslip.specialPensionFundsExtra,
		];
	}

	return payslipSlice.currentPayslip === null ? null : (
		<>
			{payslipSlice.recalculating && (
				<LaunaLoadingOverlay
					visible={true}
					label="Endurreikna launaseðil ..."
				/>
			)}
			{editMode && <PayslipViewEditBar />}
			<div
				style={{
					maxWidth: "1100px",
					fontSize: "14px",
					padding: "7px 24px 19px",
					margin: "0 auto",
					border: "2px solid #ccc",
					borderRadius: "5px",
					backgroundColor: "#fff",
					color: "#000",
					WebkitPrintColorAdjust: "exact",
				}}>
				<PayslipHeader
					data={{
						logo,
						employee: payslipSlice.currentPayslip?.employee,
						periodStart: payslipSlice.currentPayslip?.periodStart,
						periodEnd: payslipSlice.currentPayslip?.periodEnd,
						payDay,
						salaryTotal: payslipSlice.currentPayslip?.salaryTotal,
						salaryToPay: payslipSlice.currentPayslip?.salaryToPay,
						deductableTotal: payslipSlice.currentPayslip?.deductableTotal,
					}}
				/>
				<ErrorBoundary>
					{payslipSlice.currentPayslip &&
						payslipSlice.currentPayslip.paySlipSalaryElements && (
							<PayslipSalaryelements
								employeeId={payslipSlice.currentPayslip.employee.id}
								editMode={editMode}
							/>
						)}
				</ErrorBoundary>
				{payslipSlice.currentPayslip && (
					<PayslipHoliday payslip={payslipSlice.currentPayslip} />
				)}
				<PayslipDeduction
					data={{
						taxDeduction: payslipSlice.currentPayslip?.taxDeduction,
						deductableElements:
							payslipSlice.currentPayslip?.paySlipSalaryElements?.filter(
								(el) => el.salaryElementType === 1 //filter out salary elements which are of type: Deductable(enum value 1)
							),
						pensionFund: pensionFund,
						specialPensionFunds: specialPensionFunds,
						union:
							payslipSlice.currentPayslip?.union &&
							payslipSlice.currentPayslip.unionFees &&
							payslipSlice.currentPayslip.unionFees.length > 0
								? {
										name: payslipSlice.currentPayslip.union.name,
										fee: getEmployeeUnionFee(
											payslipSlice.currentPayslip.unionFees
										),
								  }
								: null,
					}}
				/>
				<div
					style={{
						display: "inline-block",
						width: "85%",
						textAlign: "right",
						marginBottom: "24px",
					}}>
					<strong>Samtals frádráttur:</strong>
				</div>
				<div
					style={{ display: "inline-block", width: "15%", textAlign: "right" }}>
					<strong>
						<Amount value={payslipSlice.currentPayslip?.deductableTotal || 0} />
					</strong>
				</div>
				{payslipSlice.currentPayslip?.bankAccount && (
					<div style={{ textAlign: "right" }}>
						<strong>
							Laun lögð inn á banka:{" "}
							<BankAccountInfo data={payslipSlice.currentPayslip.bankAccount} />
						</strong>
					</div>
				)}
				<PayslipYtd
					data={{
						ytdElements: payslipSlice.currentPayslip?.ytdElements || [],
					}}
				/>
			</div>
		</>
	);
}

export default PayslipView;
