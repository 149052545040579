import React, { useEffect } from "react";
import { SalaryProcessSimpleModel } from "../../data/salaryProcess/models";
import { Box, Divider, Menu } from "@mantine/core";
import { FileInvoice, Trash } from "tabler-icons-react";
import { t } from "react-i18nify";
import { useNavigate } from "react-router";

interface Props {
	salaryProcess: SalaryProcessSimpleModel;
	onDelete?: (id: number) => void;
	// deleteEmployee?: (id: number) => void;
	// deactivateEmployee?: (id: number) => void;
	// activateEmployee?: (id: number) => void;
	// showPayslips?: (id: number) => void;
}

export default function SalaryProcessMenu(props: Props) {
	// const { t } = useTranslation();
	const navigate = useNavigate();
	const [navigateBatchId, setNavigateBatchId] = React.useState<number>(0);

	useEffect(() => {
		if (navigateBatchId > 0) {
			navigate(`/app/payslips/list/${navigateBatchId}`);
		}
	}, [navigateBatchId]);

	return (
		<>
			<Box sx={{ textAlign: "right" }}>
				<Menu>
					{props.salaryProcess.canEdit && (
						<React.Fragment key={props.salaryProcess.guid + "_menu"}>
							<Menu.Item
								onClick={() => {
									if (props.salaryProcess.batchIds.length > 0) {
										setNavigateBatchId(props.salaryProcess.batchIds[0]);
									}
								}}
								disabled={props.salaryProcess.batchIds.length === 0}
								icon={<FileInvoice />}>
								{t("payslip_batch.generic_title")}
							</Menu.Item>
							<Divider />
							<Menu.Item
								onClick={() => {
									if (props.onDelete) props.onDelete(props.salaryProcess.id);
								}}
								disabled={!props.salaryProcess.canEdit}
								icon={<Trash color="red" />}>
								{t("salary_process.delete_salary_process")}
							</Menu.Item>
						</React.Fragment>
					)}
				</Menu>
			</Box>
		</>
	);
}
