import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../app/store";
import { getCurrentCompanyId } from "../../helpers/user";
import { CompanyData } from "../types/companyData";

const initialState = {
	users: {} as CompanyData,
	isLoading: false,
	error: "",
};

export const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.users = action.payload;
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, dataLoaded } = usersSlice.actions;

export const fetchUsers = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	const companyId = getCurrentCompanyId();
	axios
		.get(
			`${process.env.REACT_APP_API_URL}/company/get/settings/${companyId}/users`
		)
		.then(function (response: AxiosResponse<[], any>) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export default usersSlice.reducer;
export const selectUsers = (state: RootState) => state.users;
