import React, { useEffect } from "react";
import { Pencil, Trash } from "tabler-icons-react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
	getPayslipEditorState,
	payslipsEditedSet,
	salaryElementRemove,
	salaryElementUpdate,
} from "../../../../data/payslips/payslipViewSlice";
import { Amount } from "../../common/currency";
import { Tooltip } from "@mantine/core";
import {
	SalaryElementEditState,
	SimpleSalaryElementProps,
	SimpleSalaryElementPropsExt,
} from "../../../../data/types/payslip";
import { useTranslation } from "react-i18next";
import PayslipSalaryElementEditor from "./editor/payslipSalaryElementEditor";

function PayslipSalaryElement(props: {
	data: SimpleSalaryElementPropsExt;
	editMode?: boolean;
	someEvent: () => void;
}) {
	const { t } = useTranslation();
	const [showUpdateElement, setShowUpdateElement] = React.useState(false);
	const dispatch = useAppDispatch();

	const payslipViewState = useAppSelector(getPayslipEditorState);

	// salary elements cannot be deleted if there is only one left
	const canDeleteElement = (employeeId: number) => {
		const unchangedElements = payslipViewState.salaryElements.filter(
			(el) =>
				el.editState === SalaryElementEditState.UNCHANGED &&
				el.employeeId === employeeId &&
				el.canEdit
		);
		const deletedElements = payslipViewState.salaryElements.filter(
			(el) =>
				el.editState === SalaryElementEditState.DELETED &&
				el.employeeId === employeeId
		);

		return unchangedElements.length > deletedElements.length + 1;
	};

	let firstColWidth = props.editMode ? "50%" : "55%";
	return (
		<>
			<div key={props.data.id} style={{ marginTop: "3px" }}>
				{props.editMode && (
					<div style={{ display: "inline-block", width: "5%" }}>
						<span
							style={{ position: "absolute", marginTop: -17, marginLeft: 4 }}>
							<Tooltip
								position="right"
								label={
									t("add_employees.salary.edit_salary_item") +
									props.data.originalDescription
								}>
								{props.data.canEdit && (
									<Pencil
										size={24}
										style={{ cursor: "pointer" }}
										onClick={() => {
											setShowUpdateElement(true);
										}}
									/>
								)}
							</Tooltip>
						</span>
					</div>
				)}
				<div
					style={{
						display: "inline-block",
						width: firstColWidth,
					}}>
					{props.data.originalDescription}
					<span style={{ position: "absolute", marginTop: -3, marginLeft: 4 }}>
						<Tooltip
							position="right"
							label={
								t("add_employees.salary.remove_salary_item") +
								props.data.originalDescription
							}>
							{canDeleteElement(props.data.employeeId) && props.data.canEdit && (
								<Trash
									size={24}
									style={{ cursor: "pointer" }}
									onClick={() => {
										dispatch(payslipsEditedSet(true));
										dispatch(
											salaryElementRemove({
												id: props.data.id,
												employeeId: props.data.employeeId,
											})
										);
									}}
								/>
							)}
						</Tooltip>
					</span>
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					<Amount value={props.data.originalRate} />
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					{props.data.originalUnits}
				</div>
				<div
					style={{
						display: "inline-block",
						width: "15%",
						textAlign: "right",
					}}>
					<Amount value={props.data.originalValue} />
				</div>
			</div>
			{showUpdateElement && (
				<PayslipSalaryElementEditor
					editState={SalaryElementEditState.EDITED}
					editElement={{
						id: props.data.id,
						description: props.data.originalDescription,
						units: props.data.originalUnits,
						value: props.data.originalValue,
						rate: props.data.originalRate,
						canEdit: true,
					}}
					title={t("payslips.payslip_editor.edit_element_line")}
					// Salary elements are unique in the payslip so skip the ids of the salary elements already in the payslip
					skipElementIds={[]}
					onClosed={() => {
						setShowUpdateElement(false);
					}}
					onElementUpdated={(el: SimpleSalaryElementProps) => {
						setShowUpdateElement(false);
						const elWithRate: SimpleSalaryElementPropsExt = {
							id: el.id,
							description: el.description,
							originalDescription: props.data.originalDescription,
							rate: el.value || 0,
							originalRate: props.data.originalRate || 0,
							units: el.units,
							originalUnits: props.data.originalUnits || 0,
							value: (el.value || 0) * el.units,
							originalValue:
								(props.data.originalValue || 0) * props.data.originalUnits,
							editState: SalaryElementEditState.EDITED,
							canEdit: true,
							employeeId: props.data.employeeId,
						};
						// update the salary element in the redux store
						dispatch(payslipsEditedSet(true));
						dispatch(salaryElementUpdate(elWithRate));
					}}
				/>
			)}
		</>
	);
}

export default PayslipSalaryElement;
