import React from "react";
import { DeliveryData } from "../../data/salaryProcess/models";
import { Card, SimpleGrid, Text } from "@mantine/core";
import { useStyles } from "./salaryProcessList";
import CountdownTimer from "../ui/common/dateandtime/countdownTimer";
import { DateFull } from "../ui/common/dateAndTime";

export interface SalaryProcessDeliveryTimesProps {
	deliveryData: DeliveryData;
}

interface DeliveryTimeView {
	title: string;
	isSent: boolean;
	scheduleDate?: Date;
	actualSendDate?: Date;
}

export default function SalaryProcessDeliveryTimes(
	props: SalaryProcessDeliveryTimesProps
) {
	const { classes } = useStyles();
	const deliveryTimes: DeliveryTimeView[] = [];

	if (props.deliveryData) {
		if (props.deliveryData.payslipEmail.scheduleSendTime) {
			deliveryTimes.push({
				title: "Launaseðlar í tölvupósti",
				isSent: props.deliveryData.payslipEmail.isSent,
				scheduleDate: new Date(
					props.deliveryData.payslipEmail.scheduleSendTime
				),
				actualSendDate: props.deliveryData.payslipEmail.actualSendTime
					? new Date(props.deliveryData.payslipEmail.actualSendTime)
					: undefined,
			});
		}
		if (props.deliveryData.payslipBank.scheduleSendTime) {
			deliveryTimes.push({
				title: "Launaseðlar í banka",
				isSent: props.deliveryData.payslipBank.isSent,
				scheduleDate: new Date(props.deliveryData.payslipBank.scheduleSendTime),
				actualSendDate: props.deliveryData.payslipBank.actualSendTime
					? new Date(props.deliveryData.payslipBank.actualSendTime)
					: undefined,
			});
		}
		if (props.deliveryData.rsk.scheduleSendTime) {
			deliveryTimes.push({
				title: "RSK skilagreinar",
				isSent: props.deliveryData.rsk.isSent,
				scheduleDate: new Date(props.deliveryData.rsk.scheduleSendTime),
				actualSendDate: props.deliveryData.rsk.actualSendTime
					? new Date(props.deliveryData.rsk.actualSendTime)
					: undefined,
			});
			if (props.deliveryData.lifStett.scheduleSendTime) {
				deliveryTimes.push({
					title: "Líf/Stétt skilagreinar",
					isSent: props.deliveryData.lifStett.isSent,
					scheduleDate: new Date(props.deliveryData.lifStett.scheduleSendTime),
					actualSendDate: props.deliveryData.lifStett.actualSendTime
						? new Date(props.deliveryData.lifStett.actualSendTime)
						: undefined,
				});
				if (props.deliveryData.paymentRequest.scheduleSendTime) {
					deliveryTimes.push({
						title: "Greiðslubeiðni í banka",
						isSent: props.deliveryData.paymentRequest.isSent,
						scheduleDate: new Date(
							props.deliveryData.paymentRequest.scheduleSendTime
						),
						actualSendDate: props.deliveryData.paymentRequest.actualSendTime
							? new Date(props.deliveryData.paymentRequest.actualSendTime)
							: undefined,
					});
				}
				if (props.deliveryData.csupport.scheduleSendTime) {
					deliveryTimes.push({
						title: "Meðlag",
						isSent: props.deliveryData.csupport.isSent,
						scheduleDate: new Date(
							props.deliveryData.csupport.scheduleSendTime
						),
						actualSendDate: props.deliveryData.csupport.actualSendTime
							? new Date(props.deliveryData.csupport.actualSendTime)
							: undefined,
					});
				}
			}
		}
	}

	// order deliveryTimes by scheduleDate
	deliveryTimes.sort((a, b) => {
		if (a.scheduleDate && b.scheduleDate) {
			return a.scheduleDate.getTime() - b.scheduleDate.getTime();
		}
		return 0;
	});

	return (
		<Card withBorder p="xl" radius="md" className={classes.lightcard}>
			<SimpleGrid cols={3} spacing="sm">
				{deliveryTimes.map((deliveryTime, index) => (
					<div key={index}>
						<Text size="sm" weight={900} style={{ textAlign: "center" }}>
							<span
								style={{
									borderBottom: "1px solid",
								}}>
								{deliveryTime.title}
							</span>
						</Text>
						<Text size="sm" weight={700} style={{ textAlign: "center" }}>
							{!deliveryTime.isSent && deliveryTime.scheduleDate && (
								<>
									<DateFull date={deliveryTime.scheduleDate} />
									<CountdownTimer targetDate={deliveryTime.scheduleDate} />
								</>
							)}
						</Text>
						<Text size="sm">
							{deliveryTime.actualSendDate?.toLocaleDateString("is-IS")}
						</Text>
					</div>
				))}
			</SimpleGrid>
		</Card>
	);
}
