import React, { Suspense } from "react";
import { MantineProvider } from "@mantine/core";
import launaTheme from "./components/ui/themes/launaTheme";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import axios from "axios";
import store from "./app/store";
import { Provider } from "react-redux";
import "./i18n";
// import registerServiceWorker from "./registerServiceWorker";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

// pass api key with every request to API
axios.interceptors.request.use((request) => {
	request.headers.apikey = `${process.env.REACT_APP_API_KEY}`;
	if (localStorage.getItem("paasDashboardUser")) {
		const accessToken = JSON.parse(
			localStorage.getItem("paasDashboardUser")
		).accessToken;
		request.headers.Authorization = `Bearer ${accessToken}`;
	}
	return request;
});

axios.interceptors.response.use((response) => {
	return response;
});

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<MantineProvider theme={launaTheme} withGlobalStyles withNormalizeCSS>
			<Provider store={store}>
				<Suspense fallback="Loading...">
					<App />
				</Suspense>
			</Provider>
		</MantineProvider>
	</BrowserRouter>,
	rootElement
);

//registerServiceWorker();
