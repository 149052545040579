import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "@mantine/form";
import {
  PasswordInput,
  TextInput,
  Center,
  Anchor,
  Space,
  Alert,
} from "@mantine/core";
import { AlertCircle, At } from "tabler-icons-react";
import { useSetState } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import getErrorMessageFromResponse from "../../helpers/errorMessages";
import axios from "axios";
import { PrimaryButton } from "../../components/ui/buttons";
import Wrapper from "../../components/ui/Wrapper";

export default function Signup() {
  const { t } = useTranslation();

  let returnTo = window.history.state.usr
    ? window.history.state.usr.from.pathname
    : "/app";
  let redirectUrl = process.env.REACT_APP_URL + returnTo;

  const [state, setState] = useSetState({
    formSubmitting: false,
    loginError: "",
  });
  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      password: "",
    },

    validate: (values) => ({
      name: values.name.length > 0 ? null : t("errors.required_field"),
      email: /^\S+@\S+$/.test(values.email)
        ? null
        : t("authentication.email_address_invalid"),
      password:
        values.password.length > 3
          ? null
          : t("authentication.password_too_short"),
    }),
  });

  return (
    <Wrapper title={t("signup.user_sign_up")}>
      <form
        onSubmit={form.onSubmit((values) => {
          [values.firstName, values.lastName] = values.name.split(" ");
          //values.firstName = firstName
          setState({ formSubmitting: true, loginError: "" });
          axios
            .post(`${process.env.REACT_APP_API_URL}/account/register`, values, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.Status === "ERROR") {
                  setState({
                    formSubmitting: false,
                    loginError: response.data.Message,
                  });
                } else {
                  var localStorageData = {
                    accessToken: response.data.accessToken,
                    email: response.data.email,
                    sessionId: response.data.sessionId,
                  };
                  localStorage.setItem(
                    "paasDashboardUser",
                    JSON.stringify(localStorageData)
                  );
                  setState({
                    formSubmitting: false,
                    loginError: "",
                  });
                  //while we are running two applications, we need to pass the auth data to the old/legacy app.
                  //it will redirect back to this app root after storing the data.
                  let redir = `${process.env.REACT_APP_LEGACY_URL}/storeauth?e=${response.data.email}&s=${response.data.sessionId}&t=${response.data.accessToken}&r=${redirectUrl}`;
                  window.location.href = redir;
                }
              }
            })
            .catch((error) => {
              let errorMsg =
                (error.response
                  ? getErrorMessageFromResponse(error.response, t)
                  : null) || t("errors.generic_login_error");
              setState({
                formSubmitting: false,
                loginError: errorMsg,
              });
            });
        })}
      >
        <TextInput
          label={t("signup.name")}
          placeholder={t("signup.your_name")}
          {...form.getInputProps("name")}
        />
        <TextInput
          label={t("signup.email")}
          placeholder={t("signup.your_email")}
          icon={<At size={14} />}
          {...form.getInputProps("email")}
        />
        <PasswordInput
          mt="sm"
          label={t("signup.password")}
          placeholder={t("signup.choose_password")}
          {...form.getInputProps("password")}
        />

        <PrimaryButton fullWidth type="submit" loading={state.formSubmitting}>
          {t("signup.continue")}
        </PrimaryButton>

        <Center mt={21}>
          <Anchor component={Link} to="/login">
            {t("signup.already_have_account")}
          </Anchor>
        </Center>
        {state.loginError && (
          <>
            <Space h="md" />
            <Alert
              mt="mt"
              icon={<AlertCircle size={16} />}
              title={t("errors.error_occurred")}
              color="gray"
            >
              {state.loginError}
            </Alert>
          </>
        )}
      </form>
    </Wrapper>
  );
}
