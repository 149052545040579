import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../data/user/userSlice";
import usersReducer from "../data/users/usersSlice";
import employeesReducer from "../data/employees/employeeSlice";
import payslipsCreateDataReducer from "../data/payslips/payslipsCreateData";
import companySalaryElementDataReducer from "../data/payslips/companySalaryElementsSlice";
import payslipBatchesDataReducer from "../data/payslips/payslipBatchesData";
import payslipEditorSliceReducer from "../data/payslips/payslipViewSlice";
import salaryIncreaseDataReducer from "../data/salaryIncrease/salaryIncreaseCreateData";
import notificationsReducer from "../data/notifications/notificationsSlice";
import companydataReducer from "../data/company/companyDataSlice";
import blogentriesReducer from "../data/blog/blogSlice";
import bookedDataReducer from "../data/salaryIncrease/bookedData";
import salaryProcessSummaryReducer from "../data/salaryIncrease/salaryProcessSummary";
import salaryProcessSingleEmpReducer from "../data/salaryIncrease/salaryprocessSingleEmpData";
import displayAllPayslipsReducer from "../data/payslips/displayAllPayslips";
import contententriesReducer from "../data/content/pageContent";
import calculatordataReducer from "../data/public/launa.service.calculatordata";
import subscriptionDataReducer from "../data/public/launa.service.subscriptiondata";
import bankDataReducer from "../data/bank/bankDataSlice";
import settingsDataReducer from "../data/companySettings/companySettingsSlice";
import isatReducer from "../data/isat/isatSlice";
import summaryDatareducer from "../data/salaryIncrease/summaryData";
import employeeCreateDataReducer from "../data/employees/employeeCreateDataSlice";
import salaryProcessDataReducer from "../data/salaryProcess/salaryProcessSlice";

const store = configureStore({
	reducer: {
		user: userReducer,
		users: usersReducer,
		employees: employeesReducer,
		employeeCreate: employeeCreateDataReducer,
		payslipsCreateData: payslipsCreateDataReducer,
		companySalaryElementData: companySalaryElementDataReducer,
		payslipBatchesData: payslipBatchesDataReducer,
		salaryHistoryModel: salaryIncreaseDataReducer,
		salarryBookedData: bookedDataReducer,
		payslipEditor: payslipEditorSliceReducer,
		notifications: notificationsReducer,
		blogEntries: blogentriesReducer,
		contentEntries: contententriesReducer,
		calculatorData: calculatordataReducer,
		subscriptionData: subscriptionDataReducer,
		companydata: companydataReducer,
		salaryprocessSummaryData: salaryProcessSummaryReducer,
		salaryProcessSingleEmpData: salaryProcessSingleEmpReducer,
		displayAllPayslipsData: displayAllPayslipsReducer,
		bankData: bankDataReducer,
		settingsData: settingsDataReducer,
		isat: isatReducer,
		summarypayslipsData: summaryDatareducer,
		salaryProcessData: salaryProcessDataReducer,
	},
	devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
