import React from "react";
import {
	TaxDeduction,
	TaxDeductionStepElement,
} from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";
import PayslipSimpleRow from "./payslipSimpleRow";

function PayslipDeductionTax(props: { data: TaxDeduction | undefined }) {
	return (
		<>
			<div style={{ borderBottom: "1px solid #f1f1f1", padding: "8px 0" }}>
				<PayslipSimpleRow
					id={9999}
					description="Reiknuð staðgreiðsla"
					value={props.data?.taxTotal || 0}
				/>
			</div>
			<div key="taxDeductionWrapper" style={{ width: "50%" }}>
				{props.data?.taxDeductionSteps.map(
					(deduction: TaxDeductionStepElement) => (
						<div key={deduction.title}>
							<div style={{ width: "40%", display: "inline-block" }}>
								{deduction.title}
							</div>
							<div
								style={{
									textAlign: "right",
									width: "30%",
									display: "inline-block",
								}}>
								{deduction.percentage.toLocaleString("is", {
									style: "percent",
									minimumFractionDigits: 2,
								})}
							</div>
							<div
								style={{
									textAlign: "right",
									width: "30%",
									display: "inline-block",
								}}>
								<Amount value={deduction.amount} />
							</div>
						</div>
					)
				)}
			</div>
			<PayslipSimpleRow
				id={9998}
				description="Nýttur persónuafsláttur"
				value={props.data?.taxAllowanceTotal || 0}
			/>
			{props.data?.taxAllowanceMoveNextPeriod &&
			props.data.taxAllowanceMoveNextPeriod > 0 ? (
				<PayslipSimpleRow
					id={9997}
					description="Ónýttur persónuafsláttur eftir launavinnslu"
					value={props.data.taxAllowanceMoveNextPeriod}
				/>
			) : null}
			{props.data?.taxAllowanceTotalSpouse &&
			props.data.taxAllowanceTotalSpouse > 0 ? (
				<PayslipSimpleRow
					id={9996}
					description="Nýttur persónuafsláttur maka"
					value={props.data.taxAllowanceTotalSpouse}
				/>
			) : null}
			{props.data?.taxAllowanceMoveNextPeriodSpouse &&
			props.data.taxAllowanceMoveNextPeriodSpouse > 0 ? (
				<PayslipSimpleRow
					id={9995}
					description="Ónýttur persónuafsláttur maka eftir launavinnslu"
					value={props.data.taxAllowanceMoveNextPeriodSpouse}
				/>
			) : null}
			<PayslipSimpleRow
				id={9994}
				description="Frádregin staðgreiðsla"
				value={props.data?.taxTotalToDeduct || 0}
				boldDescription={true}
			/>
			<div
				style={{ borderBottom: "1px solid #f1f1f1", padding: "8px 0" }}></div>
		</>
	);
}

export default PayslipDeductionTax;
