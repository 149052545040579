import React from "react";
import {
	createStyles,
	Text,
	Container,
	ActionIcon,
	Group,
} from "@mantine/core";
import { IconBrandTwitter, IconBrandFacebook, IconSend } from "@tabler/icons";
import { useNavigate } from "react-router";
import { useWindowScroll } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
	footer: {
		marginTop: 120,
		paddingTop: theme.spacing.xl * 2,
		paddingBottom: theme.spacing.xl * 2,
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[0],
		borderTop: `1px solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
		}`,
	},

	logo: {
		maxWidth: 200,

		[theme.fn.smallerThan("sm")]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	},

	description: {
		marginTop: 5,

		[theme.fn.smallerThan("sm")]: {
			marginTop: theme.spacing.xs,
			textAlign: "center",
		},
	},

	inner: {
		display: "flex",
		justifyContent: "space-between",

		[theme.fn.smallerThan("sm")]: {
			flexDirection: "column",
			alignItems: "center",
		},
	},

	groups: {
		display: "flex",
		flexWrap: "wrap",

		[theme.fn.smallerThan("sm")]: {
			display: "block",
		},
	},

	wrapper: {
		width: 160,
	},

	link: {
		display: "block",
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[1]
				: theme.colors.gray[6],
		fontSize: theme.fontSizes.sm,
		paddingTop: 3,
		paddingBottom: 3,

		"&:hover": {
			textDecoration: "underline",
		},
	},

	footerTextBlock: {
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[1]
				: theme.colors.gray[6],
		fontSize: theme.fontSizes.md,
		textAlign: "right",
	},

	title: {
		fontSize: theme.fontSizes.lg,
		fontWeight: 700,
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		marginBottom: theme.spacing.xs / 2,
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
	},

	afterFooter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: theme.spacing.xl,
		paddingTop: theme.spacing.xl,
		paddingBottom: theme.spacing.xl,
		borderTop: `1px solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
		}`,

		[theme.fn.smallerThan("sm")]: {
			flexDirection: "column",
		},
	},

	social: {
		[theme.fn.smallerThan("sm")]: {
			marginTop: theme.spacing.xs,
		},
	},
}));

interface FooterLinksProps {
	data: {
		title: string;
		links: { label: string; link: string }[];
	}[];
}

export function LaunaFooter() {
	const navigate = useNavigate();
	// ignore scroll, it's not used but needs to be declared
	// eslint-disable-next-line
	const [scroll, scrollTo] = useWindowScroll();
	const { classes } = useStyles();
	const date = new Date();
	const footerData: FooterLinksProps = {
		data: [
			{
				title: "footernav",
				links: [
					{ label: "Um launa", link: "/efni/um-launa" },
					{ label: "Notkunarskilmálar", link: "/efni/notkunarskilmalar" },
					{ label: "Persónuverndarstefna", link: "/efni/personuverndarstefna" },
					{ label: "Vafrakökur", link: "/efni/vafrakokur" },
				],
			},
			{
				title: "footerinfo1",
				links: [
					{ label: "Teymi ehf.", link: "" },
					{ label: "Jónsgeisla 35", link: "" },
					{ label: "113 Reykjavík", link: "" },
				],
			},
			{
				title: "footerinfo2",
				links: [
					{ label: "Kt. 561215-1890", link: "" },
					{ label: "VSK-nr. 122468", link: "" },
					{ label: "Sími: 893-6931", link: "" },
				],
			},
		],
	};

	const groupsLinks = footerData.data.map((group, index) => {
		const links = group.links
			.filter((l) => l.link.length > 0)
			.map((link, index) => (
				<Text<"a">
					key={index}
					className={classes.link}
					component="a"
					href={link.link}
					onClick={(event) => {
						event.preventDefault();
						scrollTo({ x: 0, y: 0 });
						navigate(link.link);
					}}>
					{link.label}
				</Text>
			));

		return (
			<div className={classes.wrapper} key={group.title}>
				{links}
			</div>
		);
	});

	const groupsInfo = footerData.data.map((group, index) => {
		const infos = group.links
			.filter((l) => l.link.length === 0)
			.map((link, index) => (
				<Text<"div"> key={index} className={classes.footerTextBlock}>
					{link.label}
				</Text>
			));

		return (
			<div className={classes.wrapper} key={group.title}>
				{infos}
			</div>
		);
	});

	return (
		<footer className={classes.footer}>
			<Container className={classes.inner}>
				<div className={classes.logo}>
					<div className={classes.groups}>{groupsLinks}</div>
				</div>
				<div className={classes.groups}>{groupsInfo}</div>
			</Container>
			<Container className={classes.afterFooter}>
				<Text color="dimmed" size="sm">
					© 2017-{date.getFullYear()} launa | Teymi ehf
				</Text>

				<Group spacing={0} className={classes.social} position="right" noWrap>
					<ActionIcon size="lg">
						<IconBrandTwitter
							size={18}
							stroke={1.5}
							onClick={() => {
								window.location.href = "https://twitter.com/launa35573517";
							}}
						/>
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandFacebook
							size={18}
							stroke={1.5}
							onClick={() => {
								window.location.href = "https://www.facebook.com/launakerfi";
							}}
						/>
					</ActionIcon>
					<ActionIcon size="lg">
						<IconSend
							size={18}
							stroke={1.5}
							onClick={() => {
								window.location.href = "mailto:launa@launa.is";
							}}
						/>
					</ActionIcon>
				</Group>
			</Container>
		</footer>
	);
}
