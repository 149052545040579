import React, { useEffect, useState } from "react";
import {
	TextInput,
	Select,
	Text,
	SimpleGrid,
	NumberInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SelectListItem } from "../../data/types/addEmployees";
import { UnionFee } from "../../data/types/payslip";
import { Percentage } from "tabler-icons-react";

function UnionComponent({
	unions,
	formik,
}: {
	unions: SelectListItem[];
	formik: any;
}) {
	const { t } = useTranslation();

	const unionId = formik.values.settings.unionId;
	const selectedUnion = unions.find(
		(union) => JSON.parse(union.value).id === unionId
	);
	const [selected, setSelected] = useState<string | undefined>(
		selectedUnion?.value
	);

	const { setFieldValue, values } = formik;

	const setSelectedUnion = (value: string) => {
		setSelected(value);
		const union = JSON.parse(value);
		setFieldValue("settings.unionId", union.id);
		setFieldValue(
			"settings.unionFees",
			union.globalUnionFees.map((union: UnionFee) => {
				union.percentage = union.percentage * 100;
				return union;
			})
		);
	};

	const [valuesFixed, setValuesFixed] = useState(false);

	useEffect(() => {
		if (!valuesFixed) {
			values.settings.unionFees = values.settings.unionFees.map(
				(union: UnionFee) => {
					union.percentage = union.percentage * 100;
					return union;
				}
			);

			setValuesFixed(true);
		}
	}, [valuesFixed]);

	return (
		<>
			<Text size="lg">{t("add_employees.unions.title")}</Text>
			<Select
				searchable
				spellCheck={false}
				value={selected}
				onChange={setSelectedUnion}
				data={unions}
			/>
			<SimpleGrid
				cols={4}
				mb={12}
				breakpoints={[{ maxWidth: 755, cols: 1, spacing: "sm" }]}>
				{values.settings.unionFees.map(
					(globalUnionFee: UnionFee, index: number) => (
						<div key={globalUnionFee.title}>
							<NumberInput
								label={globalUnionFee.title}
								name={`settings.unionFees[${index}].percentage`}
								onChange={(val) => {
									setFieldValue(`settings.unionFees[${index}].percentage`, val);
								}}
								precision={globalUnionFee.isFixedAmount ? 0 : 2}
								value={
									globalUnionFee.isFixedAmount
										? values.settings.unionFees[index].fixedMonthlyFee
										: values.settings.unionFees[index].percentage
								}
								sx={() => ({
									input: {
										textAlign: "right",
									},
								})}
								rightSection={
									globalUnionFee.isFixedAmount ? null : <Percentage />
								}
							/>
						</div>
					)
				)}
			</SimpleGrid>
		</>
	);
}
export default UnionComponent;
