import React, { MouseEventHandler } from "react";
import {
	createStyles,
	Card,
	Avatar,
	Text,
	Group,
	Grid,
	Center,
	Space,
	UnstyledButton,
} from "@mantine/core";
import { BasicEmployeeData } from "../../../data/types/employee";
import EmployeeMenu from "./employeeMenu";
import { formatRegistrationId } from "../../../helpers/functions";

export const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		minHeight: "200px",
	},

	avatar: {
		border: `2px solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
		}`,
	},
}));

interface Props {
	employee: BasicEmployeeData;
	onEmployeeSelect?: MouseEventHandler;
	deleteEmployee?: (id: number) => void;
	deactivateEmployee?: (id: number) => void;
	activateEmployee?: (id: number) => void;
	showPayslips?: (id: number) => void;
	fetchPayslip?: (id: number) => void;
}

function EmployeeCard(props: Props) {
	const { classes } = useStyles();
	const employee = props.employee;

	return (
		<Grid.Col md={6} lg={3}>
			<Card withBorder p="xl" radius="md" className={classes.card}>
				<EmployeeMenu
					onEmployeeSelect={props.onEmployeeSelect}
					employee={employee}
					deleteEmployee={props.deleteEmployee}
					deactivateEmployee={props.deactivateEmployee}
					activateEmployee={props.activateEmployee}
					showPayslips={props.showPayslips}
				/>
				<Card.Section sx={{ height: 60 }} />
				<Avatar
					src={employee.photo}
					size={80}
					radius={80}
					mx="auto"
					mt={-30}
					className={classes.avatar}
				/>
				<Center>
					<Space h="md" />
					<UnstyledButton
						sx={{ "&:hover": { textDecoration: "underline" } }}
						onClick={props.onEmployeeSelect}>
						<Text
							align="center"
							size="lg"
							weight={500}
							mt="sm"
							style={{ minHeight: "56px" }}>
							{employee.fullName}
						</Text>
					</UnstyledButton>
				</Center>
				<Text align="center" size="sm" color="dimmed">
					{formatRegistrationId(employee.registrationId)}
				</Text>
				<Group
					mt="md"
					position="center"
					spacing={30}
					style={{ minHeight: "25px" }}>
					{employee.companyRole}
				</Group>
			</Card>
		</Grid.Col>
	);
}

export default EmployeeCard;
