import React from "react";
import { PensionFund } from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";

type PensionData = {
	key: string;
	title: string;
	pensionFunds: PensionFund[];
};

function PayslipDeductionPension(props: { data: PensionData }) {
	return (
		<div key={props.data.key}>
			{props.data.pensionFunds
				.filter((pf) => pf.items != null && pf.items.length > 0)
				.map((pf: PensionFund) => (
					<div key={pf.entityNo}>
						<div style={{ marginTop: "3px" }}>
							<div
								style={{
									display: "inline-block",
									width: "50%",
								}}>
								<strong>{props.data.title}:</strong> {pf.name},{" "}
								{pf.items && pf.items[0].description}
							</div>
							<div
								style={{
									display: "inline-block",
									width: "50%",
									textAlign: "right",
								}}>
								<Amount value={pf.items && pf.items[0].amount} />
							</div>
						</div>
						<div style={{ marginTop: "3px" }}>
							{pf.items && pf.items.length > 1 && (
								<i>
									<Amount
										value={pf.items && pf.items[1].amount}
										label={pf.items && pf.items[1].description}
									/>
								</i>
							)}
						</div>
						{pf.items && pf.items.length > 2 && (
							<i>
								<Amount
									value={pf.items[2].amount}
									label="Þar af framlag launagreiðanda í endurhæfingarsjóð"
								/>
							</i>
						)}
					</div>
				))}
		</div>
	);
}

export default PayslipDeductionPension;
