import React from "react";
import CompanyActions from "../components/companyActions";
import CompanyCharts from "../components/charts/companyCharts";

export default function Dashboard() {
	return (
		<>
			<CompanyCharts />
			<CompanyActions />
		</>
	);
}
