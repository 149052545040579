import React from "react";
import { Text } from "@mantine/core";
import { Employee } from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";
import { Date, DateRange } from "../../common/dateAndTime";
import { RegistrationId } from "../../common/registrationId";

type HeaderData = {
	logo: string | null;
	employee: Employee | undefined;
	periodStart: string | undefined;
	periodEnd: string | undefined;
	payDay: string | undefined;
	salaryTotal: number | undefined;
	deductableTotal: number | undefined;
	salaryToPay: number | undefined;
};

function PayslipHeader(props: { data: HeaderData }) {
	return (
		<div>
			<div
				key="headerCompanyInfo"
				style={{ display: "grid", gridTemplateColumns: "80% 20%" }}>
				<div>
					<h5>{props.data.employee?.company.name}</h5>
					<RegistrationId value={props.data.employee?.company.registrationId} />
				</div>
				{props.data.logo !== null && props.data.logo.length > 0 && (
					<div
						style={{
							textAlign: "right",
						}}>
						<img
							alt=""
							src={props.data.logo}
							style={{ maxWidth: "200px", maxHeight: "50px" }}
						/>
					</div>
				)}
			</div>
			<div
				key="headerEmployeeInfo"
				style={{ display: "grid", gridTemplateColumns: "60% 40%" }}>
				<div>
					<Text>{props.data.employee?.fullName}</Text>
					<RegistrationId value={props.data.employee?.registrationId} />
					<Text>{props.data.employee?.address}</Text>
					<Text>
						{props.data.employee?.postalCode} {props.data.employee?.city}
					</Text>
				</div>

				<div
					style={{
						display: "inline-grid",
						gridTemplateColumns: "40% 60%",
					}}>
					<div>
						<div>Dagsetning:</div>
						<div>Tímabil:</div>
						<div>
							<b>Laun:</b>
						</div>
						<div>
							<b>Frádráttur:</b>
						</div>
						<div>
							<b>Útborgað:</b>
						</div>
					</div>
					<div style={{ textAlign: "right" }}>
						<Date value={props.data.payDay || ""} />
						<DateRange
							from={props.data.periodStart || ""}
							to={props.data.periodEnd || ""}
						/>
						<Amount value={props.data.salaryTotal || 0} />
						<Amount value={props.data.deductableTotal || 0} />
						<Amount value={props.data.salaryToPay || 0} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default PayslipHeader;
