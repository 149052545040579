import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleGrid, Title, Text, Box, Group } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { PrimaryButton } from "../../ui/buttons";
import { useForm } from "@mantine/form";
import axios from "axios";
import { IconCheck } from "@tabler/icons";
import { CardView, PaperView } from "../../ui/common/sharedComponents";
export default function ReverseGear({ reverseId }: { reverseId: number }) {
	const { t } = useTranslation();
	const [butoon_loading_state, setButtonLoadingState] = useState(false);
	var detail = t("salary_processe_list.reverse_gear_detail", {
		returnObjects: true,
	});

	const form_data = useForm({
		initialValues: {
			ID: reverseId && Number(reverseId.toString().split(":")[0]),
		},
	});
	return (
		<CardView
			value={
				<>
					<SimpleGrid>
						<Title order={4} color="dimmed">
							{t("salary_processe_list.reverse_gear_header") +
								"\t #" +
								reverseId.toString().split(":")[0] +
								"\t" +
								t("salary_processe_list.reverse_period") +
								"\t" +
								reverseId.toString().split(":")[1]}
						</Title>
					</SimpleGrid>
					<form
						onSubmit={form_data.onSubmit((values) => {
							//api-dev.launa.is/api/v2/salaryprocess/reverse
							setButtonLoadingState(true);
							axios
								.post(
									`${process.env.REACT_APP_API_URL}/api/v2/salaryprocess/reverse`,
									values,
									{
										headers: {
											Accept: "application/json",
											"Content-Type": "application/json",
										},
									}
								)
								.then(function (response) {
									console.log(response.data);
									setButtonLoadingState(false);
									showNotification({
										title: "",
										message: t("salary_processe_list.reverse_grear_message"),
										autoClose: true,
										disallowClose: false,
										icon: <IconCheck size={16} />,
									});
								})
								.catch((error) => {
									setButtonLoadingState(false);
									showNotification({
										title: "",
										message: error,
										autoClose: true,
										disallowClose: false,
										icon: <IconCheck size={16} />,
									});
								});
						})}>
						<Box>{t("salary_processe_list.reverse_gear_title")}</Box>

						<SimpleGrid spacing={"xs"} cols={1} p={"md"}>
							{detail &&
								detail
									.toString()
									.split(",")
									.map((element, index) => (
										<Text size="sm" color="dimmed" key={index}>
											{element}
										</Text>
									))}
						</SimpleGrid>
						<Text size="sm" color="red">
							{t("salary_processe_list.reverse_gear_detail_note")}
						</Text>
						<Group position="center">
							<PrimaryButton type="submit" loading={butoon_loading_state}>
								{t("salary_processe_list.reverse_gear_button")}
							</PrimaryButton>
						</Group>
					</form>
				</>
			}
		/>
	);
}
