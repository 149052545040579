import React from "react";
import { useParams } from "react-router-dom";
import PublicPagesWrapper from "../components/ui/publicpages/PublicPagesWrapper";


export interface IArticle {
    article: {
        fields: {
            title: string;
            description: string;
            publishDate: Date;
            content: string
        }
    }
};

export default function BlogEntry() {
    let { slug } = useParams();
    return (
        <>
            <PublicPagesWrapper title="Ástæðulaust að flækja hlutina | launa - Blogg">
                <div>Blog entry page: {slug}</div>
            </PublicPagesWrapper>
        </>
    );
}