import { LoadingOverlay } from "@mantine/core";
import React, { useEffect, useState } from "react";
import PublicPagesWrapper from "../components/ui/publicpages/PublicPagesWrapper";
import { blogClient } from "./contentfulClient";

export interface IArticle {
	fields: {
		title: string;
		slug: string;
		description: string;
		publishDate: Date;
		content: string;
	};
}

export default function Blog() {
	const [articles, setArticles] = useState<IArticle[]>([]);
	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			const resp = await blogClient.getEntries({
				content_type: "blogPost",
				order: "-fields.publishDate",
				"fields.active": true,
			});
			setArticles(resp.items as IArticle[]);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (loading) fetchData();
		// console.log(articles);
	}, [loading, articles]);

	if (!loading && articles.length > 0) {
		articles.map((entry) => console.log(entry.fields.title));
		// console.log("articles", articles);
	}

	return (
		<PublicPagesWrapper title="Ástæðulaust að flækja hlutina | launa - Blogg">
			<LoadingOverlay visible={loading} />
			{!loading && articles.length === 0 ? (
				<div>Engar færslur fundust</div>
			) : (
				articles.map((entry) => (
					<div key={entry.fields.slug}>{entry.fields.title}</div>
				))
			)}
		</PublicPagesWrapper>
	);
}
