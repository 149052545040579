import React, { Component, ErrorInfo, ReactNode } from "react";
import Rollbar from "rollbar";
import { Context, getRollbarFromContext } from "@rollbar/react";
import GlobalError from "./globalError";

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	static contextType = Context;
	rollbar: Rollbar | undefined;

	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidMount(): void {
		this.rollbar = getRollbarFromContext(this.context as any);
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.rollbar?.error(error);
	}

	public render() {
		if (this.state.hasError) {
			return <GlobalError />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
