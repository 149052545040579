import React from "react";

export default function Company() {
	return (
		<div>
			This is the main Company page.
			<br /> It should only be shown if user is authenticated
		</div>
	);
}
