import React, { useEffect, useState } from "react";
import SalaryProcessessesLegacy from "../../components/legacy/salaryProcesses/salaryProcesses";
import { Grid, Switch, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import CreateSalaryProcess from "../../components/salaryProcesses/CreateSalaryProcess";
import { List, Pencil } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router";
import SalaryProcessList from "../../components/salaryProcesses/salaryProcessList";
export default function SalaryProcesses() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams<{ tab: string }>();

	const [showLegacy, setShowLegacy] = React.useState(false);

	const getTabFromUrl = () => {
		if (params.tab) {
			switch (params.tab) {
				case "list":
					return 1;
				case "create":
				default:
					return 0;
			}
		}
	};

	const setTab = (indx: number) => {
		setActiveTab(indx);
		switch (indx) {
			case 1:
				navigate("/app/salaryprocesses/list");
				return;
			case 0:
			default:
				navigate("/app/salaryprocesses/create");
				return;
		}
	};

	const [activeTab, setActiveTab] = useState(getTabFromUrl);

	useEffect(() => {
		setActiveTab(getTabFromUrl);
	}, [params.tab]);

	return (
		<>
			<Title order={1}>{t("salary_processe_list.header")}</Title>
			<Tabs
				active={activeTab}
				onTabChange={(indx) => {
					setTab(indx);
				}}>
				<Tabs.Tab
					key="tab-create"
					icon={<Pencil size={14} />}
					label="Stofna launavinnslu">
					<CreateSalaryProcess />
				</Tabs.Tab>
				<Tabs.Tab
					icon={<List size={14} />}
					key="tab-list"
					label="Launavinnslur">
					<SalaryProcessList />
					<Grid>
						<Grid.Col span={6}></Grid.Col>
						<Grid.Col span={6}>
							<Switch
								style={{ float: "right" }}
								label="Sýna eldir launavinnslur"
								checked={showLegacy}
								onChange={() => setShowLegacy(!showLegacy)}
							/>
						</Grid.Col>
						<Grid.Col span={12}>
							{showLegacy && <SalaryProcessessesLegacy />}
						</Grid.Col>
					</Grid>
				</Tabs.Tab>
			</Tabs>
		</>
	);
}
