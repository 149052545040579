import { Center, Modal, Space } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { ActionButton } from "./ui/buttons";
import { logOut } from "../helpers/user";
import { t } from "react-i18nify";
export default function UserIdleTimer({ timeout }: { timeout: number }) {
	const [opened, setOpened] = useState(false);
	const [remaining, setRemaining] = useState<number>();

	const promptBeforeIdle = timeout < 60000 ? timeout : 60000;

	const onIdle = () => {
		setOpened(false);
		logOut();
	};
	const onPrompt = () => {
		setOpened(true);
	};

	const { activate, getRemainingTime } = useIdleTimer({
		onIdle,
		onPrompt,
		timeout,
		promptBeforeIdle,
		stopOnIdle: true,
		throttle: 1000,
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	});

	return (
		<>
			<Modal opened={opened} onClose={() => {}} withCloseButton={false}>
				<Center>
					{t("settings.user.logging_out_after")} {remaining}{" "}
					{t("settings.user.seconds")}
				</Center>
				<Space h="md" />
				<Center>
					<ActionButton
						onClick={() => {
							activate();
							setOpened(false);
						}}
					>
						{t("settings.user.keep_me_logged_in")}
					</ActionButton>
				</Center>
			</Modal>
		</>
	);
}
