import React, { useState, useEffect, useRef } from "react";
import { SimpleGrid, Divider, Modal, Group, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CardView } from "../../components/ui/common/sharedComponents";
import {
	TableData,
	tableHeaderColumn,
} from "../../components/ui/common/tableData";
import { BookedData, Payslip } from "../../data/types/payslip";
import { Amount } from "../../components/ui/common/currency";
import { IconSearch, IconSend } from "@tabler/icons";
import { At } from "tabler-icons-react";
import { ActionButton } from "../../components/ui/buttons";
import axios from "axios";
import { useForm } from "@mantine/form";
export default function Payslips({
	payslipData,
	isLoading,
}: {
	payslipData: BookedData;
	isLoading: boolean;
}) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);
	const [loading_state, setLoadingState] = useState(isLoading);
	const [sendEmailModal, setSendEmailModal] = useState(false);
	const [emailAddress, setEmailAddress] = useState<any>(null);
	const [guid, setGuid] = useState<any>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	var summaryTotalsalary = 0;
	var summarySalarypaid = 0;
	var summaryInsuranceFee = 0;
	const form = useForm({
		initialValues: {
			email: "",
			guid: "",
		},
		validate: {
			email: (value) =>
				/^\S+@\S+$/.test(value)
					? null
					: t("salary_process_view.email_address_invalid"),
		},
	});
	const tableHeaderTranslations: string[] = t(
		"salary_process_view.payslipTable",
		{
			returnObjects: true,
		}
	);
	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[3] },
		{ text: tableHeaderTranslations[4] },
		{ text: tableHeaderTranslations[5] },
		{ text: tableHeaderTranslations[6] },
	];
	useEffect(() => {
		var bookedRow: any[] =
			payslipData.paySlips &&
			payslipData.paySlips.map((fetch_data: Payslip, index: number) => (
				<tr key={index}>
					<td>{fetch_data.id}</td>
					<td>{fetch_data.employee.fullName}</td>
					<td>{fetch_data.employee.registrationId}</td>
					<td>
						{fetch_data.bankAccount?.bankNUmber +
							"-" +
							fetch_data.bankAccount?.hofudbok +
							"-" +
							fetch_data.bankAccount?.accountNumber}
					</td>
					<td>{<Amount value={fetch_data.salaryTotal} />}</td>
					<td>{<Amount value={fetch_data.salaryToPay} />}</td>
					<td>
						{
							<SimpleGrid
								cols={3}
								spacing="lg"
								breakpoints={[
									{ maxWidth: "lg", cols: 3, spacing: "md" },
									{ maxWidth: "xl", cols: 3, spacing: "md" },
									{ maxWidth: "md", cols: 1, spacing: "md" },
									{ maxWidth: "sm", cols: 1, spacing: "sm" },
									{ maxWidth: "xs", cols: 1, spacing: "xs" },
								]}
							>
								<Amount value={fetch_data.insuranceFees.total} />
								<IconSearch
									size={25}
									onClick={(e) => {}}
									style={{
										cursor: "pointer",
									}}
								/>
								<IconSend
									onClick={() => {
										setEmailAddress({
											guid: fetch_data.guid,
											email: fetch_data.employee.email,
											empid: fetch_data.employee.id,
											id: fetch_data.id,
											fullName: fetch_data.employee.fullName,
										});
										setSendEmailModal(true);
									}}
									style={{
										cursor: "pointer",
									}}
								/>
							</SimpleGrid>
						}
					</td>
				</tr>
			));
		payslipData.paySlips &&
			payslipData.paySlips.map((fetch_data: Payslip, index: number) => {
				summaryTotalsalary = summaryTotalsalary + fetch_data.salaryTotal;
				summarySalarypaid = summarySalarypaid + fetch_data.salaryToPay;
				summaryInsuranceFee =
					summaryInsuranceFee + fetch_data.insuranceFees.total;
			});
		bookedRow &&
			bookedRow.push(
				<tr key={-1}>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td>{<Amount value={summaryTotalsalary} />}</td>
					<td>{<Amount value={summarySalarypaid} />}</td>
					<td>{<Amount value={summaryInsuranceFee} />}</td>
				</tr>
			);
		setRows(bookedRow);
		setLoadingState(loading_state);
	}, [t, payslipData]);

	return (
		<CardView
			value={
				<>
					{rows && <TableData tableHeader={tableHeaders} tableBody={rows} />}
					{emailAddress && (
						<Modal
							opened={sendEmailModal}
							onClose={() => {
								setSendEmailModal(false);
								setEmailAddress(null);
							}}
							size="lg"
							title={`${t(
								"salary_processe_list.inbooking_payslip_send_email_header"
							)} #${emailAddress.id}	   									
												${emailAddress.fullName}`}
						>
							<Divider />
							<SimpleGrid>
								<form
									onSubmit={form.onSubmit((values) => {
										values.guid = guid;
										setButtonLoading(true);
										axios
											.post(
												`${process.env.REACT_APP_API_URL}/payslip/send/email`,
												values,
												{
													headers: {
														Accept: "application/json",
														"Content-Type": "application/json",
													},
												}
											)
											.then(function (response) {
												setButtonLoading(false);
											})
											.catch(function (error) {
												setButtonLoading(false);
											});
									})}
								>
									<TextInput
										label={t(
											"salary_processe_list.inbooking_payslip_send_email_input_label"
										)}
										placeholder={t("company.email")}
										{...form.getInputProps("email")}
										icon={<At size={14} />}
									/>
									<Group position="center" spacing={"lg"} mt={"lg"}>
										<ActionButton
											type="submit"
											onClick={() => {
												setGuid(emailAddress.guid);
											}}
											loading={buttonLoading}
										>
											{
												t(
													"salary_processe_list.inbooking_payslip_send_email_button_label",
													{ returnObjects: true }
												)[0]
											}
										</ActionButton>
										<ActionButton
											onClick={() => {
												setSendEmailModal(false);
												setEmailAddress(null);
											}}
										>
											{
												t(
													"salary_processe_list.inbooking_payslip_send_email_button_label",
													{ returnObjects: true }
												)[1]
											}
										</ActionButton>
									</Group>
								</form>
							</SimpleGrid>
						</Modal>
					)}
				</>
			}
		/>
	);
}
