import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmployeeFormComponent from "../components/employees/employeeForm";

function EmployeeFormPage() {
	const { t } = useTranslation();
	return (
		<EmployeeFormComponent
			title={t("add_employees.register_employees")}
			onEditCancel={() => console.log("cancel click")}
		/>
	);
}

export default EmployeeFormPage;
