import React, { useState } from "react";
import { Outlet, useLocation } from "react-router";
import { AppShell, useMantineTheme } from "@mantine/core";
import { SidemenuNav } from "./ui/sidemenu";
import { Topbar } from "./ui/topbar";
import { isProd, isUserBetaPreview } from "../helpers/user";
import NoMatch from "../pages/public/404";
import { PublicHtmlHeader } from "./publicHtmlHeader";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import ErrorBoundary from "./ui/shared/errors/ErrorBoundary";

export function LayoutPublic(props: { children: any }) {
	let location = useLocation();

	if (isProd()) {
		ReactGA.send({ hitType: "pageview", page: location.pathname });
	}

	return (
		<div>
			<PublicHtmlHeader />
			{props.children ? props.children : <Outlet />}
		</div>
	);
}

export function LayoutPrivate() {
	const theme = useMantineTheme();
	const [opened, setOpened] = useState(true);
	return isUserBetaPreview() ? (
		<AppShell
			styles={{
				main: {
					background:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
				},
			}}
			navbarOffsetBreakpoint="sm"
			asideOffsetBreakpoint="sm"
			fixed
			header={<Topbar setOpened={setOpened} opened={opened} />}
			navbar={<SidemenuNav opened={opened} />}>
			<Helmet>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<ErrorBoundary>
				<Outlet />
			</ErrorBoundary>
		</AppShell>
	) : (
		<NoMatch />
	);
}
