import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleGrid, Text } from "@mantine/core";
import { format } from "date-fns";
import { BookedData } from "../../data/types/payslip";
export default function PayslipsProcessing({
	payrollProcessData,
}: {
	payrollProcessData: BookedData;
}) {
	const { t } = useTranslation();

	return (
		<>
			{payrollProcessData.paySlips && (
				<SimpleGrid cols={2} p={"xl"}>
					<Text size="sm">
						<b>
							{
								t("salary_process_view.payroll_processing", {
									returnObjects: true,
								})[0]
							}
						</b>
						<br />
						{format(new Date(payrollProcessData.periodStarts), "dd.MMM yyyy") +
							" - " +
							format(new Date(payrollProcessData.periodStarts), "dd.MMM yyyy")}
					</Text>

					<Text size="sm">
						<b>
							{
								t("salary_process_view.payroll_processing", {
									returnObjects: true,
								})[1]
							}
						</b>
						<br />
						{format(
							new Date(payrollProcessData.processDateTime),
							"dd.MMM yyyy hh:mm:ss aaa "
						)}
					</Text>
					<Text size="sm">
						<b>
							{" "}
							{
								t("salary_process_view.payroll_processing", {
									returnObjects: true,
								})[2]
							}{" "}
						</b>

						<br />
						{format(new Date(payrollProcessData.salaryPayDay), "dd.MMM yyyy ")}
					</Text>
					<Text size="sm">
						{
							t("salary_process_view.payroll_processing", {
								returnObjects: true,
							})[3]
						}

						<br />
						{payrollProcessData.paySlips.length}
					</Text>
				</SimpleGrid>
			)}
		</>
	);
}
