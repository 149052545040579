import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SimpleGrid, Text, Group, Table } from "@mantine/core";
import { SalaryProcessSummaryItem } from "../../../data/types/payslip";
import { Amount } from "../../ui/common/currency";
import { CardView } from "../../ui/common/sharedComponents";
import { TableData, tableHeaderColumn } from "../../ui/common/tableData";
function SalaryProcessTabelView({
	salaprocessSummarydata,
}: {
	salaprocessSummarydata: any;
}) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);

	const tableHeaderTranslations: string[] = t(
		"salary_processe_list.salary_summary_table",
		{
			returnObjects: true,
		}
	);

	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
	];
	useEffect(() => {
		var tablebody =
			salaprocessSummarydata &&
			salaprocessSummarydata.summaryData.Items &&
			salaprocessSummarydata.summaryData.Items.map(
				(data: SalaryProcessSummaryItem, index: number) => (
					<tr key={index}>
						<td>
							{
								t("salary_processe_list.salary_summary_label", {
									returnObjects: true,
								})[index]
							}
						</td>
						<td> {<Amount value={data.Val} />}</td>
						<td>{data.Description}</td>
					</tr>
				)
			);
		setRows(tablebody);
	}, [salaprocessSummarydata]);
	return (
		<CardView
			value={<TableData tableHeader={tableHeaders} tableBody={rows} />}
		/>
	);
}
function SalaryProcessCardView({
	salaprocessSummarydata,
}: {
	salaprocessSummarydata: any;
}) {
	const { t } = useTranslation();

	return (
		<CardView
			value={
				<>
					{salaprocessSummarydata.summaryData.Items && (
						<SimpleGrid cols={4}>
							{salaprocessSummarydata.summaryData.Items.map(
								(data: SalaryProcessSummaryItem, index: number) => (
									<Group direction="column" key={index} p={"xs"}>
										<Text
											style={{
												cursor: "pointer",
											}}>
											{
												t("salary_processe_list.salary_summary_label", {
													returnObjects: true,
												})[index]
											}
											<br></br>
											{<Amount value={data.Val} />}
										</Text>
									</Group>
								)
							)}
						</SimpleGrid>
					)}
				</>
			}
		/>
	);
}
export { SalaryProcessCardView, SalaryProcessTabelView };
