import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../app/store";
import { Bank, BankData, DataStatus } from "../types/shared";

const initialState: BankData = {
	banks: [],
	isLoading: false,
	status: DataStatus.NOT_FETCHED,
	error: "",
};

export const bankDataSlice = createSlice({
	name: "bankdata",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
			state.status = DataStatus.FETCHING;
		},
		hasError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.isLoading = false;
			state.status = DataStatus.EMPTY;
		},
		dataLoaded: (state, action: PayloadAction<Bank[]>) => {
			state.banks = action.payload;
			state.isLoading = false;
			state.status = DataStatus.FETCHED;
		},
	},
});

export const { startLoading, hasError, dataLoaded } = bankDataSlice.actions;

export const fetchBankData = () => (dispatch: Dispatch) => {
	dispatch(startLoading());
	axios
		.get(`${process.env.REACT_APP_API_URL}/data/collection/get/bankbranches`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
		.then(function (response: AxiosResponse<Bank[], any>) {
			if (response.status === 200) {
				dispatch(dataLoaded(response.data));
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export default bankDataSlice.reducer;
export const selectBankData = (state: RootState) => state.bankData;
