export enum DataStatus {
	NOT_FETCHED = "not_fetched",
	EMPTY = "empty",
	FETCHING = "fetching",
	FETCHED = "fetched",
	ERROR = "error", //used if data is fetched but there is an error
	NEEDS_UPDATE = "needs_update", //used if data is fetched but needs to be updated (re-fetched)
}

type CompanyConnection = {
	id: number;
	name: string;
	apiKey: string;
};
type ListItem = {
	value: string;
	label: string;
};

type Branch = {
	branch: string;
	bankNumber: number;
};

type Bank = {
	name: string;
	branches: Branch[];
};

type BankData = {
	banks: Bank[];
	isLoading: boolean;
	status: DataStatus;
	error: string;
};

export type { CompanyConnection, ListItem, BankData, Bank, Branch };
