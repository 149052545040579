import * as ctf from 'contentful';

const pageClient = ctf.createClient({
    space: process.env.REACT_APP_CONTENT_SPACE_ID as string,
    accessToken: process.env.REACT_APP_CONTENT_ACCESS_TOKEN as string,
});

const blogClient = ctf.createClient({
    space: process.env.REACT_APP_BLOG_SPACE_ID as string,
    accessToken: process.env.REACT_APP_BLOG_ACCESS_TOKEN as string,
});

export { pageClient, blogClient };