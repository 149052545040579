import {
	Modal,
	Text,
	NumberInput,
	Button,
	TextInput,
	createStyles,
	Container,
	Anchor,
	Space,
	Select,
	SimpleGrid,
	Box,
} from "@mantine/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AsteriskSimple, Percentage, Plus } from "tabler-icons-react";
import { SelectListItem } from "../../data/types/addEmployees";
import { SalaryElement } from "../../data/types/employeeFormData";
import LaunaCurrencyInput from "../ui/common/launaCurrencyInput";

function SalaryItemsRateCalculateComponent({
	salaryElements,
	formik,
}: {
	salaryElements: SelectListItem[];
	formik: any;
}) {
	const { t } = useTranslation();
	const [opened, setOpened] = useState(false);

	const hourlyItems = [
		{
			id: 2,
			description: "Dagvinnutaxti",
			rate: 0,
			value: 0,
			units: 1,
		},
		{
			id: 3,
			description: "Eftirvinnutaxti",
			rate: 33,
			value: 0,
			units: 1,
		},
		{
			id: 4,
			description: "Stórhátíðartaxti",
			rate: 45,
			value: 0,
			units: 1,
		},
		{
			id: 5,
			description: "Mánaðarlaun",
			rate: 90,
			value: 0,
			units: 1,
		},
	];

	const itemsGrid = {
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		alignItems: "end",
		rowGap: "7px",
		columnGap: "24px",
	};

	const useStyles = createStyles((theme, _params, getRef) => ({
		container: {
			// assign reference to selector
			ref: getRef("container"),
		},
		grid: {
			width: "100%",
		},
		root: {
			display: "flex",
			gap: "30px",
		},
		steps: {
			width: "md",
		},
		content: {
			alignContent: "start",
			width: "100%",
		},
		modalContent: itemsGrid,
		spanTwoColumns: {
			gridColumn: "1 / 3",
		},
		salaryElements: itemsGrid,
		rightAlign: {
			cursor: "default",
			display: "inline-block",
			width: "100%",
			textAlign: "right",
		},
		description: {
			marginTop: "16px",
		},
		selectedItem: {
			marginTop: "3px",
			display: "flex",
			flexDirection: "row",
			gap: "16px",
			flexWrap: "wrap",
			alignItems: "end",
		},
	}));

	const { classes } = useStyles();
	const rightAlignedInputClasses = {
		input: classes.rightAlign,
		label: classes.rightAlign,
	};
	const requiredMark = <AsteriskSimple size={9} color="#ff6b6b" />;

	const salaryItemSelector = (
		<Select
			placeholder={t("add_employees.salary.add_salary_item")}
			searchable
			clearable
			dropdownPosition="bottom"
			spellCheck={false}
			allowDeselect={false}
			icon={<Plus />}
			data={salaryElements}
			onChange={(value: string) => {
				const element: SalaryElement = JSON.parse(value);
				if (element) {
					element.value = 0;
					const currentSalaryElements = formik.values.salary.salaryElements;
					const index = currentSalaryElements.findIndex(
						(prev: SalaryElement) => prev.id === element.id
					);
					if (index === -1) {
						formik.setFieldValue("salary.salaryElements", [
							...currentSalaryElements,
							element,
						]);
					}
				}
			}}
		/>
	);

	const unselectItem = (id: number) => {
		const newSalaryElements = formik.values.salary.salaryElements.filter(
			(item: SalaryElement) => item.id !== id
		);
		formik.setFieldValue("salary.salaryElements", newSalaryElements);
	};

	const RemoveSelectedButton = (props: { id: number }) => (
		<Button
			onClick={() => {
				unselectItem(props.id);
			}}
			color="red">
			{t("add_employees.salary.salary_elements.remove_button")}
		</Button>
	);

	let selectedItems =
		formik.values.salary && formik.values.salary.salaryElements
			? formik.values.salary.salaryElements.map(
					(item: SalaryElement, index: number) => (
						<SimpleGrid
							cols={3}
							breakpoints={[{ maxWidth: 850, cols: 1 }]}
							key={item.id}>
							<div>
								<TextInput
									key={"salaryelement_applied_description_" + index}
									label="&nbsp;"
									spellCheck={false}
									defaultValue={item.description}
									onChange={(ev) => {
										formik.setFieldValue(
											"salary.salaryElements[" + index + "].description",
											ev.target.value
										);
									}}
								/>
							</div>
							<div>
								<LaunaCurrencyInput
									label={item.salaryElementValueType === 1 ? "Taxti" : "Upphæð"}
									value={
										formik.getFieldProps(
											"salary.salaryElements[" + index + "].value"
										).value
									}
									onChange={(val) => {
										formik.setFieldValue(
											"salary.salaryElements[" + index + "].value",
											val
										);
									}}
								/>
							</div>
							<Box
								sx={{
									marginTop: "27px",
									"@media (max-width: 850px)": {
										marginTop: "0",
										textAlign: "center",
									},
								}}>
								<RemoveSelectedButton id={item.id} />
							</Box>
						</SimpleGrid>
					)
			  )
			: [];

	const addSelectedPopupItems = () => {
		//add to formik
		const alreadySelected = formik.values.salary.salaryElements;
		const notFromPopup = alreadySelected.filter((element: SalaryElement) => {
			const index = hourlyItems.findIndex((ele) => ele.id === element.id);
			return index === -1;
		});
		formik.setFieldValue("salary.salaryElements", [
			...form.values.salaryElements,
			...notFromPopup,
		]);
		setOpened(false);
	};

	const form = useFormik({
		initialValues: { salaryElements: hourlyItems },
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const popupItems = form.values.salaryElements.map((element, index) => {
		return element.id === 2 ? (
			<div key={element.id} className={classes.modalContent}>
				<TextInput
					className={classes.spanTwoColumns}
					mt={3}
					spellCheck={false}
					defaultValue={element.description}
				/>

				<TextInput
					classNames={rightAlignedInputClasses}
					min={0}
					label={t("add_employees.salary.hourly_item_modal.rate")}
					type="number"
					value={form.values.salaryElements[index].value}
					onChange={(e) => {
						const newValue = parseInt(e.currentTarget.value);
						if (!isNaN(newValue)) {
							const newElements = form.values.salaryElements.map((element) => {
								element.value = newValue + element.rate;
								return element;
							});
							form.setFieldValue(`salaryElements`, newElements);
						}
					}}
				/>
			</div>
		) : (
			<SimpleGrid cols={3} key={element.id}>
				<TextInput
					className={classes.description}
					spellCheck={false}
					style={{ paddingTop: "12px" }}
					defaultValue={element.description}
				/>
				<NumberInput
					classNames={{ input: classes.rightAlign }}
					min={1}
					rightSection={<Percentage />}
					label={t("add_employees.salary.hourly_item_modal.alag")}
					value={form.values.salaryElements[index].rate}
				/>
				<TextInput
					classNames={rightAlignedInputClasses}
					label={t("add_employees.salary.hourly_item_modal.rate")}
					type="number"
					readOnly
					value={form.values.salaryElements[index].value}
				/>
			</SimpleGrid>
		);
	});

	return (
		<>
			<Text size="lg">{t("add_employees.salary.title")}</Text>
			<Text size="xs">
				{t("add_employees.salary.required") + " "} {requiredMark}
			</Text>
			<Space h="md" />
			<Container style={{ textAlign: "right" }}>
				<Anchor
					component="button"
					type="button"
					mr={12}
					onClick={() => {
						setOpened(true);
					}}>
					{t("add_employees.salary.add_hourly_wage_items")}
				</Anchor>
			</Container>
			<Space h="md" />
			{salaryItemSelector}
			<Space h="md" />
			{selectedItems}
			<Modal
				opened={opened}
				size="lg"
				overlayOpacity={0.3}
				onClose={() => setOpened(false)}
				title={t("add_employees.salary.hourly_item_modal.title")}>
				<Text size="sm">
					{t("add_employees.salary.hourly_item_modal.instruction")}
				</Text>
				<form>{popupItems}</form>
				<Button fullWidth mt={12} onClick={addSelectedPopupItems}>
					{t("add_employees.salary.hourly_item_modal.add_button")}
				</Button>
			</Modal>
		</>
	);
}
export default SalaryItemsRateCalculateComponent;
