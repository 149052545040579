import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Modal, Center, ScrollArea } from "@mantine/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
	fetchUserData,
	selectUser,
	userFirstLogin,
} from "../data/user/userSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";

interface Props {
	openModal: boolean;
}

export default function Conditions(props: Props) {
	const { t } = useTranslation();
	const user = useAppSelector(selectUser);
	const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setOpened(props.openModal);
	}, [props.openModal]);
	const acceptConditions = () => {
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/user/accept/useragreement/${user.userConditions.InternalVersionNumber}`
			)
			.then(function (response) {
				dispatch(fetchUserData());
				setLoading(false);
				dispatch(userFirstLogin({ Html: "", InternalVersionNumber: "" }));
			})
			.catch(function (error) {
				console.log(error);
			});
		setOpened(false);
		navigate("/app");
	};
	return (
		<Modal
			opened={opened}
			withCloseButton={false}
			size="full"
			title={t("conditions.heading")}
			onClose={() => {}}
		>
			<ScrollArea style={{ height: "75vh" }}>
				<div
					dangerouslySetInnerHTML={{ __html: user.userConditions.Html }}
				></div>
			</ScrollArea>
			<Center mt={5}>
				<Button onClick={acceptConditions} loading={loading}>
					{t("conditions.accept_conditions")}
				</Button>
			</Center>
		</Modal>
	);
}
