import React, { useEffect, useState } from "react";
import { PayslipBatchesData } from "../../../data/types/payslipBatches";
import { useTranslation } from "react-i18next";
import { ColumnAlign, TableData, tableHeaderColumn } from "../common/tableData";
import { format, setDefaultOptions } from "date-fns";
import { is } from "date-fns/locale";
import { Modal, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { LaunaLoadingOverlay } from "../common/loadingOverlay";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconExclamationMark } from "@tabler/icons";
import { DeleteButton } from "../buttons";
import { Link } from "react-router-dom";
import PayslipBatchView from "./payslipBatchView";

export default function PayslipBatchesList(props: {
	data: PayslipBatchesData[];
	openBatchId: number | undefined;
	onBatchOpened: (batchId: number) => any;
	onDeleted: () => any;
}) {
	setDefaultOptions({ locale: is });
	const { t } = useTranslation();
	const modals = useModals();
	const [loading, setLoading] = useState(false);
	const [selectedBatchId, setSelectedBatchId] = useState(0);

	useEffect(() => {
		if (props.openBatchId) {
			setSelectedBatchId(props.openBatchId);
		}
	}, [props.openBatchId]);

	useEffect(() => {
		props.onBatchOpened(selectedBatchId);
	}, [selectedBatchId]);

	const deletePayslipBatch = (batchId: number) => {
		setLoading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/payslips/batch/${batchId}/delete`
			)
			.then((response) => {
				setLoading(false);
				console.log("delete response", response);
				showNotification({
					message: "Bunka eytt",
					color: "green",
					icon: <IconCheck size={16} />,
				});
				props.onDeleted();
			})
			.catch((err) => {
				setLoading(false);
				console.log("delete error", err);
				showNotification({
					title: "Error",
					message: "Villa kom upp við að eyða bunka",
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
			});
	};

	const openConfirmDeleteModal = (batchId: number, batchName: string) =>
		modals.openConfirmModal({
			title: t("common.confirm"),
			children: (
				<Text size="sm" style={{ textAlign: "center" }}>
					{t("payslip_batch.messages.are_you_sure_delete")}
					<br />
					<strong>{batchName}</strong>
				</Text>
			),
			labels: {
				confirm: t("payslip_batch.buttons.confirm_delete"),
				cancel: t("payslip_batch.buttons.cancel_delete"),
			},
			confirmProps: { color: "red" },
			onConfirm: () => deletePayslipBatch(batchId),
		});

	const tableHeaders: tableHeaderColumn[] = [
		{ text: t("payslip_batch.list.batch_name") },
		{ text: t("payslip_batch.list.period") },
		{ text: t("payslip_batch.list.created") },
		{ text: t("payslip_batch.list.payslip_count"), align: ColumnAlign.RIGHT },
		{ text: "", align: ColumnAlign.RIGHT },
	];

	const viewBatchTrigger = (
		item: PayslipBatchesData,
		text: string,
		bold: boolean
	) => (
		<Text<typeof Link>
			style={{ fontWeight: bold ? "bold" : "normal" }}
			component={Link}
			to="#"
			onClick={() => setSelectedBatchId(item.id)}>
			{text}
		</Text>
	);

	const tableBody = props.data.map((item) => {
		const periodDate = new Date(
			item.year + "-" + item.month.toString().padStart(2, "0") + "-01T00:00"
		);
		return (
			<tr key={"payslip-batches-" + item.id}>
				<td>{viewBatchTrigger(item, item.batchName, true)}</td>
				<td>
					{viewBatchTrigger(
						item,
						format(periodDate, "MMMM yyyy") +
							"(" +
							item.month +
							"-" +
							item.year +
							")",
						false
					)}
				</td>
				<td>
					{viewBatchTrigger(
						item,
						format(new Date(item.created), "d. MMMM yyyy - HH:mm"),
						false
					)}
				</td>
				<td style={{ textAlign: "right" }}>{item.payslipCount}</td>
				<td style={{ textAlign: "right" }}>
					<DeleteButton
						onClick={() => {
							openConfirmDeleteModal(item.id, item.batchName);
						}}
						text={t("common.delete")}
					/>
				</td>
			</tr>
		);
	});

	return (
		<>
			<LaunaLoadingOverlay visible={loading} />
			<TableData tableHeader={tableHeaders} tableBody={tableBody} />
			{selectedBatchId > 0 && (
				<Modal opened={true} size="full" onClose={() => setSelectedBatchId(0)}>
					<PayslipBatchView batchId={selectedBatchId} />
				</Modal>
			)}
		</>
	);
}
