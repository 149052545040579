import React from "react";
import CurrencyInput from "react-currency-input-field";
import { Text } from "@mantine/core";
interface Props {
	value?: string;
	defaultValue?: string;
	label?: string;
	onChange: (value: string | undefined) => void;
}

export default function LaunaCurrencyInput(props: Props) {
	return (
		<>
			<Text align="right">{props.label}</Text>
			<CurrencyInput
				decimalSeparator=","
				groupSeparator="."
				step={1000}
				min={0}
				allowNegativeValue={false}
				disableAbbreviations={true}
				allowDecimals={false}
				suffix=" ISK"
				style={{
					textAlign: "right",
					marginTop: props.label ? "3px" : 0,
				}}
				className="mantine-TextInput-filledVariant mantine-TextInput-input mantine-1uw1a2d"
				onValueChange={props.onChange}
				value={props.value}
				defaultValue={props.defaultValue || "0"}
			/>
		</>
	);
}
