import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Center,
	SegmentedControl,
	Box,
	Title,
	Tooltip,
	Modal,
	Menu,
	Divider,
	SimpleGrid,
	Space,
	createStyles,
} from "@mantine/core";
import {
	CardView,
	PaperView,
} from "../../../components/ui/common/sharedComponents";
import { BookedData } from "../../../data/types/payslip";
import { format } from "date-fns";
import { IconInfoCircle } from "@tabler/icons";
import {
	TableData,
	tableHeaderColumn,
} from "../../../components/ui/common/tableData";
import DisplaySalaryProcessSummary from "./salaryprocessView";
import { LockOpen } from "tabler-icons-react";

import { ActionButton } from "../../../components/ui/buttons";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { selectSalaryProcessSingleEmpData } from "../../../data/salaryIncrease/salaryprocessSingleEmpData";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
	itemHovered: {
		backgroundColor: theme.colors[theme.black],
		color: theme.white,
	},
}));
export default function InProgress({
	salaryBookedData,
}: {
	salaryBookedData: BookedData[];
}) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);
	const [selectedId, setSelectedId] = useState<any>();
	const [deletedId, setDeletedId] = useState<any>();
	const [openModal, setOpenModal] = useState(false);
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [currentId, setCurrentId] = useState<number>(0);
	var salaryprocessSingleEmpdata = useAppSelector(
		selectSalaryProcessSingleEmpData
	);
	useEffect(() => {
		if (currentId) {
			salaryprocessSingleEmpdata.employeeData &&
				navigate("/app/salaryprocesses/view/" + currentId, {
					replace: true,
				});
		}
	}, [currentId]);

	const handleOnClick = (curentId: any) => {
		setCurrentId(curentId);
	};
	const openConfirmModal = (Id: any) => (
		<Menu
			classNames={classes}
			withArrow={true}
			size={"xl"}
			arrowDistance={80}
			position="bottom"
			control={
				<Box style={{ color: "cyan" }}>
					{
						t("salary_processe_list.inprogress_button", {
							returnObjects: true,
						})[1]
					}
				</Box>
			}>
			<Menu.Label>
				<Box>
					<b>{t("salary_processe_list.inprogress_reverse_header")}</b>{" "}
				</Box>
			</Menu.Label>
			<Divider />
			<SimpleGrid cols={2}>
				<Menu.Item p={"xs"}>
					<ActionButton
						color={"gray"}
						onClick={() => {
							axios
								.delete(
									`${process.env.REACT_APP_API_URL}/salaryprocess/delete/${Id}`
								)
								.then(function (response) {})
								.catch(function (error) {});
						}}
						type="submit">
						{
							t("salary_processe_list.inprogress_reverse_button", {
								returnObjects: true,
							})[0]
						}
					</ActionButton>
				</Menu.Item>
				<Menu.Item
					rightSection={
						<ActionButton color={"gray"} onClick={() => {}}>
							{
								t("salary_processe_list.inprogress_reverse_button", {
									returnObjects: true,
								})[1]
							}
						</ActionButton>
					}>
					<Space />
				</Menu.Item>
			</SimpleGrid>
		</Menu>
	);
	useEffect(() => {
		if (currentId) {
			//dispatch(fetchSalaryProcessSinglEmpData(currentId, succecCallback));
			salaryprocessSingleEmpdata.employeeData &&
				navigate("/app/salaryprocesses/view/" + currentId, {
					replace: true,
				});
		}
	}, [currentId]);
	useEffect(() => {
		const bookedRow = salaryBookedData.map(
			(data: BookedData, index: number) => (
				<tr key={data.id}>
					<td>{data.id}</td>
					<td>
						{format(new Date(data.processDateTime), "d. MMMM yyyy h:mm aaa")}
					</td>
					<td>{data.modified}</td>
					<td>{data.yearMonth}</td>
					<td>
						{format(new Date(data.periodStarts), "d. MMMM yyyy") +
							" - " +
							format(new Date(data.periodEnds), "d. MMMM yyyy")}
					</td>
					<td>{format(new Date(data.salaryPayDay), "d. MMMM yyyy")}</td>
					<td>
						<SegmentedControl
							fullWidth
							size="md"
							data={[
								{
									value: "info",
									label: (
										<Center
											onClick={() => {
												// setSelectedId(data.id);
												// setOpenModal(true);
											}}>
											<Tooltip
												withArrow={true}
												arrowSize={6}
												radius={"md"}
												position={"bottom"}
												label={t("salary_processe_list.inbooking_tooltip", {
													returnObjects: true,
												})}>
												<IconInfoCircle
													style={{ color: "cyan" }}
													size={25}
													cursor={"pointer"}
													onClick={(e) => {}}
												/>
											</Tooltip>
										</Center>
									),
								},

								{
									value: "revers",
									label: openConfirmModal(data.id),
								},
							]}
						/>
					</td>
				</tr>
			)
		);
		setRows(bookedRow);
	}, [salaryBookedData, t]);

	const tableHeaderTranslations: string[] = t(
		"salary_processe_list.inprogress_table",
		{
			returnObjects: true,
		}
	);
	const tableHeaders: tableHeaderColumn[] = [
		{ text: tableHeaderTranslations[0] },
		{ text: tableHeaderTranslations[1] },
		{ text: tableHeaderTranslations[2] },
		{ text: tableHeaderTranslations[3] },
		{ text: tableHeaderTranslations[4] },
		{ text: tableHeaderTranslations[5] },
		{ text: tableHeaderTranslations[6] },
	];
	return (
		<>
			<CardView
				value={
					rows.length === 0 ? (
						<Title order={6}>{t("salary_processe_list.no_inprogress")}</Title>
					) : (
						<TableData tableHeader={tableHeaders} tableBody={rows} />
					)
				}
			/>

			{selectedId && (
				<Modal
					size="100%"
					withinPortal={true}
					opened={openModal}
					onClose={() => {
						setOpenModal(false);
						setSelectedId(null);
					}}>
					<DisplaySalaryProcessSummary selectedId={selectedId} />
				</Modal>
			)}
		</>
	);
}
