import React from "react";
import {
	Text,
	SimpleGrid,
	Tooltip,
	Select,
	TextInput,
	createStyles,
	Grid,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ChevronDown, Help, Percentage } from "tabler-icons-react";

function TaxComponent({ formik }: { formik: any }) {
	const { t } = useTranslation();
	const { values, handleChange, setFieldValue } = formik;

	const useStyles = createStyles((theme) => ({
		grid: {
			width: "100%",
		},
	}));

	const { classes } = useStyles();
	return (
		<div>
			<Text size="lg">{t("add_employees.cash.title")}</Text>
			<SimpleGrid cols={3} breakpoints={[{ maxWidth: 850, cols: 1 }]}>
				<TextInput
					rightSection={<Percentage />}
					name="settings.taxFreeAllowance"
					type="number"
					max={100}
					onChange={handleChange}
					value={values.settings?.taxFreeAllowance}
					label={t("add_employees.cash.personal_discount")}
				/>
				<TextInput
					rightSection="ISK"
					value={values.settings?.taxFreeAllowanceUnused}
					type="number"
					name="settings.taxFreeAllowanceUnused"
					onChange={handleChange}
					label={t("add_employees.cash.unused_personal_discount")}
				/>
				<Select
					label={
						<>
							{t("add_employees.cash.first_tax_rate")}
							&nbsp;
							<Tooltip
								withArrow
								width={220}
								wrapLines
								label={t("add_employees.cash.first_tax_rate_tootlip")}>
								<Help size={16} />
							</Tooltip>
						</>
					}
					rightSection={<ChevronDown size={14} />}
					onChange={(value) => setFieldValue("settings.startingTaxStep", value)}
					value={values.settings?.startingTaxStep.toString()}
					data={[
						{ value: "1", label: "1" },
						{ value: "2", label: "2" },
						{ value: "3", label: "3" },
					]}
				/>
				<TextInput
					rightSection={<Percentage />}
					value={values.settings?.taxFreeAllowanceSpouse}
					type="number"
					name="settings.taxFreeAllowanceSpouse"
					onChange={handleChange}
					label={t("add_employees.cash.spouses_personal_discount")}
				/>
				<TextInput
					rightSection="ISK"
					value={values.settings?.taxFreeAllowanceSpouseUnused}
					type="number"
					name="settings.taxFreeAllowanceSpouseUnused"
					onChange={handleChange}
					label={t("add_employees.cash.spouses_unused_personal_discount")}
				/>
			</SimpleGrid>
			<Grid columns={24} mt={21} className={classes.grid}>
				<Grid.Col md={8}>
					<Select
						label={t("add_employees.cash.cash_calculation_config")}
						placeholder="Pick one"
						rightSection={<ChevronDown size={14} />}
						value={values.settings?.taxCalculationConfig}
						onChange={(value) =>
							setFieldValue("settings.taxCalculationConfig", value)
						}
						data={t("add_employees.cash.cash_calculation_config_values", {
							returnObjects: true,
						})}
					/>
				</Grid.Col>
				<Grid.Col md={8} style={{ marginTop: 24 }}>
					{t(
						`add_employees.cash.cash_calculation_config_description.${values.settings.taxCalculationConfig}`
					)}
				</Grid.Col>
			</Grid>
		</div>
	);
}

export default TaxComponent;
