import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SalaryProcessSummary } from "../types/payslip";
import { getCurrentCompanyId } from "../../helpers/user";
import { RootState } from "../../app/store";
interface salaryPocessSummaryData {
	summaryData: SalaryProcessSummary;
	selectedId: number;
	isLoading: boolean;
	error: boolean;
}
const initialState: salaryPocessSummaryData = {
	summaryData: {} as SalaryProcessSummary,
	selectedId: 0,
	isLoading: false,
	error: false,
};
export const salaryProcessSummary = createSlice({
	name: "salaryProcessSummary",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		dataLoaded: (state, action) => {
			state.summaryData = action.payload.Data;
			state.selectedId = getCurrentCompanyId();
			state.isLoading = false;
		},
	},
});
export const { startLoading, hasError, dataLoaded } =
	salaryProcessSummary.actions;
//thunk function for async api call
export const fetchSalaryProcessSummaryData =
	(selectedId: number) => (dispatch: Dispatch) => {
		dispatch(startLoading());
		const currentSelectedId = selectedId;
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/salaryprocess/${currentSelectedId}/summary`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					dispatch(dataLoaded(response.data));
				}
			})
			.catch((error) => {
				dispatch(hasError(error.request.responseText.Message));
			});
	};

export default salaryProcessSummary.reducer;
export const selectSalaryProcessSummaryData = (state: RootState) =>
	state.salaryprocessSummaryData;
