function getErrorMessageFromResponse(response, t) {
  let translationKey =
    response && response.data && response.data.Key ? response.data.Key : null;

  if (
    translationKey === null &&
    response &&
    response.data &&
    response.data.Message
  )
    translationKey = response.data.Message;

  let serverMessage =
    response && response.data && response.data.Message
      ? response.data.Message
      : null;

  return translationKey ? t(translationKey) : serverMessage;
}

export default getErrorMessageFromResponse;
