import { type } from "os";

type Payslip = {
	id: number;
	periodStart: string;
	periodEnd: string;
	employee: Employee;
	bankAccount?: BankAccount;
	paySlipSalaryElements: PaySlipSalaryElement[];
	ytdElements: YtdElement[];
	salaryTaxBase: number;
	salaryTaxBaseOriginal: number;
	salaryTotal: number;
	salaryToPay: number;
	salaryEmployerCostTotal: number;
	deductableTotal: number;
	taxDeduction: TaxDeduction;
	insuranceFees: InsuranceFees;
	unionFees: UnionFee[] | null;
	union?: Union | null;
	pensionFund?: PensionFund | null;
	specialPensionFund?: PensionFund | null;
	specialPensionFundsExtra?: PensionFund[] | null;
	guid: string;
	holidayCollected: HolidayCollected | null;
	blockBlobExists: boolean;
	numberOfSickDays: number;
	numberOfSickDaysChildren: number;
	numberOfSickDaysUsedInPeriod: number;
	numberOfSickDaysUsedInPeriodChildren: number;
	numberOfPreviouslyCollectedSickDays: number;
	numberOfPreviouslyCollectedSickDaysChildren: number;
};

type HolidayCollected = {
	amount: number;
	percentage: number;
	description: string;
	optionId: number;
};

type BankAccount = {
	bankNUmber: number;
	hofudbok: number;
	accountNumber: number;
};

type Employee = {
	id: number;
	companyId: number;
	departmentIds: number[] | null;
	salaryGroupIds: number[] | null;
	registrationId: string;
	fullName: string;
	dateOfBirth: string;
	dateOfHire: string;
	address: string | null;
	address2: string | null;
	city: string | null;
	region: string | null;
	postalCode: string | null;
	country: string | null;
	photo: string | null;
	phone: string | null;
	email: string | null;
	notes: string | null;
	starfshlutfall: number;
	isSalaryHourlyBased: boolean;
	salary: Salary;
	settings: PayslipSettings;
	holidaySettings: HolidaySettings;
	company: PayslipCompany;
	paySlips: any | null;
	deductDistributionTables: any[]; //TODO: test with a payslip that has this data
	specialPensionFundsExtra: PensionFund | null;
	specialPensionFundExtraSettings: PensionFund[] | null;
};

type PayslipCompany = {
	id: number;
	registrationId: string;
	name: string;
	address: string | null;
	address2: string | null;
	city: string | null;
	region: string | null;
	postalCode: string | null;
	country: string | null;
	email: string | null;
	phone: string | null;
	showCostOnPayslip: boolean;
};

export enum HolidaySettingTypeEnum {
	/// Dögum safnað
	DaysCollected = 0,
	/// Orlof á öll laun greitt
	PaidWithSalary = 1,
	/// orlof á öll laun á orlofsreikning
	PaidIntoHolidayAccount = 2,
	/// Dögum safnað og orlof greitt með launum á yfirvinnu
	DaysCollectedAndPaidOnOvertime = 3,
	/// Dögum safnað og orlof á yfirvinnu á orlofsreikning
	DaysCollectedAndPaidIntoHolidayAccount = 4,
	/// orlof á yfirvinnu greitt
	PaidOnOverTimeWithSalary = 5,
}

type HolidaySettings = {
	holidayPeriods: HolidayPeriod[] | null;
	holidaySettingType: HolidaySettingTypeEnum;
	percentageOfSalary: number;
	holidayBankAccount: BankAccount | null;
	holidayDayOption: HolidayDayOption;
	daysCollected: number;
};

type HolidayDayOption = {
	id: number;
	description: string;
	details: string;
	percentage: number;
	numberOfDays: number;
};

type HolidayPeriod = {
	id: number;
	periodStart: string;
	periodEnd: string;
	isCurrentPeriod: boolean;
	daysCollected: number;
};

type Salary = {
	salaryTableId: number;
	salaryElements: SalaryElement[];
};

type SalaryElement = {
	id: number;
	description: string;
	value: number;
	units: number;
	baseId: number;
	rate: number;
};

type PayslipSettings = {
	unionId: number;
	pensionFundId: number;
	specialPensionFundId: number;
	unionFees: any;
	bankAccount: null;
	payrollPeriodId: number;
	payrollPaymentMethodId: number;
	specialPensionFundPercentageEmployee: number;
	specialPensionFundPercentageCompany: number;
	taxFreeAllowance: number;
	taxFreeAllowanceSpouse: number;
	taxFreeAllowanceUnused: number;
	taxFreeAllowanceSpouseUnused: number;
	pensionFundPercentageEmployee: number;
	pensionFundPercentageCompany: number;
	istarf95: null;
	numberOfSickDaysPerSalaryProcess: number | null;
	numberOfChildrenSickDaysPerSalaryProcess: number | null;
	usedSickDays: any[];
	collectedSickDays: null;
	isPensionFundPercentage: boolean;
	isSpecialPensionFundPercentage: boolean;
	startingTaxStep: number;
	taxCalculationConfig: string;
};

type InsuranceFees = {
	total: number;
	items: TaxDeductionStepElement[];
};

type TaxDeductionStepElement = {
	title: string;
	percentage: number;
	amount: number;
	taxableAmount?: number;
};

type PaySlipSalaryElement = {
	id: number;
	units: number;
	value: number;
	description: string;
	salaryElementType: number;
	salaryElementTypeStringed: string | null;
	salaryElementValueType: number;
	salaryElementValueTypeStringed: string | null;
	isCalcTaxed: boolean;
	isCalcInsuranceFee: boolean;
	isCalcPension: boolean;
	isCalcUnion: boolean;
	isCalcHoliday: boolean;
	isCalcHolidayPaid: boolean;
	alagPercentage: number | null;
	isBaseForAlag: boolean | null;
	baseId: number;
	baseUnitValue: number;
	deductDistributionItemId: number;
	deleted: null;
};

type PensionFund = {
	name: null | string;
	entityNo: number;
	collectorEntityId: number;
	items: PensionFundItem[] | null;
};

type PensionFundItem = {
	description: string;
	percentage: number;
	amount: number;
	subtractEntity: number;
};

type TaxDeduction = {
	taxTotal: number;
	taxAllowanceTotal: number;
	taxAllowanceTotalSpouse: number;
	taxTotalToDeduct: number;
	taxDeductionSteps: TaxDeductionStepElement[];
	taxAllowanceMoveNextPeriod?: number;
	taxAllowanceMoveNextPeriodSpouse: number;
	isYouth: boolean;
};

type Union = {
	entityNo: number;
	name: string;
	collectorNo: number;
};

type UnionFee = {
	title: string;
	isFixedAmount: boolean;
	fixedMonthlyFee: number;
	fixedYearlyFee: number;
	percentage: number;
	amount: number;
	subtractEntity: number;
	entityType: string;
	id?: number;
};

type YtdElement = {
	id: number;
	baseId: string;
	value: number;
	description: string;
};
type BookedData = {
	id: number;
	created: string;
	employee: Employee;
	deliveryData: DeliveryData;
	deliverySetting: DeliverySetting;
	paySlips: Payslip[];
	salaryProcessReport: SalaryProcessReport;
	acChart: AccoutChart[];
	salaryProcessDatas: [];
	salaryProcessErrors: [];
	isNoSend: boolean;
	isProcessed: string;
	modified: string;
	periodEnds: string;
	periodStarts: string;
	processDateTime: string;
	salaryPayDay: string;
	saved: string;
	skilagreinLogs: any;
	sendSchedulerBank: boolean;
	sendSchedulerLif: boolean;
	sendSchedulerPayslips: boolean;
	sendSchedulerRsk: boolean;
	settingsLifSendTime: string;
	settingsLifSent: boolean;
	settingsPaymentSendTime: string;
	settingsPaymentSent: false;
	settingsPayslipEmailSendTime: string;
	settingsPayslipSendTime: string;
	settingsPayslipSendToEmail: boolean;
	settingsPayslipSendToOnlineBank: boolean;
	settingsPayslipsEmailSent: boolean;
	settingsPayslipsSent: boolean;
	settingsRskSendTime: string;
	settingsRskSent: boolean;
	skilagreinRskJson: string;
	skilagreinarLifStett: string;
	skilagreinarLifStettUrls: string;
	yearMonth: string;
};
type SalaryProcessSummary = {
	Description: string;
	ID: number;
	Items: SalaryProcessSummaryItem[];
};
type SalaryProcessSummaryItem = {
	Description: string;
	IsAmount: boolean;
	Name: string;
	Val: number;
	Weight: number;
};
type DeliverySetting = {
	lifstettSent: string;
	medlagSent: string;
	paymentsSent: string;
	payslipsBankSent: string;
	payslipsEmailSent: string;
	rskSent: string;
	sendLifStett: string;
	sendMedlag: string;
	sendPayments: string;
	sendPayslipsBank: string;
	sendPayslipsEmail: string;
	sendRsk: string;
};
type DeliveryData = {
	csupport: string;
	lifStett: string;
	paymentRequest: string;
	payslipBank: string;
	payslipEmail: string;
	rsk: string;
};

type SalaryProcessReport = {
	payslipReports: PayslipReports;
	reportItems: ReportItems;
};
type PayslipReports = {
	employe: Employee;
};
type ReportItems = {
	amount: number;
	description: string;
};
type AccoutChart = {
	amount: string;
	creditkey: string;
	debitkey: string;
	description: string;
};

export interface SimpleSalaryElementProps {
	id: number;
	description: string;
	units: number;
	value: number | null;
	canEdit: boolean;
}

export interface SimpleSalaryElementWithRateProps
	extends SimpleSalaryElementProps {
	rate: number;
}

/// The state of a salary element in the payslip editor
export enum SalaryElementEditState {
	NEW,
	EDITED,
	DELETED,
	UNCHANGED,
}
export interface SimpleSalaryElementPropsExt extends SimpleSalaryElementProps {
	rate: number;
	originalRate: number;
	originalDescription: string;
	originalUnits: number;
	originalValue: number | null;
	editState: SalaryElementEditState;
	employeeId: number;
	payslipGuid?: string;
}

type EmployeePayslip = {
	employeeEmail: string;
	employeeName: string;
	guid: string;
	id: number;
	periodFrom: string;
	periodTo: string;
	salaryToPay: number;
	salaryTotal: number;
	yearMonth: string;
};

export type {
	Payslip,
	Employee,
	PaySlipSalaryElement,
	YtdElement,
	TaxDeduction,
	TaxDeductionStepElement,
	PensionFund,
	BankAccount,
	UnionFee,
	HolidayPeriod,
	HolidaySettings,
	BookedData,
	DeliverySetting,
	SalaryProcessReport,
	DeliveryData,
	SalaryProcessSummary,
	SalaryProcessSummaryItem,
	AccoutChart,
	EmployeePayslip,
};
