import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	getNotifications,
	notificationRead,
	notificationRemoved,
} from "../../data/notifications/notificationsSlice";
import {
	Accordion,
	Center,
	Grid,
	Group,
	Space,
	Text,
	Title,
	createStyles,
} from "@mantine/core";
import { is } from "date-fns/locale";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Mail, MailOpened } from "tabler-icons-react";
import { formatDateTimeStringWithMonthName } from "../../helpers/functions";
import { setDefaultOptions } from "date-fns";
import { NoData } from "../../components/ui/common/sharedComponents";
import axios from "axios";
import { Heartbeat } from "../../data/types/common/heartbeat";
import { DeleteButton } from "../../components/ui/buttons";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";

interface NotificationData {
	id: number;
	isRead: boolean;
	created: string;
	title: string;
	message: string;
}

export const useStyles = createStyles((theme) => ({
	message: {
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		marginLeft: 40,
		padding: 10,
		borderRadius: 8,
	},
}));

function Notifications() {
	const dispatch = useAppDispatch();
	const notifications = useAppSelector(getNotifications);
	// const [notifications, setNotifications] = useState(notificationsFromStore);
	const [unreadCount, setUnReadCount] = useState(0);
	const { classes } = useStyles();
	const { t } = useTranslation();
	const modals = useModals();
	setDefaultOptions({ locale: is });

	useEffect(() => {
		setUnReadCount(
			notifications.data.filter((n: NotificationData) => !n.isRead).length
		);
	}, [notifications.data]);

	function NotificationLabel({ title, datetime, isRead }: any) {
		return (
			<Group noWrap>
				{isRead ? (
					<MailOpened style={{ marginTop: -28 }} color="green" />
				) : (
					<Mail style={{ marginTop: -22 }} color="orange" />
				)}
				<div>
					<Text color={isRead ? "white" : "orange"}>{title}</Text>
					<Text size="sm" color="dimmed" weight={400}>
						{datetime}
					</Text>
				</div>
			</Group>
		);
	}

	function notificationClicked(notification: NotificationData) {
		if (!notification.isRead) {
			const heartbeat: Heartbeat = {
				userId: 0,
				activity: "readmessage:" + notification.id,
				initiatedBy: "user",
				sessionId: "",
			};
			//Mark the cached notification as read
			dispatch<any>(notificationRead(notification.id));
			// We are posting to the API to mark the notification as read.
			// We are not showing anything to the user on success or failure since it's not
			// extremely important to let the user know if marking a notification read failed or succeeded.
			// If there is an error then the backend exception logger (Exceptionless) should catch it and then handled by fixing issue in API.
			axios
				.post(`${process.env.REACT_APP_API_URL}/api/heartbeat`, heartbeat)
				.catch((error) => {
					console.log(error);
				});
		}
	}

	const deleteNotification = (id: number) => {
		const heartbeat: Heartbeat = {
			userId: 0,
			activity: "deletemessage:" + id,
			initiatedBy: "user",
			sessionId: "",
		};
		//Remove the notification from redux cache
		dispatch<any>(notificationRemoved(id));
		//Post to API
		axios
			.post(`${process.env.REACT_APP_API_URL}/api/heartbeat`, heartbeat)
			.catch((error) => {
				console.log(error);
				showNotification({
					title: "Error",
					message: "Villa kom upp við að eyða tilkynningu",
					color: "red",
					icon: <IconExclamationMark size={16} />,
				});
			});
	};

	const openConfirmDeleteModal = (id: number) =>
		modals.openConfirmModal({
			title: t("common.confirm"),
			children: (
				<Text size="sm" style={{ textAlign: "center" }}>
					{t("notifications.confirm_delete")}
				</Text>
			),
			labels: {
				confirm: t("notifications.confirm_delete_yes"),
				cancel: t("notifications.confirm_delete_no"),
			},
			confirmProps: { color: "red" },
			onConfirm: () => deleteNotification(id),
		});

	return (
		<>
			<Title order={1}>{t("notifications.page_title")}</Title>
			<Text size="sm">
				{unreadCount > 0
					? t("notifications.page_subheader_unread", {
							unreadCount: unreadCount,
							totalCount: notifications.data.length,
					  })
					: t("notifications.page_subheader_allread", {
							totalCount: notifications.data.length,
					  })}
			</Text>
			<Space h="md" />
			{notifications.error ? (
				<div>error occurred</div>
			) : !notifications.isLoading ? (
				notifications.data.length < 1 ? (
					<NoData visible message={t("notifications.no_notifications")} />
				) : (
					<>
						<Accordion iconPosition="right">
							{notifications.data.map(
								(notification: NotificationData) =>
									notification && (
										<Accordion.Item
											onClick={() => notificationClicked(notification)}
											label={
												<NotificationLabel
													title={notification.title}
													datetime={formatDateTimeStringWithMonthName(
														notification.created
													)}
													isRead={notification.isRead}
												/>
											}
											key={notification.id}>
											<Grid columns={12}>
												<Grid.Col span={10}>
													<Text size="sm" className={classes.message}>
														{notification.message}
													</Text>
												</Grid.Col>
												<Grid.Col span={2} style={{ marginTop: 2 }}>
													<DeleteButton
														onClick={() =>
															openConfirmDeleteModal(notification.id)
														}
														text={t("common.delete")}
														fullWidth
													/>
												</Grid.Col>
											</Grid>
										</Accordion.Item>
									)
							)}
						</Accordion>
					</>
				)
			) : (
				// TODO: convert this to a component
				<Center>
					<div>{t("common.fetching_notifications")}</div>
				</Center>
			)}
		</>
	);
}

export default Notifications;
