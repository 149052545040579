import React from "react";

import { Header, MediaQuery, Burger, Box } from "@mantine/core";
import logo from "../../assets/logo-white.png";
import { Link } from "react-router-dom";
import CompanySelector from "./companySelector";
import UserMenu from "./userMenu";

interface Props {
	setOpened: (val: boolean) => void;
	opened: boolean;
}

export function Topbar({ setOpened, opened }: Props) {
	//const [burger, setBurger] = useState(opened);
	return (
		<Header height={70} p="md">
			<div style={{ display: "flex", alignItems: "center", height: "100%" }}>
				<MediaQuery largerThan="sm" styles={{ display: "none" }}>
					<Burger
						opened={opened}
						onClick={() => setOpened(!opened)}
						size="sm"
						mr="xl"
					/>
				</MediaQuery>

				<MediaQuery largerThan="sm" styles={{ marginLeft: "80px" }}>
					<Link to="/app">
						<img src={logo} alt="Launa" style={{ maxWidth: "150px" }} />
					</Link>
				</MediaQuery>
				<Box sx={{ textAlign: "left", width: "300px", paddingTop: "8px" }}>
					<CompanySelector />
				</Box>
				<Box sx={{ textAlign: "right", width: "100%", paddingTop: "8px" }}>
					<UserMenu />
				</Box>
			</div>
		</Header>
	);
}
