import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SimpleGrid, Title, LoadingOverlay, Center } from "@mantine/core";
import { TabView } from "../../components/ui/common/tabView";
import PayslipsProcessing from "./payrollprocessing";
import ReportsData from "./reportsData";
import Payslips from "./paySlips";
import AllPayslips from "./allPayslips";
import {
	fetchSalaryProcessSinglEmpData,
	selectSalaryProcessSingleEmpData,
} from "../../data/salaryIncrease/salaryprocessSingleEmpData";
import { CardView } from "../../components/ui/common/sharedComponents";
import AccountingEntrie from "./accountingEntries";
export default function SalaryProcessBookedView() {
	const { t } = useTranslation();
	const pathName = window.location.pathname;
	const pathLength = window.location.pathname.split("/").length;
	const curentId = Number(pathName.split("/")[pathLength - 1]);
	const dispatch = useAppDispatch();
	var salaryprocessSingleEmpData = useAppSelector(
		selectSalaryProcessSingleEmpData
	);
	useEffect(() => {
		if (curentId) {
			dispatch(fetchSalaryProcessSinglEmpData(curentId));
		}
	}, [curentId]);
	const tabValue = [
		<PayslipsProcessing
			payrollProcessData={salaryprocessSingleEmpData.employeeData}
		/>,
		<ReportsData payslipData={salaryprocessSingleEmpData.employeeData} />,
		<Payslips
			payslipData={salaryprocessSingleEmpData.employeeData}
			isLoading={salaryprocessSingleEmpData.isLoading}
		/>,
		<AllPayslips payslipData={salaryprocessSingleEmpData.employeeData} />,
		<AccountingEntrie
			accountingData={salaryprocessSingleEmpData.employeeData.acChart}
		/>,
	];
	return (
		<CardView
			value={
				<>
					<LoadingOverlay
						visible={salaryprocessSingleEmpData.isLoading}
						radius={"xl"}
					/>
					{salaryprocessSingleEmpData.error ? (
						<div>
							{t("common.derror_occurred") + salaryprocessSingleEmpData.error}
						</div>
					) : !salaryprocessSingleEmpData.isLoading ? (
						salaryprocessSingleEmpData.employeeData.paySlips?.length < 1 ? (
							<div>{t("common.data_no_found")}</div>
						) : (
							<>
								<SimpleGrid mb={"lg"}>
									<Title order={2}>{t("salary_processe_list.header")}</Title>
								</SimpleGrid>

								<TabView
									tabs={t("salary_process_view.tabs", {
										returnObjects: true,
									})}
									tabsValue={tabValue}
								/>
							</>
						)
					) : (
						<Center>
							<div>{t("common.fetching_payroll")}</div>
						</Center>
					)}
				</>
			}
		/>
	);
}
