import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { pageClient } from "../../site/contentfulClient";

import { RootState } from "../../app/store";

interface ContentEntry {
	article: {
		fields: {
			titill: string;
			slug: string;
			efni: Date;
		};
	};
}

interface ContentState {
	contentEntries: Array<ContentEntry>;
	isLoading: boolean;
	error: boolean;
}

const initialState: ContentState = {
	contentEntries: [] as Array<ContentEntry>,
	isLoading: false,
	error: false,
};

export const contentSlice = createSlice({
	name: "pageentries",
	initialState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
		},
		stopLoading: (state) => {
			state.isLoading = false;
		},
		hasError: (state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		contentEntriesLoaded: (state, action) => {
			state.contentEntries = action.payload;
			state.isLoading = false;
		},
	},
});

export const { startLoading, hasError, contentEntriesLoaded } =
	contentSlice.actions;

export const fetchAllContentEntries = () => (dispatch: Dispatch) => {
	dispatch(startLoading());

	try {
		console.log("gonna get some content");
		pageClient.getEntries({ content_type: "page" }).then((entries) => {
			dispatch(contentEntriesLoaded(entries.items));
		});
	} catch (error: any) {
		console.log(error);
		dispatch(hasError(error));
	}
};

export default contentSlice.reducer;
export const fetchContentEntries = (state: RootState) => state.contentEntries;
