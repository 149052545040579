import React from "react";
import { Payslip, PensionFund } from "../../../../../data/types/payslip";
import PayslipHeader from "../payslipHeader";
import PayslipDeduction from "../payslipDeduction";
import PayslipYtd from "../payslipYtd";
import { BankAccountInfo } from "../../../common/bankAccount";
import { Amount } from "../../../common/currency";
import { getEmployeeUnionFee } from "../../../../../helpers/functions";
import PayslipSalaryelementsReadOnly from "../payslipSalaryElementsReadOnly";
import PayslipHoliday from "../payslipHoliday";

/* This component is used where we only want to render the payslip as html.
The reason why we are not just re-using the PayslipView component is that it
uses the useAppDispatch hook for Root state, and that causes problems 
when rendering the component and extracting it as pure html to post 
to the server when creating html payslips to save to Azure storage.
*/
function PayslipViewReadOnly({
	payslipData,
	payDay,
	logo,
}: {
	payslipData: Payslip | null;
	payDay: string;
	logo: string | null;
}) {
	/* Employees are legally bound to pay into a pension fund so this should never be null
	 But we will check it just to be safe. */
	var pensionFund = payslipData?.pensionFund ? [payslipData.pensionFund] : [];

	//Special pension funds are optional for employees
	var specialPensionFunds: PensionFund[] = payslipData?.specialPensionFund
		? [payslipData?.specialPensionFund]
		: [];

	/* An employee can have multiple special pension funds.
	Because the option of having multiple was added later in the backend
	any special pension funds are stored as array in specialPensionFundsExtra.
	Here we will join them all using the ... spread syntax. */
	if (
		payslipData?.specialPensionFundsExtra &&
		payslipData?.specialPensionFundsExtra.length > 0
	) {
		specialPensionFunds = [
			...specialPensionFunds,
			...payslipData.specialPensionFundsExtra,
		];
	}
	return payslipData === null ? null : (
		<div
			style={{
				maxWidth: "1100px",
				fontFamily: "sans-serif",
				fontSize: "14px",
				padding: "7px 24px 19px",
				margin: "0 auto",
				border: "2px solid #ccc",
				borderRadius: "5px",
				backgroundColor: "#fff",
				color: "#000",
				WebkitPrintColorAdjust: "exact",
			}}>
			<PayslipHeader
				data={{
					logo,
					employee: payslipData.employee,
					periodStart: payslipData.periodStart,
					periodEnd: payslipData.periodEnd,
					payDay,
					salaryTotal: payslipData.salaryTotal,
					salaryToPay: payslipData.salaryToPay,
					deductableTotal: payslipData.deductableTotal,
				}}
			/>
			<PayslipSalaryelementsReadOnly
				salaryElements={payslipData.paySlipSalaryElements}
			/>
			<PayslipHoliday payslip={payslipData} />
			<PayslipDeduction
				data={{
					taxDeduction: payslipData.taxDeduction,
					deductableElements: payslipData.paySlipSalaryElements?.filter(
						(el) => el.salaryElementType === 1 //filter out salary elements which are of type: Deductable(enum value 1)
					),
					pensionFund: pensionFund,
					specialPensionFunds: specialPensionFunds,
					union:
						payslipData.union &&
						payslipData.unionFees &&
						payslipData.unionFees.length > 0
							? {
									name: payslipData.union.name,
									fee: getEmployeeUnionFee(payslipData.unionFees),
							  }
							: null,
				}}
			/>
			<div
				style={{
					display: "inline-block",
					width: "85%",
					textAlign: "right",
					marginBottom: "24px",
				}}>
				<strong>Samtals frádráttur:</strong>
			</div>
			<div
				style={{ display: "inline-block", width: "15%", textAlign: "right" }}>
				<strong>
					<Amount value={payslipData.deductableTotal} />
				</strong>
			</div>
			{payslipData.bankAccount && (
				<div style={{ textAlign: "right" }}>
					<strong>
						Laun lögð inn á banka:{" "}
						<BankAccountInfo data={payslipData.bankAccount} />
					</strong>
				</div>
			)}
			<PayslipYtd data={{ ytdElements: payslipData.ytdElements }} />
		</div>
	);
}

export default PayslipViewReadOnly;
