import axios from "axios";
import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface CalculatorDataState {
    pensionFunds: Array<any>, //TODO: type this
    unions: Array<any>, //TODO: type this
    pensionPercentageEmployee: number,
    pensionPercentageEmployer: number,
    periods: Array<String>,
    isLoading: boolean,
    error: any //TODO: type this
};

const initialState: CalculatorDataState = {
    pensionFunds: [],
    unions: [],
    pensionPercentageEmployee: 0.0,
    pensionPercentageEmployer: 0.0,
    periods: [] as Array<string>,
    isLoading: false,
    error: null
}

export const calculatorData = createSlice({
    name: "calculatordata",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        hasError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        dataLoaded: (state, action) => {
            state.pensionFunds = action.payload.pensionFunds;
            state.unions = action.payload.unions;
            state.pensionPercentageEmployee = action.payload.pensionPercentageEmployee;
            state.pensionPercentageEmployer = action.payload.pensionPercentageEmployer;
            state.periods = action.payload.periods;
            state.isLoading = false;
        }
    }
});

export const { startLoading, hasError, dataLoaded } = calculatorData.actions;

export const getCalculatorData = () => (dispatch: Dispatch) => {
    dispatch(startLoading());
    return axios
        .get(`${process.env.REACT_APP_API_URL}/web/calculator/get/data`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //'apikey': '8AD8C89C-5947-4E5B-8D70-E3CAE917F230', already injected on all axios requests
            },
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(dataLoaded(response.data));
            }
        })
        .catch((error: any) => {
            console.log("error", error);
            dispatch(hasError(error));
        });
};

export default calculatorData.reducer;
export const selectCalculatorData = (state: RootState) => state.calculatorData;