import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
	createStyles,
	Header,
	Container,
	Group,
	Burger,
	Paper,
	Transition,
	Button,
} from "@mantine/core";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";
import { Key, LayoutDashboard } from "tabler-icons-react";
import logo from "../../../assets/logo-white.png";
import { isAuthenticated, isUserBetaPreview } from "../../../helpers/user";

const HEADER_HEIGHT = 90;

const useStyles = createStyles((theme) => ({
	root: {
		position: "fixed",
		transition: "all .4s",
		WebkitTransition: ".4s",
		top: 0,
		right: 0,
		left: 0,
		zIndex: 99,
		background: 0,
		borderBottom: 0,
		paddingTop: 55,
	},
	scrolledHeader: {
		backgroundColor: "#9353a1",
		backgroundImage:
			"linear-gradient(to right,rgba(147,83,161,.8),rgba(42,191,200,.8),rgba(250,165,54,.8),rgba(239,73,112,.8),rgba(238,53,128,.73))",
		boxShadow: "0 6px 10px -4px rgb(0 0 0 / 15%)",
		paddingTop: 5,
	},
	dropdown: {
		position: "absolute",
		top: HEADER_HEIGHT,
		left: 0,
		right: 0,
		zIndex: 0,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
		overflow: "hidden",

		[theme.fn.largerThan("sm")]: {
			display: "none",
		},
	},

	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: "100%",
		maxWidth: 1200,
	},

	links: {
		[theme.fn.smallerThan("sm")]: {
			display: "none",
		},
	},

	burger: {
		[theme.fn.largerThan("sm")]: {
			display: "none",
		},
	},

	link: {
		fontFamily: "Montserrat,Helvetica,Arial,sans-serif!important",
		display: "block",
		lineHeight: 1,
		padding: "8px 12px",
		textDecoration: "none",
		color: "#FFFFFF",
		fontSize: theme.fontSizes.lg,
		textTransform: "uppercase",
		fontWeight: 600,

		[theme.fn.smallerThan("sm")]: {
			borderRadius: 0,
			padding: theme.spacing.md,
		},
	},

	linkActive: {
		"&, &:hover": {
			borderBottom: "1px solid #fff",
		},
	},
}));

function useNav() {
	const navigate = useNavigate();
	const navigateRef = useRef({ navigate });
	useEffect(() => {
		navigateRef.current.navigate = navigate;
	}, [navigate]);
	return useCallback((location: string) => {
		navigateRef.current.navigate(location);
	}, []);
}

export function HeaderResponsive() {
	const navigate = useNav();

	const [opened, { toggle }] = useDisclosure(false);
	const { classes } = useStyles();
	const [scroll] = useWindowScroll();
	const [rootClass, setRootClass] = useState(classes.root);
	const betaPreview: boolean = isUserBetaPreview();
	const legacyUrls = {
		login: process.env.REACT_APP_LEGACY_URL + "/dashboard",
	};

	useEffect(() => {
		if (scroll.y > 399) {
			setRootClass(classes.root + " " + classes.scrolledHeader);
		} else {
			setRootClass(classes.root);
		}
	}, [scroll, classes.root, classes.scrolledHeader]);

	return (
		<Header height={HEADER_HEIGHT} mb={120} className={rootClass}>
			<Container className={classes.header}>
				<img
					alt=""
					onClick={() => navigate("/")}
					src={logo}
					style={{ maxWidth: "120px", cursor: "pointer" }}
				/>
				<Group spacing={5} className={classes.links}>
					{betaPreview ? (
						isAuthenticated() ? (
							<Button
								key="loginpage"
								radius={8}
								component="a"
								rel="noopener noreferrer"
								href="/app"
								onClick={(event: any) => {
									event.preventDefault();
									navigate("/app");
								}}
								leftIcon={<LayoutDashboard size={18} />}
								className={classes.link}
								styles={(theme) => ({
									root: {
										border: 0,
										height: 42,
										paddingLeft: 20,
										paddingRight: 20,
									},
									leftIcon: {
										marginRight: 15,
									},
								})}>
								Stjórnborð
							</Button>
						) : (
							<Button
								key="loginpage"
								radius={8}
								component="a"
								rel="noopener noreferrer"
								href="/login"
								onClick={(event: any) => {
									event.preventDefault();
									navigate("/login");
								}}
								leftIcon={<Key size={18} />}
								className={classes.link}
								styles={(theme) => ({
									root: {
										border: 0,
										height: 42,
										paddingLeft: 20,
										paddingRight: 20,
									},
									leftIcon: {
										marginRight: 15,
									},
								})}>
								Innskráning
							</Button>
						)
					) : (
						<Button
							key="loginpage-legacy"
							radius={8}
							component="a"
							rel="noopener noreferrer"
							href={legacyUrls.login}
							// onClick={(event: any) => {
							// 	event.preventDefault();
							// 	navigate("login");
							// }}
							leftIcon={<Key size={18} />}
							className={classes.link}
							styles={(theme) => ({
								root: {
									border: 0,
									height: 42,
									paddingLeft: 20,
									paddingRight: 20,
								},
								leftIcon: {
									marginRight: 15,
								},
							})}>
							Innskráning
						</Button>
					)}
				</Group>

				<Burger
					opened={opened}
					onClick={toggle}
					className={classes.burger}
					size="md"
				/>

				<Transition transition="pop-top-right" duration={200} mounted={opened}>
					{(styles) => (
						<Paper className={classes.dropdown} withBorder style={styles}>
							{/* {items} */}
							<Button
								key="loginpage"
								radius={8}
								component="a"
								rel="noopener noreferrer"
								href="/login"
								onClick={(event: any) => {
									event.preventDefault();
									navigate("/login");
								}}
								leftIcon={<Key size={18} />}
								className={classes.link}
								styles={(theme) => ({
									root: {
										border: 0,
										height: 42,
										paddingLeft: 20,
										paddingRight: 20,
									},
									leftIcon: {
										marginRight: 15,
									},
								})}>
								Innskráning
							</Button>
						</Paper>
					)}
				</Transition>
			</Container>
		</Header>
	);
}
