import React, { useState, useEffect } from "react";
import { Group, TypographyStylesProvider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CardView } from "../../components/ui/common/sharedComponents";
import { ActionButton } from "../../components/ui/buttons";
import { BookedData, Payslip } from "../../data/types/payslip";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./allpayslips.css";
import {
	allPayslipsData,
	fetchAllPayslipsData,
} from "../../data/payslips/displayAllPayslips";
import axios from "axios";
import { format } from "date-fns";
import exportFromJSON from "export-from-json";
import { getCurrentCompanyId } from "../../helpers/user";
export default function AllPayslips({
	payslipData,
}: {
	payslipData: BookedData;
}) {
	const { t } = useTranslation();
	const [allPayslips, setAllPayslips] = useState<Payslip[]>();
	const [loading, setLoading] = useState(false);
	const pathName = window.location.pathname;
	const pathLength = window.location.pathname.split("/").length;
	const curentId = Number(pathName.split("/")[pathLength - 1]);
	const companyId = getCurrentCompanyId();
	var displasyallPayslipsData = useAppSelector(allPayslipsData);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (allPayslips) {
			dispatch(fetchAllPayslipsData(allPayslips));
		}
	}, [allPayslips]);
	const exportTxtData = (exportData: any, file_name: string) => {
		const data = exportData;
		const fileName = `${file_name}${format(
			new Date(),
			"MMMM-dd-yyyy hh:mm:ss:mmm aaa"
		)}`;
		const exportType = exportFromJSON.types.txt;
		return exportFromJSON({ data, fileName, exportType });
	};
	const downloadPaymnetRegister = () => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/payslip/get/combined/pdf/${companyId}/${curentId}`,
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					exportTxtData(
						response.data,
						`${t("common.payment_register")}_si080${curentId}`
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	return (
		<CardView
			value={
				<>
					<Group spacing={"xl"} position={"apart"} mb={"lg"}>
						<ActionButton
							type="button"
							loading={displasyallPayslipsData.isLoading}
							onClick={(e) => {
								setAllPayslips(payslipData.paySlips);
							}}
						>
							{t("salary_process_view.allpayslip_button")}
						</ActionButton>
						<ActionButton
							onClick={(e) => {
								downloadPaymnetRegister();
							}}
							loading={loading}
						>
							{t("salary_process_view.allpayslip_download_button")}
						</ActionButton>
					</Group>

					<CardView
						value={
							<>
								<TypographyStylesProvider>
									<div
										dangerouslySetInnerHTML={{
											__html: displasyallPayslipsData.data,
										}}
									></div>
								</TypographyStylesProvider>
							</>
						}
					/>
				</>
			}
		/>
	);
}
