import React from "react";
import { createStyles, Title, Text, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Robot } from "tabler-icons-react";
import { t } from "react-i18nify";

const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	label: {
		textAlign: "center",
		fontWeight: 900,
		fontSize: 220,
		lineHeight: 1,
		marginBottom: theme.spacing.xl * 1.5,
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[4]
				: theme.colors.gray[2],

		[theme.fn.smallerThan("sm")]: {
			fontSize: 120,
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: "center",
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan("sm")]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 500,
		margin: "auto",
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl * 1.5,
	},
}));

/*
This is the error component that is shown when an error is caught in the outermost (App.tsx) ErrorBoundary component.
*/
export default function GlobalError(props: { message?: string }) {
	const { classes } = useStyles();

	return (
		<Container className={classes.root}>
			<div className={classes.label}>
				<Robot size={360} />
			</div>
			<Title className={classes.title}>{t("global_errors.title")}</Title>
			<Text
				color="dimmed"
				size="lg"
				align="center"
				className={classes.description}>
				{props.message && (
					<Text size="lg" weight={900}>
						{props.message}
					</Text>
				)}
				<Text>{t("global_errors.bug_logged")}</Text>
				<Text>{t("global_errors.try_again_later")}</Text>
			</Text>
		</Container>
	);
}
