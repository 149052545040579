import { hubConnection } from 'signalr-no-jquery';
import { Connection } from 'signalr-no-jquery';
import axios from 'axios';
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { notificationReceived } from '../data/notifications/notificationsSlice';

const announceId = (id: string) => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/user/setsignalrconnid/${id}`, null, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then(function () {
            if (process.env.NODE_ENV !== 'production')
                console.log("signaR connection id set:", id);
        })
        .catch((error) => {
            console.log("error setting signalR ID", error);
        })
};

const startSignalRConnection = (connection: Connection) => connection.start({ jsonp: true })
    .done(function () {
        announceId(connection.id);
    })
    .fail(function () {
        console.log('Could not establish signalR connection');
    });

function initializeSignalr(dispatch: Dispatch<AnyAction>) {
    const connection = hubConnection(`${process.env.REACT_APP_API_URL}`);
    const hubProxy = connection.createHubProxy('NotificationHub');

    hubProxy.on('delivernotification', function (notification: string) {
        dispatch<any>(notificationReceived(notification));
    });

    // connect
    startSignalRConnection(connection);

    // re-establish the connection if connection dropped
    hubProxy.on("ondisconnect", function () {
        setTimeout(startSignalRConnection(connection), 5000)
    });
};

export default initializeSignalr;