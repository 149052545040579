import React, { useEffect, useRef, useState } from "react";
import PublicPagesWrapper from "../../../components/ui/publicpages/PublicPagesWrapper";
import { Center, Stack, Space, Button, createStyles } from "@mantine/core";
import { HomeHero } from "./homeHero";
import { FeaturesGrid } from "./homeSections/features";
// import { PublicCalculator } from "./homeSections/calculator";
import { useWindowScroll } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
	getSubscriptionData,
	selectSubscriptionData,
} from "../../../data/public/launa.service.subscriptiondata";
import { IconArrowDown } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
	centerHomerHero: { width: "100%", height: "calc(50vh)", color: "#ffffff" },
	subHeader: {
		position: "sticky",
		top: 90,
		padding: 24,
		maxWidth: 1200,
		margin: "0 auto",
		zIndex: 1,
	},
	scrolledSubHeader: {
		backgroundColor: "#9353a1",
		backgroundImage:
			"linear-gradient(to right,rgba(147,83,161,.8),rgba(42,191,200,.8),rgba(250,165,54,.8),rgba(239,73,112,.8),rgba(238,53,128,.73))",
		boxShadow: "0 6px 10px -4px rgb(0 0 0 / 15%)",
	},
	subSectionWrapper: {
		paddingTop: 180,
	},
}));

function HomePage() {
	const { classes, cx } = useStyles();
	const [scroll] = useWindowScroll();
	const [subHeaderClass, setSubHeaderClass] = useState(classes.subHeader);
	const [activeSubSection, setActiveSubsection] = useState("");
	const subscriptionData = useSelector(selectSubscriptionData);
	const [subscriptionDataLoaded, setSubscriptionDataLoaded] = useState(false);
	const [subscriptionTable, setSubscriptionTable] = useState([] as any);
	const dispatch = useDispatch();

	const refSubSection1 = useRef<null | HTMLDivElement>(null);
	const refSubSection3 = useRef<null | HTMLDivElement>(null);

	function handleSubSectionClick(target: string) {
		setActiveSubsection(target);
		switch (target) {
			case "launa":
				refSubSection1?.current?.scrollIntoView({ behavior: "smooth" });
				break;
			case "reiknivelin":
				refSubSection3?.current?.scrollIntoView({ behavior: "smooth" });
				break;
		}
	}

	function setButtonVariant(target: string) {
		if (scroll.y < 580 || activeSubSection === "") return "white";

		if (activeSubSection === target) return undefined;
		else return "white";
	}

	useEffect(() => {
		if (scroll.y > 580) {
			setSubHeaderClass(classes.subHeader + " " + classes.scrolledSubHeader);
		} else {
			setSubHeaderClass(classes.subHeader);
		}
	}, [scroll, classes.subHeader, classes.scrolledSubHeader]);

	useEffect(() => {
		if (!subscriptionDataLoaded && !subscriptionData.isLoading) {
			dispatch<any>(getSubscriptionData());
			setSubscriptionDataLoaded(true);
		}
	}, [dispatch, subscriptionDataLoaded, subscriptionData]);

	useEffect(() => {
		if (!subscriptionData.isLoading && subscriptionData.tabledata.length > 0) {
			setSubscriptionTable(subscriptionData.tabledata);
		}
	}, [subscriptionData, subscriptionTable]);

	return (
		<PublicPagesWrapper title="Ástæðulaust að flækja hlutina | launa">
			<Center className={cx(classes.centerHomerHero)}>
				<HomeHero />
			</Center>
			<div className={cx(subHeaderClass)}>
				<Center>
					<Button
						variant={setButtonVariant("launa")}
						onClick={() => handleSubSectionClick("launa")}
						radius={8}
						rightIcon={<IconArrowDown />}>
						nánar um launa kerfið
					</Button>
					<Space w="md" />
				</Center>
			</div>
			<Stack>
				<Space h="md" />
				<div ref={refSubSection1} className={classes.subSectionWrapper}>
					<>
						<FeaturesGrid
							title=""
							description=""
							subscriptionData={subscriptionTable}
						/>
					</>
				</div>
				<Space h="md" />
			</Stack>
		</PublicPagesWrapper>
	);
}

export default HomePage;
