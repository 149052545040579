import React from "react";
import { Amount } from "../../common/currency";

function PayslipSimpleRow(props: {
	id: number;
	description: string;
	value: number | null;
	boldDescription?: boolean;
}) {
	return (
		<div key={props.id} style={{ marginTop: "3px" }}>
			<div
				style={{
					display: "inline-block",
					width: "50%",
					fontWeight: props.boldDescription ? "bold" : "normal",
				}}>
				{props.description}
			</div>
			<div
				style={{
					display: "inline-block",
					width: "50%",
					textAlign: "right",
				}}>
				<Amount value={props.value} />
			</div>
		</div>
	);
}

export default PayslipSimpleRow;
