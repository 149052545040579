import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SimpleGrid, Title, Loader } from "@mantine/core";
import { List, LayoutGrid } from "tabler-icons-react";
import {
	SalaryProcessCardView,
	SalaryProcessTabelView,
} from "./salaryprocessTableView";
import {
	fetchSalaryProcessSummaryData,
	selectSalaryProcessSummaryData,
} from "../../../data/salaryIncrease/salaryProcessSummary";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { TabView } from "../../ui/common/tabView";
import { CardView } from "../../ui/common/sharedComponents";
export default function DisplaySalaryProcessSummary({
	selectedId,
}: {
	selectedId: number;
}) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	var salaprocessSummarydata = useAppSelector(selectSalaryProcessSummaryData);
	useEffect(() => {
		if (selectedId) {
			dispatch(fetchSalaryProcessSummaryData(selectedId));
		}
	}, [selectedId]);
	const tabValue = [
		<SalaryProcessCardView salaprocessSummarydata={salaprocessSummarydata} />,
		<SalaryProcessTabelView salaprocessSummarydata={salaprocessSummarydata} />,
	];
	return (
		<CardView
			value={
				<>
					{salaprocessSummarydata.error ? (
						<div>
							{t("common.derror_occurred") + salaprocessSummarydata.error}
						</div>
					) : !salaprocessSummarydata.isLoading ? (
						salaprocessSummarydata.summaryData && (
							<>
								<SimpleGrid mb={"lg"}>
									<Title order={4}>
										{t("salary_processe_list.salary_summary_header") + "\t"}
										{"#" +
											"\t" +
											salaprocessSummarydata.summaryData.ID +
											"\t|" +
											"\t" +
											salaprocessSummarydata.summaryData.Description}
									</Title>
								</SimpleGrid>

								<TabView
									tabs={[<LayoutGrid />, <List />]}
									tabsValue={tabValue}
								/>
							</>
						)
					) : (
						<Loader size={"md"} />
					)}
				</>
			}
		/>
	);
}
