import React from "react";
import { Tabs, Title } from "@mantine/core";
export function TabView(props: { tabs: any[]; tabsValue: any[] }) {
	return (
		<>
			{Array.isArray(props.tabs) ? (
				<Tabs mb={"lg"}>
					{props.tabs &&
						props.tabs.map((elemet: any, id: number) => (
							<Tabs.Tab key={id} label={<Title order={6}>{elemet}</Title>}>
								{props.tabsValue[id]}
							</Tabs.Tab>
						))}
				</Tabs>
			) : (
				<></>
			)}
		</>
	);
}
