import { format, isValid, parseISO } from "date-fns";
import { UnionFee } from "../data/types/payslip";
import kennitala from "kennitala";

function amountFormatter(amount: number | any) {
	return amount.toLocaleString("en-DE");
}
function formatDateString(date: string) {
	const parsedDate = parseISO(date);
	const isValidDate = isValid(parsedDate);

	return isValidDate ? format(parsedDate, "dd/MM/yyyy") : "";
}
function formatDateTimeString(date: string) {
	const parsedDate = parseISO(date);
	const isValidDate = isValid(parsedDate);

	return isValidDate ? format(parsedDate, "dd/MM/yyyy HH:mm") : "";
}

function formatDateWithMonthName(date: Date) {
	const isValidDate = isValid(date);
	return isValidDate ? format(date, "dd. MMMM yyyy HH:mm") : "";
}

function formatDateTimeStringWithMonthName(date: string) {
	const parsedDate = parseISO(date);
	const isValidDate = isValid(parsedDate);

	return isValidDate ? format(parseISO(date), "dd. MMMM yyyy HH:mm") : "";
}

function formatDateTimeToIsoString(date: Date) {
	const isValidDate = isValid(date);

	return isValidDate ? format(date, "yyyy-MM-dd'T'HH:mm:ss") : "";
}

function getEmployeeUnionFee(unionFees: UnionFee[]) {
	var employeeUnionFee = unionFees.find((uf) => uf.subtractEntity === 0);

	return employeeUnionFee ? employeeUnionFee : null;
}

function formatRegistrationId(registrationId: string) {
	return kennitala.format(registrationId);
}
export {
	amountFormatter,
	formatDateString,
	formatDateTimeString,
	formatDateWithMonthName,
	formatDateTimeStringWithMonthName,
	formatDateTimeToIsoString,
	getEmployeeUnionFee,
	formatRegistrationId,
};
