import React from "react";
import { YtdElement } from "../../../../data/types/payslip";
import { Amount } from "../../common/currency";

type YtdData = {
	ytdElements: YtdElement[];
};

function PayslipYtd(props: { data: YtdData }) {
	return (
		<>
			<div>
				<div
					style={{
						padding: "3px",
						marginTop: "5px",
						backgroundColor: "#f1f1f1",
					}}>
					<b>Frá áramótum</b>
				</div>
				{props.data.ytdElements.map((element: YtdElement) => (
					<div key={element.baseId} style={{ marginTop: "3px" }}>
						<div
							style={{
								display: "inline-block",
								width: "50%",
							}}>
							{element.description}
						</div>
						<div
							style={{
								display: "inline-block",
								width: "50%",
								textAlign: "right",
							}}>
							<Amount value={element.value} />
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default PayslipYtd;
