import React from "react";
import { ChartData } from "../../data/types/companyData";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getChartColors, getChartOptions } from "./chartUtils";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartProps {
	data: ChartData;
	lastfourlabels: string[];
	title?: string;
}

function PiChart(props: ChartProps) {
	const { t } = useTranslation();
	let propsData = props.data;
	let lastfourLabels = props.lastfourlabels;

	let dataset = {
		...propsData.datasets[0],
		backgroundColor: getChartColors(),
	};
	dataset.borderColor = ["#000000"];

	let translatedLabels = propsData.labels.map((label) =>
		t("charts.labels." + label)
	);

	let chartData: ChartData = {
		...propsData,
		datasets: [dataset],
		labels: translatedLabels,
	};
	let sumAmount = dataset.data.reduce((a, b) => a + b, 0);
	let monthTitle =
		lastfourLabels.length > 0 ? lastfourLabels[lastfourLabels.length - 1] : "";

	let tt = {
		usePointStyle: true,
		callbacks: {
			labelPointStyle: function (context: any) {
				return {
					pointStyle: "triangle",
					rotation: 0,
				};
			},
		},
	};

	let baseOptions = getChartOptions(
		(props.title || "Launakostnaður ") + monthTitle,
		sumAmount.toLocaleString("da-DA")
	);
	let basePlugins = baseOptions.plugins;
	let plugins = { ...basePlugins, tooltips: tt };
	let options = {
		...baseOptions,
		plugins: plugins,
	};

	return <Pie height={280} options={options} data={chartData} />;
}

export default PiChart;
