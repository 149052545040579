import React, { MouseEventHandler, useState } from "react";
import { Modal, Center, Button, Group, Menu, Box } from "@mantine/core";
import { List, Pencil, Trash, UserOff, UserPlus } from "tabler-icons-react";
import { t } from "react-i18nify";
import { BasicEmployeeData } from "../../../data/types/employee";

interface Props {
	employee: BasicEmployeeData;
	onEmployeeSelect?: MouseEventHandler;
	deleteEmployee?: (id: number) => void;
	deactivateEmployee?: (id: number) => void;
	activateEmployee?: (id: number) => void;
	showPayslips?: (id: number) => void;
}

export default function EmployeeMenu(props: Props) {
	const [opened, setOpened] = useState(false);
	const [show, setShow] = useState(false);

	const employee = props.employee;

	return (
		<>
			<Box sx={{ textAlign: "right" }}>
				<Menu>
					<Menu.Item
						icon={<Pencil size={16} />}
						onClick={props.onEmployeeSelect}>
						{t("employees.edit")}
					</Menu.Item>
					{!employee.inactive && (
						<Menu.Item
							icon={<UserOff size={16} />}
							onClick={() => setOpened(true)}>
							{t("employees.deactivate")}
						</Menu.Item>
					)}
					{employee.inactive && (
						<Menu.Item
							icon={<UserPlus size={16} />}
							onClick={() => {
								if (props.activateEmployee)
									props.activateEmployee(props.employee.id);
							}}>
							{t("employees.activate")}
						</Menu.Item>
					)}
					{employee.hasPayslips ? (
						<Menu.Item
							icon={<List size={16} />}
							onClick={() => {
								if (props.showPayslips) props.showPayslips(employee.id);
							}}>
							{t("employees.payslips")}
						</Menu.Item>
					) : (
						<Menu.Item
							icon={<Trash color="red" size={16} />}
							onClick={() => setShow(true)}>
							{t("employees.delete")}
						</Menu.Item>
					)}
				</Menu>
			</Box>
			<Modal
				opened={opened}
				onClose={() => {
					setOpened(false);
				}}>
				<Center>{t("employees.are_you_sure")}</Center>
				<Center>{employee.fullName}</Center>
				<Center>{t("employees.employee_moved_to_inactive")}</Center>
				<Group position="center">
					<Button
						onClick={() => {
							setOpened(false);
							if (props.deactivateEmployee)
								props.deactivateEmployee(employee.id);
						}}>
						{t("common.yes")}
					</Button>
					<Button onClick={() => setOpened(false)} color="gray">
						{t("common.no")}
					</Button>
				</Group>
			</Modal>
			<Modal
				opened={show}
				onClose={() => {
					setShow(false);
				}}>
				<Center>{t("employees.are_you_sure_delete")}</Center>
				<Center>{employee.fullName}</Center>
				<Center>{t("employees.not_reversible")}</Center>
				<Group position="center">
					<Button
						onClick={() => {
							setShow(false);
							if (props.deleteEmployee) props.deleteEmployee(employee.id);
						}}
						color="red">
						{t("common.yes")}
					</Button>
					<Button onClick={() => setOpened(false)} color="gray">
						{t("common.no")}
					</Button>
				</Group>
			</Modal>
		</>
	);
}
